import React, { useCallback } from 'react'

import { Table, Typography, Checkbox } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'

import { Swatch } from 'components/Swatch/Swatch'
import { useLocale } from 'stores/UserStore'
import { useSendNotification } from 'utils/Notifications'
import messages from 'services/intl/messageDefinitions'
import { mergePolygonWithExisting, roundCoordinate } from './helpers'

export const MultipleProductsTable = ({
    useEditing,
    polygons,
    useProducts,
    unassignedCount,
    useActiveItem,
    putRequest,
    useDraftItem,
    useUpdateDraftItem,
    mutateProducts = () => { },
}) => {
    const products = useProducts()
    const intl = useIntl()
    const editing = useEditing()
    const activeItem = useActiveItem()
    const locale = useLocale()
    const draftItem = useDraftItem()
    const updateDraftItem = useUpdateDraftItem()
    const sendNotification = useSendNotification()

    const handleToggle = (event, polygonId) => {
        const { checked } = event.target
        updateExistingOrDraftItem(mergePolygonWithExisting(activeItem.polygons, polygonId, checked))
    }

    const itemPutCall = useCallback(
        async (data, locale) => {
            await putRequest(data, locale)
            mutateProducts()
            sendNotification(intl.formatMessage({ ...messages.avalancheForecastSaved }, { name: data.name }))
        },
        [intl, mutateProducts, putRequest, sendNotification]
    )

    const update = useCallback(
        (details) => {
            const data = { ...activeItem }
            for (let d in details) {
                const detail = details[d]
                data[detail.key] = detail.value
            }
            itemPutCall(data, locale)
        },
        [activeItem, itemPutCall, locale]
    )

    const updateExistingOrDraftItem = useCallback(
        (polygons) => {
            if (activeItem && activeItem.id) {
                const updateData = {
                    key: 'polygons',
                    value: polygons,
                }
                update([updateData])
            } else {
                updateDraftItem({
                    ...draftItem,
                    polygons,
                })
            }
        },
        [activeItem, draftItem, update, updateDraftItem]
    )

    const dataSource = polygons.features.map((polygon) => {
        const currentProduct = products.find((product) => {
            return product.polygons.find((id) => {
                return id === polygon.properties.id
            })
        })

        return {
            ...polygon.properties,
            centroidLng: polygon.properties.CENTROID_X || (polygon.properties.centroid && polygon.properties.centroid[0]) || null,
            centroidLat: polygon.properties.CENTROID_Y || (polygon.properties.centroid && polygon.properties.centroid[1]) || null,
            currentProductId: currentProduct?.id,
            currentProductName: currentProduct?.name || intl.formatMessage({ ...messages.polygonA11yTableNoProduct }),
            currentProductColour: currentProduct?.colour,
        }
    })

    const dataSourceUnassigned = dataSource.filter((polygon) => !polygon.currentProductId)
    const dataSourceAssigned = dataSource.filter((polygon) => polygon.currentProductId)

    const columns = [
        {
            title: intl.formatMessage({ ...messages.polygonA11yTableId }),
            dataIndex: 'polygon_number',
            key: 'polygon_number',
            sorter: (a, b) => {
                return a.polygon_number - b.polygon_number
            },
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: intl.formatMessage({ ...messages.polygonA11yTableName }),
            dataIndex: 'polygon_name',
            key: 'polygon_name',
        },
        {
            title: intl.formatMessage({ ...messages.polygonA11yTableMountainRange }),
            dataIndex: 'mountain_range',
            key: 'mountain_range',
            sorter: (a, b) => {
                return a.mountain_range?.localeCompare(b.mountain_range)
            },
            defaultSortOrder: 'ascend',
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: intl.formatMessage({ ...messages.polygonA11yTableCentroidLng }),
            dataIndex: 'centroidLng',
            key: 'centroidLng',
            render: (text) => roundCoordinate(text),
        },
        {
            title: intl.formatMessage({ ...messages.polygonA11yTableCentroidLat }),
            dataIndex: 'centroidLat',
            key: 'centroidLat',
            render: (text) => roundCoordinate(text),
        },
        {
            title: intl.formatMessage({ ...messages.polygonA11yTableCurrentProduct }),
            dataIndex: 'currentProductName',
            key: 'currentProductName',
            sorter: (a, b) => {
                return a.currentProductName.localeCompare(b.currentProductName)
            },
            render: (text, item) => (
                <div>
                    {item.currentProductColour && <Swatch colour={item.currentProductColour} />}
                    {text}
                </div>
            ),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: intl.formatMessage({ ...messages.polygonA11yTableInActiveProduct }),
            render: (_, record) => {
                const checked =
                    activeItem &&
                    activeItem.polygons &&
                    activeItem.polygons.find((polygonId) => polygonId === record.id)
                // We can only toggle if the polygon is assigned to the active product, or the polygon is unassigned
                const disabled = !editing || (record.currentProductId && record.currentProductId !== activeItem?.id)

                return (
                    <Checkbox
                        checked={checked}
                        disabled={disabled}
                        onChange={(event) => handleToggle(event, record.id)}
                    />
                )
            },
        },
    ]

    return (
        <div>
            <div style={styles.text}>
                <Typography.Title level={2}>
                    <FormattedMessage {...messages.polygonTable} />
                </Typography.Title>
                <Typography.Title level={3}>
                    <FormattedMessage
                        id="a11yTableUnassignedPolygons"
                        defaultMessage="Unassigned Polygons ({unassignedCount})"
                        values={{ unassignedCount }}
                    />
                </Typography.Title>
            </div>
            <Table
                dataSource={dataSourceUnassigned}
                columns={columns}
                pagination={false}
                data-test="multipleProductsUnassignedPolygonTable"
            />
            <div style={styles.text}>
                <Typography.Title level={3}>
                    <FormattedMessage id="a11yTableAssignedPolygons" defaultMessage="Assigned Polygons" />
                </Typography.Title>
            </div>
            <Table
                dataSource={dataSourceAssigned}
                columns={columns}
                pagination={false}
                data-test="multipleProductsAssignedPolygonTable"
            />
        </div>
    )
}

const styles = {
    text: {
        margin: 'var(--s1)',
    },
}
