import React, { useEffect, useState } from 'react'

import { Row, Col, Spin, Typography } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { FormattedMessage } from 'react-intl'

import { useDaysInView } from 'stores/ForecastStore'
import { getWindowDimensions } from 'utils/screen'
import messages from 'services/intl/messageDefinitions'

export const DayCardLayout = ({ dayCards, parentColWidth }) => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
    const daysInView = useDaysInView()
    let numberOfVisibleDays = 0

    const loadIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    )

    for (let key in daysInView) {
        if (daysInView[key]) {
            numberOfVisibleDays++
        }
    }

    const isSmallScreen = numberOfVisibleDays > 3 && windowDimensions.width < 1580
    const colDivider = isSmallScreen ? 48 : 24
    const colWidth = parentColWidth ? parentColWidth : colDivider / numberOfVisibleDays
    const cards = dayCards.map((card, i) => {
        if (daysInView[i + 1]) {
            return (
                <Col span={colWidth} key={card.key} style={styles.dayCard}>
                    {card}
                </Col>
            )
        }
    })

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
        <>
            <Row style={styles.container} gutter={8}>
                {dayCards.length ? (
                    cards
                ) : (
                    <div style={styles.spinnerContainer}>
                        <Typography style={styles.loadingText}>
                            <FormattedMessage {...messages.loadingForecast} />
                        </Typography>
                        <Spin style={styles.spinner} indicator={loadIcon} />
                    </div>
                )}
            </Row>
        </>
    )
}

const styles = {
    loadingText: {
        fontSize: '20px',
        fontWeight: '400',
        color: 'var(--grey)',
    },
    spinnerContainer: {
        margin: '200px auto',
    },
    spinner: {
        margin: '15px auto',
        display: 'block',
    },
    container: {
        margin: 'var(--s0)',
        flex: '1',
    },
    dayCard: {
        marginBottom: 'var(--s-2)',
    },
}
