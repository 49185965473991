import { useEffect } from 'react'

import bbox from '@turf/bbox'

import { useMap } from 'components/Map/Map'
import { createHighwayForecastFeatureCollection } from 'components/Map/mapHelpers'

const source = 'polygons'
const labelsSource = 'labels'

const layers = [
    {
        id: source,
        type: 'fill',
        source,
        paint: {
            'fill-color': ['get', 'colorCode'],
            'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.7, 0.5],
        },
    },
    {
        id: source + '-line',
        source,
        type: 'line',
        paint: {
            'line-color': ['get', 'colorCode'],
            'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 3, 1],
        },
    },
    {
        id: labelsSource + '-path',
        source: labelsSource,
        type: 'symbol',
        layout: {
            'text-field': ['get', 'pathOrder'],
            'text-size': 18,
            'text-allow-overlap': true,
        },
        paint: {
            'text-color': '#000',
            'text-halo-color': '#fff',
            'text-halo-width': 1,
        },
    },
]

const easing = () => 1 // This will ease instantly, since we aren't concerned with animation here

const PADDING = 15 // Padding for the map bounds smaller works better since these are small polygons

export const HighwayPolygons = ({ products, polygons, labelsGeoJson, groupID, onScreenshot }) => {
    // Map params
    const map = useMap()

    // Add sources and layers for polygons
    useEffect(() => {
        const onLoad = () => {
            // Adding the polygon source and layers to the map
            const polygonsGeoJson = createHighwayForecastFeatureCollection(products, polygons, groupID)

            if (!map.getSource(source)) {
                map.addSource(source, { promoteId: 'id', type: 'geojson', data: polygonsGeoJson })
            }
            if (!map.getSource(labelsSource)) {
                map.addSource(labelsSource, { promoteId: 'id', type: 'geojson', data: labelsGeoJson })
            }

            for (const layer of layers) {
                if (!map.getLayer(layer.id)) {
                    map.addLayer(layer)
                }
            }

            // Zoom to the bounds of the polygons, either all of them, or filtered by groupID
            if (polygonsGeoJson.features.length > 0) {
                const bounds = bbox(polygonsGeoJson)
                map.fitBounds(bounds, { padding: PADDING, easing })
            }

            // Listen for the 'idle' event to ensure the map is fully rendered
            map.once('idle', () => {
                const screenshot = map.getCanvas().toDataURL()
                onScreenshot(groupID, screenshot)

                // Clean up the map and container
                map.remove()
            })
        }

        if (map.isStyleLoaded()) {
            onLoad()
        } else {
            map.on('load', onLoad)
        }

        return () => {
            map.off('load', onLoad)
        }
    }, [groupID, labelsGeoJson, map, onScreenshot, polygons, products])

    return null
}
