import { Forecast as ForecastContent } from 'components/ContentSection/Forecast'

import { MENU_ITEMS, progressToPercent } from 'utils/HighwayContent'
import { fetchHighwayForecast, putHighwayForecast } from 'services/HighwayForecasts'

export const HwyFxContent = () => {
    return (
        <ForecastContent
            progressToPercent={progressToPercent}
            menuItems={MENU_ITEMS}
            fetchForecast={fetchHighwayForecast}
            putForecast={putHighwayForecast}
        />
    )
}
