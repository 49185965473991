import { useEffect } from 'react'

import { useThemeSwitcher } from 'react-css-theme-switcher'

import { useMap } from 'components/Map/Map'
import { useSelectedPolygonId } from 'stores/ArchiveStore'
import {
    darkSchemePolylinesColour,
    darkSchemePolylinesFeatureStateColour,
    lightSchemePolylinesColour,
    lightSchemePolylinesFeatureStateColour,
} from 'components/Map/mapHelpers'

export const polylineSource = 'polylines'

export const PolylinesWithFeatureState = ({ polylines }) => {
    // Map params
    const map = useMap()

    // Secondary data
    const selectedPolygonId = useSelectedPolygonId()
    const { currentTheme } = useThemeSwitcher()
    const polylinesColour = currentTheme === 'light' ? lightSchemePolylinesColour : darkSchemePolylinesColour
    const polylinesFeatureStateColour =
        currentTheme === 'light' ? lightSchemePolylinesFeatureStateColour : darkSchemePolylinesFeatureStateColour

    // Update map layers based on filters
    useEffect(() => {
        if (map.getSource(polylineSource)) {
            map.getSource(polylineSource).setData(polylines)
        }
    }, [map, polylines])

    // Add sources and layers for polylines
    useEffect(() => {
        if (!map.getSource(polylineSource)) {
            map.addSource(polylineSource, { promoteId: 'id', type: 'geojson', data: polylines })
        }
        if (!map.getLayer(polylineSource)) {
            map.addLayer({
                id: polylineSource,
                source: polylineSource,
                type: 'line',
                paint: {
                    'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 18, 15],
                    'line-color': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        polylinesFeatureStateColour,
                        polylinesColour,
                    ],
                    'line-opacity': 0.5,
                },
                layout: {
                    'line-cap': 'round',
                    'line-join': 'round',
                },
            })
        }
    }, [])

    // Feature state
    useEffect(() => {
        if (selectedPolygonId) {
            map.removeFeatureState({ source: polylineSource }) // Clear all other hover states
            map.setFeatureState({ source: polylineSource, id: selectedPolygonId }, { hover: true })
            map.setFeatureState({ source: polylineSource, id: selectedPolygonId + '-line' }, { hover: true })
        } else {
            map.removeFeatureState({ source: polylineSource })
        }
    }, [selectedPolygonId, map])

    return null
}
