import axios from 'axios'
import { Auth0Client } from '@auth0/auth0-spa-js'

import { sendErrorNotification } from 'utils/Notifications'

const authClient = new Auth0Client({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
})

export const APIClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_API_KEY,
    },
})

APIClient.interceptors.request.use(async (request) => {
    let token

    if (window.Cypress) {
        const auth0 = JSON.parse(localStorage.getItem('auth0Cypress'))
        token = auth0.body.access_token
    } else {
        token = await authClient.getTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            domain: process.env.REACT_APP_AUTH0_DOMAIN,
        })
    }

    request.headers['Authorization'] = `Bearer ${token}`

    return request
})

APIClient.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        let res = error.response
        sendErrorNotification(JSON.stringify(res.data))
        console.error('API request error ' + res.status, res.data)
        return res
    }
)
