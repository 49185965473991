import { useState } from 'react'

import { Checkbox, Row, Alert } from 'antd'

import { useContent } from 'stores/ForecastStore'

import './MenuItem.css'
import { FormattedMessage, FormattedList, useIntl } from 'react-intl'
import messages from 'services/intl/messageDefinitions'

export const MenuItem = ({
    label,
    checked,
    selected,
    view,
    clickHandler,
    Badge,
    update,
    disabled,
    sectionsWExceededChars = [],
}) => {
    const intl = useIntl()

    const content = useContent()
    const [showAlert, setShowAlert] = useState(false)
    const className = selected ? 'menu-item-container-active' : 'menu-item-container'

    const handleClick = () => {
        clickHandler(view)
    }

    const updateForecastProgress = () => {
        const progress = [...content.progress].map((element) => {
            return element.element === view ? { ...element, complete: !element.complete } : element
        })
        return { ...content, progress }
    }

    const handleCheckClick = (event) => {
        // stop propagation so the checkbox doesn't trigger the click handler on the menu item
        event.stopPropagation()

        // generate new content with updated progress
        const newAvalancheForecast = updateForecastProgress()
        update(newAvalancheForecast)
    }

    const getContainerStyle = () => {
        if (selected) return styles.activeContainer
        if (disabled) return styles.disabledContainer

        return styles.container
    }

    const showTimedAlert = () => {
        setShowAlert(true)
        setTimeout(() => {
            setShowAlert(false)
        }, 5000)
    }

    const exceededSections = sectionsWExceededChars
        .map((section) => {
            return intl.formatMessage(messages[section])
        })
    const message = exceededSections.length > 1 ? (
        <FormattedMessage
            {...messages.sectionListWithOvercount}
            values={{
                sectionList: <FormattedList type="conjunction" value={exceededSections} />,
            }}
        />
    ) : (
        <FormattedMessage
            {...messages.sectionWithOvercount}
            values={{
                section: exceededSections[0],
            }}
        />
    )

    return (
        <div
            //classname is being used for Cypress testing
            className={className}
            onClick={disabled ? showTimedAlert : handleClick}
            style={getContainerStyle()}
            data-test={`${view}MenuTab`}
        >
            <Checkbox style={styles.checkbox} checked={checked} onClick={handleCheckClick} />

            <Row style={styles.menuAndBadge}>
                <h3 style={styles.label}>{label}</h3>
                {Badge}
            </Row>
            {showAlert && (
                <div style={styles.alertContainer}>
                    <Alert
                        message={message}
                        type="error"
                        showIcon
                        closable
                        onClose={() => setShowAlert(false)}
                    />
                </div>
            )}
        </div>
    )
}

const styles = {
    container: {
        padding: '0.7rem var(--s0) 0.5rem',
        borderTop: '1px solid var(--grey-lighter)',
        borderBottom: '1px solid var(--grey-lighter)',
        cursor: 'pointer',
        backgroundColor: 'var(--background-colour)',
        display: 'flex',
        justifyContent: 'flex-start',
    },
    activeContainer: {
        padding: '0.7rem var(--s0) 0.5rem',
        borderTop: '1px solid var(--grey-lighter)',
        borderBottom: '1px solid var(--grey-lighter)',
        borderRight: '3px solid var(--blue-lt)',
        cursor: 'pointer',
        backgroundColor: 'var(--blue-lighter)',
        display: 'flex',
        justifyContent: 'flex-start',
    },
    disabledContainer: {
        padding: '0.7rem var(--s0) 0.5rem',
        borderTop: '1px solid var(--grey-lighter)',
        borderBottom: '1px solid var(--background-colour)',
        borderRight: '3px solid var(--grey-lighter)',
        cursor: 'pointer',
        background: 'var(--grey-lighter)',
        display: 'flex',
        justifyContent: 'flex-start',
    },
    label: {
        marginLeft: 'var(--s0)',
        fontWeight: '300',
    },
    checkbox: {
        marginTop: 'var(--s-5)',
    },
    menuAndBadge: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    alertContainer: {
        display: 'flex',
        position: 'absolute',
        bottom: '20px',
        left: '20px',
        paddingRight: '20px',
        zIndex: '100',
    },
}
