import { useCallback, useState } from 'react'

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Col, DatePicker, Row, Divider, Space, Typography, Upload } from 'antd'
import moment from 'moment'
import { FormattedMessage, useIntl } from 'react-intl'
import ExifReader from 'exifreader'

import { Select } from 'components/Dropdown/Select'
import { TextField } from 'components/TextField/TextField'
import { TranslatedSimpleTextField } from 'components/TextField/TranslatedSimpleTextField'
import { TranslatedTextField } from 'components/TextField/TranslatedTextField'
import messages from 'services/intl/messageDefinitions'
import { useContent } from 'stores/ForecastStore'
import { MAX_IMAGES } from 'utils/Image'
import { sendErrorNotification, infoNotification } from 'utils/Notifications'
import { SECTIONS } from './NewMedia'
import {
    addTranslationToImageCaption,
    isValidUrl,
    removeTranslationFromImageAltText,
    removeTranslationFromImageCaption,
    supportedUrl,
    updateImageCaption,
    updateImageField,
} from './helpers'

import './ImageForm.css'

const VIEW = 'media'

export const ImageForm = ({ image, apiPut }) => {
    const intl = useIntl()
    const product = useContent()

    const [isUploading, setIsUploading] = useState(false)
    const [isUpdatingUrl, setIsUpdatingUrl] = useState(false)
    const [isDateTakenNull, setIsDateTakenNull] = useState(image.dateTaken === null)

    const disabled = !image.url

    const updateProduct = useCallback(
        async (data) => {
            await apiPut(data, VIEW)
        },
        [apiPut]
    )

    // Click handlers
    const handleTagChange = (value) => {
        // HACK: If it's not a string, it's an event and we have to parse the value (accessibility mode)
        if (typeof value !== 'string') {
            value = value.target.value
        }

        const updatedProduct = updateImageField(product, image.id, { tag: value })
        updateProduct(updatedProduct)
    }

    const handleCreditChange = (value) => {
        const updatedProduct = updateImageField(product, image.id, { credit: value })
        updateProduct(updatedProduct)
    }

    const handleCaptionChange = (language, value) => {
        const updatedProduct = updateImageCaption(product, image.id, language, value)
        updateProduct(updatedProduct)
    }

    const handleAddCaptionLanguage = (language) => {
        const updatedProduct = addTranslationToImageCaption(product, image.id, language)
        updateProduct(updatedProduct)
    }

    const handleRemoveCaptionLanguage = (language) => {
        const updatedProduct = removeTranslationFromImageCaption(product, image.id, language)
        updateProduct(updatedProduct)
    }

    const handleAltTextChange = (language, value) => {
        const updatedProduct = updateImageField(product, image.id, { altText: { ...image.altText, [language]: value } })
        updateProduct(updatedProduct)
    }

    const handleAddAltTextLanguage = (language) => {
        const updatedProduct = updateImageField(product, image.id, { altText: { ...image.altText, [language]: '' } })
        updateProduct(updatedProduct)
    }

    const handleRemoveAltTextLanguage = (language) => {
        const updatedProduct = removeTranslationFromImageAltText(product, image.id, language)
        updateProduct(updatedProduct)
    }

    const handleUrlChange = async (value) => {
        setIsUpdatingUrl(true)

        if (!isValidUrl(value)) {
            sendErrorNotification(intl.formatMessage({ ...messages.invalidImageUrl }))
            return
        }
        const dateTaken = await extractEXIFDate(value)

        // Get image metadata via a GET request, via https://support.cloudinary.com/hc/en-us/articles/202521032-How-can-I-get-the-size-or-dimensions-of-an-image-by-its-public-ID#5
        const dimensionsUrl = value.replace('/upload/', '/upload/fl_getinfo/')
        const response = await fetch(dimensionsUrl)
        if (response && response.error) {
            throw new Error(response.error.message)
        }
        const metadata = await response.json()
        const { width, height } = metadata.output

        const updatedProduct = updateImageField(product, image.id, {
            url: value,
            width,
            height,
            supportedUrl: value,
            dateTaken: dateTaken ? dateTaken : image.dateTaken,
        })
        updateProduct(updatedProduct)
        if (dateTaken) {
            setIsDateTakenNull(false)
            infoNotification(
                intl.formatMessage({ ...messages.dateTakenExtracted }, { date: dateTaken.format('YYYY-MM-DD') })
            )
        }

        setIsUpdatingUrl(false)
    }

    const handleDateTakenChange = (value) => {
        if (!value) {
            setIsDateTakenNull(true)
            return
        }

        setIsDateTakenNull(false)
        const updatedProduct = updateImageField(product, image.id, { dateTaken: value })
        updateProduct(updatedProduct)
    }

    // TODO: re-enable me once operational policy confirmed
    // const handleArchivedPhotoChange = (value) => {
    //     const updatedProduct = updateImageField(product, image.id, { isArchived: value })
    //     updateProduct(updatedProduct)
    // }

    // Image uploading
    const uploadImage = async (options) => {
        const { onSuccess, onError, file } = options
        const data = new FormData()
        const dateTaken = await extractEXIFDate(file)

        data.append('file', file)
        data.append('upload_preset', process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET)
        data.append('cloud_name', process.env.REACT_APP_CLOUDINARY_NAME)

        fetch(process.env.REACT_APP_CLOUDINARY_BASE_URL, {
            method: 'post',
            body: data,
        })
            .then((response) => {
                onSuccess(file)
                return response.json()
            })
            .then((response) => {
                // Get url from Cloudinary response
                const { url, width, height } = response
                // TODO: error handling not working as POST errors are getting intercepted
                if (response && response.error) {
                    throw new Error(response.error.message)
                }
                const updatedProduct = updateImageField(product, image.id, {
                    url,
                    supportedUrl: supportedUrl(url),
                    width,
                    height,
                    dateTaken: dateTaken ? dateTaken : image.dateTaken,
                })
                updateProduct(updatedProduct)
                if (dateTaken) {
                    setIsDateTakenNull(false)
                    infoNotification(
                        intl.formatMessage({ ...messages.dateTakenExtracted }, { date: dateTaken.format('YYYY-MM-DD') })
                    )
                }
            })
            .catch((err) => {
                if (
                    err &&
                    err.message &&
                    (err.message === 'Invalid image file' || err.message === 'Resource is invalid')
                ) {
                    sendErrorNotification(intl.formatMessage({ ...messages.invalidImageFile }))
                }
                sendErrorNotification(intl.formatMessage({ ...messages.imageUploadFailed }))
                onError(err)
            })
    }

    const handleImageUploadChange = (info) => {
        if (info.file.status === 'uploading') {
            setIsUploading(true)
            return
        }

        if (info.file.status === 'done') {
            setIsUploading(false)
        }
    }

    // Misc. components
    const uploadButton = (
        <div data-test={'imageUpload'} style={styles.upLoadSpace}>
            {isUploading || isUpdatingUrl ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={styles.uploadMessage}>
                <FormattedMessage {...messages.upload} />
            </div>
        </div>
    )

    return (
        <form>
            <Row gutter={16}>
                <Col md={12} lg={8}>
                    <Space direction="vertical" style={styles.flex}>
                        <Upload
                            listType="picture-card"
                            showUploadList={false}
                            customRequest={uploadImage}
                            onChange={handleImageUploadChange}
                        >
                            {(image.supportedUrl || image.url) && !isUpdatingUrl ? (
                                <img src={image.supportedUrl || image.url} alt="thumbnail" style={styles.fullImage} />
                            ) : (
                                uploadButton
                            )}
                        </Upload>
                        <TextField
                            value={image.url}
                            onChange={(value) => handleUrlChange(value)}
                            dataTest={'imageURL'}
                            placeholder={intl.formatMessage({ ...messages.cloudinaryUrl })}
                            ariaLabel={intl.formatMessage({ ...messages.cloudinaryUrlLabel })}
                        />
                    </Space>
                </Col>
                <Col md={12} lg={16}>
                    {/* TODO: re-enable me once operational policy confirmed */}
                    {/* 
                                <FormattedMessage {...messages.archivedPhoto} />
                                <Switch
                                    disabled={disabled}
                                    checked={image.isArchived}
                                    onChange={(value) => handleArchivedPhotoChange(value)}
                                    data-test={'arhivedPhoto'}
                                    aria-label={intl.formatMessage({ ...messages.archivedPhoto })}
                                />
                    */}
                    <div style={styles.flex}>
                        <div style={styles.flexChild}>
                            <Typography.Title level={5}>
                                * <FormattedMessage {...messages.dateTaken} />
                            </Typography.Title>
                            <DatePicker
                                disabled={disabled || image.isArchived}
                                value={isDateTakenNull ? null : moment(image.dateTaken)}
                                onChange={(value) => handleDateTakenChange(value)}
                                data-test={'imageDateTaken'}
                                aria-label={intl.formatMessage({ ...messages.dateTaken })}
                            />
                            {isDateTakenNull && (
                                <span style={styles.errorHelperText}>
                                    <FormattedMessage {...messages.dateTakenError} />
                                </span>
                            )}
                        </div>
                        <div style={styles.flexChild}>
                            <Typography.Title level={5}>
                                <FormattedMessage {...messages.credit} />
                            </Typography.Title>
                            <TextField
                                disabled={disabled}
                                value={image.credit}
                                onChange={handleCreditChange}
                                dataTest={'imageCredit'}
                                ariaLabel={intl.formatMessage({ ...messages.credit })}
                                style={styles.noMargin}
                            />
                        </div>
                        <div style={styles.flexChild}>
                            <Typography.Title level={5}>
                                <FormattedMessage {...messages.tagSection} />
                            </Typography.Title>
                            <Select
                                disabled={disabled}
                                value={image.tag}
                                onChange={handleTagChange}
                                dataTest={'imageTag'}
                                style={styles.fullWidth}
                                ariaLabel={intl.formatMessage({ ...messages.tag })}
                            >
                                {SECTIONS.map((section) => {
                                    const sectionImages = product.media.images.filter(
                                        (image) => image.tag === section.tag
                                    )

                                    return (
                                        <Select.Option
                                            key={section.tag}
                                            value={section.tag}
                                            disabled={
                                                section.disabled ||
                                                (sectionImages.length >= MAX_IMAGES && !sectionImages.includes(image))
                                            }
                                        >
                                            <FormattedMessage {...messages[section.translationKey]} />
                                        </Select.Option>
                                    )
                                })}
                            </Select>
                        </div>
                    </div>
                    <Space direction="vertical" style={{ ...styles.marginTop, ...styles.flex }}>
                        <div>
                            <Typography.Title level={5}>
                                * <FormattedMessage {...messages.altText} />
                            </Typography.Title>
                            <TranslatedSimpleTextField
                                handleChange={handleAltTextChange}
                                handleAddTranslation={handleAddAltTextLanguage}
                                handleRemoveTranslation={handleRemoveAltTextLanguage}
                                translations={image.altText}
                                disabled={disabled}
                                partialLabel={intl.formatMessage({ ...messages.altText })}
                                placeholder={intl.formatMessage({ ...messages.altTextFieldHelperText })}
                            />
                        </div>
                        <div style={styles.marginTop}>
                            <Typography.Title level={5}>
                                * <FormattedMessage {...messages.caption} />
                            </Typography.Title>
                            <TranslatedTextField
                                handleChange={handleCaptionChange}
                                handleAddTranslation={handleAddCaptionLanguage}
                                handleRemoveTranslation={handleRemoveCaptionLanguage}
                                translations={image.caption}
                                plainText={false}
                                placeholder={intl.formatMessage({ ...messages.captionTextHelperText })}
                                basic={true}
                            />
                        </div>
                    </Space>
                </Col>
            </Row>
        </form>
    )
}

const styles = {
    upLoadSpace: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    uploadMessage: {
        marginTop: 8,
    },
    fullImage: {
        maxHeight: '500px',
    },
    flex: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: 12,
    },
    flexChild: {
        flex: 1,
    },
    marginTop: {
        marginTop: 'var(--s0)',
    },
    fullWidth: {
        width: '100%',
    },
    errorHelperText: {
        fontSize: '12px',
        color: '#E7494C',
        marginBottom: '-18px',
    },
}

const extractEXIFDate = async (file) => {
    try {
        const tags = await ExifReader.load(file, { async: true })
        const { DateTimeOriginal, OffsetTime } = tags
        const dateTime = DateTimeOriginal ? DateTimeOriginal.description : null
        const offsetTime = OffsetTime ? OffsetTime.description : null
        const dateTakenString = dateTime && offsetTime ? `${dateTime} ${offsetTime}` : null

        return dateTakenString ? moment(dateTakenString, 'YYYY:MM:DD HH:mm:ss ZZ') : null
    } catch (error) {
        console.warn('Error reading exif data', error)
    }

    return null
}
