import React from 'react'

import { FormattedMessage, useIntl } from 'react-intl'
import { Table } from 'antd'

import { PROBLEM_TYPES } from '../../utils/Snowpack/ProblemTypes'
import messages from 'services/intl/messageDefinitions'

export const AvalancheProblemTypeInfoTable = () => {
    const keys = PROBLEM_TYPES
    const intl = useIntl()

    const columns = [
        {
            title: <FormattedMessage {...messages['avalancheProblemTypes.titles.name']} />,
            key: 'name',
            dataIndex: 'name',
            render: (name) => (
                <>
                    <h2>{name}</h2>
                </>
            ),
        },
        {
            title: <FormattedMessage {...messages['avalancheProblemTypes.titles.description']} />,
            key: 'description',
            dataIndex: 'description',
        },
        {
            title: <FormattedMessage {...messages['avalancheProblemTypes.titles.formation']} />,
            key: 'formation',
            dataIndex: 'formation',
        },
        {
            title: <FormattedMessage {...messages['avalancheProblemTypes.titles.persistence']} />,
            key: 'persistence',
            dataIndex: 'persistence',
        },
    ]

    const data = keys.map((key) => ({
        key: key,
        name: intl.formatMessage({ ...messages[`avalancheProblemTypes.${key}.name`] }),
        description: intl.formatMessage({ ...messages[`avalancheProblemTypes.${key}.description`] }),
        formation: intl.formatMessage({ ...messages[`avalancheProblemTypes.${key}.formation`] }),
        persistence: intl.formatMessage({ ...messages[`avalancheProblemTypes.${key}.persistence`] }),
    }))

    return <Table pagination={false} dataSource={data} columns={columns} />
}
