import useSWR from 'swr'

import { useLocale } from 'stores/UserStore'
import { fetchHighwayForecasts } from 'services/HighwayForecasts'
import { HighwayForecastStore } from 'stores/HighwayForecastStore'
import { filterAndSort, unassignedCount } from 'utils/Product'
import { usePolygons } from 'hooks/polygons'

export const useHighwayForecasts = () => {
    const language = useLocale()
    const key = ['products/highwayforecast', language]
    const { data, isLoading, mutate } = useSWR(key, () => fetchHighwayForecasts(language))

    return {
        highwayForecasts: data?.data,
        isLoading,
        mutate,
    }
}

export const useFilteredHighwayForecasts = () => {
    const { highwayForecasts, isLoading } = useHighwayForecasts()
    const filters = HighwayForecastStore.useFilters()

    return isLoading ? [] : filterAndSort(highwayForecasts, 'status', filters)
}

export const useActiveHighwayForecast = () => {
    const activeProductId = HighwayForecastStore.useActiveProductId()
    const { highwayForecasts } = useHighwayForecasts()
    const draftProduct = HighwayForecastStore.useDraftProduct()

    if (activeProductId === 'draft') {
        return draftProduct
    }

    return highwayForecasts?.find((product) => product.id === activeProductId)
}

export const useUnassignedPolygons = () => {
    const polygons = usePolygons()
    const draftProduct = HighwayForecastStore.useDraftProduct()
    const filteredProducts = useFilteredHighwayForecasts()

    if (!polygons || !filteredProducts || !draftProduct) {
        return -1
    }

    const count = unassignedCount(polygons, filteredProducts, draftProduct)

    return count
}
