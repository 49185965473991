import React from 'react'

import { FormattedMessage } from 'react-intl'
import { Alert, Typography } from 'antd'

import { Accessibility } from 'components/Accessibility'
import messages from 'services/intl/messageDefinitions'

export const Instructions = () => {
    const { Text } = Typography
    const standardA11yComponent = (
        <Alert
            style={style.alert}
            message={
                <FormattedMessage
                    {...messages.instructions}
                    values={{
                        shift: (
                            <Text keyboard>
                                <FormattedMessage {...messages.shift} />
                            </Text>
                        ),
                    }}
                />
            }
            type="info"
        />
    )
    const enhancedA11yComponent = (
        <Alert style={style.alert} message={<FormattedMessage {...messages.instructionsEnhancedA11y} />} type="info" />
    )

    return <Accessibility standardA11yComponent={standardA11yComponent} enhancedA11yComponent={enhancedA11yComponent} />
}

const style = {
    alert: {
        marginBottom: 'var(--s-1)',
    },
}
