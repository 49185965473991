import { useEffect } from 'react'

import { useMap } from 'components/Map/Map'

export const Polylines = ({ polylines }) => {
    // Map params
    const map = useMap()
    const polylineSource = 'polylines'

    // Update map layers based on filters
    useEffect(() => {
        if (map.getSource(polylineSource)) {
            map.getSource(polylineSource).setData(polylines)
        }
    }, [map, polylines, polylineSource])

    // Add sources and layers for polylines
    useEffect(() => {
        if (!map.getSource(polylineSource)) {
            map.addSource(polylineSource, { promoteId: 'id', type: 'geojson', data: polylines })
        }
        if (!map.getLayer(polylineSource)) {
            map.addLayer({
                id: polylineSource,
                source: polylineSource,
                type: 'line',
                paint: {
                    'line-width': 5,
                    'line-color': ['get', 'colour'],
                    'line-opacity': 0.8,
                },
                layout: {
                    'line-cap': 'round',
                    'line-join': 'round',
                },
            })
        }
    }, [])

    return null
}
