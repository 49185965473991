import { FormattedMessage } from 'react-intl'
import { Tooltip } from 'antd'
import {
    BoldOutlined,
    ItalicOutlined,
    ArrowsAltOutlined,
    DeleteOutlined,
    DisconnectOutlined,
    LinkOutlined
} from '@ant-design/icons'

import { getCharacterCount } from 'utils/String'
import { RemainingCharacterCount } from 'components/RemainingCharacterCount/RemainingCharacterCount'
import messages from 'services/intl/messageDefinitions'

// A stripped-down version of the MenuBar component that only includes basic formatting options
export const MenuBarBasic = ({
    editor,
    setLink,
    setModalVisible,
    removeHandler,
    expandable,
    setDeleteModalVisible,
    characterLimit,
}) => {
    const html = editor ? editor.getHTML() : ''
    const characterCount = getCharacterCount(html)

    if (!editor) {
        return null
    }

    return (
        <div className="toolbar">
            {removeHandler && (
                <Tooltip placement="top" title={<FormattedMessage {...messages.removeTranslation} />}>
                    <span style={styles.toolBtn} onClick={() => setDeleteModalVisible(true)}>
                        <DeleteOutlined />
                    </span>
                </Tooltip>
            )}
            <Tooltip placement="top" title={<FormattedMessage {...messages.bold} />}>
                <span
                    style={styles.toolBtn}
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    className={editor.isActive('bold') ? 'is-active' : ''}
                >
                    <BoldOutlined />
                </span>
            </Tooltip>
            <Tooltip placement="top" title={<FormattedMessage {...messages.italic} />}>
                <span
                    style={styles.toolBtn}
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    className={editor.isActive('italic') ? 'is-active' : ''}
                >
                    <ItalicOutlined />
                </span>
            </Tooltip>
            <Tooltip placement="top" title={<FormattedMessage {...messages.link} />}>
                <span style={styles.toolBtn} onClick={setLink} className={editor.isActive('link') ? 'is-active' : ''}>
                    <LinkOutlined />
                </span>
            </Tooltip>
            <Tooltip placement="top" title={<FormattedMessage {...messages.unlink} />}>
                <span style={styles.toolBtn} onClick={() => editor.chain().focus().unsetLink().run()}>
                    <DisconnectOutlined />
                </span>
            </Tooltip>
            {expandable && (
                <Tooltip placement="top" title={<FormattedMessage {...messages.popOutEditor} />}>
                    <span onClick={() => setModalVisible(true)} style={styles.toolBtn}>
                        <ArrowsAltOutlined />
                    </span>
                </Tooltip>
            )}
            {characterLimit && (
                <Tooltip placement="top" title={<FormattedMessage {...messages.charactersRemaining} />}>
                    <span style={styles.charCount}>
                        <RemainingCharacterCount limit={characterLimit} count={characterCount} />
                    </span>
                </Tooltip>
            )}
            <div style={styles.dividerContainer}>
                <div style={styles.divider} />
            </div>
        </div>
    )
}

const styles = {
    toolBtn: {
        color: '#999',
        cursor: 'pointer',
        // marginRight: '16px',
        padding: '6px 8px',
        display: 'inline-block',
        borderRadius: '5px',
    },
    dividerContainer: {
        width: '100%',
        height: '1px',
        padding: '0px 6px',
    },
    divider: {
        width: '100%',
        height: '1px',
        background: 'var(--grey-light)',
    },
    charCount: {
        marginRight: '8px',
        marginTop: '6px',
        float: 'right',
    },
}
