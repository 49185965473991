import React from 'react'

import { FormattedMessage } from 'react-intl'
import { useEnhancedAccessibility } from 'stores/UserStore'
import messages from 'services/intl/messageDefinitions'

import { truncate } from 'utils/String'

export const WeakLayerTreeNode = ({ name, type, status }) => {
    const enhancedAccessibility = useEnhancedAccessibility()

    return (
        <div className="weaklayer-tree-node" style={{ ...styles.layerNode, borderLeft: `3px solid transparent` }}>
            <p style={styles.layerNodeName}>{truncate(name, 30)}</p>
            {enhancedAccessibility && ' - '}
            <p style={styles.layerNodeType}>
                <FormattedMessage id={type} defaultMessage="" />
            </p>
            {enhancedAccessibility && (
                <>
                    {' - '}
                    <FormattedMessage {...messages[status]} />
                </>
            )}
        </div>
    )
}

const styles = {
    layerNode: {
        // background: 'var(--grey-lighter)',
        height: '100%',
        padding: '5px 8px',
    },
    layerNodeName: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginBottom: '-1px',
        fontSize: '12px',
    },
    layerNodeType: {
        color: 'var(--grey)',
        marginBottom: '0px',
        fontSize: '12px',
    },
}
