import React, { useState } from 'react'

import { GithubPicker } from 'react-color'
import { Button } from 'antd'
import { useIntl } from 'react-intl'

import { pickTextColorBasedOnBgColor, shadeColor } from 'utils/Color'
import messages from 'services/intl/messageDefinitions'

import './ColorPicker.css'

export const LimitedColourPicker = ({ colourMap, colourNames, initialColour = '#FF7F50', onUpdate, disabled }) => {
    const intl = useIntl()
    const [colour, setColour] = useState({ hex: initialColour })
    const [colourName, setColourName] = useState(
        intl.formatMessage({ ...messages[colourMap.get(initialColour.toUpperCase())] })
    )
    const [pickerVisible, togglePicker] = useState(false)

    const hidePicker = () => {
        if (pickerVisible) {
            togglePicker(false)
        }
    }

    const handleColourChange = (event) => {
        const { hex } = event
        setColour({ hex })
        setColourName(intl.formatMessage({ ...messages[colourMap.get(hex.toUpperCase())] }))
        onUpdate(hex)
        hidePicker()
    }

    const showPicker = (show) => {
        !disabled && togglePicker(show)
    }

    return (
        <div onClick={hidePicker} style={styles.container}>
            <Button
                onClick={() => showPicker(!pickerVisible)}
                type="primary"
                block
                style={{
                    background: colour.hex,
                    border: 'var(--grey)',
                    color: pickTextColorBasedOnBgColor(shadeColor(colour.hex, -35), '#ffffff', '#000000'),
                    opacity: disabled ? 0.5 : 1,
                }}
                aria-label={`Colour Picker: ${colourName}`}
                disabled={disabled}
            >
                {colourName}
            </Button>
            {pickerVisible && (
                <div onMouseLeave={() => showPicker(false)} role="application">
                    <GithubPicker
                        colors={colourNames}
                        className="colorPicker"
                        color={colour}
                        onChangeComplete={handleColourChange}
                    />
                </div>
            )}
        </div>
    )
}

const styles = {
    container: {
        width: '100%',
        height: '100%',
    },
}
