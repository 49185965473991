import { Row, Col, Collapse, Space, Typography } from 'antd'
import { FormattedMessage } from 'react-intl'
import messages from 'services/intl/messageDefinitions'
import { generateCloudinaryThumbnailUrl } from 'utils/Image'
import { TextField } from 'components/TextField/TextField'
import { Select } from 'components/Dropdown/Select'
import { TAGS } from 'utils/Image'

export const ImagePreview = ({ product }) => {
    const { media } = product || {}
    const { images } = media || {}

    return (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            {images.map((image, index) => {
                return <Image key={image.id} product={product} image={image} index={index} count={images.length} />
            })}
        </Space>
    )
}

const Image = ({ image, index, count }) => {
    const header = (
        <div style={{ display: 'inline-flex', gap: 'var(--s0)' }}>
            {image.supportedUrl || image.url ? (
                <img src={generateCloudinaryThumbnailUrl(image.supportedUrl || image.url, 50, 50)} alt="thumbnail" />
            ) : null}
            <FormattedMessage {...messages.imageIndex} values={{ index: index + 1, count }} />
        </div>
    )

    return (
        <Collapse className="image-form">
            <Collapse.Panel header={header}>
                <Row>
                    <img src={image.supportedUrl || image.url} alt="thumbnail" style={styles.fullImage} />
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Row style={{ margin: 'var(--s-2) 0' }}>
                            <FormattedMessage {...messages.credit} />
                        </Row>
                        <Row>
                            <TextField disabled={true} value={image.credit} dataTest={'imageCredit'} />
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row style={{ margin: 'var(--s-2) 0' }}>
                            <FormattedMessage {...messages.tag} />
                        </Row>
                        <Row>
                            <Select
                                value={image.tag}
                                disabled={true}
                                name="tag"
                                style={{ width: '100%' }}
                                data-test={'imageTag'}
                            >
                                {[...TAGS].map(({ label, value }) => (
                                    <Select.Option key={value} value={value}>
                                        <FormattedMessage {...messages[label]} />
                                    </Select.Option>
                                ))}
                            </Select>
                        </Row>
                    </Col>
                </Row>
                <Row style={{ marginTop: 'var(--s0)' }}>
                    <Typography.Title level={5}>
                        <FormattedMessage {...messages.caption} />
                    </Typography.Title>
                </Row>
                <MediaTextDisplay image={image} />
            </Collapse.Panel>
        </Collapse>
    )
}

const MediaTextDisplay = ({ image }) => {
    return Object.keys(image.caption).map((language) => {
        return (
            <div key={language}>
                <p style={styles.translationLabel}>{<FormattedMessage {...messages[language]} />}</p>
                <div dangerouslySetInnerHTML={{ __html: image.caption[language] }}></div>
            </div>
        )
    })
}

const styles = {
    translationLabel: {
        marginBottom: '5px',
        marginLeft: '2px',
        fontWeight: 'bold',
    },
    fullImage: {
        width: '100%',
        maxWidth: '500px',
    },
}
