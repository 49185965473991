import React, { useCallback, useState, useEffect } from 'react'

import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Divider, Typography, Row, Col, Modal } from 'antd'

import { useLocale } from 'stores/UserStore'
import { SpecialProductStore } from 'stores/SpecialProductStore'
import { Stat } from 'components/Stat/Stat'
import { Date as DateDisplay } from 'components/Date/Date'
import { PolygonList } from 'components/PolygonList/PolygonList'
import { CreateForm } from './CreateForm'
import { EditContentForm } from 'components/Product/EditContentForm'
import { DeleteButton } from 'components/DeleteButton/DeleteButton'
import { Preview } from './Preview'
import { Spacer } from 'components/VerticalSpacer/Spacer'
import {
    putSpecialProduct,
    cloneSpecialProduct,
    publishSpecialProduct,
    deleteSpecialProduct,
    expireSpecialProductNow,
} from 'services/SpecialProducts'
import { getLabelForType } from 'utils/SpecialProducts'
import { sendErrorNotification, useSendNotification } from 'utils/Notifications'
import messages from 'services/intl/messageDefinitions'
import { API_SUCCESS_CODES } from 'utils/Constants'
import { usePolygons } from 'hooks/polygons'
import { useSpecialProducts } from 'hooks/specialProducts'

export const Details = ({ item, closeAndReload, closeAndOpenCloned }) => {
    // External imports
    const intl = useIntl()
    const { Title } = Typography

    // User store data
    const locale = useLocale()

    // Store data
    const editingProductSetup = SpecialProductStore.useEditingProductSetup()
    const setEditingProductSetup = SpecialProductStore.useSetEditingProductSetup()
    const editingProductContent = SpecialProductStore.useEditingProductContent()
    const setEditingProductContent = SpecialProductStore.useSetEditingProductContent()
    const { mutate } = useSpecialProducts()
    const polygons = usePolygons()

    // Local state
    const [product, setProduct] = useState(item)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [expireModalVisible, setExpireModalVisible] = useState(false)
    const [cloneError, setCloneError] = useState(false)

    // Notifications
    const sendNotification = useSendNotification()

    useEffect(() => {
        setProduct(item)
    }, [item])

    const updateProduct = useCallback(async (data, locale) => {
        const response = await putSpecialProduct(data, locale)
        if (API_SUCCESS_CODES.includes(response.status)) {
            sendNotification(intl.formatMessage({ ...messages.specialProductSaved }, { name: data.name }))
            mutate()
            setProduct(response.data)
        } else {
            sendErrorNotification(intl.formatMessage({ ...messages.specialProductSaveError }, { name: data.name }))
        }
    }, [])

    const cloneSelectedProduct = useCallback(async (product, locale) => {
        const response = await cloneSpecialProduct(product.id, locale)
        if (API_SUCCESS_CODES.includes(response.status)) {
            closeAndOpenCloned(response.data.id)
        } else {
            if (response.data && response.data.message === 'Polygons are assigned to draft product') {
                setCloneError(true)
            } else {
                sendErrorNotification(intl.formatMessage({ ...messages.productCloneError }))
            }
        }
    }, [])

    const publish = async (product) => {
        await publishSpecialProduct(product.id, locale)
        mutate()
    }

    const deleteProduct = async (product, locale) => {
        await deleteSpecialProduct(product.id, locale)
        sendNotification(intl.formatMessage({ ...messages.specialProductDeleted }, { name: product.name }))
        setDeleteModalVisible(false)
        closeAndReload()
    }

    const expireProduct = async (product, locale) => {
        const response = await expireSpecialProductNow(product.id, locale)
        if (API_SUCCESS_CODES.includes(response.status)) {
            sendNotification(intl.formatMessage({ ...messages.productExpiredNow }, { name: product.name }))
            setExpireModalVisible(false)
            closeAndReload(response.data)
        } else {
            sendErrorNotification(
                intl.formatMessage({
                    ...messages.expireNowError,
                })
            )
        }
    }

    // Updates the various fields in the product
    // details should be in the format: [{key: <field to be changed> value: <updated values>}, {...}]
    const update = (details) => {
        const data = { ...product }
        for (let d in details) {
            const detail = details[d]
            data[detail.key] = detail.value
        }

        updateProduct(data, locale)
    }

    const apiPut = (data) => {
        updateProduct(data, locale)
    }

    const toggleEdit = () => {
        setEditingProductSetup(!editingProductSetup)
    }

    const toggleContentEdit = () => {
        setEditingProductContent(!editingProductContent)
    }

    const detailsView = (
        <div style={styles.detailsBody}>
            <Title level={2} style={styles.title}>
                {product.name}
            </Title>
            <div style={styles.header}>
                <div>{product.username}</div>
                <DateDisplay label={'lastModified'} time={true} value={product.lastModifiedDateTime} />
            </div>
            <div style={styles.detailsContainer}>
                <Divider orientation="left" plain>
                    <FormattedMessage {...messages.details} />
                </Divider>
                <Row>
                    <Col style={styles.detailCol} span={12}>
                        <Stat label={'issueDateTime'} value={product.issueDateTime} type={'date'} time={true} />
                    </Col>
                    <Col style={styles.detailCol} span={12}>
                        <Stat label={'expiryDateTime'} value={product.expiryDateTime} type={'date'} time={true} />
                    </Col>
                </Row>
                <Row>
                    <Col style={styles.detailCol} span={12}>
                        <Stat label={'startDateTime'} value={product.startDate} type={'date'} time={true} />
                    </Col>
                </Row>
                <Row>
                    {item.originalExpiryDateTime && (
                        <Col style={styles.detailCol} span={12}>
                            <Stat
                                label={'originalExpiryDateTime'}
                                value={product.originalExpiryDateTime}
                                type={'date'}
                                time={true}
                            />
                        </Col>
                    )}
                    <Col style={styles.detailCol} span={12}>
                        <Stat
                            label={'specialProductType'}
                            value={getLabelForType(product.specialProductType)}
                            type="text"
                        />
                    </Col>
                </Row>
                <PolygonList polygonList={product.polygons} colour={product.colour} polygons={polygons} />
            </div>
            {product.status === 'draft' && (
                <>
                    <Row style={styles.buttonContainer}>
                        <Button onClick={toggleEdit}>
                            <FormattedMessage {...messages.editSetup} />
                        </Button>
                        <Button
                            onClick={toggleContentEdit}
                            disabled={!product.userID}
                            title={intl.formatMessage({ ...messages.missingForecaster })}
                            data-test="editContentButton"
                        >
                            <FormattedMessage {...messages.editContent} />
                        </Button>
                        <Button onClick={() => cloneSelectedProduct(product, locale)}>
                            <FormattedMessage {...messages.cloneToDraft} />
                        </Button>
                        <Button type="primary" onClick={() => publish(product)}>
                            <FormattedMessage {...messages.publish} />
                        </Button>
                    </Row>
                    <DeleteButton setDeleteModalVisible={setDeleteModalVisible} buttonText={'deleteSpecialProduct'} />
                </>
            )}
            {(product.status === 'live' || product.status === 'completed') && (
                <>
                    <Row style={styles.buttonContainer}>
                        <Button onClick={() => cloneSelectedProduct(product, locale)}>
                            <FormattedMessage {...messages.cloneToDraft} />
                        </Button>
                        <Button
                            onClick={() => setExpireModalVisible(true)}
                            disabled={product.status === 'completed' || new Date(product.expiryDateTime) < Date.now()}
                        >
                            <FormattedMessage {...messages.expireNow} />
                        </Button>
                    </Row>
                    <DeleteButton setDeleteModalVisible={setDeleteModalVisible} buttonText={'deleteSpecialProduct'} />
                </>
            )}

            <Spacer />
            {['completed', 'live'].includes(product.status) && <Preview item={product} />}
        </div>
    )

    const editSetupView = (
        <>
            <CreateForm
                toggleEdit={toggleEdit}
                editing={editingProductSetup}
                update={update}
                initialValues={product}
                autoUpdate={true}
                closeAndReload={closeAndReload}
                setDeleteModalVisible={setDeleteModalVisible}
            />
        </>
    )

    const editContentView = (
        <EditContentForm toggleContentEdit={toggleContentEdit} item={product} update={update} apiPut={apiPut} />
    )

    // Show details by default; otherwise, show editSetup or editContent
    let contentView = detailsView

    if (editingProductSetup) {
        contentView = editSetupView
    }

    if (editingProductContent) {
        contentView = editContentView
    }

    return (
        <>
            {contentView}
            <Modal
                title={<FormattedMessage {...messages.warning} />}
                visible={deleteModalVisible}
                onOk={() => deleteProduct(product, locale)}
                onCancel={() => setDeleteModalVisible(false)}
                okText={<FormattedMessage {...messages.yesConfirmDeleteSpecialProduct} />}
                cancelText={<FormattedMessage {...messages.cancel} />}
            >
                <p>
                    <FormattedMessage {...messages.deleteSpecialProductConfirmation} values={{ name: product.name }} />
                </p>
            </Modal>
            <Modal
                title={<FormattedMessage {...messages.warning} />}
                visible={expireModalVisible}
                onOk={() => expireProduct(product, locale)}
                onCancel={() => setExpireModalVisible(false)}
                okText={<FormattedMessage {...messages.yesConfirmExpireNow} />}
                cancelText={<FormattedMessage {...messages.cancel} />}
            >
                <p>
                    <FormattedMessage {...messages.expireProductConfirmation} values={{ name: product.name }} />
                </p>
            </Modal>
            <Modal
                title={<FormattedMessage {...messages.clonePolygonError} />}
                visible={cloneError}
                onCancel={() => setCloneError(false)}
                okText={<FormattedMessage {...messages.ok} />}
                footer={
                    <Button type="primary" onClick={() => setCloneError(false)}>
                        <FormattedMessage {...messages.ok} />
                    </Button>
                }
            >
                <p>
                    <FormattedMessage {...messages.productCloneErrorPolygons} />
                </p>
            </Modal>
        </>
    )
}

const styles = {
    detailsBody: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
    },
    title: {
        fontWeight: '400',
        flex: '0 1 auto',
    },
    header: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 'var(--s-1)',
        justifyContent: 'space-between',
        flex: '0 1 auto',
    },
    detailsContainer: {
        marginTop: 'var(--s0)',
    },
    detailCol: {
        marginBottom: 'var(--s0)',
    },
    buttonContainer: {
        marginTop: '25px',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 'var(--s0)',
    },
}
