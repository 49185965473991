import { Tooltip } from 'antd'
import { FormattedMessage } from 'react-intl'
import { ArrowsAltOutlined, DeleteOutlined } from '@ant-design/icons'

import { getCharacterCount } from 'utils/String'
import { RemainingCharacterCount } from 'components/RemainingCharacterCount/RemainingCharacterCount'
import messages from 'services/intl/messageDefinitions'

export const PlainTextMenu = ({ editor, setModalVisible, expandable, characterLimit, setDeleteModalVisible }) => {
    const html = editor ? editor.getHTML() : ''
    const characterCount = getCharacterCount(html)

    if (!editor) {
        return null
    }

    return (
        <div className="toolbar">
            <Tooltip placement="top" title={<FormattedMessage {...messages.removeTranslation} />}>
                <span style={styles.toolBtn} onClick={() => setDeleteModalVisible(true)}>
                    <DeleteOutlined />
                </span>
            </Tooltip>
            {expandable && (
                <Tooltip placement="top" title={<FormattedMessage {...messages.popOutEditor} />}>
                    <span onClick={() => setModalVisible(true)} style={styles.toolBtn}>
                        <ArrowsAltOutlined />
                    </span>
                </Tooltip>
            )}
            {characterLimit && (
                <Tooltip placement="top" title={<FormattedMessage {...messages.charactersRemaining} />}>
                    <span style={styles.charCount}>
                        <RemainingCharacterCount limit={characterLimit} count={characterCount} />
                    </span>
                </Tooltip>
            )}
            <div style={styles.dividerContainer}>
                <div style={styles.divider} />
            </div>
        </div>
    )
}

const styles = {
    toolBtn: {
        color: '#999',
        cursor: 'pointer',
        // marginRight: '16px',
        padding: '6px 8px',
        display: 'inline-block',
        borderRadius: '5px',
    },
    dividerContainer: {
        width: '100%',
        height: '1px',
        padding: '0px 6px',
    },
    divider: {
        width: '100%',
        height: '1px',
        background: 'var(--grey-light)',
    },
    charCount: {
        marginRight: '8px',
        marginTop: '6px',
        float: 'right',
    },
}
