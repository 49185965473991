import { APIClient } from './APIClient'

export const fetchAvalancheForecasts = (language) => {
    return APIClient.get(`/v1/${language}/products/avalancheforecast`, language)
}

export const fetchAvalancheForecast = (id, language) => {
    return APIClient.get(`/v1/${language}/products/id/${id}`, language)
}

export const fetchAvalancheForecastsByDates = (startDate, endDate, searchTerm, language) => {
    return APIClient.get(
        `/v1/${language}/products/avalancheforecast/history?startDate=${startDate}&endDate=${endDate}&searchTerm=${searchTerm}`,
        language
    )
}

export const postAvalancheForecast = (data, language) => {
    return APIClient.post(`/v1/${language}/products/avalancheforecast`, {
        ...data,
    })
}

export const putAvalancheForecast = (data, language) => {
    return APIClient.put(`/v1/${language}/products/avalancheforecast`, {
        ...data,
    })
}

export const publishAvalancheForecast = (id, language) => {
    return APIClient.post(`/v1/${language}/products/publish/${id}`)
}

export const deleteAvalancheForecast = (id, language) => {
    return APIClient.delete(`/v1/${language}/products/id/` + id, language)
}

export const cloneAvalancheForecast = (id, language) => {
    return APIClient.post(`/v1/${language}/products/clone/` + id, language)
}

export const cloneAllAvalancheForecasts = (language) => {
    return APIClient.post(`/v1/${language}/products/avalancheforecast/cloneall`, language)
}
