import { APIClient } from './APIClient'

export const fetchWeakLayers = (language) => {
    return APIClient.get(`/v1/${language}/weaklayers/today`)
}

export const fetchWeakLayersSchema = (language) => {
    return APIClient.get(`/v1/${language}/weaklayers/schema`)
}

export const postWeakLayer = (data, language) => {
    return APIClient.post(`/v1/${language}/weaklayers`, {
        ...data,
    })
}

export const putWeakLayer = (data, language) => {
    return APIClient.put(`/v1/${language}/weaklayers/${data.id}`, {
        ...data,
    })
}

export const deleteWeakLayer = (id, language) => {
    return APIClient.delete(`/v1/${language}/weaklayers/${id}`)
}
