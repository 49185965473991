import React, { useState, useEffect } from 'react'

import { Input } from 'antd'

export const TextField = ({
    onChange,
    value,
    disabled = false,
    dataTest = '',
    autoUpdate = false,
    placeholder = '',
    ariaLabel = '',
}) => {
    const [content, setContent] = useState(value)
    const [touched, setTouched] = useState(false)

    const handleChange = (event) => {
        setTouched(true)
        setContent(event.target.value)
    }

    useEffect(() => {
        if (autoUpdate) {
            setContent(value)
        }
    }, [value, autoUpdate])

    // Debounce
    useEffect(() => {
        const handler = setTimeout(() => {
            if (touched) {
                onChange(content)
                setTouched(false)
            }
        }, 700)

        return () => {
            clearTimeout(handler)
        }
    }, [content, onChange, touched])

    return (
        <Input
            disabled={disabled}
            value={content}
            onChange={handleChange}
            data-test={dataTest}
            style={styles.input}
            placeholder={placeholder}
            aria-label={ariaLabel}
        />
    )
}

const styles = {
    input: {
        marginBottom: '18px',
    },
}
