import { create } from 'utils/LocalStorage'

// TODO: build this list using env vars
export const EN = 'en'
export const FR = 'fr'
export const LOCALES = new Set([EN, FR])
export const LOCALE_TEXTS = new Map([
    [EN, 'English'],
    [FR, 'Français'],
])

export function runLocaleSideEffects(locale) {
    document.documentElement.lang = locale
}

// Helpers

export function getFromNavigator() {
    const [language] = window.navigator.languages

    return language.substring(0, 2) || 'en'
}
