import { useState, useEffect } from 'react'

import { NavLink, useLocation } from 'react-router-dom'
import { Menu, Avatar } from 'antd'
import { FormattedMessage } from 'react-intl'

import { usePicture } from 'stores/UserStore'
import messages from 'services/intl/messageDefinitions'
import './Nav.css'
import { ALL_VISIBLE_TABS } from 'utils/Constants'

export const Nav = () => {
    const location = useLocation()
    const userPicture = usePicture()
    const tabs = process.env.REACT_APP_VISIBLE_TABS ? JSON.parse(process.env.REACT_APP_VISIBLE_TABS) : ALL_VISIBLE_TABS

    return (
        <>
            <Menu mode="horizontal" selectedKeys={[location.pathname]}>
                {tabs.includes('weak-layers') && (
                    <Menu.Item key="/weak-layers">
                        <NavLink to="/weak-layers">
                            <FormattedMessage {...messages.weakLayers} />
                        </NavLink>
                    </Menu.Item>
                )}
                {tabs.includes('avalanche-forecast') && (
                    <Menu.Item key="/avalanche-forecast">
                        <NavLink to="/avalanche-forecast">
                            <FormattedMessage {...messages.avalancheForecast} />
                        </NavLink>
                    </Menu.Item>
                )}
                {tabs.includes('regional-discussions') && (
                    <Menu.Item key="/regional-discussions">
                        <NavLink to="/regional-discussions">
                            <FormattedMessage {...messages.regionalDiscussions} />
                        </NavLink>
                    </Menu.Item>
                )}
                {tabs.includes('special-products') && (
                    <Menu.Item key="/special-products">
                        <NavLink to="/special-products">
                            <FormattedMessage {...messages.specialProducts} />
                        </NavLink>
                    </Menu.Item>
                )}
                {tabs.includes('hwy-forecast') && (
                    <Menu.Item key="/hwy-forecast">
                        <NavLink to="/hwy-forecast">
                            <FormattedMessage {...messages.hwyForecast} />
                        </NavLink>
                    </Menu.Item>
                )}
                {tabs.includes('hwy-discussion') && (
                    <Menu.Item key="/hwy-discussion">
                        <NavLink to="/hwy-discussion">
                            <FormattedMessage {...messages.hwyDiscussion} />
                        </NavLink>
                    </Menu.Item>
                )}
                {tabs.includes('mitigation') && (
                    <Menu.Item key="/mitigation">
                        <NavLink to="/mitigation">
                            <FormattedMessage {...messages.mitigation} />
                        </NavLink>
                    </Menu.Item>
                )}
                {tabs.includes('documentation') && (
                    <Menu.Item key="/documentation">
                        <NavLink to="/documentation">
                            <FormattedMessage {...messages.documentation} />
                        </NavLink>
                    </Menu.Item>
                )}
                {tabs.includes('archive') && (
                    <Menu.Item key="/archive">
                        <NavLink to="/archive">
                            <FormattedMessage {...messages.archive} />
                        </NavLink>
                    </Menu.Item>
                )}
                {tabs.includes('profile') && (
                    <Menu.Item style={styles.profile} key="/profile">
                        <NavLink to="/profile">
                            <Avatar size="large" src={userPicture} />
                        </NavLink>
                    </Menu.Item>
                )}
            </Menu>
        </>
    )
}

const styles = {
    profile: {
        marginLeft: 'auto',
    },
}
