import { createEmptyProduct } from './Product'

export const createEmptyRegionalDiscussion = (locale = 'en') => {
    return {
        ...createEmptyProduct('regionaldiscussion'),
        message: {
            [locale]: '',
        },
        communications: {
            headline: {
                [locale]: '',
            },
            sms: {
                [locale]: '',
            },
        },
    }
}
