import { FormattedMessage } from 'react-intl'

import { Stat } from 'components/Stat/Stat'
import messages from 'services/intl/messageDefinitions'

export const AccessibleChartReadOnly = ({ chartPolygons, formMarker }) => {
    return (
        <>
            <Stat
                label="centroidSize"
                value={
                    chartPolygons.centroid.size ? chartPolygons.centroid.size : <FormattedMessage {...messages.NA} />
                }
            />
            <Stat
                label="centroidLikelihood"
                value={
                    chartPolygons.centroid.likelihood ? (
                        <FormattedMessage {...messages[chartPolygons.centroid.likelihood]} />
                    ) : (
                        <FormattedMessage {...messages.NA} />
                    )
                }
            />
            <Stat
                label="sizeRangeFrom"
                value={chartPolygons.size.from ? chartPolygons.size.from : <FormattedMessage {...messages.NA} />}
            />
            <Stat
                label="sizeRangeTo"
                value={chartPolygons.size.to ? chartPolygons.size.to : <FormattedMessage {...messages.NA} />}
            />
            <Stat
                label="likelihoodRangeFrom"
                value={
                    chartPolygons.likelihood.from ? (
                        <FormattedMessage {...messages[chartPolygons.likelihood.from]} />
                    ) : (
                        <FormattedMessage {...messages.NA} />
                    )
                }
            />
            <Stat
                label="likelihoodRangeTo"
                value={
                    chartPolygons.likelihood.to ? (
                        <FormattedMessage {...messages[chartPolygons.likelihood.to]} />
                    ) : (
                        <FormattedMessage {...messages.NA} />
                    )
                }
            />
            <Stat
                label="formDerivedSize"
                value={formMarker.size ? formMarker.size : <FormattedMessage {...messages.NA} />}
            />
            <Stat
                label="formDerivedLikelihood"
                value={
                    formMarker.likelihood ? (
                        <FormattedMessage {...messages[formMarker.likelihood]} />
                    ) : (
                        <FormattedMessage {...messages.NA} />
                    )
                }
            />
        </>
    )
}
