import useSWR from 'swr'

import { useLocale } from 'stores/UserStore'
import { fetchRegionalDiscussions } from 'services/RegionalDiscussions'
import { RegionalDiscussionStore } from 'stores/RegionalDiscussionStore'
import { filterAndSort, unassignedCount } from 'utils/Product'
import { usePolygons } from 'hooks/polygons'

export const useRegionalDiscussions = () => {
    const language = useLocale()
    const key = ['products/regionaldiscussion', language]
    const { data, isLoading, mutate } = useSWR(key, () => fetchRegionalDiscussions(language))

    return {
        regionalDiscussions: data?.data,
        isLoading,
        mutate,
    }
}

export const useFilteredRegionalDiscussions = () => {
    const { regionalDiscussions, isLoading } = useRegionalDiscussions()
    const filters = RegionalDiscussionStore.useFilters()

    return isLoading ? [] : filterAndSort(regionalDiscussions, 'status', filters)
}

export const useActiveRegionalDiscussion = () => {
    const activeProductId = RegionalDiscussionStore.useActiveProductId()
    const { regionalDiscussions } = useRegionalDiscussions()
    const draftProduct = RegionalDiscussionStore.useDraftProduct()

    if (activeProductId === 'draft') {
        return draftProduct
    }

    return regionalDiscussions?.find((product) => product.id === activeProductId)
}

export const useUnassignedPolygons = () => {
    const polygons = usePolygons()
    const draftProduct = RegionalDiscussionStore.useDraftProduct()
    const filteredProducts = useFilteredRegionalDiscussions()

    if (!polygons || !filteredProducts || !draftProduct) {
        return -1
    }

    const count = unassignedCount(polygons, filteredProducts, draftProduct)

    return count
}
