export const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window
    return {
        width,
        height,
    }
}

export const getDrawerDetailsWidth = (vwWidth) => {
    let colWidth = 24
    switch (true) {
        case vwWidth > 65:
            colWidth = 6
            break
        case vwWidth > 50:
            colWidth = 8
            break
        case vwWidth > 35:
            colWidth = 12
            break
        default:
            colWidth = 24
            break
    }
    return colWidth
}
