import React, { useEffect } from 'react'

import { IntlProvider } from 'react-intl'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher'
import { useAuth0 } from '@auth0/auth0-react'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import { Layout } from 'antd'

import Messages from 'services/intl/messages'
import { getUserId } from 'utils/User'
import { AppRouter } from './Router/AppRouter'
import { LoadingSpinner } from 'components/LoadingSpinner/LoadingSpinner'
import { Nav } from 'components/Nav/Nav'
import { useLocale, useSetPicture, useSetId, useUserDetails } from 'stores/UserStore'
import { themeMap } from 'utils/ColourScheme'
import { useColourScheme } from 'stores/UserStore'
import './styles/global.css'
import './styles/colours.css'
import './styles/reset.css'

export function App() {
    const locale = useLocale()
    const colourScheme = useColourScheme()

    function onError(err) {
        if (err.code === 'MISSING_TRANSLATION') {
            if (process.env.REACT_APP_LOG_I18N_MISSING_TRANSLATIONS === 'true') {
                console.warn('Missing translation', err.message)
            }
            return
        }
        throw err
    }

    return (
        <ThemeSwitcherProvider defaultTheme={colourScheme} themeMap={themeMap}>
            <IntlProvider
                key={locale}
                messages={Messages.get(locale)}
                defaultLocale="en-CA"
                locale={locale + '-CA'}
                onError={onError}
            >
                <AppLayout />
            </IntlProvider>
        </ThemeSwitcherProvider>
    )
}

// This needs to be a separate component from the <ThemeSwitcherProvider> to call the useThemeSwitcher hook below
const AppLayout = () => {
    const { Header, Content } = Layout
    const { isLoading: isLoadingUserDetails } = useUserDetails()
    const { status } = useThemeSwitcher()
    const { isAuthenticated, loginWithRedirect, isLoading, user } = useAuth0()
    const setPicture = useSetPicture()
    const setId = useSetId()

    if (!isAuthenticated && !isLoading) {
        if (!window.Cypress) {
            loginWithRedirect()
        } else {
            const auth0 = JSON.parse(localStorage.getItem('auth0Cypress'))
            const { user: testUser } = auth0.body.decodedToken
            setId(getUserId(testUser.sub))
        }
    } else if (!isLoading && isAuthenticated) {
        setId(getUserId(user.sub))
        setPicture(user.picture)
    }

    return (
        <>
            {(status === 'loading' || isLoadingUserDetails) && <LoadingSpinner />}
            {status === 'loaded' && (
                <Layout>
                    <Header style={styles.header}>
                        <Nav />
                    </Header>
                    <section className="ant-layout-content">
                        <div className="App">
                            <AppRouter />
                        </div>
                    </section>
                </Layout>
            )}
        </>
    )
}

const styles = {
    header: {
        boxShadow: '0 6px 4px 0 rgba(0, 0, 0, 0.15)',
        zIndex: '9999',
        position: 'relative',
        background: 'var(--background-colour)',
        borderBottom: '1px solid var(--grey-light)',
    },
}
