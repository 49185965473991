import { APIClient } from './APIClient'

// TODO: combine cloning endpoints, ie cloneAvalancheForecast
export const cloneProduct = (id, language) => {
    return APIClient.post(`/v1/${language}/products/clone/` + id, language)
}

export const markReviewed = (id) => {
    return APIClient.post(`/v1/en/products/id/${id}/markreviewed`)
}
