import moment from 'moment'

export const filterAndSort = (list, filterType, filters) => {
    const priority = FILTER_OPTIONS.map((filter) => filter.value)
    let filteredList = []

    for (let f in priority) {
        if (filters.indexOf(priority[f]) > -1) {
            const filtered = filterSortList(list, filterType, priority[f])
            filteredList = filteredList.concat(filtered)
        }
    }

    return filteredList
}

const filterSortList = (list, filterType, filter) => {
    const newList = list.filter((obj) => {
        return obj[filterType] === filter
    })

    return newList.sort(function(a, b) {
        return new Date(b.burialDate) - new Date(a.burialDate)
    })
}

export const FILTER_OPTIONS = [
    {
        label: 'active',
        value: 'active',
        default: true, // default will determine if filter is active on component load
    },
    {
        label: 'developing',
        value: 'developing',
        default: false,
    },
    {
        label: 'dormant',
        value: 'dormant',
        default: false,
    },
    {
        label: 'inactive',
        value: 'inactive',
        default: false,
    },
]

export const getColourForGrainType = (grainType) => {
    return GRAIN_TYPES.find((grainOption) => grainOption.value === grainType)?.colour || '#FF0000'
}

// TODO: Get the colours from the server eventually rather than duplicating them here
export const GRAIN_TYPES = [
    {
        label: 'precipParticles',
        value: 'precipParticles',
        colour: '#FF00FF',
    },
    {
        label: 'decomposingFragmented',
        value: 'decomposingFragmented',
        colour: '#990099',
    },
    {
        label: 'facetedCrystals',
        value: 'facetedCrystals',
        colour: '#00FFFF',
    },
    {
        label: 'depthHoar',
        value: 'depthHoar',
        colour: '#0000FF',
    },
    {
        label: 'surfaceHoar',
        value: 'surfaceHoar',
        colour: '#FFA500',
    },
    {
        label: 'meltFreezeCrust',
        value: 'meltFreezeCrust',
        colour: '#FFFF00',
    },
    {
        label: 'crustFacet',
        value: 'crustFacet',
        colour: '#FF0000',
    },
    {
        label: 'crustSurfaceHoar',
        value: 'crustSurfaceHoar',
        colour: '#FFC0CB',
    },
    {
        label: 'surfaceHoarFacet',
        value: 'surfaceHoarFacet',
        colour: '#008000',
    },
]

export const schema = {
    id: null,
    name: null,
    burialDate: moment(),
    grainType: null,
    status: null,
    thickness: {
        from: null,
        to: null,
    },
    depth: {
        from: null,
        to: null,
    },
    comments: [],
    polygons: [],
    aspectElevations: [],
    validDate: null,
    versionNumber: null,
    colour: '#FF00FF',
    lastModifiedDate: null,
}
