import React from 'react'

import { Collapse, Table } from 'antd'
import { FormattedMessage } from 'react-intl'

import { hexToRGB } from 'utils/Color'
import messages from 'services/intl/messageDefinitions'

export const PolygonList = ({ polygonList: polygonIdList, colour, polygons }) => {
    const { Panel } = Collapse

    if (!polygonIdList || !polygons) {
        return null
    }

    const polygonListWithDataInjected = polygonIdList.map((polygonId) => {
        const polygonData = polygons.features?.find((polygon) => polygon.properties.id === polygonId)
        if (polygonData) {
            return {
                key: polygonId,
                id: polygonData.properties.polygon_number,
                name: polygonData.properties.polygon_name,
                mountainRange: polygonData.properties.mountain_range,
            }
        } else {
            return null
        }
    })

    const columns = [
        {
            title: <FormattedMessage {...messages['polygonList.titles.id']} />,
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: <FormattedMessage {...messages['polygonList.titles.name']} />,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: <FormattedMessage {...messages['polygonList.titles.mountainRange']} />,
            dataIndex: 'mountainRange',
            key: 'mountainRange',
        },
    ]

    // Only show polygons with a name
    const dataSource = polygonListWithDataInjected[0]?.name !== undefined ? polygonListWithDataInjected : []

    const locale = {
        emptyText: (
            <span>
                <p>
                    <FormattedMessage {...messages.noPolygonDetails} />
                </p>
            </span>
        ),
    }

    return (
        <Collapse style={{ background: hexToRGB(colour, 0.35) }} defaultActiveKey={[]}>
            <Panel
                header={
                    <div>
                        <FormattedMessage {...messages.polygons} values={{ value: polygonListWithDataInjected.length }} />
                    </div>
                }
                key="1"
            >
                <Table
                    locale={polygonIdList.length ? locale : undefined}
                    pagination={false}
                    dataSource={dataSource}
                    columns={columns}
                />
            </Panel>
        </Collapse>
    )
}
