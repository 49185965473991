import { useEffect } from 'react'

import { useThemeSwitcher } from 'react-css-theme-switcher'

import { useMap } from 'components/Map/Map'
import { darkSchemePolygonsColour, lightSchemePolygonsColour } from 'components/Map/mapHelpers'
import { useSelectedPolygonId } from 'stores/ArchiveStore'

export const polygonSource = 'polygons'

export const PolygonsWithFeatureState = ({ polygons }) => {
    // Map params
    const map = useMap()

    // Secondary data
    const { currentTheme } = useThemeSwitcher()
    const polygonsColour = currentTheme === 'light' ? lightSchemePolygonsColour : darkSchemePolygonsColour
    const selectedPolygonId = useSelectedPolygonId()

    // Update map layers based on filters
    useEffect(() => {
        if (map.getSource(polygonSource)) {
            map.getSource(polygonSource).setData(polygons)
        }
    }, [map, polygons])

    // Add sources and layers for polygons
    useEffect(() => {
        if (!map.getSource(polygonSource)) {
            map.addSource(polygonSource, { promoteId: 'id', type: 'geojson', data: polygons })
        }
        if (!map.getLayer(polygonSource)) {
            map.addLayer({
                id: polygonSource,
                source: polygonSource,
                type: 'fill',
                paint: {
                    'fill-color': polygonsColour,
                    'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.9, 0.5],
                },
            })
            map.addLayer({
                id: polygonSource + '-line',
                source: polygonSource,
                type: 'line',
                paint: {
                    'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 7, 1.5],
                    'line-color': polygonsColour,
                },
                layout: {
                    'line-cap': 'round',
                    'line-join': 'round',
                },
            })
        }
    }, [])

    // Feature state
    useEffect(() => {
        if (selectedPolygonId) {
            map.removeFeatureState({ source: polygonSource }) // Clear all other hover states
            map.setFeatureState({ source: polygonSource, id: selectedPolygonId }, { hover: true })
            map.setFeatureState({ source: polygonSource, id: selectedPolygonId + '-line' }, { hover: true })
        } else {
            map.removeFeatureState({ source: polygonSource })
        }
    }, [selectedPolygonId, map])

    return null
}
