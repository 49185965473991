import React, { useState } from 'react'

import { FormattedMessage } from 'react-intl'
import { useSearchParams } from 'react-router-dom'
import { Typography, Space, List, Button, Drawer } from 'antd'

import { CheckBoxFilter } from 'components/CheckBoxFilter/CheckBoxFilter'
import { Accordion } from 'components/Accordion/Accordion'
import { noop } from 'utils/function'
import messages from 'services/intl/messageDefinitions'
import './Sidebar.css'

export const Sidebar = ({
    title,
    emptyMessage,
    dataProvider,
    Details,
    Summary,
    disableSetupButton = false,
    filterOptions,
    activeFilters,
    useAddFilter,
    useRemoveFilter,
    useSetActiveId,
    useSetEditing,
    useSetEditingContent = noop,
    useResetDraft,
    useClearActiveID,
    createLabel,
    CreateForm,
    mutateProducts = () => { }, // TODO: remove the default noop function when weak layers are deprecated
    secondaryMutate = () => { },
    useActiveItem,
    radioStyleFiltering,
    buttons,
}) => {
    const { Title } = Typography
    const [coverVisible, setCoverVisibility] = useState(false)
    const [createDrawerVisible, setCreateDrawerVisible] = useState(false)
    const [queryParams, setQueryParams] = useSearchParams()
    const listData = dataProvider()
    const clearActiveID = useClearActiveID()
    const setActiveId = useSetActiveId()
    const setEditingSetup = useSetEditing()
    const setEditingContent = useSetEditingContent()
    const resetDraft = useResetDraft()
    const addFilter = useAddFilter()

    const noDataFound = (
        <p>
            <FormattedMessage {...messages[emptyMessage]} />
        </p>
    )

    const handleCoverClick = () => {
        // TODO: this should be more robust (e.g. passing in to onClose in Accordion)
        clearActiveID()
        setCoverVisibility(false)
        setCreateDrawerVisible(false)
    }

    const onCloseCreateDrawer = () => {
        setCoverVisibility(false)
        setCreateDrawerVisible(false)
        setEditingSetup(false)
        onClose()
    }

    const onClose = () => {
        resetDraft()
        clearActiveID()
        setEditingSetup(false)
        typeof setEditingContent === 'function' && setEditingContent(false) // HACK: close the content-editing workflow if it's open (Regional Dicussions or Special Products)
    }

    const onOpenCreateDrawer = () => {
        setActiveId('draft')
        setEditingSetup(true)
        setCoverVisibility(true)
        setCreateDrawerVisible(true)
    }

    const closeAndReload = () => {
        mutateProducts()
        secondaryMutate()
        setCoverVisibility(false)
        setCreateDrawerVisible(false)
        onClose()
    }

    const closeAndOpenCloned = (productId) => {
        mutateProducts()
        secondaryMutate()
        addFilter('draft')
        setQueryParams('focus', productId)
        setActiveId(productId)
    }

    const cover = <div onClick={handleCoverClick} style={styles.cover}></div>

    const dataElems = listData.map((item, index) => {
        const summary = <Summary item={item} key={index} useSetActiveId={useSetActiveId} />
        const details = (
            <Details item={item} key={index} closeAndReload={closeAndReload} closeAndOpenCloned={closeAndOpenCloned} />
        )

        return (
            <List.Item className="sidebar-list-item" key={item.id}>
                <Accordion
                    summary={summary}
                    details={details}
                    setCoverVisibility={setCoverVisibility}
                    onClose={onClose}
                    coverVisible={coverVisible}
                    data={item}
                    useSetActiveId={useSetActiveId}
                    useActiveItem={useActiveItem}
                />
            </List.Item>
        )
    })

    const createDrawer = (
        <Drawer
            className="weakLayersDrawer"
            contentWrapperStyle={{
                width: '36vw',
                marginTop: '2px',
                paddingTop: '0px',
                borderLeft: '1px solid var(--grey-light)',
            }}
            closable={true}
            mask={false}
            destroyOnClose={true}
            placement="right"
            onClose={onCloseCreateDrawer}
            visible={createDrawerVisible}
        >
            <CreateForm
                drawerVisible={createDrawerVisible}
                closeDrawer={onCloseCreateDrawer}
                create={true}
                update={() => { }}
            />
        </Drawer>
    )

    return (
        <Space direction="vertical" style={styles.container}>
            <Title data-test={title} style={styles.title}>
                <FormattedMessage {...messages[title]} />
            </Title>
            <div style={styles.filterContainer}>
                <CheckBoxFilter
                    options={filterOptions}
                    activeFilters={activeFilters}
                    useAddFilter={useAddFilter}
                    useRemoveFilter={useRemoveFilter}
                    radioStyle={radioStyleFiltering}
                />
            </div>
            <div style={styles.buttons}>
                <Button
                    type="primary"
                    disabled={disableSetupButton}
                    onClick={onOpenCreateDrawer}
                    data-test={'createButton'}
                >
                    <FormattedMessage {...messages[createLabel]} />
                </Button>
                {buttons}
            </div>
            {dataElems.length > 0 ? dataElems : noDataFound}
            {createDrawer}
            {coverVisible ? cover : null}
        </Space>
    )
}

const styles = {
    filterContainer: {
        margin: 'var(--s-1) 0',
    },
    buttons: {
        marginBottom: 'var(--s-1)',
        display: 'flex',
        gap: 'var(--s-1)',
        flexWrap: 'wrap',
    },
    container: {
        padding: 'var(--s1)',
        display: 'flex',
    },
    noMargin: {
        margin: 0,
    },
    title: {
        margin: 0,
        fontWeight: 300,
    },
    cover: {
        width: '40%',
        height: '100vh',
        background: 'rgba(0, 0, 0, 0.52)',
        position: 'fixed',
        top: '0',
        right: '0',
        cursor: 'pointer',
    },
}
