import React from 'react'
import { hexToRGB, pickTextColorBasedOnBgColor, shadeColor } from 'utils/Color'

export const MapStatusBar = ({ product, text }) => {
    const { colour } = product || {}
    const backgroundColour = hexToRGB(colour, 0.5)
    const shadedBackgroundColour = shadeColor(colour, -35) // Slightly darker with map colours below the transparent background bleeding through, especially in dark mode
    const textColour = pickTextColorBasedOnBgColor(shadedBackgroundColour, '#ffffff', '#000000')
    const borderColour = colour
    const shadowColour = hexToRGB(pickTextColorBasedOnBgColor(shadedBackgroundColour, '#000000', '#ffffff'), 0.6)

    return (
        product.name && (
            <div style={styles.wrapper}>
                <header
                    style={{
                        ...styles.header,
                        backgroundColor: backgroundColour,
                        color: textColour,
                        borderColor: borderColour,
                        textShadow: `0px 0px 2px ${shadowColour}`,
                    }}
                >
                    {text}
                </header>
            </div>
        )
    )
}

const styles = {
    wrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        display: 'flex',
    },
    header: {
        padding: 'var(--s-2) var(--s1)',
        backgroundColor: 'var(--primary)',
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.3)',
        backdropFilter: 'blur(4px)',
        borderBottom: '2px solid',
        borderLeft: '2px solid',
        borderRight: '2px solid',
        margin: '0 auto',
        borderBottomLeftRadius: 'var(--radius)',
        borderBottomRightRadius: 'var(--radius)',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 'var(--s-1)',
    },
}
