import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'

import { Input, Radio, Form, Row, Col, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { FormattedMessage, useIntl } from 'react-intl'
import { useThemeSwitcher } from 'react-css-theme-switcher'
import debounce from 'lodash/debounce'

import { DATE_FORMATS } from 'utils/Date'
import { LOCALE_TEXTS } from 'utils/Locales'
import { useUpdateDetails, useUserDetails, useEditingDisabled } from 'stores/UserStore'
import messages from 'services/intl/messageDefinitions'
import { runLocaleSideEffects } from 'utils/Locales'
import { successNotification } from 'utils/Notifications'

const AUTOSAVE_TIMEOUT = 2000

export const UserDetailsForm = () => {
    const intl = useIntl()
    const { switcher } = useThemeSwitcher()
    const [form] = Form.useForm()
    const { details: userDetails } = useUserDetails()
    const [name, setName] = useState(userDetails.name)
    const [email, setEmail] = useState(userDetails.email)
    const [phone, setPhone] = useState(userDetails.phone)
    const [radioCallSign, setRadioCallSign] = useState(userDetails.radioCallSign)
    const [nameTouched, setNameTouched] = useState(false)
    const [emailTouched, setEmailTouched] = useState(false)
    const [phoneTouched, setPhoneTouched] = useState(false)
    const [radioCallSignTouched, setRadioCallSignTouched] = useState(false)
    const updateUserDetails = useUpdateDetails()
    const editingDisabled = useEditingDisabled()
    const successMessage = useMemo(() => intl.formatMessage({ ...messages.profileSettingsUpdated }), [intl])

    const autoUpdate = (changeValues) => {
        const changedField = Object.keys(changeValues)[0]
        const parsedValue = Object.values(changeValues)[0]
        const updateData = { key: changedField, value: parsedValue }

        updateDetails(updateData)
    }

    const updateUserDetailsInStore = useCallback(
        async (data) => {
            const newUserDetails = updateUserDetailsObject(userDetails, data)

            await updateUserDetails(newUserDetails)
            successNotification(successMessage, 'profile-settings-updated')
        },
        [userDetails, updateUserDetails, successMessage]
    )

    const debouncedNameHandler = useRef(
        debounce((newName) => {
            setName(newName)
            setNameTouched(true)
        }, AUTOSAVE_TIMEOUT)
    ).current

    const debouncedEmailHandler = useRef(
        debounce((newEmail) => {
            setEmail(newEmail)
            setEmailTouched(true)
        }, AUTOSAVE_TIMEOUT)
    ).current

    const debouncedPhoneHandler = useRef(
        debounce((newPhone) => {
            setPhone(newPhone)
            setPhoneTouched(true)
        }, AUTOSAVE_TIMEOUT)
    ).current

    const debouncedRadioCallSignHandler = useRef(
        debounce((newRadioCallSign) => {
            setRadioCallSign(newRadioCallSign)
            setRadioCallSignTouched(true)
        }, AUTOSAVE_TIMEOUT)
    ).current

    useEffect(() => {
        if (nameTouched) {
            updateUserDetailsInStore({
                key: 'name',
                value: name,
            })
            setNameTouched(false)
        }
    }, [name, nameTouched, updateUserDetailsInStore])

    useEffect(() => {
        if (emailTouched) {
            updateUserDetailsInStore({
                key: 'email',
                value: email,
            })
            setEmailTouched(false)
        }
    }, [email, emailTouched, updateUserDetailsInStore])

    useEffect(() => {
        if (phoneTouched) {
            updateUserDetailsInStore({
                key: 'phone',
                value: phone,
            })
            setPhoneTouched(false)
        }
    }, [phone, phoneTouched, updateUserDetailsInStore])

    useEffect(() => {
        if (radioCallSignTouched) {
            updateUserDetailsInStore({
                key: 'radioCallSign',
                value: radioCallSign,
            })
            setRadioCallSignTouched(false)
        }
    }, [radioCallSign, radioCallSignTouched, updateUserDetailsInStore])

    const updateDetails = useCallback(
        async (changedField) => {
            const updateData = {
                key: null,
                value: null,
            }

            if (changedField.key === 'colourScheme') {
                const colourScheme = changedField.value
                switcher({ theme: colourScheme })
            }
            if (changedField.key === 'language') {
                runLocaleSideEffects(changedField.value)
            }

            switch (changedField.key) {
                case 'name':
                    debouncedNameHandler(changedField.value)
                    break
                case 'email':
                    debouncedEmailHandler(changedField.value)
                    break
                case 'phone':
                    debouncedPhoneHandler(changedField.value)
                    break
                case 'radioCallSign':
                    debouncedRadioCallSignHandler(changedField.value)
                    break
                default:
                    updateData.value = changedField.value
                    updateData.key = changedField.key
            }

            if (updateData.key === null) {
                return
            }

            const newUserDetails = updateUserDetailsObject(userDetails, updateData)
            await updateUserDetails(newUserDetails)
            successNotification(successMessage, 'profile-settings-updated')
        },
        [
            debouncedEmailHandler,
            debouncedNameHandler,
            debouncedPhoneHandler,
            debouncedRadioCallSignHandler,
            successMessage,
            switcher,
            updateUserDetails,
            userDetails,
        ]
    )

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            debouncedNameHandler.cancel()
            debouncedEmailHandler.cancel()
            debouncedPhoneHandler.cancel()
            debouncedRadioCallSignHandler.cancel()
        }
    }, [debouncedEmailHandler, debouncedNameHandler, debouncedPhoneHandler, debouncedRadioCallSignHandler])

    // Explicitly reset form after saved edits trigger a re-render (see: https://stackoverflow.com/questions/65382963/antd-form-initial-value-not-updating-after-state-change). Note that this interferes with the text-field debouces, so I will hide it for now.
    useEffect(() => {
        form.resetFields()
    }, [form, userDetails])

    return (
        <Form
            className="grid"
            form={form}
            initialValues={userDetails}
            onValuesChange={autoUpdate}
            disabled={editingDisabled}
        >
            <Row gutter={12}>
                <Col span={12}>
                    <Form.Item
                        name="name"
                        label={<FormattedMessage id="name" defaultMessage="Name" description="User settings page" />}
                    >
                        {/* The flush on blur triggers an immediate save to prevent stale-state issues */}
                        <Input onBlur={() => debouncedNameHandler.flush()} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="email"
                        label={
                            <FormattedMessage
                                id="emailAddress"
                                defaultMessage="Email Address"
                                description="User settings page"
                            />
                        }
                    >
                        <Input onBlur={() => debouncedEmailHandler.flush()} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span={12}>
                    <Form.Item
                        name="phone"
                        label={
                            <FormattedMessage
                                id="phone"
                                defaultMessage="Phone Number"
                                description="User settings page"
                            />
                        }
                    >
                        <Input onBlur={() => debouncedPhoneHandler.flush()} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="radioCallSign"
                        label={
                            <FormattedMessage
                                id="callsign"
                                defaultMessage="Call Sign"
                                description="User settings page"
                            />
                        }
                    >
                        <Input onBlur={() => debouncedRadioCallSignHandler.flush()} />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item name="language" label={<FormattedMessage {...messages.language} />}>
                <Radio.Group>
                    {[...LOCALE_TEXTS].map(([key, value]) => (
                        <Radio key={key} value={key}>
                            {value}
                        </Radio>
                    ))}
                </Radio.Group>
            </Form.Item>

            <Form.Item name="colourScheme" label={<FormattedMessage {...messages.colourScheme} />}>
                <Radio.Group>
                    <Radio value="light">
                        <FormattedMessage {...messages.light} />
                    </Radio>
                    <Radio value="dark">
                        <FormattedMessage {...messages.dark} />
                    </Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item name="dateFormat" label={<FormattedMessage {...messages.dateFormat} />}>
                <Radio.Group>
                    {[...DATE_FORMATS].map((key) => (
                        <Radio key={key} value={key}>
                            <FormattedMessage {...messages[key]} />
                        </Radio>
                    ))}
                </Radio.Group>
            </Form.Item>
            <Form.Item name="notifications" label={<FormattedMessage {...messages.notifications} />}>
                <Radio.Group>
                    <Radio value="error">
                        <FormattedMessage {...messages.errorNotifications} />
                    </Radio>
                    <Radio value="all">
                        <FormattedMessage {...messages.allNotifications} />
                    </Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                name="enhancedAccessibility"
                label={
                    <Tooltip title={intl.formatMessage({ ...messages.accessibilityTooltip })}>
                        <FormattedMessage {...messages.accessibility} /> <QuestionCircleOutlined />
                    </Tooltip>
                }
            >
                <Radio.Group>
                    <Radio value={false} data-test="standardAccessibility">
                        <FormattedMessage {...messages.standardAccessibility} />
                    </Radio>
                    <Radio value={true} data-test="enhancedAccessibility">
                        <FormattedMessage {...messages.enhancedAccessibility} />
                    </Radio>
                </Radio.Group>
            </Form.Item>
        </Form>
    )
}

// Helpers

function updateUserDetailsObject(userDetails, updateData) {
    const data = { ...userDetails }

    data[updateData.key] = updateData.value

    return data
}
