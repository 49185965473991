import { Row, Col, Tag } from 'antd'
import { DeleteOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import moment from 'moment'
import { FormattedMessage, useIntl } from 'react-intl'

import { useLocale } from 'stores/UserStore'
import messages from 'services/intl/messageDefinitions'
import { generateCloudinaryThumbnailUrl } from 'utils/Image'

export const ImageCard = ({ image, handleImageClick, handleLeft, handleDelete, handleRight }) => {
    const locale = useLocale()
    const intl = useIntl()

    const isOldImage = !image.isArchived && moment().diff(moment(image.dateTaken), 'days') >= 3
    const isAllFieldsComplete = areAllFieldsComplete(image, locale)

    const tags = []
    if (isOldImage) {
        tags.push(
            <Tag color="orange" style={styles.tag} key="oldImageTag">
                {intl.formatMessage({ ...messages.daysAgo }, { value: moment().diff(moment(image.dateTaken), 'days') })}
            </Tag>
        )
    }
    if (!isAllFieldsComplete) {
        tags.push(
            <Tag color="red" style={styles.tag} key="isAllFieldsCompleteTag">
                <FormattedMessage {...messages.incomplete} />
            </Tag>
        )
    }
    if (image.isArchived) {
        tags.push(
            <Tag color="grey" style={styles.tag} key="isArchivedTag">
                <FormattedMessage {...messages.archival} />
            </Tag>
        )
    }

    return (
        <div style={{ ...styles.imageContainer }} role="button" tabIndex={0} aria-label={'edit image'}>
            <Row style={styles.contolsContainer}>
                <Col style={styles.controlCol} span={8}>
                    {handleLeft && <ArrowLeftOutlined onClick={(e) => handleLeft(e, image)} role="button" />}
                </Col>
                <Col style={styles.controlCol} span={8}>
                    <DeleteOutlined onClick={(e) => handleDelete(e, image)} />
                </Col>
                <Col style={styles.controlCol} span={8}>
                    {handleRight && <ArrowRightOutlined onClick={(e) => handleRight(e, image)} />}
                </Col>
            </Row>
            <Row style={styles.imageSection} onClick={() => handleImageClick()}>
                <img src={generateCloudinaryThumbnailUrl(image.url, 600, 600)} alt={image.alt} style={styles.image} />
                <div style={styles.chipContainer}>{tags.map((tag) => tag)}</div>
            </Row>
        </div>
    )
}

const styles = {
    imageContainer: {
        marginRight: 'var(--s-1)',
        marginBottom: 'var(--s0)',
        width: '200px',
        height: '244px',
        border: '2px solid var(--grey-dark)',
        borderRadius: '4px',
    },
    contolsContainer: {
        width: '100%',
        height: '40px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    controlCol: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    imageSection: {
        height: '200px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        cursor: 'pointer',
        tabIndex: 0,
    },
    image: {
        width: '200px',
        height: '200px',
        objectFit: 'cover',
    },
    chipContainer: {
        position: 'absolute',
        bottom: '5px',
        left: '5px',
        display: 'flex',
        flexDirection: 'column',
    },
    tag: {
        marginBottom: '2px',
    },
}

export const areAllFieldsComplete = (image, locale = 'en') => {
    return image.altText && image.altText[locale] && image.caption && image.caption[locale] && image.dateTaken
        ? true
        : false
}
