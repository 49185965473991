import React, { useState, useEffect, useCallback } from 'react'

import { FormattedMessage, useIntl } from 'react-intl'
import { Form, Input, DatePicker, Divider, Row, Col, Button, InputNumber } from 'antd'
import moment from 'moment'

import { useLocale } from 'stores/UserStore'
import { useUpdateWeakLayers, useDraftLayer, useUpdateDraftLayer, useAddFilter } from 'stores/WeakLayerStore'
import { postWeakLayer, fetchWeakLayers } from 'services/WeakLayers'
import { FILTER_OPTIONS, getColourForGrainType, GRAIN_TYPES, schema } from 'utils/WeakLayers'
import { AspectElevation } from 'components/AspectElevation/AspectElevation'
import { useSendNotification } from 'utils/Notifications'
import { CommentEditor } from 'components/CommentEditor/CommentEditor'
import { Comments } from 'components/Comments/Comments'
import { Instructions } from 'components/Map/Instructions'
import { Swatch } from 'components/Swatch/Swatch'
import messages from 'services/intl/messageDefinitions'
import { Select } from 'components/Dropdown/Select'
import './LayerForm.css'
import { Accessibility } from 'components/Accessibility'

export const LayerForm = ({
    toggleEdit,
    update,
    initialValues = schema,
    autoUpdate,
    closeDrawer,
    create,
    drawerVisible,
}) => {
    const intl = useIntl()
    const [name, setName] = useState(initialValues.name)
    const [nameTouched, setNameTouched] = useState(false)
    const [showDepthError, setShowDepthError] = useState(false)
    const [showThicknessError, setShowThicknessError] = useState(false)
    const locale = useLocale()
    const updateWeakLayers = useUpdateWeakLayers()
    const draftLayer = useDraftLayer()
    const updateDraftLayer = useUpdateDraftLayer()
    const addFilter = useAddFilter()
    // const [colour, setColour] = useState(initialValues.colour)
    const [form] = Form.useForm()
    const sendNotification = useSendNotification()
    const formattedInitialValues = { ...initialValues }
    formattedInitialValues.burialDate = moment(initialValues.burialDate)
    formattedInitialValues.thicknessFrom = initialValues.thickness.from
    formattedInitialValues.thicknessTo = initialValues.thickness.to
    formattedInitialValues.depthFrom = initialValues.depth.from
    formattedInitialValues.depthTo = initialValues.depth.to

    const statusOptions = FILTER_OPTIONS.map((item, i) => {
        return (
            <Select.Option key={i} value={item.value}>
                <FormattedMessage {...messages[item.label]} />
            </Select.Option>
        )
    })

    const grainTypeOptions = GRAIN_TYPES.map((item, i) => {
        const standardA11yComponent = <Swatch colour={item.colour} />

        return (
            <Select.Option key={i} value={item.value}>
                <Accessibility standardA11yComponent={standardA11yComponent} />
                <FormattedMessage {...messages[item.label]} />
            </Select.Option>
        )
    })

    const getLayers = useCallback(async () => {
        const response = await fetchWeakLayers(locale)
        updateWeakLayers(response.data)
    }, [])

    const postLayer = useCallback(async (data, locale) => {
        await postWeakLayer(data, locale)
        sendNotification(intl.formatMessage({ ...messages.weakLayerSaved }, { name: data.name }))
        getLayers()
        closeDrawer()
        form.resetFields()
    }, [])

    const disabledDate = (current) => {
        return current && current > moment()
    }

    // validate that from is less than to
    const validateRange = (from, to) => {
        if (from < to) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        form.resetFields()
    }, [drawerVisible])

    // prevent multiple PUT request when user is typing
    useEffect(() => {
        const handler = setTimeout(() => {
            if (autoUpdate && nameTouched) {
                const updateData = {
                    key: 'name',
                    value: name,
                }
                update([updateData])
                setNameTouched(false)
            }
        }, 1000)

        return () => {
            clearTimeout(handler)
        }
    }, [autoUpdate, name, nameTouched, update])

    const cancelDraft = () => {
        closeDrawer()
        form.resetFields()
    }

    const createNewLayer = (formData, errors) => {
        // TODO: we should get this from the schema in utils
        const newWeakLayer = {
            thickness: {},
            depth: {},
            aspectElevations: [],
            polygons: [],
        }
        newWeakLayer.depth.from = parseInt(formData.depthFrom)
        newWeakLayer.depth.to = parseInt(formData.depthTo)
        newWeakLayer.thickness.from = parseFloat(formData.thicknessFrom)
        newWeakLayer.thickness.to = parseFloat(formData.thicknessTo)
        newWeakLayer.name = formData.name
        newWeakLayer.burialDate = formData.burialDate.format('YYYY-MM-DD')
        newWeakLayer.status = formData.status
        newWeakLayer.grainType = formData.grainType
        newWeakLayer.polygons = draftLayer.polygons // TODO have only a single source of truth
        newWeakLayer.aspectElevations = draftLayer.aspectElevations // TODO have only a single source of truth
        newWeakLayer.comments = draftLayer.comments // TODO have only a single source of truth

        if (newWeakLayer.depth.from > newWeakLayer.depth.to) {
            showDepthError(true)
        } else if (newWeakLayer.thickness.from > newWeakLayer.thickness.to) {
            showThicknessError(true)
        } else {
            postLayer(newWeakLayer, locale)
        }
    }

    const updateDetails = (changedField) => {
        const updateData = {
            key: null,
            value: null,
        }

        switch (changedField.key) {
            case 'burialDate':
                updateData.value = changedField.value.format('YYYY-MM-DD')
                updateData.key = 'burialDate'
                break
            case 'name':
                // wait a couple seconds to let user finish typing
                const formattedName = changedField.value.trim() // remove trailing or procedding white space
                if (formattedName.length >= 3 && formattedName.length <= 100) {
                    setName(formattedName)
                    setNameTouched(true)
                }
                break
            case 'status':
                addFilter(changedField.value)
            default:
                updateData.value = changedField.value
                updateData.key = changedField.key
        }

        if (updateData.value && updateData.key) {
            update([updateData])
        }
    }

    // validate details regardless of autoUpdate value
    const autoChecker = (changeValues) => {
        const changedField = Object.keys(changeValues)[0]
        const parsedValue = Object.values(changeValues)[0]

        let valid = true
        let updateData = []

        switch (changedField) {
            case 'depthFrom':
            case 'depthTo':
                const depthFrom = parseInt(form.getFieldsValue().depthFrom)
                const depthTo = parseInt(form.getFieldsValue().depthTo)

                if (isNaN(depthFrom) || isNaN(depthTo)) {
                    setShowDepthError(false)
                    valid = false
                    break
                }

                if (!validateRange(depthFrom, depthTo)) {
                    setShowDepthError(true)
                    valid = false
                } else {
                    setShowDepthError(false)
                    updateData = { key: 'depth', value: { from: depthFrom, to: depthTo } }
                }
                break

            case 'thicknessFrom':
            case 'thicknessTo':
                const thicknessFrom = Math.round(parseFloat(form.getFieldsValue().thicknessFrom) * 10) / 10
                const thicknessTo = Math.round(parseFloat(form.getFieldsValue().thicknessTo) * 10) / 10

                if (isNaN(thicknessFrom) || isNaN(thicknessTo)) {
                    setShowThicknessError(false)
                    valid = false
                    break
                }

                if (!validateRange(thicknessFrom, thicknessTo)) {
                    setShowThicknessError(true)
                    valid = false
                } else {
                    setShowThicknessError(false)
                    updateData = { key: 'thickness', value: { from: thicknessFrom, to: thicknessTo } }
                }
                break

            case 'grainType':
                if (draftLayer) {
                    updateDraftLayer({
                        ...draftLayer,
                        colour: getColourForGrainType(changeValues.grainType),
                    })
                }
            default:
                updateData = { key: changedField, value: parsedValue }
        }

        if (valid && autoUpdate) {
            updateDetails(updateData)
        }
    }

    const doneAction = autoUpdate ? (
        <Button type="primary" style={styles.closeEditing} onClick={toggleEdit} data-test={'closeEditingButton'}>
            <FormattedMessage {...messages.closeEditing} />
        </Button>
    ) : (
        <div style={styles.buttonContainer}>
            <Button onClick={cancelDraft} style={styles.cancelButton}>
                <FormattedMessage {...messages.cancel} />
            </Button>
            <Button type="primary" onClick={() => form.submit()} data-test={'createWeakLayerSubmitButton'}>
                <FormattedMessage {...messages.create} />
            </Button>
        </div>
    )

    const title = !autoUpdate ? (
        <h1 style={styles.title}>
            <FormattedMessage {...messages.createNewWeakLayer} />
        </h1>
    ) : (
        <h1 style={styles.title}>
            <FormattedMessage {...messages.editWeakLayer} />
        </h1>
    )

    return (
        <div>
            {title}
            <Instructions />
            <Form
                form={form}
                layout="horizontal"
                onFinish={createNewLayer}
                autoComplete="off"
                onValuesChange={autoChecker}
                initialValues={formattedInitialValues}
            >
                <Form.Item
                    label={<FormattedMessage {...messages.weakLayerName} />}
                    labelCol={{ span: 24 }}
                    name="name"
                    rules={[
                        { required: true, message: <FormattedMessage {...messages.nameRequired} /> },
                        { min: 3, message: <FormattedMessage {...messages.nameTooShort} /> },
                        { max: 100, message: <FormattedMessage {...messages.nameTooLong} /> },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage {...messages.burialDate} />}
                    labelCol={{ span: 24 }}
                    name="burialDate"
                    rules={[{ required: true, message: <FormattedMessage {...messages.burialDateRequiredMessage} /> }]}
                >
                    <DatePicker disabledDate={disabledDate} />
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage {...messages.status} />}
                    labelCol={{ span: 24 }}
                    name="status"
                    rules={[{ required: true, message: <FormattedMessage {...messages.statusRequiredMessage} /> }]}
                    data-test={'createWeakLayerSelectStatus'}
                >
                    <Select>{statusOptions}</Select>
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage {...messages.grainType} />}
                    labelCol={{ span: 24 }}
                    name="grainType"
                    rules={[{ required: true, message: <FormattedMessage {...messages.grainTypeRequiredMessage} /> }]}
                    data-test={'createWeakLayerSelectGrainType'}
                >
                    <Select listHeight="100%">{grainTypeOptions}</Select>
                </Form.Item>
                <Row gutter={16}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label={<FormattedMessage {...messages.depthFrom} />}
                            labelCol={{ span: 24 }}
                            name="depthFrom"
                            rules={[
                                { required: true, message: <FormattedMessage {...messages.depthRequiredMessage} /> },
                            ]}
                        >
                            <InputNumber type="number" min={0} precision={0} style={styles.inputNumber} />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label={<FormattedMessage {...messages.depthTo} />}
                            labelCol={{ span: 24 }}
                            name="depthTo"
                            rules={[
                                { required: true, message: <FormattedMessage {...messages.depthRequiredMessage} /> },
                            ]}
                        >
                            <InputNumber type="number" min={0} precision={0} style={styles.inputNumber} />
                        </Form.Item>
                    </Col>
                    <Col style={showDepthError ? styles.show : styles.hide} className="gutter-row" span={24}>
                        <p style={styles.errorMessage}>
                            <FormattedMessage {...messages.toFromError} />
                        </p>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label={<FormattedMessage {...messages.thicknessFrom} />}
                            labelCol={{ span: 24 }}
                            name="thicknessFrom"
                            rules={[
                                {
                                    required: true,
                                    message: <FormattedMessage {...messages.thicknessRequiredMessage} />,
                                },
                            ]}
                        >
                            <InputNumber type="number" min={0} precision={1} step={0.1} style={styles.inputNumber} />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={12}>
                        <Form.Item
                            label={<FormattedMessage {...messages.thicknessTo} />}
                            labelCol={{ span: 24 }}
                            name="thicknessTo"
                            rules={[
                                {
                                    required: true,
                                    message: <FormattedMessage {...messages.thicknessRequiredMessage} />,
                                },
                            ]}
                        >
                            <InputNumber type="number" min={0} precision={1} step={0.1} style={styles.inputNumber} />
                        </Form.Item>
                    </Col>
                    <Col style={showThicknessError ? styles.show : styles.hide} className="gutter-row" span={24}>
                        <p style={styles.errorMessage}>
                            <FormattedMessage {...messages.toFromError} />
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p style={styles.label}>
                            <FormattedMessage {...messages.aspectElevation} />
                        </p>
                    </Col>
                </Row>
                <Row style={styles.grid} align="center">
                    <Col className="aspectElevationContainer" style={styles.aspectElevation}>
                        <AspectElevation
                            updateDraftItem={updateDraftLayer}
                            draftItem={draftLayer}
                            aspectElevations={create ? draftLayer.aspectElevations : initialValues.aspectElevations}
                            update={update}
                            create={create}
                            colour={create ? draftLayer.colour : initialValues.colour}
                        />
                    </Col>
                </Row>
            </Form>
            {doneAction}
            <Divider plain />
            <CommentEditor update={update} item={create ? draftLayer : initialValues} create={create} />
            <Comments comments={create ? draftLayer.comments : initialValues.comments} />
        </div>
    )
}

const styles = {
    button: {
        margin: '0 var(--s0) 0 0',
        color: '#fff',
    },
    closeEditing: {
        float: 'right',
        marginBottom: 'var(--s1)',
    },
    container: {
        display: 'flex',
    },
    cancelButton: {
        margin: '0 var(--s0) 0 0',
        color: '#fff',
        background: 'var(--grey)',
        borderColor: 'var(--grey)',
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'right',
        justifyContent: 'right',
    },
    errorMessage: {
        fontSize: '14px',
        marginTop: '-25px',
        color: 'var(--red)',
        fontWeight: '300',
    },
    hide: {
        display: 'none',
    },
    show: {
        display: 'block',
    },
    aspectElevation: {
        width: '100%',
    },
    title: {
        fontWeight: 300,
    },
    inputNumber: {
        width: '100%',
    },
}
