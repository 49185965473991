import { useIntl } from 'react-intl'

import { create } from 'utils/LocalStorage'
import { putAvalancheForecast } from 'services/AvalancheForecast'
import { useLocale } from 'stores/UserStore'
import { API_SUCCESS_CODES } from 'utils/Constants'
import { sendErrorNotification } from 'utils/Notifications'
import messages from 'services/intl/messageDefinitions'

export const MAX_UNDO_ITEMS = 20

export const useUndo = () => {
    const undo = create('undo')
    const locale = useLocale()
    const intl = useIntl()

    // undo the last update to the forecast by section
    const undoUpdate = async ({ versionNumber, content, section }) => {
        const undoData = await undo.get()
        const { undoItems, popMostRecent } = undoData[content.id]

        // if there is nothing to undo, return
        if (undoItems[section].length < 1) {
            sendErrorNotification(intl.formatMessage({ ...messages.nothingToUndo }))
            return undefined
        }

        // if the last update was made by a user action, pop the most recent update so the next undo will be the previous update
        if (popMostRecent && undoItems[section].length > 1) {
            await undoItems[section].pop()
        }

        const lastItem = undoItems[section].pop()
        const updatedUndo = {
            [content.id]: {
                undoItems: {
                    ...undoItems,
                    [section]: [...undoItems[section]],
                },
                popMostRecent: false,
            },
        }
        undo.set(updatedUndo)

        const response = await putAvalancheForecast(
            {
                ...content,
                [section]: lastItem[section],
                versionNumber,
            },
            locale
        )

        if (API_SUCCESS_CODES.includes(response.status) && response.data) {
            return response.data
        } else {
            sendErrorNotification(intl.formatMessage({ ...messages.undoError }))
        }

        return undefined
    }

    // store the current content in the undo store by section
    const storeInUndo = async (content, section) => {
        const undoData = await undo.get()

        // check if content is undefined, null, not an object, or an empty object
        if (
            content === undefined ||
            content === null ||
            typeof content !== 'object' ||
            Object.keys(content).length < 1
        ) {
            return
        }

        const { undoItems } = undoData[content.id]

        if (undoItems[section] && undoItems[section].length > MAX_UNDO_ITEMS) {
            undoItems[section].shift()
        } else if (!undoItems[section]) {
            undoItems[section] = []
        }

        const updatedUndo = {
            ...undoData,
            [content.id]: {
                undoItems: {
                    ...undoItems,
                    [section]: [...undoItems[section], content],
                },
                popMostRecent: true,
            },
        }
        undo.set(updatedUndo)
    }

    // initialize the undo store by section, this can be called any time the content is updated it ensures there is an undo store for the section
    const initUndo = (content, section) => {
        const undoStore = undo.get()

        // check if content is undefined, null, not an object, or an empty object
        if (
            content === undefined ||
            content === null ||
            typeof content !== 'object' ||
            Object.keys(content).length < 1
        ) {
            return
        }
        
        const undoData = undoStore && undoStore[content.id] ? undoStore[content.id] : undefined

        if (undoData === undefined || undoData === null || typeof undoData.undoItems !== 'object') {
            undo.set({
                ...undoData,
                [content.id]: {
                    undoItems: {
                        [section]: [content],
                    },
                    popMostRecent: true,
                },
            })
            return
        }

        const { undoItems } = undoData
        if (undoItems === undefined || undoItems === null) {
            undo.set({
                ...undoData,
                [content.id]: {
                    undoItems: {
                        [section]: [content],
                    },
                    popMostRecent: true,
                },
            })
        } else if (undoItems[section] === undefined || undoItems[section] === null || undoItems[section].length < 1) {
            undo.set({
                ...undoData,
                [content.id]: {
                    undoItems: {
                        ...undoItems,
                        [section]: [content],
                    },
                    popMostRecent: true,
                },
            })
        }
        return
    }

    return {
        undoUpdate,
        storeInUndo,
        initUndo,
    }
}
