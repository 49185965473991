import React from 'react'
import { EditScreenHeader } from 'components/EditScreenHeader/EditScreenHeader'

export const EditScreenLayout = ({ children, showDaySelector, compressed }) => {
    return (
        <div style={{ ...styles.container, background: !compressed ? 'var(--grey-light)' : undefined }}>
            <EditScreenHeader showDaySelector={showDaySelector} hideTitle={compressed} />
            {children}
        </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexFlow: 'column',
        minHeight: '100%',
    },
}
