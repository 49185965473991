import create from 'zustand'
import produce from 'immer'
import { filterAndSort, schema } from 'utils/WeakLayers'

const WeakLayerStore = create((set, get) => ({
    weakLayers: [],
    filteredLayers: [],
    filters: ['active'],
    polygons: [],
    editingWeakLayer: false,
    activeWeakLayerId: null,
    draftLayer: schema,
    updateDraftLayer: (draftLayer) => {
        set({ draftLayer })
    },
    resetDraftLayer: () => {
        set({ draftLayer: schema })
    },
    updateWeakLayers: (weakLayers) => {
        const updatedList = filterAndSort(get().weakLayers, 'status', get().filters)
        set({ weakLayers, filteredLayers: updatedList })
    },
    updateLayer: (layer) => {
        set(
            produce((draft) => {
                for (let l in draft.weakLayers) {
                    if (draft.weakLayers[l].id == layer.id) {
                        draft.weakLayers[l] = layer
                    }
                }
            })
        )
    },
    addFilter: (filter) => {
        set(
            produce((draft) => {
                let filters = draft.filters
                if (filters.indexOf(filter) < 0) {
                    filters.push(filter)
                }
            })
        )
        // run side effects
        const updatedList = filterAndSort(get().weakLayers, 'status', get().filters)
        set({ filteredLayers: updatedList })
    },
    removeFilter: (filter) => {
        set(
            produce((draft) => {
                let filters = draft.filters
                const spliceIndex = filters.indexOf(filter)
                filters.splice(spliceIndex, 1)
            })
        )
        // run side effects
        const updatedList = filterAndSort(get().weakLayers, 'status', get().filters)
        set({ filteredLayers: updatedList })
    },
    updatePolygons: (polygons) => {
        set({ polygons })
    },
    setActiveWeakLayerId: (id) => {
        set({ activeWeakLayerId: id })
    },
    clearActiveWeakLayerId: () => {
        set({ activeWeakLayerId: null, editingWeakLayer: false })
    },
    setEditingWeakLayer: (editingWeakLayer) => {
        set({ editingWeakLayer })
    },
}))

export const useWeakLayers = () => WeakLayerStore((state) => state.weakLayers)
export const useUpdateWeakLayers = () => WeakLayerStore((state) => state.updateWeakLayers)
export const useUpdateLayer = () => WeakLayerStore((state) => state.updateLayer)
export const useFilters = () => WeakLayerStore((state) => state.filters)
export const useAddFilter = () => WeakLayerStore((state) => state.addFilter)
export const useRemoveFilter = () => WeakLayerStore((state) => state.removeFilter)
export const useFilteredLayers = () => WeakLayerStore((state) => state.filteredLayers)
export const usePolygons = () => WeakLayerStore((state) => state.polygons)
export const useUpdatePolygons = () => WeakLayerStore((state) => state.updatePolygons)
export const useDraftLayer = () => WeakLayerStore((state) => state.draftLayer)
export const useUpdateDraftLayer = () => WeakLayerStore((state) => state.updateDraftLayer)
export const useResetDraftLayer = () => WeakLayerStore((state) => state.resetDraftLayer)
export const useActiveWeakLayer = () =>
    WeakLayerStore((state) => {
        if (state.activeWeakLayerId === 'draft') {
            return state.draftLayer
        }
        return state.weakLayers.find((weakLayer) => weakLayer.id === state.activeWeakLayerId)
    })
export const useSetActiveWeakLayerId = () => WeakLayerStore((state) => state.setActiveWeakLayerId)
export const useClearActiveWeakLayerId = () => WeakLayerStore((state) => state.clearActiveWeakLayerId)
export const useEditingWeakLayer = () => WeakLayerStore((state) => state.editingWeakLayer)
export const useSetEditingWeakLayer = () => WeakLayerStore((state) => state.setEditingWeakLayer)
