import React, { useRef, useEffect } from 'react'

import * as ReactDOM from 'react-dom'
import mapbox from 'mapbox-gl'

import { useMap } from './Map'

export const Marker = ({ options = {}, lngLat, on = {}, children }) => {
    const map = useMap()
    const ref = useRef(document.createElement('div'))
    const marker = useRef(null)

    useEffect(() => {
        marker.current = new mapbox.Marker({ ...options, element: ref.current })

        marker.current.setLngLat(lngLat).addTo(map)

        return () => {
            marker.current.remove()
        }
    }, [])

    useEffect(() => {
        marker.current.setLngLat(lngLat)
    }, [lngLat])

    useEffect(() => {
        const events = Object.entries(on)

        for (const [type, listener] of events) {
            marker.current.on(type, listener)
        }

        return () => {
            for (const [type, listener] of events) {
                marker.current.off(type, listener)
            }
        }
    }, [on])

    return ReactDOM.createPortal(children, ref.current)
}
