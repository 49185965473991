import { APIClient } from './APIClient'

export const fetchSpecialProducts = (language) => {
    return APIClient.get(`/v1/${language}/products/specialproduct`, language)
}

export const fetchSpecialProduct = (id, language) => {
    return APIClient.get(`/v1/${language}/products/id/${id}`, language)
}

export const fetchSpecialProductsByDates = (startDate, endDate, searchTerm, language) => {
    return APIClient.get(
        `/v1/${language}/products/specialproduct/history?startDate=${startDate}&endDate=${endDate}&searchTerm=${searchTerm}`,
        language
    )
}

export const postSpecialProduct = (data, language) => {
    return APIClient.post(`/v1/${language}/products/specialproduct`, {
        ...data,
    })
}

export const putSpecialProduct = (data, language) => {
    return APIClient.put(`/v1/${language}/products/specialproduct`, {
        ...data,
    })
}

export const publishSpecialProduct = (id, language) => {
    return APIClient.post(`/v1/${language}/products/publish/${id}`)
}

export const deleteSpecialProduct = (id, language) => {
    return APIClient.delete(`/v1/${language}/products/id/` + id, language)
}

export const cloneSpecialProduct = (id, language) => {
    return APIClient.post(`/v1/${language}/products/clone/` + id, language)
}

export const cloneAllSpecialProducts = (language) => {
    return APIClient.post(`/v1/${language}/products/specialproduct/cloneall`, language)
}

export const expireSpecialProductNow = (id, language) => {
    return APIClient.post(`/v1/${language}/products/id/${id}/expirenow`, language)
}
