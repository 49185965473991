import { useEffect, useCallback, useMemo, useRef } from 'react'

import MapboxDraw from '@mapbox/mapbox-gl-draw'
import bbox from '@turf/bbox'
import intersect from '@turf/intersect'
import { useThemeSwitcher } from 'react-css-theme-switcher'

import { hexToRGB } from 'utils/Color'
import FreeDraw from 'utils/FreeDraw'
import { useLocale } from 'stores/UserStore'
import { useMap } from './Map'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { darkSchemePolygonsColour, lightSchemePolygonsColour } from './mapHelpers'

const MAP_BUFFER = 50

export const SingleProduct = ({
    useEditing,
    useActiveItem,
    polygons,
    useUpdateItem,
    apiCall,
    useDraftItem,
    useUpdateDraftItem,
}) => {
    const source = 'polygons'
    const map = useMap()
    const isEditing = useEditing()
    const activeItem = useActiveItem()
    const resetFilter = useMemo(() => ['in', 'id', ''], [])
    const locale = useLocale()
    const draftItem = useDraftItem()
    const updateDraftItem = useUpdateDraftItem()
    const updateItem = useUpdateItem()
    const drawToolRef = useRef()
    const { currentTheme } = useThemeSwitcher()
    const polygonsColour = currentTheme === 'light' ? lightSchemePolygonsColour : darkSchemePolygonsColour

    const zoomExtents = useCallback((polygons) => {
        if (process.env.REACT_APP_INITIAL_ZOOM) {
            map.fitBounds(JSON.parse(process.env.REACT_APP_INITIAL_ZOOM))
        } else {
            let bounds = bbox(polygons)
            map.fitBounds(bounds, { padding: MAP_BUFFER })
        }
    }, [])

    useEffect(() => {
        zoomExtents(polygons)
    }, [])

    useEffect(() => {
        if (activeItem && activeItem.polygons.length > 0 && !isEditing) {
            const activeItemPolygons = {
                ...polygons,
                features: polygons.features.filter((polygon) => {
                    return activeItem.polygons.includes(polygon.properties.id)
                }),
            }
            const bounds = bbox(activeItemPolygons)
            map.fitBounds(bounds, { padding: MAP_BUFFER })
        }

        if (!activeItem) {
            zoomExtents(polygons)
        }
    }, [activeItem, isEditing])

    const itemPutCall = useCallback(
        async (data, locale) => {
            const response = await apiCall(data, locale)
            updateItem(response.data)
        },
        [apiCall, updateItem]
    )

    const update = useCallback(
        (details) => {
            const data = { ...activeItem }
            for (let d in details) {
                const detail = details[d]
                data[detail.key] = detail.value
            }
            itemPutCall(data, locale)
        },
        [activeItem, itemPutCall, locale]
    )

    const mergeClickedPolygonsWithExisting = useCallback(
        (ids) => {
            if (activeItem) {
                const polygons = new Set(activeItem.polygons)
                for (const id of ids) {
                    if (polygons.has(id)) {
                        polygons.delete(id)
                    } else {
                        polygons.add(id)
                    }
                }

                return Array.from(polygons)
            }
        },
        [activeItem]
    )

    const mergeLassoedPolygonsWithExisting = useCallback(
        (ids, mode) => {
            if (activeItem) {
                const polygons = new Set(activeItem.polygons)
                if (mode === 'add') {
                    for (const id of ids) {
                        if (!polygons.has(id)) {
                            polygons.add(id)
                        }
                    }
                } else {
                    for (const id of ids) {
                        if (polygons.has(id)) {
                            polygons.delete(id)
                        }
                    }
                }

                return Array.from(polygons)
            }
        },
        [activeItem]
    )

    const updateExistingOrDraftItem = useCallback(
        (polygons) => {
            if (activeItem && activeItem.id) {
                const updateData = {
                    key: 'polygons',
                    value: polygons,
                }
                update([updateData])
            } else {
                updateDraftItem({
                    ...draftItem,
                    polygons,
                })
            }
        },
        [activeItem, draftItem, update, updateDraftItem]
    )

    const clickPolygon = useCallback(
        (event) => {
            if (activeItem && isEditing) {
                const features = event.target.queryRenderedFeatures(event.point, {
                    layers: [source, source + '-line'],
                })
                const ids = new Set(features.map((feature) => feature.properties.id))

                if (ids.size > 0) {
                    updateExistingOrDraftItem(mergeClickedPolygonsWithExisting(ids))
                }
            }
        },
        [activeItem, isEditing, updateExistingOrDraftItem, mergeClickedPolygonsWithExisting]
    )

    const updateArea = useCallback(
        (event) => {
            const shiftHeld = window.event.shiftKey
            const mode = shiftHeld ? 'remove' : 'add'
            const [polygon] = event.features

            const ids = polygons.features
                .filter((feature) => intersect(feature, polygon))
                .map((feature) => feature.properties.id)

            updateExistingOrDraftItem(mergeLassoedPolygonsWithExisting(ids, mode))

            drawToolRef.current.deleteAll()
        },
        [polygons.features, updateExistingOrDraftItem, mergeLassoedPolygonsWithExisting]
    )

    useEffect(() => {
        drawToolRef.current = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: true,
            },
            modes: Object.assign(MapboxDraw.modes, {
                draw_polygon: FreeDraw,
            }),
        })
        map.addControl(drawToolRef.current)

        return () => {
            // HACK: Check if the drawTool exists before removing it to prevent crash during navigation
            if (map.hasControl(drawToolRef.current)) {
                map.removeControl(drawToolRef.current)
            }
        }
    }, [])

    // Show/hide the lasso button
    useEffect(() => {
        const lassoButton = document.getElementsByClassName('mapbox-gl-draw_ctrl-draw-btn')
        if (activeItem && isEditing) {
            if (lassoButton) {
                lassoButton[0].classList.remove('inactive')
            }
        } else {
            if (lassoButton) {
                lassoButton[0].classList.add('inactive')
            }
        }
    }, [activeItem, isEditing])

    useEffect(() => {
        if (!map.getSource(source)) {
            map.addSource(source, { promoteId: 'id', type: 'geojson', data: polygons })
        }
        if (!map.getLayer(source)) {
            map.addLayer({
                id: source,
                source,
                type: 'fill',
                paint: {
                    'fill-color': polygonsColour,
                },
            })

            map.addLayer({
                id: source + '-line',
                source,
                type: 'line',
                paint: {
                    'line-color': polygonsColour,
                    'line-width': 1.5,
                },
            })

            map.addLayer({
                id: 'active-' + source,
                source,
                type: 'fill',
                paint: {
                    'fill-color': 'rgba(45,45,45,0.35)',
                },
                filter: resetFilter,
            })

            map.addLayer({
                id: 'active-' + source + '-line',
                source,
                type: 'line',
                paint: {
                    'line-color': 'rgba(45,45,45,0.35)',
                    'line-width': 1.5,
                },
                filter: resetFilter,
            })
        }
    }, [])

    useEffect(() => {
        // Add listeners only once to prevent repeated calls
        map.on('click', clickPolygon)
        map.on('draw.create', updateArea)

        return () => {
            map.off('click', clickPolygon)
            map.off('draw.create', updateArea)
        }
    }, [activeItem, clickPolygon, isEditing, map])

    useEffect(() => {
        if (activeItem) {
            const { polygons, colour = '#f5a142' } = activeItem
            const filter = ['in', 'id', ...polygons]

            map.setFilter('active-' + source, filter)
            map.setFilter('active-' + source + '-line', filter)
            map.setPaintProperty('active-' + source, 'fill-color', hexToRGB(colour, 0.35))
            map.setPaintProperty('active-' + source + '-line', 'line-color', hexToRGB(colour, 1))
        } else {
            map.setFilter('active-' + source, resetFilter)
            map.setFilter('active-' + source + '-line', resetFilter)
        }
    }, [activeItem, map, resetFilter])

    return null
}
