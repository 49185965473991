import React, { useEffect } from 'react'

import { useSearchParams } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { FILTER_OPTIONS } from 'utils/Product'
import { usePolygons } from 'hooks/polygons'
import messages from 'services/intl/messageDefinitions'
import { useLocale } from 'stores/UserStore'
import { HighwayDiscussionStore } from 'stores/HighwayDiscussionStore'
import { Map, WithMapReady } from 'components/Map/Map'
import { Sidebar } from 'components/Sidebar/Sidebar'
import { Details } from 'components/Highways/HighwayDiscussions/Details'
import { Summary } from 'components/Product/Summary'
import { CreateForm } from 'components/Highways/HighwayDiscussions/CreateForm'
import { TwoColLayoutPrimaryRight } from 'components/TwoColumnLayout/TwoColLayoutPrimaryRight'
import { UnassignedPolygons } from 'components/Map/UnassignedPolygons'
import { putHighwayDiscussion, cloneAllHighwayDiscussions } from 'services/HighwayDiscussions'
import { MapStatusBar } from 'components/MapStatusBar/MapStatusBar'
import { ProductStatusBarText } from 'components/Product/ProductStatusBarText'
import { CloneAllButton } from 'components/Product/CloneAllButton'
import { Accessibility } from 'components/Accessibility'
import { MultipleProductsTable } from 'components/Map/Accessibility/MultipleProductsTable'
import { MultiProductPolyline } from 'components/Map/MultiProductPolyline'
import { Polygons } from 'components/Map/Polygons'
import { ViewOnlyPolygons } from 'components/Map/ViewOnlyForecastPolygons'
import { createFeatureCollection } from 'components/Map/mapHelpers'
import { useFilteredHighwayPaths, useHighwayPaths } from 'hooks/highwayPaths'
import { usePolylines } from 'hooks/polylines'
import { useActiveHighwayDiscussion, useHighwayDiscussions, useFilteredHighwayDiscussions, useUnassignedPolylines } from 'hooks/highwayDiscussions'

export const HighwayDiscussions = () => {
    // Query and API params
    const [queryParams] = useSearchParams()
    const locale = useLocale()
    const intl = useIntl()
    const queryID = queryParams.get('focus') || ''
    const filteredHighwayPaths = useFilteredHighwayPaths()
    const polygons = usePolygons()
    const polylines = usePolylines()
    const unassignedCount = useUnassignedPolylines()
    const { mutate: mutateHighwayDiscussions } = useHighwayDiscussions()
    const { mutate: mutateHighwayPaths } = useHighwayPaths()

    const mutate = () => {
        mutateHighwayDiscussions()
        mutateHighwayPaths()
    }

    // Store
    const { highwayDiscussions } = useHighwayDiscussions()
    const filters = HighwayDiscussionStore.useFilters()
    const addFilter = HighwayDiscussionStore.useAddFilter()
    const setActiveHighwayDiscussionId = HighwayDiscussionStore.useSetActiveProductId()
    const activeHighwayDiscussion = useActiveHighwayDiscussion()

    // Secondary data
    const disableSetupButton = filters[0] !== 'draft'

    useEffect(() => {
        document.title = intl.formatMessage(messages.hwyDiscussion)
    }, [])

    useEffect(() => {
        if (queryID) {
            setActiveHighwayDiscussionId(queryID)
        }
        filters.forEach((filter) => {
            // default filter
            addFilter(filter)
        })
        if (activeHighwayDiscussion && activeHighwayDiscussion.id === queryID) {
            addFilter(activeHighwayDiscussion.status)
        }
    }, [highwayDiscussions])

    const standardA11yComponent = (
        <>
            <Map locale={locale}>
                <WithMapReady>
                    {polylines && polylines.features && (
                        <MultiProductPolyline
                            apiCall={putHighwayDiscussion}
                            polygons={polylines}
                            useFilteredProducts={useFilteredHighwayDiscussions}
                            useEditing={HighwayDiscussionStore.useEditingProductSetup}
                            useActiveProduct={useActiveHighwayDiscussion}
                            mutateProducts={mutate}
                            useDraftProduct={HighwayDiscussionStore.useDraftProduct}
                            useUpdateDraftProduct={HighwayDiscussionStore.useUpdateDraftProduct}
                            useAssignedPolygons={useFilteredHighwayDiscussions}
                            productSource="highwayDiscussion"
                        />
                    )}
                    {polygons && polygons.features && (
                        <>
                            <Polygons polygons={polygons} />
                            <ViewOnlyPolygons
                                polygons={createFeatureCollection(filteredHighwayPaths, polygons, true)}
                            />
                        </>
                    )}
                </WithMapReady>
            </Map>
            <UnassignedPolygons unassignedCount={unassignedCount} />
            {activeHighwayDiscussion && (
                <MapStatusBar
                    product={activeHighwayDiscussion}
                    text={<ProductStatusBarText product={activeHighwayDiscussion} />}
                />
            )}
        </>
    )

    const enhancedA11yComponent = polylines && polylines.features && (
        <MultipleProductsTable
            useEditing={HighwayDiscussionStore.useEditingProductSetup}
            unassignedCount={unassignedCount}
            useActiveItem={useActiveHighwayDiscussion}
            useProducts={useFilteredHighwayDiscussions}
            putRequest={putHighwayDiscussion}
            mutateProducts={mutate}
            polygons={polylines}
            useDraftItem={HighwayDiscussionStore.useDraftProduct}
            useUpdateDraftItem={HighwayDiscussionStore.useUpdateDraftProduct}
        />
    )

    return (
        <TwoColLayoutPrimaryRight
            left={
                <Accessibility
                    standardA11yComponent={standardA11yComponent}
                    enhancedA11yComponent={enhancedA11yComponent}
                />
            }
            right={
                <Sidebar
                    title={'hwyDiscussion'}
                    emptyMessage={'noHighwayDiscussionsFound'}
                    Details={Details}
                    Summary={Summary}
                    dataProvider={useFilteredHighwayDiscussions}
                    filterProvider={HighwayDiscussionStore.useFilters}
                    listData={highwayDiscussions}
                    filterOptions={FILTER_OPTIONS}
                    activeFilters={filters}
                    useAddFilter={HighwayDiscussionStore.useAddFilter}
                    useRemoveFilter={HighwayDiscussionStore.useRemoveFilter}
                    useClearActiveID={HighwayDiscussionStore.useClearActiveProductId}
                    createLabel={'createHighwayDiscussion'}
                    disableSetupButton={disableSetupButton}
                    useSetActiveId={HighwayDiscussionStore.useSetActiveProductId}
                    useSetEditing={HighwayDiscussionStore.useSetEditingProductSetup}
                    useSetEditingContent={HighwayDiscussionStore.useSetEditingProductContent}
                    useResetDraft={HighwayDiscussionStore.useResetDraftProduct}
                    CreateForm={CreateForm}
                    useActiveItem={useActiveHighwayDiscussion}
                    mutateProducts={mutate}
                    radioStyleFiltering={true}
                    buttons={
                        <CloneAllButton
                            mutateProducts={mutate}
                            useFilters={HighwayDiscussionStore.useFilters}
                            useAddFilter={HighwayDiscussionStore.useAddFilter}
                            cloneAllApiCall={cloneAllHighwayDiscussions}
                            buttonId={'cloneAllButton'}
                            confirmationText="cloneAllYes"
                        />
                    }
                ></Sidebar>
            }
        />
    )
}
