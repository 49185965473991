import { createNewImage } from 'utils/Image'

export const addImage = (product, locale = 'en') => {
    return {
        ...product,
        media: {
            ...product.media,
            images: [...product.media.images, createNewImage(locale)],
        },
    }
}
export const removeImage = (product, imageId) => {
    return {
        ...product,
        media: {
            ...product.media,
            images: product.media.images.filter((image) => image.id !== imageId),
        },
    }
}

export const updateImageField = (product, imageId, fields) => {
    return {
        ...product,
        media: {
            ...product.media,
            images: product.media.images.map((image) => {
                return image.id === imageId ? { ...image, ...fields } : image
            }),
        },
    }
}

export const updateImageCaption = (product, imageId, language, value) => {
    return {
        ...product,
        media: {
            ...product.media,
            images: product.media.images.map((image) => {
                return image.id === imageId ? { ...image, caption: { ...image.caption, [language]: value } } : image
            }),
        },
    }
}

export const addTranslationToImageCaption = (product, imageId, language) => {
    return {
        ...product,
        media: {
            ...product.media,
            images: product.media.images.map((image) => {
                return image.id === imageId ? { ...image, caption: { ...image.caption, [language]: '' } } : image
            }),
        },
    }
}

export const removeTranslationFromImageCaption = (product, imageId, language) => {
    return {
        ...product,
        media: {
            ...product.media,
            images: product.media.images.map((image) => {
                if (image.id === imageId) {
                    const remainingTranslations = {}
                    for (let key in image.caption) {
                        if (key !== language) {
                            remainingTranslations[key] = image.caption[key]
                        }
                    }

                    return {
                        ...image,
                        caption: remainingTranslations,
                    }
                }

                return image
            }),
        },
    }
}

export const supportedUrl = (url) => {
    return SUPPORTED_FILE_TYPES.includes(
        url
            .split('.')
            .pop()
            .toLowerCase()
    )
        ? url
        : url.substr(0, url.lastIndexOf('.')) + '.jpg'
}

const SUPPORTED_FILE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp']
