import moment from 'moment'

// Products with the same generic structure, including avalancheForecasts, regionalDiscussions, and specialProducts

// Allowed colours for these generic products. Excludes colours similar to the danger ratings (black, red, orange, yellow, green) as well as grey/white. TODO: make these colour-blind friendly
export const COLOUR_MAP = new Map([
    ['#FF7F50', 'coral'],
    ['#00FFFF', 'cyan'],
    ['#7FFFD4', 'aquamarine'],
    ['#DEB887', 'burlywood'],
    ['#B8860B', 'darkGoldenrod'],
    ['#00008B', 'darkBlue'],
    ['#008B8B', 'darkCyan'],
    ['#8B008B', 'darkMagenta'],
    ['#BDB76B', 'darkKhaki'],
    ['#FF1493', 'deepPink'],
    ['#CD5C5C', 'indianRed'],
    ['#20B2AA', 'lightSeaGreen'],
    ['#DA70D6', 'orchid'],
    ['#9ACD32', 'yellowGreen'],
    ['#D2B48C', 'tan'],
    ['#EE82EE', 'violet'],
    ['#A0522D', 'sienna'],
])
export const COLOUR_NAMES = Array.from(COLOUR_MAP, ([_, value]) => value)
export const COLOUR_HEXES = Array.from(COLOUR_MAP, ([value, _]) => value)

export const createEmptyProduct = (type) => {
    let issueENV
    let expiryENV

    try {
        issueENV = JSON.parse(process.env.REACT_APP_PRODUCT_ISSUE_TIME)
        expiryENV = JSON.parse(process.env.REACT_APP_PRODUCT_EXPIRY_TIME)
    } catch (e) {
        issueENV = []
        expiryENV = []
    }

    return {
        name: null,
        type, // 'avalancheforecast', 'regionaldiscussion', 'specialproduct', 'highwayforecast'
        colour: type === 'specialproduct' ? '#FF0000' : COLOUR_HEXES[0], // red for special products
        status: null,
        userID: null,
        polygons: [],
        username: null,
        media: {
            images: [],
        },
        userHandle: null,
        issueDateTime: moment(new Date()).set({ hour: issueENV[0] || 16, minute: issueENV[1] || 0 }),
        expiryDateTime: moment(new Date())
            .add(1, 'days')
            .set({ hour: expiryENV[0] || 16, minute: expiryENV[1] || 0 }),
        dayOne: 'issueDatePlusOne',
    }
}

export const FILTER_OPTIONS = [
    {
        label: 'draft',
        value: 'draft',
        default: true, // default will determine if filter is active on component load
    },
    {
        label: 'completed',
        value: 'completed',
        default: false,
    },
    {
        label: 'live',
        value: 'live',
        default: false,
    },
]

export const filterAndSort = (list, filterType, filters) => {
    let filteredList = []

    for (let f in filters) {
        const filtered = filterSortList(list, filterType, filters[f])
        filteredList = filteredList.concat(filtered)
    }

    return filteredList
}

const filterSortList = (list, filterType, filter) => {
    const newList = list.filter((obj) => {
        return obj[filterType] === filter
    })

    const newListWithoutFxers = newList
        .filter((obj) => {
            return !obj.username
        })
        .sort((a, b) => {
            return a.name.localeCompare(b.name)
        })

    const newListWithFxers = newList
        .filter((obj) => {
            return obj.username
        })
        .sort((a, b) => {
            return a.username === b.username ? a.name.localeCompare(b.name) : a.username.localeCompare(b.username)
        })

    return newListWithFxers.concat(newListWithoutFxers)
}

export const unassignedCount = (polygons, filteredProducts, draftProduct) => {
    let unassignedCount = -1
    if (polygons.features && polygons.features.length > 0) {
        const allPolygons = polygons.features.map((feature) => feature.properties.id)

        const assignedPolygons = [...filteredProducts, draftProduct]
            .map((product) => {
                return product.polygons
            })
            .flat()
        unassignedCount = [...allPolygons].filter((polygon) => !assignedPolygons.includes(polygon)).length
    }

    return unassignedCount
}
