import React from 'react'

import { FormattedMessage, useIntl } from 'react-intl'
import { Table } from 'antd'

import { DISTRIBUTION_TYPES } from 'utils/Snowpack/Distribution'
import { uuid } from 'utils/String'
import messages from 'services/intl/messageDefinitions'

export const AvalancheDistributionInfoTable = () => {
    const keys = DISTRIBUTION_TYPES
    const intl = useIntl()

    const columns = [
        {
            title: <FormattedMessage {...messages['distribution.titles.distribution']} />,
            key: 'distribution',
            dataIndex: 'distribution',
            render: (distribution) => (
                <>
                    <h2>{distribution}</h2>
                </>
            ),
        },
        {
            title: <FormattedMessage {...messages['distribution.titles.spatialDensity']} />,
            key: 'spatialDensity',
            dataIndex: 'spatialDensity',
        },
        {
            title: <FormattedMessage {...messages['distribution.titles.evidence']} />,
            key: 'evidence',
            dataIndex: 'evidence',
        },
    ]

    const data = keys.map((key) => ({
        key: uuid(),
        distribution: intl.formatMessage({ ...messages[`distribution.${key}.distribution`] }),
        spatialDensity: intl.formatMessage({ ...messages[`distribution.${key}.spatialDensity`] }),
        evidence: intl.formatMessage({ ...messages[`distribution.${key}.evidence`] }),
    }))

    return <Table pagination={false} dataSource={data} columns={columns} />
}
