import { uuid } from 'utils/String'

export const schema = (locale) => {
    return {
        id: uuid(),
        public: true,
        problemType: null,
        weakLayer: {},
        depth: {
            min: null,
            max: null,
        },
        comment: {
            [locale]: null,
        },
        aspectElevations: [],
        terrainTravelAdvice: [],
        // Conceptual model fields
        // form fields
        distribution: null,
        sensitivity: null,
        typicalSize: null,
        // chart fields
        size: {
            min: null,
            max: null,
        },
        likelihoodRange: {
            min: null,
            max: null,
        },
        hazardSize: null,
        likelihood: null,
    }
}

export const HAZARD_LIKELIHOOD_MATRIX = {
    widespread: {
        unreactive: 'unlikely',
        stubborn: 'possible',
        reactive: 'veryLikely',
        touchy: 'certain',
    },
    specific: {
        unreactive: 'unlikely',
        stubborn: 'possible',
        reactive: 'likely',
        touchy: 'veryLikely',
    },
    isolated: {
        unreactive: 'unlikely',
        stubborn: 'unlikely',
        reactive: 'possible',
        touchy: 'likely',
    },
}

export const LIKELIHOOD_TEXTS = ['unlikely', 'possible', 'likely', 'veryLikely', 'almostCertain']

export const LIKELIHOODS_TO_VALUES = new Map([
    ['certain', 5],
    ['certain_veryLikely', 4.5],
    ['veryLikely', 4],
    ['veryLikely_likely', 3.5],
    ['likely', 3],
    ['likely_possible', 2.5],
    ['possible', 2],
    ['possible_unlikely', 1.5],
    ['unlikely', 1],
])

export const VALUES_TO_LIKELIHOODS = new Map(Array.from(LIKELIHOODS_TO_VALUES.entries()).map(([k, v]) => [v, k]))

export const LIKELIHOODS = Array.from(LIKELIHOODS_TO_VALUES.keys())

export const SIZES = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5]
