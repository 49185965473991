import { Row, Tag } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import { DeleteOutlined } from '@ant-design/icons'

import { AddLanguageDropdown } from 'components/Language/AddLanguageDropdown'
import messages from 'services/intl/messageDefinitions'
import { useLocale } from 'stores/UserStore'
import { TextField } from './TextField'

export const TranslatedSimpleTextField = ({
    handleChange,
    handleAddTranslation,
    handleRemoveTranslation,
    translations,
    translationRequired = true,
    disabled = false,
    partialLabel = '',
    placeholder = '',
}) => {
    const locale = useLocale()

    return (
        <>
            {translations &&
                Object.keys(translations)
                    // sort alphabetically, but put the current locale first
                    .sort((a, b) => {
                        if (a === locale) {
                            return -1
                        }
                        if (b === locale) {
                            return 1
                        }
                        return a.localeCompare(b)
                    })
                    .map((language, index) => {
                        const key = language + '-' + index

                        return (
                            <div key={key}>
                                <Row>
                                    <p style={styles.translationLabel}>
                                        {<FormattedMessage {...messages[language]} />}
                                    </p>
                                    {translationRequired && (
                                        <Tag color="red" style={styles.translationTag}>
                                            <FormattedMessage {...messages.translationRequired} />
                                        </Tag>
                                    )}
                                    <button
                                        type="button"
                                        style={styles.deleteButton}
                                        onClick={() => handleRemoveTranslation(language)}
                                    >
                                        <DeleteOutlined />
                                        <span style={styles.visuallyHidden}>
                                            <FormattedMessage {...messages.removeTranslation} />
                                        </span>
                                    </button>
                                </Row>
                                <TextField
                                    value={translations[language]}
                                    onChange={(value) => handleChange(language, value)}
                                    disabled={disabled}
                                    ariaLabel={partialLabel + ' ' + language}
                                    placeholder={placeholder}
                                />
                            </div>
                        )
                    })}
            <AddLanguageDropdown
                existingTranslations={translations}
                handleClick={(lang) => handleAddTranslation(lang)}
                buttonDisabled={disabled}
            />
        </>
    )
}

const styles = {
    translationLabel: {
        marginBottom: '5px',
        marginLeft: '2px',
    },
    translationTag: {
        marginBottom: 'var(--s-3)',
        marginLeft: 'var(--s-1)',
    },
    deleteButton: {
        border: 'none',
        background: 'none',
        cursor: 'pointer',
        padding: '0',
        margin: '0',
    },
    visuallyHidden: {
        position: 'absolute',
        width: '1px',
        height: '1px',
        padding: '0',
        margin: '-1px',
        overflow: 'hidden',
        clip: 'rect(0, 0, 0, 0)',
        whiteSpace: 'nowrap',
        border: '0',
    },
}
