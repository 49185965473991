import useSWR from 'swr'

import { useLocale } from 'stores/UserStore'
import { HighwayDiscussionStore } from 'stores/HighwayDiscussionStore'
import { fetchHighwayForecasts } from 'services/HighwayForecasts'

export const useHighwayPaths = () => {
    const language = useLocale()
    const key = ['products/highwayforecast', language]
    const { data, isLoading, mutate } = useSWR(key, () => fetchHighwayForecasts(language))

    return {
        viewOnlyHighwayForecasts: data?.data,
        isLoading,
        mutate,
    }
}

// These are avalanche-path polygons, coloured according to hazard rating (used in Highway Discussions)
export const useFilteredHighwayPaths = () => {
    const { viewOnlyHighwayForecasts, isLoading } = useHighwayPaths()

    const filters = HighwayDiscussionStore.useFilters()
    const filter = filters[0]

    return isLoading ? [] : viewOnlyHighwayForecasts?.filter((forecast) => forecast.status === filter)
}
