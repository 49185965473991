export const mergePolygonWithExisting = (existing, id, add = true) => {
    const polygons = new Set(existing)
    if (add) {
        polygons.add(id)
    } else {
        polygons.delete(id)
    }

    return Array.from(polygons)
}

export const roundCoordinate = (coordinate) => {
    if (!coordinate) {
        return null
    }

    return parseFloat(coordinate).toFixed(4)
}
