import React, { useEffect, useState, useRef } from 'react'

import { FormattedMessage } from 'react-intl'
import { Button, Modal } from 'antd'

import { useContent, useSetContent, useAvalancheProblems, useSetAvalancheProblems } from 'stores/ForecastStore'
import { DayCardLayout } from 'components/DayCardLayout/DayCardLayout'
import { EditScreenLayout } from 'components/EditScreenLayout/EditScreenLayout'
import { DayCard } from 'components/DayCard/DayCard'
import { uuid } from 'utils/String'
import { getWindowDimensions } from 'utils/screen'
import { AvalancheProblemEditor } from 'components/AvalancheForecast/Content/AvalancheProblems/AvalancheProblemEditor/AvalancheProblemEditor'
import messages from 'services/intl/messageDefinitions'
import { ProblemDisplay } from 'components/AvalancheForecast/Content/AvalancheProblems/ProblemDisplay'
import { arrayWithoutElementAtIndex, arrayUp, arrayDown } from 'utils/Array'
import { useUndo } from 'components/AvalancheForecast/Content/useUndo'
import { FloatMenu } from '../FloatMenu'

const MAX_AVALANCHE_PROBLEMS = 6
const MAX_PUBLIC_AVALANCHE_PROBLEMS = 3
const VIEW = 'avalancheProblems'

export const AvalancheProblems = ({ update, dayDetails, setReviewDrawerVisible, preview, compressed, colWidth }) => {
    const [cards, updateCards] = useState([])
    const [createModalVisible, setCreateModalVisible] = useState(false)
    const [currentDay, setCurrentDay] = useState()
    const [currentTerrainTravelAdvice, setCurrentTerrainTravelAdvice] = useState()
    const content = useContent()
    const setContent = useSetContent()
    const days = useAvalancheProblems()
    const updateDays = useSetAvalancheProblems()
    const title = <FormattedMessage {...messages.avalancheProblems} />
    const daysRef = useRef(days)
    const windowDimensions = getWindowDimensions()
    const editorHeight = windowDimensions.height - 100 + 'px'
    const { undoUpdate, initUndo } = useUndo()

    const cloneToNextDay = (dayToClone) => {
        const newDays = [...days].map((day, i) => {
            return day.position === dayToClone.position + 1
                ? { ...day, problems: dayToClone.problems, terrainTravelAdvice: dayToClone.terrainTravelAdvice }
                : day
        })
        updateDays(newDays)
    }

    const cloneToAll = (dayToClone) => {
        const newDays = [...days].map((day, i) => {
            return { ...day, problems: dayToClone.problems, terrainTravelAdvice: dayToClone.terrainTravelAdvice }
        })
        updateDays(newDays)
    }

    const actionHandlers = {
        cloneNext: (day) => cloneToNextDay(day),
        cloneAll: (day) => cloneToAll(day),
        reviewNote: () => setReviewDrawerVisible(true),
        preview: () => preview(),
    }

    const moveProblem = (newProblems, dayIndex, days) => {
        const newDays = [...days].map((day, i) => {
            return i === dayIndex
                ? {
                      ...day,
                      problems: newProblems,
                      terrainTravelAdvice: newProblems.length ? day.terrainTravelAdvice : [],
                  }
                : day
        })
        updateDays(newDays)
    }

    const resetProblem = (newProblems, dayIndex, days, terrainTravelAdvice) => {
        const newDays = [...days].map((day, i) => {
            return i === dayIndex ? { ...day, problems: newProblems, terrainTravelAdvice } : day
        })
        updateDays(newDays)
    }

    const UpdateProblem = (problems, id, newProblem) => {
        // old school for loop used on purpose
        const newProblems = []
        for (let p = 0; p < problems.length; p++) {
            if (problems[p].id === id) {
                newProblems.push(newProblem)
            } else {
                newProblems.push(problems[p])
            }
        }
        return newProblems
    }

    const publicToggle = (problems, dayIndex, days, id, value) => {
        const newProblems = problems.map((problem) => {
            return problem.id === id ? { ...problem, public: value.target.checked } : problem
        })

        const newDays = [...days].map((day, i) => {
            return i === dayIndex ? { ...day, problems: newProblems } : day
        })

        updateDays(newDays)
    }

    const generateDayCards = (structuredDays) => {
        return structuredDays.map((day, d) => {
            const createHandler = () => {
                setCurrentDay(day)
                setCurrentTerrainTravelAdvice(day.terrainTravelAdvice)
                setCreateModalVisible(true)
            }

            const currentPublicProblems = day.problems
                ? day.problems.filter((prob) => {
                      return prob.public
                  })
                : []

            const problems = day.problems
                ? day.problems.map((problem, i) => {
                      return (
                          <ProblemDisplay
                              key={uuid()}
                              content={content}
                              problem={problem}
                              canToggle={currentPublicProblems.length < 3}
                              terrainTravelAdvice={day.terrainTravelAdvice}
                              publicToggle={(value) => publicToggle(day.problems, d, structuredDays, problem.id, value)}
                              handleDelete={() =>
                                  moveProblem(arrayWithoutElementAtIndex(day.problems, i), d, structuredDays)
                              }
                              handleDown={
                                  i != day.problems.length - 1
                                      ? () => moveProblem(arrayDown(day.problems, i), d, structuredDays)
                                      : undefined
                              }
                              handleUp={
                                  i != 0
                                      ? () => moveProblem(arrayUp(day.problems, i - 1), d, structuredDays)
                                      : undefined
                              }
                              handleUpdate={(newProblem, advice) =>
                                  resetProblem(
                                      UpdateProblem(day.problems, problem.id, newProblem),
                                      d,
                                      structuredDays,
                                      advice
                                  )
                              }
                          />
                      )
                  })
                : []

            const dayContent = (
                <>
                    {problems.length < MAX_AVALANCHE_PROBLEMS && (
                        <Button
                            onClick={createHandler}
                            type="primary"
                            style={styles.newButton}
                            data-test={'newAvalancheProblemButton'}
                        >
                            <FormattedMessage
                                id="newAvalancheProblem"
                                defaultMessage="New Avalanche Problem"
                                description="Avalanche Problems form"
                            />
                        </Button>
                    )}
                    <div style={styles.problemContainer}>{problems}</div>
                </>
            )

            return (
                <DayCard
                    day={day}
                    key={uuid()}
                    cardContent={dayContent}
                    actionHandlers={actionHandlers}
                    dataProvider={useAvalancheProblems}
                    editable={compressed ? false : true}
                />
            )
        })
    }

    const unstructureDayData = (daysList) => {
        return daysList.map((day, d) => {
            return {
                position: d,
                problems: day.problems ? day.problems : [],
                terrainTravelAdvice: day.terrainTravelAdvice,
            }
        })
    }

    const structureDayData = (data) => {
        return dayDetails.map((detail, d) => {
            const dayIndex = data.findIndex((el) => el.position === detail.position)

            let dataDay = { problems: [], terrainTravelAdvice: [] }
            if (dayIndex !== -1) {
                dataDay = data[dayIndex]
            }

            return {
                // DayCard Setup
                value: d + 1,
                type: d === 0 ? 'Nowcast' : 'Forecast',
                date: detail.date,
                day: detail.day,

                // DayCard problems
                position: d,
                problems: dataDay.problems,
                terrainTravelAdvice: dataDay.terrainTravelAdvice,
            }
        })
    }

    const saveProblem = (newProblem, terrainTravelAdvice, days) => {
        const dayIndex = days.findIndex((el) => el.position === currentDay.position)
        const currentPublicProblems = days[dayIndex].problems
            ? days[dayIndex].problems.filter((prob) => {
                  return prob.public
              })
            : []

        if (currentPublicProblems.length >= MAX_PUBLIC_AVALANCHE_PROBLEMS) {
            newProblem.public = false
        } else {
            newProblem.public = true
        }

        const newDays = [...days].map((day, i) => {
            const problems = day.problems ? [...day.problems, newProblem] : [newProblem]
            return i === dayIndex ? { ...day, problems: problems, terrainTravelAdvice } : day
        })

        updateDays(newDays)
    }

    const undo = async () => {
        const undoResult = await undoUpdate({ versionNumber: content.versionNumber, content, section: VIEW })
        if (undoResult) {
            setContent(undoResult)
            const list =
                undoResult.avalancheProblems && undoResult.avalancheProblems.days
                    ? undoResult.avalancheProblems.days
                    : []
            const structured = structureDayData(list)

            if (undoResult && dayDetails.length > 1) {
                updateDays(structured)
                updateCards(generateDayCards(structured))
            }
        }
    }

    useEffect(() => {
        // this should only be truthy once
        const list = content.avalancheProblems && content.avalancheProblems.days ? content.avalancheProblems.days : []
        const structured = structureDayData(list)
        if (content && dayDetails.length > 1 && !days.length) {
            updateDays(structured)
        }

        if (content && dayDetails.length > 1) {
            updateCards(generateDayCards(structured))
        }
    }, [content, dayDetails])

    useEffect(() => {
        if (days.length && daysRef.current.length) {
            const unstructuredDays = unstructureDayData(days)

            initUndo(content, VIEW)
            update({ ...content, avalancheProblems: { days: unstructuredDays } }, VIEW)
        }
        daysRef.current = days // used to prevent update on load
    }, [days])

    return (
        <EditScreenLayout dayDetails={dayDetails} title={title} compressed={compressed}>
            <DayCardLayout dayCards={cards} parentColWidth={colWidth} />
            <Modal
                title={<FormattedMessage {...messages.avalancheProblem} />}
                centered
                destroyOnClose
                maskClosable={false}
                visible={createModalVisible}
                footer={null}
                onCancel={() => setCreateModalVisible(false)}
                width={'95%'}
                bodyStyle={{ height: editorHeight, overflow: 'scroll' }}
            >
                <AvalancheProblemEditor
                    hideModal={() => setCreateModalVisible(false)}
                    content={content}
                    saveProblem={(problem, advice) => saveProblem(problem, advice, days)}
                    terrainTravelAdvice={currentTerrainTravelAdvice}
                    currentDay={currentDay}
                />
            </Modal>
            <FloatMenu undoHandler={undo} />
        </EditScreenLayout>
    )
}

const styles = {
    newButton: {
        position: 'absolute',
        bottom: '20px',
        right: '20px',
    },
    problemContainer: {
        height: '100%',
        maxWidth: '600px',
        margin: '0 auto 50px',
    },
}
