import React from 'react'
import { List } from 'antd'

export const Menu = ({ pos, handleMenuClick }) => {
    const menus = [
        { title: 'Unselect all aspects and elevations', action: 'unselect' },
        { title: 'Select all aspects and elevations', action: 'select' },
    ]

    return (
        <div
            style={{
                position: 'absolute',
                top: pos.y,
                left: pos.x,
                background: 'var(--background-colour)',
                zIndex: 1,
                boxShadow: '2px 2px 5px 0px #0002',
                cursor: 'pointer',
            }}
        >
            <List
                size="small"
                bordered
                dataSource={menus}
                renderItem={(item) => <List.Item onClick={() => handleMenuClick(item.action)}>{item.title}</List.Item>}
            />
        </div>
    )
}
