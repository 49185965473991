import { useEffect, useMemo } from 'react'

import { useMap } from 'components/Map/Map'
import bbox from '@turf/bbox'

const MAP_BUFFER = 50

export const ActivePolylineProduct = ({ product, polylines }) => {
    const map = useMap()
    const source = 'activePolylineProduct'

    const activeItemPolygons = useMemo(() => {
        return {
            ...polylines,
            features: polylines.features
                .filter((lineSegment) => {
                    return product.polygons.includes(lineSegment.properties.id)
                })
                .map((lineSegment) => {
                    // Inject colour into the geoJSON
                    return {
                        ...lineSegment,
                        properties: {
                            ...lineSegment.properties,
                            colour: product.colour,
                        },
                    }
                }),
        }
    }, [polylines, product])

    // Add source and layers
    useEffect(() => {
        if (!map.getSource(source)) {
            map.addSource(source, {
                type: 'geojson',
                data: activeItemPolygons,
                promoteId: 'polygonId',
            })
        }
        if (!map.getLayer(source)) {
            map.addLayer({
                id: source,
                source: source,
                type: 'line',
                paint: {
                    'line-color': ['get', 'colour'],
                    'line-width': 15,
                    'line-opacity': 0.7,
                },
                layout: {
                    'line-cap': 'round',
                    'line-join': 'round',
                },
            })
        }

        // Cleanup on unmount, necessary to remove items after unselection (panel close)
        return () => {
            if (map.getLayer(source)) {
                map.removeLayer(source)
            }
            if (map.getSource(source)) {
                map.removeSource(source)
            }
        }
    }, [])

    // Update map layers when the product changes
    useEffect(() => {
        if (map.getSource(source)) {
            map.getSource(source).setData(activeItemPolygons)
        }
    }, [activeItemPolygons, map, polylines, product])

    // Zoom to the active product's polygons
    useEffect(() => {
        if (product && product.polygons.length > 0) {
            const bounds = bbox(activeItemPolygons)
            map.fitBounds(bounds, { padding: MAP_BUFFER })
        }
    }, [activeItemPolygons, map, polylines, product])
}
