import React from 'react'

import { MitigationIcon } from 'components/Map/MitigationIcon'

export const ArchiveMitigationIconSet = ({ polygons, mitigation }) => {
    const { polygons: mitigationPolygons } = mitigation
    const icons = mitigationPolygons.map((polygonId) => {
        const polygon = polygons.features.find((polygon) => polygon.properties.id === polygonId)

        return {
            id: polygonId,
            lngLat: polygon.properties.centroid || [polygon.properties.CENTROID_X, polygon.properties.CENTROID_Y],
        }
    })

    return icons.map((icon) => {
        return icon.lngLat && <MitigationIcon key={icon.id} lngLat={icon.lngLat} />
    })
}
