import React, { useEffect, useState, useCallback, useMemo } from 'react'

import { useMap } from 'components/Map/Map'
import { Icon } from 'components/Map/Icon'
import { useFilteredAvalancheForecasts } from 'hooks/avalancheForecasts'

const ZOOM_CUTOFF = 5.5 // Hide items when zoomed out below this threshold
const Y_OFFSET = -30 // This small offset prevents overlap with the polygon name/id text label (in the case of single-polygon products)

export const DangerRatingIconSet = () => {
    const filteredAvalancheForecasts = useFilteredAvalancheForecasts()
    const map = useMap()
    const [show, setShow] = useState(map.getZoom() > ZOOM_CUTOFF)

    const zoomHandler = useCallback(() => {
        setShow(map.getZoom() > ZOOM_CUTOFF)
    }, [map])

    useEffect(() => {
        // Add listeners only once to prevent repeated calls
        map.on('zoom', zoomHandler)

        return () => {
            map.off('zoom', zoomHandler)
        }
    }, [map, zoomHandler])

    const iconInjectedForecasts = useMemo(() => {
        return filteredAvalancheForecasts
            .filter((forecast) => forecast.polygons.length > 0)
            .filter((forecast) => forecast.centroid)
            .map((forecast) => {
                return {
                    ...forecast,
                    icon: {
                        longitude: forecast.centroid[0],
                        latitude: forecast.centroid[1],
                    },
                }
            })
    }, [filteredAvalancheForecasts])

    return iconInjectedForecasts.map((forecast) => {
        const { id, name, icon, dangerRatings, polygons } = forecast
        const offsetY = polygons.length === 1 ? Y_OFFSET : 0
        const { longitude, latitude } = icon

        return (
            <Icon
                key={id}
                name={name}
                longitude={longitude}
                latitude={latitude}
                show={show}
                offsetY={offsetY}
                dangerRatings={dangerRatings && dangerRatings.length >= 2 ? dangerRatings[1] : {}}
            />
        )
    })
}
