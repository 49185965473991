export const allAspects = [
    'n_btl',
    'n_tln',
    'n_alp',
    'ne_btl',
    'ne_tln',
    'ne_alp',
    'e_btl',
    'e_tln',
    'e_alp',
    'se_btl',
    'se_tln',
    'se_alp',
    's_btl',
    's_tln',
    's_alp',
    'sw_btl',
    'sw_tln',
    'sw_alp',
    'w_btl',
    'w_tln',
    'w_alp',
    'nw_btl',
    'nw_tln',
    'nw_alp',
]
