import React from 'react'

import { useEnhancedAccessibility } from 'stores/UserStore'

export const TwoColLayoutPrimaryRight = ({
    left,
    right,
    leftRole = 'main',
    rightRole = 'complementary',
    leftFraction = 60,
    boxShadow = true,
}) => {
    // TODO:: drag to resize sidebar
    const shadow = boxShadow ? '-4px 0 8px rgba(0, 0, 0, 0.25)' : 'none'
    const enhancedAccessibility = useEnhancedAccessibility()
    const overflowY = enhancedAccessibility ? 'auto' : 'inherit' // Prevents scrollbar above map with enhance-a11y mode off

    return (
        <div style={styles.wrapper}>
            <div style={{ flex: leftFraction / 100, position: 'relative', overflowY }} role={leftRole}>
                {left}
            </div>
            <div style={{ ...styles.rightPanel, boxShadow: shadow, flex: (100 - leftFraction) / 100 }} role={rightRole}>
                <div style={styles.column}>{right}</div>
            </div>
        </div>
    )
}

const styles = {
    wrapper: {
        display: 'flex',
        height: '100%',
    },
    rightPanel: {
        position: 'relative',
        background: 'var(--background-colour)',
        borderLeft: '1px solid var(--grey-light)',
    },
    column: {
        // HACK: Is there a better way to do this than position: absolute?
        position: 'absolute',
        height: '100%',
        top: 0,
        left: 0,
        right: 0,
        overflowY: 'auto',
    },
}
