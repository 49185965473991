import { Forecast as ForecastContent } from 'components/ContentSection/Forecast'

import { MENU_ITEMS, progressToPercent } from 'utils/ForecastContent'
// import { progressToPercent } from 'utils/ForecastContent'
// import { MENU_ITEMS } from 'utils/HighwayContent'
import { fetchAvalancheForecast, putAvalancheForecast } from 'services/AvalancheForecast'

export const AvFxContent = ({ compressed, colWidth }) => {
    return (
        <ForecastContent
            colWidth={colWidth}
            compressed={compressed}
            progressToPercent={progressToPercent}
            menuItems={MENU_ITEMS}
            fetchForecast={fetchAvalancheForecast}
            putForecast={putAvalancheForecast}
        />
    )
}
