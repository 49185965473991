import React, { useState, useEffect, useCallback } from 'react'

import { Form, Input, Divider, Empty } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import lunr from 'lunr'

import { noop } from 'utils/function'
import { fetchConfidenceStatements } from 'services/Confidence'
import { useLocale } from 'stores/UserStore'
import { Statement } from 'components/SelectorForm/Statement'
import messages from 'services/intl/messageDefinitions'
import { sendErrorNotification } from 'utils/Notifications'

export const ConfidenceSelectorForm = ({ selectedKeys, selectHandler, maxHeight, limit }) => {
    const [filteredStatements, setFilteredStatements] = useState([])
    const [statements, setStatements] = useState([])
    const [filter, setFilter] = useState('')
    const [localSelectedStatements, setLocalSelectedStatements] = useState([])
    const intl = useIntl()
    const locale = useLocale()
    const [form] = Form.useForm()

    const getStatements = useCallback(async () => {
        const response = await fetchConfidenceStatements(locale)
        setStatements(response.data)
    }, [])

    const getStatementsFromKeys = (selectedStatementKeys) => {
        if (!selectedStatementKeys || statements.length === 0) {
            return []
        }
        return selectedStatementKeys.map((key) => {
            return statements.find((statement) => statement.key === key)
        })
    }

    const swapStatements = useCallback((array, index1, index2) => {
        const temp = array[index1]
        array[index1] = array[index2]
        array[index2] = temp
        return array
    })

    const handleStatementClick = (statement) => {
        if (localSelectedStatements.includes(statement)) {
            return
        }
        if (limit && localSelectedStatements.length >= limit) {
            sendErrorNotification(intl.formatMessage({ ...messages.statementLimit }))
            return
        }
        setLocalSelectedStatements([...localSelectedStatements, statement])
        selectHandler([...localSelectedStatements, statement])
    }

    const handleUp = (statement) => {
        const index = localSelectedStatements.indexOf(statement)
        if (index < 1 || index >= localSelectedStatements.length) {
            return
        }

        const newlocalSelectedStatements = swapStatements(localSelectedStatements, index, index - 1)
        setLocalSelectedStatements([...newlocalSelectedStatements])
        selectHandler([...newlocalSelectedStatements])
    }

    const handleDown = (statement) => {
        const index = localSelectedStatements.indexOf(statement)
        if (index < 0 || index >= localSelectedStatements.length - 1) {
            return
        }

        const newlocalSelectedStatements = swapStatements(localSelectedStatements, index, index + 1)
        setLocalSelectedStatements([...newlocalSelectedStatements])
        selectHandler([...newlocalSelectedStatements])
    }

    const handleDelete = (statement) => {
        const newlocalSelectedStatements = localSelectedStatements.filter((s) => s.key !== statement.key)
        setLocalSelectedStatements(newlocalSelectedStatements)
        selectHandler(newlocalSelectedStatements)
    }

    const search = (query) => {
        const index = getIndex(statements)

        const results = index.search(query)
        const newFilteredStatements = results.map((ref) => {
            return statements.find((statement) => statement.key === ref.ref)
        })

        setFilteredStatements(newFilteredStatements)
    }

    const getIndex = (statements) => {
        const index = lunr(function() {
            this.field('message')
            this.field('tags')
            this.ref('key')
            statements.forEach((statement) => {
                this.add(statement)
            }, this)
        })
        return index
    }

    const handleFilterChange = (changedValues) => {
        setFilter(Object.values(changedValues)[0])
        if (Object.values(changedValues)[0] === '') {
            setFilteredStatements([])
        }
    }

    // debounce
    useEffect(() => {
        const handler = setTimeout(() => {
            if (filter) {
                search(filter)
            }

            if (filter === '') {
                setFilteredStatements([])
            }
        }, 200)

        return () => {
            clearTimeout(handler)
        }
    }, [filter])

    useEffect(() => {
        getStatements()
    }, [])

    useEffect(() => {
        setLocalSelectedStatements(getStatementsFromKeys(selectedKeys))
    }, [statements])

    return (
        <div style={{ ...styles.statementList, maxHeight: maxHeight }} data-test={'confidenceStatements'}>
            {localSelectedStatements.length > 0 &&
                localSelectedStatements.map((statement, i) => (
                    <Statement
                        key={statement.key}
                        statement={statement}
                        handleClick={noop}
                        selected={true}
                        handleUp={i === 0 ? false : handleUp}
                        handleDown={i >= localSelectedStatements.length - 1 ? false : handleDown}
                        handleDelete={handleDelete}
                    />
                ))}
            <Divider orientation="left" plain>
                <FormattedMessage {...messages.filter} />
            </Divider>
            <Form form={form} onValuesChange={handleFilterChange}>
                <Form.Item name="terrainAndTravelAdvice">
                    <Input
                        placeholder={intl.formatMessage({ ...messages.tagSearch })}
                        value={filter}
                        data-test={'confidenceStatementSelectorInput'}
                    />
                </Form.Item>
            </Form>
            {filteredStatements.length || !filter.length ? (
                filteredStatements.map((statement) => (
                    <Statement
                        key={statement.key}
                        statement={statement}
                        handleClick={handleStatementClick}
                        disabled={localSelectedStatements.includes(statement)}
                        selected={localSelectedStatements.includes(statement)}
                    />
                ))
            ) : (
                <div>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
            )}
        </div>
    )
}

const styles = {
    statementList: {
        overflowY: 'auto',
        overflowX: 'hidden',
    },
}
