import React, { useCallback, useEffect } from 'react'

import { FormattedMessage } from 'react-intl'
import { Button, Space } from 'antd'

import { useSetContent } from 'stores/ForecastStore'
import messages from 'services/intl/messageDefinitions'
import { useLocale } from 'stores/UserStore'
import { addImage } from './helpers'
import { ImageForm } from './ImageForm'
import { useUndo } from 'components/AvalancheForecast/Content/useUndo'
import { FloatMenu } from 'components/AvalancheForecast/Content/FloatMenu'

// Input:
// images: [
//     { ...newImage },
// ]
export const ImageFormSet = ({ product, apiPut }) => {
    const VIEW = 'media'
    const locale = useLocale()
    const { undoUpdate, initUndo } = useUndo()
    const setContent = useSetContent()
    const { media } = product || {}
    const { images } = media || {}

    const undo = async () => {
        const undoResult = await undoUpdate({ versionNumber: product.versionNumber, content: product, section: VIEW })
        if (undoResult) {
            setContent(undoResult)
        }
    }

    const updateProduct = useCallback(async (data) => {
        await apiPut(data, VIEW)
    }, [])

    const handleAddImageClick = () => {
        const updatedProduct = addImage(product, locale)
        updateProduct(updatedProduct)
    }

    useEffect(() => {
        initUndo({ section: VIEW })
    }, [])

    return (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            {images && images.length > 0 && (
                <>
                    {[...images].map((image, index) => {
                        return (
                            <ImageForm
                                key={image.id}
                                apiPut={apiPut}
                                product={product}
                                image={image}
                                index={index}
                                count={images.length}
                                view={VIEW}
                            />
                        )
                    })}
                </>
            )}
            <Button onClick={handleAddImageClick} data-test={'addImageButton'}>
                <FormattedMessage {...messages.addImage} />
            </Button>
            {/* <FloatMenu undoHandler={undo} /> */}
        </Space>
    )
}
