import React, { useCallback, useEffect } from 'react'

import { useSearchParams } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { FILTER_OPTIONS } from 'utils/WeakLayers'
import { fetchWeakLayers } from 'services/WeakLayers'
import { usePolygons } from 'hooks/polygons'
import { putWeakLayer } from 'services/WeakLayers'
import messages from 'services/intl/messageDefinitions'
import { useLocale } from 'stores/UserStore'
import {
    useUpdateWeakLayers,
    useWeakLayers,
    useFilteredLayers,
    useAddFilter,
    useRemoveFilter,
    useFilters,
    useActiveWeakLayer,
    useClearActiveWeakLayerId,
    useEditingWeakLayer,
    useSetActiveWeakLayerId,
    useSetEditingWeakLayer,
    useResetDraftLayer,
    useUpdateDraftLayer,
    useDraftLayer,
    useUpdateLayer,
} from 'stores/WeakLayerStore'
import { Map, WithMapReady } from 'components/Map/Map'
import { Sidebar } from 'components/Sidebar/Sidebar'
import { Summary } from 'components/WeakLayers/Summary'
import { Details } from 'components/WeakLayers/Details'
import { LayerForm } from 'components/WeakLayers/LayerForm'
import { SingleProduct } from 'components/Map/SingleProduct'
import { MapStatusBar } from 'components/MapStatusBar/MapStatusBar'
import { MapStatusBarText } from 'components/WeakLayers/MapStatusBarText'
import { TwoColLayoutPrimaryRight } from 'components/TwoColumnLayout/TwoColLayoutPrimaryRight'
import { AspectElevation } from 'components/AspectElevation/AspectElevation'
import { Accessibility } from 'components/Accessibility'
import { SingleProductTable } from 'components/Map/Accessibility/SingleProductTable'

export const WeakLayers = () => {
    const [queryParams, setQueryParams] = useSearchParams()
    const locale = useLocale()
    const updateWeakLayers = useUpdateWeakLayers()
    const polygons = usePolygons()
    const weakLayers = useWeakLayers()
    const activeWeakLayer = useActiveWeakLayer()
    const setActiveWeakLayer = useSetActiveWeakLayerId()
    const addFilter = useAddFilter()
    const filters = useFilters()
    const queryID = queryParams.get('focus') || ''
    const intl = useIntl()

    useEffect(() => {
        document.title = intl.formatMessage(messages.weakLayers)
    }, [])

    const getLayers = useCallback(async () => {
        const response = await fetchWeakLayers(locale)
        updateWeakLayers(response.data)
    }, [])

    useEffect(() => {
        // Fetch layers on page load
        getLayers()
    }, [])

    useEffect(() => {
        if (queryID) {
            setActiveWeakLayer(queryID)
        }
        filters.forEach((filter) => {
            // default filter
            addFilter(filter)
        })
        if (activeWeakLayer && activeWeakLayer.id === queryID) {
            addFilter(activeWeakLayer.status)
        }
    }, [weakLayers])

    const standardA11yComponent = (
        <>
            <Map locale={locale}>
                <WithMapReady>
                    {polygons && polygons.features && (
                        <SingleProduct
                            useEditing={useEditingWeakLayer}
                            useActiveItem={useActiveWeakLayer}
                            useUpdateItem={useUpdateLayer}
                            apiCall={putWeakLayer}
                            polygons={polygons}
                            useDraftItem={useDraftLayer}
                            useUpdateDraftItem={useUpdateDraftLayer}
                        />
                    )}
                </WithMapReady>
            </Map>
            {activeWeakLayer && <MapStatusBar product={activeWeakLayer} text={<MapStatusBarText />} />}
            {activeWeakLayer && (
                <div style={styles.inMap}>
                    <AspectElevation
                        aspectElevations={activeWeakLayer.aspectElevations}
                        colour={activeWeakLayer.colour}
                        inMap={true}
                    />
                </div>
            )}
        </>
    )

    const enhancedA11yComponent = polygons && polygons.features && (
        <SingleProductTable
            useEditing={useEditingWeakLayer}
            useActiveItem={useActiveWeakLayer}
            useUpdateItem={useUpdateLayer}
            putRequest={putWeakLayer}
            polygons={polygons}
            useDraftItem={useDraftLayer}
            useUpdateDraftItem={useUpdateDraftLayer}
        />
    )

    return (
        <TwoColLayoutPrimaryRight
            left={
                <Accessibility
                    standardA11yComponent={standardA11yComponent}
                    enhancedA11yComponent={enhancedA11yComponent}
                />
            }
            right={
                <Sidebar
                    title={'weakLayers'}
                    emptyMessage={'noWeakLayersFound'}
                    Summary={Summary}
                    Details={Details}
                    dataProvider={useFilteredLayers}
                    filterProvider={useFilters}
                    listData={weakLayers}
                    filterOptions={FILTER_OPTIONS}
                    activeFilters={filters}
                    useAddFilter={useAddFilter}
                    useRemoveFilter={useRemoveFilter}
                    useClearActiveID={useClearActiveWeakLayerId}
                    createLabel={'createNewWeakLayer'}
                    useSetActiveId={useSetActiveWeakLayerId}
                    useSetEditing={useSetEditingWeakLayer}
                    useResetDraft={useResetDraftLayer}
                    CreateForm={LayerForm}
                    useActiveItem={useActiveWeakLayer}
                    radioStyleFiltering={false}
                />
            }
        />
    )
}

const styles = {
    inMap: {
        position: 'absolute',
        bottom: 'var(--s0)',
        left: 'var(--s2)',
        maxWidth: '325px',
    },
}
