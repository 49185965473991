import create from 'zustand'
import produce from 'immer'

import { DEFAULT_VIEW } from 'utils/Constants'

// This store is used across AvFx and HwyFx. HwyFx doesn't use the avalancheProblems state.
const ForecastStore = create((set) => {
    return {
        content: {},
        avalancheProblems: [],
        view: DEFAULT_VIEW,
        daysInView: {
            1: true,
            2: true,
            3: true,
            4: true,
        },
        setContent: (content) => {
            set({ content })
        },
        setView: (view) => {
            set({ view })
        },
        setDaysInView: (daysInView) => {
            set({ daysInView })
        },
        setAvalancheProblems: (days) => {
            set({ avalancheProblems: days })
        },
        updateAvalancheProblems: (day) => {
            set(
                produce((draft) => {
                    const dayIndex = draft.avalancheProblems.findIndex((el) => el.date === day.date)
                    draft.avalancheProblems[dayIndex] = day
                })
            )
        },
        setReviewNotes: (reviewNotes) => {
            set({ reviewNotes })
        },
    }
})

export const useView = () => ForecastStore((state) => state.view)
export const useSetView = () => ForecastStore((state) => state.setView)
export const useContent = () => ForecastStore((state) => state.content)
export const useSetContent = () => ForecastStore((state) => state.setContent)
export const useDaysInView = () => ForecastStore((state) => state.daysInView)
export const useSetDaysInView = () => ForecastStore((state) => state.setDaysInView)
export const useAvalancheProblems = () => ForecastStore((state) => state.avalancheProblems)
export const useSetAvalancheProblems = () => ForecastStore((state) => state.setAvalancheProblems)
export const useUpdateAvalancheProblems = () => ForecastStore((state) => state.updateAvalancheProblems)
