import React from 'react'
import { useActiveWeakLayer } from 'stores/WeakLayerStore'
import { useDateFormat } from 'stores/UserStore'
import { formatDate } from 'utils/Date'

export const MapStatusBarText = () => {
    const { name, burialDate, status, lastModifiedDate } = useActiveWeakLayer() || {}
    const dateFormat = useDateFormat()
    const formattedBurialDate = formatDate(burialDate, dateFormat)
    const formattedLastModifiedDate = formatDate(lastModifiedDate, dateFormat)

    return name ? (
        <>
            <strong>{name}</strong>
            <span>{formattedBurialDate}</span>
            <span className="capitalize">{status}</span>
            <span>({formattedLastModifiedDate})</span>
        </>
    ) : null
}
