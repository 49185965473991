import React from 'react'

import { FormattedMessage, useIntl } from 'react-intl'
import { Table } from 'antd'

import { SENSITIVITY_TYPES } from 'utils/Snowpack/Sensitivity'
import { uuid } from 'utils/String'
import messages from 'services/intl/messageDefinitions'

export const SensitivityTable = ({}) => {
    const keys = SENSITIVITY_TYPES
    const intl = useIntl()

    const columns = [
        {
            title: <FormattedMessage {...messages['sensitivity.titles.sensitivity']} />,
            key: 'sensitivity',
            dataIndex: 'sensitivity',
        },
        {
            title: <FormattedMessage {...messages['sensitivity.titles.release']} />,
            key: 'release',
            dataIndex: 'release',
        },
        {
            title: <FormattedMessage {...messages['sensitivity.titles.humanTriggers']} />,
            key: 'humanTriggers',
            dataIndex: 'humanTriggers',
        },
        {
            title: <FormattedMessage {...messages['sensitivity.titles.size']} />,
            key: 'size',
            dataIndex: 'size',
        },
        {
            title: <FormattedMessage {...messages['sensitivity.titles.result']} />,
            key: 'result',
            dataIndex: 'result',
        },
        {
            title: <FormattedMessage {...messages['sensitivity.titles.corniceTriggers']} />,
            key: 'corniceTriggers',
            dataIndex: 'corniceTriggers',
        },
    ]

    const data = keys.map((key) => ({
        key: uuid(),
        sensitivity: intl.formatMessage({ ...messages[`sensitivity.${key}.sensitivity`] }),
        release: intl.formatMessage({ ...messages[`sensitivity.${key}.release`] }),
        humanTriggers: intl.formatMessage({ ...messages[`sensitivity.${key}.sensitivity`] }),
        size: intl.formatMessage({ ...messages[`sensitivity.${key}.explosiveTriggersSize`] }),
        result: intl.formatMessage({ ...messages[`sensitivity.${key}.explosiveTriggersResult`] }),
        corniceTriggers: intl.formatMessage({ ...messages[`sensitivity.${key}.corniceTriggers`] }),
    }))

    return <Table pagination={false} dataSource={data} columns={columns} />
}
