import { Space, Typography, Input, Form, Select, Alert } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import debounce from 'lodash/debounce'

import messages from 'services/intl/messageDefinitions'
import { ACTIVE_PRODUCTS, PRODUCT_TRANSLATION_KEY_MAP } from 'utils/Constants'
import { useUpdateSearch, useProductTypes, useUpdateProductTypes, useSelectedPolygonId } from 'stores/ArchiveStore'
import { ArchiveTable } from './ArchiveTable'
import { LimitedRangePicker } from 'components/RangePicker/LimitedRangePicker'

export const Archive = () => {
    const { Title } = Typography
    const intl = useIntl()

    const setSearch = useUpdateSearch()
    const productTypes = useProductTypes()
    const setProductTypes = useUpdateProductTypes()
    const selectedPolygonId = useSelectedPolygonId()

    const handleSearchChange = (e) => {
        setSearch(e.target.value)
    }

    return (
        <Space direction="vertical" style={styles.container}>
            <Title>
                <FormattedMessage {...messages.archive} />
            </Title>
            <Form layout="vertical">
                <Form.Item label={<FormattedMessage {...messages.productType} />}>
                    <Select
                        mode="multiple"
                        maxTagCount="responsive"
                        optionLabelProp="label"
                        placeholder={intl.formatMessage({ ...messages.productType })}
                        onChange={setProductTypes}
                        value={productTypes}
                        status={productTypes.length > 0 ? 'valid' : 'error'}
                        options={ACTIVE_PRODUCTS.map((option) => {
                            return {
                                label: intl.formatMessage({
                                    ...messages[PRODUCT_TRANSLATION_KEY_MAP.get(option)],
                                }),
                                value: option,
                            }
                        })}
                    />
                </Form.Item>
                <Form.Item label={<FormattedMessage {...messages.dateRange} />}>
                    <LimitedRangePicker rangeLimit={31} />
                </Form.Item>
                <Form.Item label={<FormattedMessage {...messages.search} />}>
                    <Input.Search
                        placeholder={intl.formatMessage({ ...messages.archiveSearch })}
                        allowClear
                        onChange={debounce(handleSearchChange, 300)}
                        onSearch={setSearch}
                    />
                </Form.Item>
            </Form>
            <Alert
                type="info"
                style={styles.alert}
                message={
                    selectedPolygonId
                        ? intl.formatMessage(
                              {
                                  id: 'filterByPolygon',
                                  defaultMessage:
                                      'Displaying only products that include this polygon: {selectedPolygonId}',
                                  description: 'Filter by polygon',
                              },
                              {
                                  selectedPolygonId,
                              }
                          )
                        : intl.formatMessage({
                              id: 'filteredByPolygon',
                              defaultMessage:
                                  'Click on a polygon on the map to filter the below products according to the given polygon',
                              description: 'Filter by polygon',
                          })
                }
            />
            <ArchiveTable />
        </Space>
    )
}

const styles = {
    container: {
        padding: 'var(--s1)',
        display: 'flex',
        width: '100%',
        minWidth: '300px',
        overflowX: 'auto',
        overscrollBehavior: 'contain',
    },
    title: {
        margin: 0,
        fontWeight: 300,
    },
    alert: {
        marginBottom: 'var(--s-1)',
    },
}
