import React, { useEffect, useState } from 'react'

import { Row, Col, Typography } from 'antd'
import { useIntl } from 'react-intl'
import moment from 'moment'

import { getWindowDimensions } from 'utils/screen'
import messages from 'services/intl/messageDefinitions'
import { useContent } from 'stores/ForecastStore'
import { Stat } from 'components/Stat/Stat'
import { DaySelector } from 'components/DaySelector/DaySelector'

export const EditScreenHeader = ({ showDaySelector = true, hideTitle }) => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
    const [lastSaved, setLastSaved] = useState('')
    const { Title } = Typography
    const content = useContent()
    const intl = useIntl()
    const title = content.name || ''
    const titleSize = title.length > 45 ? 4 : 2

    const checkLastSaved = (timestamp) => {
        if (!timestamp) return

        const formattedLastSaved = getFormattedLastSaved(moment.duration(moment().diff(moment(timestamp))))
        setLastSaved(formattedLastSaved)
    }

    useEffect(() => {
        checkLastSaved(content.lastModifiedDateTime)

        const lastSavedInterval = setInterval(() => {
            checkLastSaved(content.lastModifiedDateTime)
        }, 60000)

        return () => clearInterval(lastSavedInterval)
    }, [content])

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const getFormattedLastSaved = (timePassed) => {
        let savedString
        if (timePassed._data.days !== 0) {
            savedString = intl.formatMessage({ ...messages.daysAgo }, { value: timePassed._data.days })
        } else if (timePassed._data.hours !== 0) {
            savedString = intl.formatMessage({ ...messages.hoursAgo }, { value: timePassed._data.hours })
        } else if (timePassed._data.minutes !== 0) {
            savedString = intl.formatMessage({ ...messages.minutesAgo }, { value: timePassed._data.minutes })
        } else {
            savedString = intl.formatMessage({ ...messages.lessThanMinute })
        }
        return savedString
    }

    const compressedView = (
        <Row style={styles.container} gutter={8}>
            <Col span={12} style={styles.selector}>
                {showDaySelector && <DaySelector />}
            </Col>
            <Col style={styles.statContainer} span={12}>
                <Stat label={'lastModified'} value={lastSaved} size={'small'} />
            </Col>
        </Row>
    )

    const stackedView = (
        <Row style={styles.container} gutter={8}>
            <Col span={24} style={styles.headerItemsStacked}>
                <Title level={titleSize} style={styles.title}>
                    {title}
                </Title>
            </Col>
            <Col span={12} style={styles.selector}>
                {showDaySelector && <DaySelector />}
            </Col>
            <Col style={styles.statContainer} span={12}>
                <Stat label={'lastModified'} value={lastSaved} size={'small'} />
            </Col>
        </Row>
    )

    const spreadView = (
        <Row style={styles.container} gutter={8}>
            {showDaySelector && (
                <Col span={6} style={styles.selector}>
                    <DaySelector />
                </Col>
            )}
            <Col
                span={showDaySelector ? 13 : 18}
                style={{ ...styles.headerItems, justifyContent: showDaySelector ? 'center' : 'start' }}
            >
                <Title level={titleSize} style={styles.title}>
                    {title}
                </Title>
            </Col>
            <Col span={showDaySelector ? 5 : 6} style={styles.statContainer}>
                <Stat label={'lastModified'} value={lastSaved} size={'small'} />
            </Col>
        </Row>
    )

    const view = windowDimensions.width < 1200 && showDaySelector ? stackedView : spreadView

    return <>{hideTitle ? compressedView : view}</>
}

const styles = {
    container: {
        margin: 'var(--s0) var(--s0) 0 var(--s0)',
        borderBottom: '1px solid var(--grey-lt)',
    },
    statContainer: {
        display: 'flex',
        paddingRight: 'var(--s0)',
        justifyContent: 'right',
    },
    title: {
        fontWeight: '300',
        marginBottom: '0',
    },
    headerItems: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headerItemsStacked: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '20px',
        paddingTop: '20px',
    },
    selector: {
        justifyContent: 'left',
    },
}
