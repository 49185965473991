import React, { useState, useEffect, useCallback, useRef } from 'react'

import { FormattedMessage, useIntl } from 'react-intl'
import { Row, Checkbox, Transfer, Card, Tag } from 'antd'

import { getWindowDimensions } from 'utils/screen'
import { allAspects } from './utils'
import messages from 'services/intl/messageDefinitions'
import { useEnhancedAccessibility } from 'stores/UserStore'
import aspects from './svgs'
import { AspectSlice } from './AspectSlice'
import { Menu } from './Menu'

import './AspectElevation.css'

export const AspectElevation = ({
    updateDraftItem,
    draftItem,
    aspectElevations,
    colour,
    create,
    update,
    errorStatus = false,
    compact = false,
}) => {
    const [showMenu, setShowMenu] = useState(false)
    const [pos, setPos] = useState({})
    const [selectedKeys, setSelectedKeys] = useState([])
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
    const container = useRef(null)
    const intl = useIntl()
    const enhancedAccessibility = useEnhancedAccessibility()

    const handleLeftClick = (aspectElevation) => {
        if (!update && !create) {
            return
        }

        const newAspectElevations = aspectElevations.includes(aspectElevation)
            ? [...aspectElevations].filter((value) => value !== aspectElevation)
            : [...aspectElevations, aspectElevation]

        if (create) {
            updateDraftItem({
                ...draftItem,
                aspectElevations: newAspectElevations,
            })
            return
        }

        update([{ key: 'aspectElevations', value: newAspectElevations }])
    }

    const handleRightClick = (event) => {
        const parentElement = container.current.getBoundingClientRect()

        if (!update && !create) {
            return
        }

        event.preventDefault()

        setPos({ x: event.clientX - parentElement.left, y: event.clientY - parentElement.top })
        setShowMenu(true)
    }

    const handleMenuClick = (action) => {
        let aspectsArray = []

        if (action === 'select') {
            aspectsArray = allAspects
        }

        if (action === 'unselect') {
            aspectsArray = []
        }

        if (create) {
            updateDraftItem({
                ...draftItem,
                aspectElevations: aspectsArray,
            })
            return
        }

        update([{ key: 'aspectElevations', value: aspectsArray }])
    }

    const onTransferChange = (nextTargetKeys) => {
        if (create) {
            updateDraftItem({
                ...draftItem,
                aspectElevations: nextTargetKeys,
            })
        } else {
            update([{ key: 'aspectElevations', value: nextTargetKeys }])
        }

        // Move the focus to the next element
        // https://app.asana.com/0/1203185610176512/1205349142871826
        const nextElement = document.getElementById('centroid.size')
        nextElement && nextElement.focus()
    }

    const onTransferSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys])
    }

    const selectAllUnselected = () => {
        const inactiveAspects = allAspects.filter((aspect) => !aspectElevations.includes(aspect))
        if (inactiveAspects.every((aspect) => selectedKeys.includes(aspect))) {
            setSelectedKeys(selectedKeys.filter((aspect) => !inactiveAspects.includes(aspect)))
        } else {
            setSelectedKeys([...selectedKeys, ...inactiveAspects])
        }
    }

    const selectAllSelected = () => {
        const activeAspects = allAspects.filter((aspect) => aspectElevations.includes(aspect))
        if (activeAspects.every((aspect) => selectedKeys.includes(aspect))) {
            setSelectedKeys(selectedKeys.filter((aspect) => !activeAspects.includes(aspect)))
        } else {
            setSelectedKeys([...selectedKeys, ...activeAspects])
        }
    }

    // handles click somewhere else to close the right click menu
    const handleClick = useCallback(() => (showMenu ? setShowMenu(false) : null), [showMenu])

    useEffect(() => {
        document.addEventListener('click', handleClick)
        return () => {
            document.removeEventListener('click', handleClick)
        }
    })

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const visuallyHiddenList = (
        <div style={styles.visuallyHidden}>
            {intl.formatMessage(
                { ...messages.accessibleAspectList },
                {
                    selectedAspects: aspectElevations.map(
                        (aspect, i) => `${i === 0 ? '' : ','} ${intl.formatMessage({ ...messages[aspect] })}`
                    ),
                }
            )}
        </div>
    )

    const rose = (
        <div ref={container} style={styles.parent}>
            {visuallyHiddenList}
            {errorStatus && (
                <p style={styles.error}>
                    <FormattedMessage {...messages.aspectElevationsRequired} />
                </p>
            )}
            <p style={styles.north}>
                <FormattedMessage {...messages.N} />
            </p>
            <p style={styles.east}>
                <FormattedMessage {...messages.E} />
            </p>
            <p style={styles.west}>
                <FormattedMessage {...messages.W} />
            </p>
            <div
                className={'aspect-elevation-rose'}
                style={styles.container}
                onContextMenu={(event) => handleRightClick(event)}
            >
                <svg style={styles.svg} viewBox="57 26 722 722">
                    {aspects.map((aspect) => {
                        const { direction } = aspect

                        return (
                            <AspectSlice
                                key={direction}
                                {...aspect}
                                isActive={aspectElevations.includes(direction)}
                                colour={colour}
                                pointer={update || create ? true : false}
                                handleLeftClick={(aspectElevation) => handleLeftClick(aspectElevation)}
                            />
                        )
                    })}
                </svg>
            </div>
            <p style={styles.south}>
                <FormattedMessage {...messages.S} />
            </p>
            {showMenu && <Menu pos={pos} handleMenuClick={(action) => handleMenuClick(action)} />}
        </div>
    )

    const leftSelectAll = (
        <Row>
            <Checkbox
                checked={
                    aspectElevations.length !== allAspects.length &&
                    allAspects
                        .filter((aspect) => !aspectElevations.includes(aspect))
                        .every((aspect) => selectedKeys.includes(aspect))
                }
                onChange={selectAllUnselected}
                aria-label={intl.formatMessage({ ...messages.selectAllAspects })}
                style={styles.AEHeaderCheckbox}
            />
            <div style={styles.AEHeaderSelectText}>
                <FormattedMessage {...messages.selectAll} />
            </div>
        </Row>
    )

    const rightSelectAll = (
        <Row>
            <Checkbox
                checked={
                    aspectElevations.length !== 0 &&
                    allAspects
                        .filter((aspect) => aspectElevations.includes(aspect))
                        .every((aspect) => selectedKeys.includes(aspect))
                }
                onChange={selectAllSelected}
                aria-label={intl.formatMessage({ ...messages.unselectAllAspects })}
                style={styles.AEHeaderCheckbox}
            />
            <div style={styles.AEHeaderSelectText}>
                <FormattedMessage {...messages.selectAll} />
            </div>
        </Row>
    )

    const aspectElevationList = (
        <>
            {visuallyHiddenList}
            {compact ? (
                aspectElevations.map((aspect) => (
                    <Tag color="blue" key={aspect}>
                        {intl.formatMessage({ ...messages[`${aspect}_compact`] })}
                    </Tag>
                ))
            ) : (
                <Card
                    size="small"
                    title={intl.formatMessage({ ...messages.selectedAspects })}
                    style={styles.aspectElevationList}
                >
                    {aspectElevations.map((aspect) => (
                        <Tag color="blue" key={aspect}>
                            {intl.formatMessage({ ...messages[aspect] })}
                        </Tag>
                    ))}
                </Card>
            )}
        </>
    )

    return (
        <>
            {enhancedAccessibility ? (
                create || update ? (
                    <>
                        {errorStatus && (
                            <p style={styles.error}>
                                <FormattedMessage {...messages.aspectElevationsRequired} />
                            </p>
                        )}
                        <Transfer
                            dataSource={allAspects.map((aspect) => ({
                                key: aspect,
                                title: intl.formatMessage({ ...messages[aspect] }),
                            }))}
                            titles={[
                                intl.formatMessage({ ...messages.unselected }),
                                intl.formatMessage({ ...messages.selected }),
                            ]}
                            selectAllLabels={[leftSelectAll, rightSelectAll]}
                            showSelectAll={false}
                            targetKeys={aspectElevations}
                            selectedKeys={selectedKeys}
                            onChange={onTransferChange}
                            onSelectChange={onTransferSelectChange}
                            render={(item) => item.title}
                            style={styles.transfer}
                        />
                    </>
                ) : (
                    aspectElevationList
                )
            ) : (
                rose
            )}
        </>
    )
}

const styles = {
    error: {
        color: 'var(--red)',
        textAlign: 'center',
    },
    parent: {
        maxWidth: '250px',
        position: 'relative',
        margin: 'auto',
    },
    container: {
        maxWidth: '250px',
        maxHeight: '250px',
        display: 'flex',
        justifyContent: 'center',
    },
    svg: {
        width: '80%',
        height: '85%',
        marginLeft: '2px',
        // marginTop: '-13px', // scary negative margin :D
    },
    north: {
        textAlign: 'center',
        color: 'var(--grey)',
        marginBottom: '0',
    },
    south: {
        textAlign: 'center',
        color: 'var(--grey)',
    },
    east: {
        position: 'absolute',
        color: 'var(--grey)',
        top: '50%',
        transform: 'translateY(-50%)',
        right: '0px',
    },
    west: {
        position: 'absolute',
        color: 'var(--grey)',
        top: '50%',
        transform: 'translateY(-50%)',
    },
    transfer: {
        marginBottom: 'var(--s0)',
        marginTop: 'var(--s-1)',
        width: '100%',
    },
    AEHeaderCheckbox: {
        marginRight: 'var(--s-4)',
    },
    AEHeaderSelectText: {
        marginTop: 'var(--s-5)',
    },
    visuallyHidden: {
        position: 'absolute',
        width: '1px',
        height: '1px',
        padding: '0',
        margin: '-1px',
        overflow: 'hidden',
        clip: 'rect(0, 0, 0, 0)',
        whiteSpace: 'nowrap',
        border: '0',
    },
    aspectElevationList: {
        width: '100%',
        marginTop: 'var(--s-1)',
        marginBottom: 'var(--s0)',
    },
}
