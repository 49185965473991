import moment from 'moment'

import { createEmptyProduct } from './Product'
import { DEFAULT_VALUE_CONFIDENCE, DEFAULT_VALUE_HWY_HAZARD, DEFAULT_VALUE_REVIEW } from './Constants'

export const createEmptyHighwayForecast = (locale = 'en') => {
    return {
        ...createEmptyProduct('highwayforecast', locale),
        issueDateTime: moment(),
        expiryDateTime: moment(new Date()).add(5, 'days').set({ hour: 18, minute: 0, second: 0, millisecond: 0 }),
        weatherSummary: {
            days: new Array(4).fill().map((el, i) => {
                return { position: i, content: { [locale]: '' } }
            }),
        },
        snowpackSummary: {
            days: new Array(4).fill().map((el, i) => {
                return { position: i, content: { [locale]: '' } }
            }),
        },
        avalancheSummary: {
            days: new Array(4).fill().map((el, i) => {
                return { position: i, content: { [locale]: '' } }
            }),
        },
        avalancheProblems: {
            days: new Array(4).fill().map((el, i) => {
                return { position: i }
            }),
        },
        hazard: DEFAULT_VALUE_HWY_HAZARD,
        confidence: {
            days: new Array(4).fill().map((el, i) => {
                return { position: i, rating: DEFAULT_VALUE_CONFIDENCE, statements: [] }
            }),
        },
        communications: {
            headline: {
                [locale]: '',
            },
            sms: {
                [locale]: '',
            },
        },
        review: {
            status: DEFAULT_VALUE_REVIEW,
            notes: '',
        },
        isTranslated: false,
    }
}

export const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
export const MITIGATION_OUTLOOK_OPTIONS = ['unlikely', 'possible', 'likely']

export const createEmptyHighwayDiscussion = (locale = 'en') => {
    return {
        ...createEmptyProduct('highwaydiscussion'),
        communications: {
            headline: {
                [locale]: '',
            },
            sms: {
                [locale]: '',
            },
        },
        operationalRecommendations: {
            [locale]: '',
        },
        mitigationRecommendations: {
            [locale]: '',
        },
        mitigationOutlook: DAYS_OF_WEEK.map((Day) => {
            return { Day, Outlook: MITIGATION_OUTLOOK_OPTIONS[0] }
        }),
    }
}

export const createEmptyMitigation = () => {
    return createEmptyProduct('mitigation')
}
