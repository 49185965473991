import { useState, useEffect } from 'react'

import { Drawer } from 'antd'
import { useIntl } from 'react-intl'

import { TwoColLayoutPrimaryLeft } from 'components/TwoColumnLayout/TwoColLayoutPrimaryLeft'
import { Archive as ArchiveComponent } from 'components/Archive/Archive'
import { useActiveProduct, useUpdateActiveProduct } from 'stores/ArchiveStore'
import { Details as AvFxDetails } from 'components/AvalancheForecast/Setup/Details'
import { Details as RegDiscDetails } from 'components/RegionalDiscussions/Details'
import { Details as SPDetails } from 'components/SpecialProducts/Details'
import { Details as HwyFxDetails } from 'components/Highways/HighwayForecasts/Details'
import { Details as HwyDiscDetails } from 'components/Highways/HighwayDiscussions/Details'
import { Details as HwyMitigationDetails } from 'components/Highways/Mitigations/Details'
import { useSpecialProductsResults } from 'hooks/archive'
import { useMitigationResults } from 'hooks/archive'
import messages from 'services/intl/messageDefinitions'
import { ArchiveMap } from 'components/Archive/Map/ArchiveMap'
import { Dragger } from 'components/Dragger/Dragger'

export const Archive = () => {
    const intl = useIntl()

    const activeProduct = useActiveProduct()
    const setActiveProduct = useUpdateActiveProduct()
    const [leftFraction, setLeftFraction] = useState(40)
    const [detailsViewWidth, setDetailsViewWidth] = useState(35)

    const { mutate: SPMutate } = useSpecialProductsResults()
    const { mutate: MitMutate } = useMitigationResults()

    useEffect(() => {
        document.title = intl.formatMessage(messages.archive)
    }, [intl])

    const resizeDetailsView = (e, data) => {
        const newWidth = detailsViewWidth + data.x / -20
        setDetailsViewWidth(newWidth)
    }

    const SPUpdateDataAfterExpiry = (product) => {
        SPMutate()
        setActiveProduct(product)
    }

    const mitUpdateDataAfterExpiry = (product) => {
        MitMutate()
        setActiveProduct(product)
    }

    const detailsView = (
        <>
            {activeProduct === null && null}
            {activeProduct?.type === 'avalancheforecast' && (
                <AvFxDetails
                    item={activeProduct}
                    closeAndOpenCloned={(id) => window.open(`/avalancheforecast?focus=${id}`)}
                    showContent
                    viewWidth={detailsViewWidth}
                />
            )}
            {activeProduct?.type === 'regionaldiscussion' && (
                <RegDiscDetails
                    item={activeProduct}
                    closeAndOpenCloned={(id) => window.open(`/regional-discussions?focus=${id}`)}
                />
            )}
            {activeProduct?.type === 'specialproduct' && (
                <SPDetails
                    item={activeProduct}
                    closeAndOpenCloned={(id) => window.open(`/special-products?focus=${id}`)}
                    closeAndReload={SPUpdateDataAfterExpiry}
                />
            )}
            {activeProduct?.type === 'highwayforecast' && (
                <HwyFxDetails
                    item={activeProduct}
                    closeAndOpenCloned={(id) => window.open(`/hwy-forecast?focus=${id}`)}
                    showContent
                    viewWidth={detailsViewWidth}
                />
            )}
            {activeProduct?.type === 'highwaydiscussion' && (
                <HwyDiscDetails
                    item={activeProduct}
                    closeAndOpenCloned={(id) => window.open(`/hwy-discussion?focus=${id}`)}
                    viewWidth={detailsViewWidth}
                />
            )}
            {activeProduct?.type === 'mitigation' && (
                <HwyMitigationDetails
                    item={activeProduct}
                    closeAndOpenCloned={(id) => window.open(`/mitigation?focus=${id}`)}
                    closeAndReload={mitUpdateDataAfterExpiry}
                />
            )}
        </>
    )

    return (
        <>
            <TwoColLayoutPrimaryLeft
                left={<ArchiveComponent />}
                right={<ArchiveMap />}
                leftFraction={leftFraction}
                boxShadow
            />
            <Drawer
                onClose={() => setActiveProduct(null)}
                closable={true}
                visible={Boolean(activeProduct)}
                mask={false}
                contentWrapperStyle={{ ...styles.setupViewDrawer, width: `${detailsViewWidth}vw` }}
            >
                <Dragger onDrag={resizeDetailsView} orientation="left" />
                {detailsView}
            </Drawer>
        </>
    )
}

const styles = {
    expandButton: {
        position: 'absolute',
        top: 90,
        right: 0,
        marginLeft: -16,
        zIndex: 1,
        background: 'var(--background-colour)',
    },
    setupViewDrawer: {
        marginTop: '2px',
        paddingTop: '0px',
        borderLeft: '1px solid var(--grey-light)',
        overscrollBehavior: 'contain',
    },
    previewDrawer: {
        width: '32vw',
        marginTop: '2px',
        paddingTop: '0px',
        borderLeft: '1px solid var(--grey-light)',
    },
}
