import { Map, WithMapReady } from 'components/Map/Map'
import { usePolygonCentroids, usePolygons } from 'hooks/polygons'
import { HighwayPolygons } from 'components/Map/HighwayPolygons'
import { useHighwayForecasts } from 'hooks/highwayForecasts'

export const HighwayMap = ({ groupID, onScreenshot }) => {
    const { highwayForecasts } = useHighwayForecasts()
    const polygons = usePolygons()
    const labelsGeoJson = usePolygonCentroids()

    if (!highwayForecasts || !polygons || !labelsGeoJson) {
        return null // Do the null check here so that props are not empty when passed to the map
    }

    return (
        <Map enforceLightStyle>
            <WithMapReady>
                <HighwayPolygons
                    products={highwayForecasts}
                    groupID={groupID}
                    polygons={polygons}
                    onScreenshot={onScreenshot}
                    labelsGeoJson={labelsGeoJson}
                />
            </WithMapReady>
        </Map>
    )
}
