import { identity, noop } from 'utils/function'

export function create(key) {
    try {
        // Trying a test key
        const testKey = '99999'
        localStorage.setItem(testKey, testKey)
        localStorage.removeItem(testKey)

        return {
            get(defaultValue) {
                const value = localStorage.getItem(key)

                if (value === null) {
                    return defaultValue
                }

                try {
                    return JSON.parse(value)
                } catch {
                    return value
                }
            },
            set(value) {
                localStorage.setItem(key, JSON.stringify(value))
            },
        }
    } catch {
        return {
            get: identity,
            set: noop,
        }
    }
}
