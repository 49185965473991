import React from 'react'

import { Row, Col, Typography, Progress, Tag } from 'antd'
import { FormattedMessage } from 'react-intl'

import { AvalancheForecastStore } from 'stores/AvalancheForecastStore'
import { progressToPercent, getColourOfReviewStatus, getReviewStatusTextColour } from 'utils/ForecastContent'
import { getWindowDimensions } from 'utils/screen'
import { Date } from 'components/Date/Date'
import messages from 'services/intl/messageDefinitions'

export const Summary = ({ item }) => {
    const { Title } = Typography
    const setActiveAvalancheForecast = AvalancheForecastStore.useSetActiveProductId()
    const windowDimensions = getWindowDimensions()

    const handleClick = () => {
        setActiveAvalancheForecast(item.id)
    }

    return (
        <div onClick={handleClick} style={{ ...styles.container, borderLeft: `6px solid ${item.colour}` }}>
            <Row>
                <Col span={16}>
                    <Title level={4} style={styles.name} data-test={`avalancheForecastTitle${item.name}`}>
                        {item.name}
                    </Title>
                </Col>
                <Col span={8}>
                    <p style={styles.forecaster}>{item.username}</p>
                </Col>
            </Row>
            <Row>
                <Col span={18}>
                    <span style={styles.dateIconSpan}>
                        <Date
                            value={item.issueDateTime}
                            label={'issued'}
                            extraStyles={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                marginRight: '10px',
                            }}
                        />

                        {/* Hide the badge if the window is too small */}
                        {item.review && item.review.status && windowDimensions.width > 1000 && (
                            <Tag
                                color={getColourOfReviewStatus(item.review.status)}
                                style={{ ...styles.tag, color: getReviewStatusTextColour(item.review.status) }}
                            >
                                <FormattedMessage {...messages[item.review.status]} />
                            </Tag>
                        )}
                    </span>
                </Col>
                <Col span={6}>
                    <Progress percent={progressToPercent(item.progress)} />
                </Col>
            </Row>
        </div>
    )
}

const styles = {
    tag: {
        height: '22px',
    },
    name: {
        fontWeight: 500,
        fontSize: '18px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    container: {
        paddingLeft: 'var(--s1)',
        height: '50px',
        position: 'relative',
    },
    forecaster: {
        textAlign: 'right',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    dateIconSpan: {
        display: 'flex',
    },
}
