import { useEffect, useCallback } from 'react'

import bbox from '@turf/bbox'

import { useMap } from 'components/Map/Map'
import {
    useActiveProduct,
    useUpdateActiveProduct,
    useUpdateSelectedPolygonId,
    useSelectedPolygonId,
} from 'stores/ArchiveStore'
import { useLocale } from 'stores/UserStore'
import { Map, WithMapReady } from 'components/Map/Map'
import { usePolygons } from 'hooks/polygons'
import { usePolylines } from 'hooks/polylines'
import { PolygonsWithFeatureState } from './PolygonsWithFeatureState'
import { PolylinesWithFeatureState } from './PolylinesWithFeatureState'
import { ActivePolygonProduct } from './ActivePolygonProduct'
import { ActivePolylineProduct } from './ActivePolylineProduct'
import { polylineSource } from './PolylinesWithFeatureState'
import { polygonSource } from './PolygonsWithFeatureState'

const MAP_BUFFER = 50

export const ArchiveMap = () => {
    const activeProduct = useActiveProduct()
    const locale = useLocale()
    const polygons = usePolygons()
    const polylines = usePolylines()

    // Show view-only polygons and polylines in grey, with click handlers.
    return (
        <Map locale={locale}>
            <WithMapReady>
                {polygons?.features && (
                    <>
                        <InitialMapZoom polygons={polygons} />
                        <PolygonsWithFeatureState polygons={polygons} />
                    </>
                )}
                {polylines?.features && <PolylinesWithFeatureState polylines={polylines} />}
                {activeProduct && activeProduct.type !== 'highwaydiscussion' && (
                    <ActivePolygonProduct product={activeProduct} polygons={polygons} />
                )}
                {activeProduct && activeProduct.type === 'highwaydiscussion' && (
                    <ActivePolylineProduct product={activeProduct} polylines={polylines} />
                )}
                <PolygonAndPolylineClickHandler />
            </WithMapReady>
        </Map>
    )
}

const InitialMapZoom = ({ polygons }) => {
    const map = useMap()

    // Set zoom extents on initial load
    // TODO: This should take both polygons and polylines
    const zoomExtents = useCallback((polygons) => {
        if (process.env.REACT_APP_INITIAL_ZOOM) {
            map.fitBounds(JSON.parse(process.env.REACT_APP_INITIAL_ZOOM))
        } else {
            let bounds = bbox(polygons)
            map.fitBounds(bounds, { padding: MAP_BUFFER })
        }
    }, [])

    useEffect(() => {
        zoomExtents(polygons)
    }, [])
}

const PolygonAndPolylineClickHandler = () => {
    const map = useMap()
    const setSelectedPolygonId = useUpdateSelectedPolygonId()
    const selectedPolygonId = useSelectedPolygonId()
    const activeProduct = useActiveProduct()
    const updateActiveProduct = useUpdateActiveProduct()

    const clickPolygon = useCallback(
        (event) => {
            const features = event.target.queryRenderedFeatures(event.point, {
                layers: [polylineSource, polygonSource],
            })
            const id = features[0]?.properties.id
            if (!activeProduct?.polygons.includes(id)) {
                updateActiveProduct(null)
            }

            if (id === selectedPolygonId) {
                setSelectedPolygonId(null)
            } else {
                setSelectedPolygonId(id)
            }
        },
        [activeProduct, selectedPolygonId, setSelectedPolygonId, updateActiveProduct]
    )

    useEffect(() => {
        map.on('click', clickPolygon)

        return () => {
            map.off('click', clickPolygon)
        }
    }, [clickPolygon, map])
}
