import { useEffect, useRef, useState } from 'react'

import { Form, Row, Col } from 'antd'
import { FormattedMessage } from 'react-intl'

import messages from 'services/intl/messageDefinitions'
import { LIKELIHOODS, SIZES } from 'utils/AvalancheProblems'
import { Select } from 'components/Dropdown/Select'

export const AccessibleHazardChart = ({ chartPolygons, formMarker, onChange, disabled = false }) => {
    // External imports
    const [form] = Form.useForm()
    const { Option } = Select

    // Local state
    const formRef = useRef()
    const [colWidth, setColWidth] = useState(12)
    const [requiredFieldsPresent, setRequiredFieldsPresent] = useState(false)

    form.setFieldsValue({
        'centroid.size': chartPolygons.centroid.size,
        'centroid.likelihood': chartPolygons.centroid.likelihood,
        'size.from': chartPolygons.size.from,
        'size.to': chartPolygons.size.to,
        'likelihood.from': chartPolygons.likelihood.from,
        'likelihood.to': chartPolygons.likelihood.to,
        'form.size': formMarker.size,
        'form.likelihood': formMarker.likelihood,
    })

    useEffect(() => {
        if (formRef?.current?.offsetWidth) {
            setColWidth(formRef.current.offsetWidth < 400 ? 24 : 12)
        }
    }, [formRef?.current?.offsetWidth])

    const generateLikelihoodOptions = (type = '') => {
        // These indexes are flipped because the LIKELIHOODS array is in reverse order
        let minIndex = LIKELIHOODS.length - 1
        let maxIndex = 0
        if (type === 'centroid') {
            minIndex = LIKELIHOODS.indexOf(chartPolygons.likelihood.from)
            maxIndex = LIKELIHOODS.indexOf(chartPolygons.likelihood.to)
        } else if (type === 'from') {
            maxIndex = LIKELIHOODS.indexOf(chartPolygons.likelihood.to) + 1
        } else if (type === 'to') {
            minIndex = LIKELIHOODS.indexOf(chartPolygons.likelihood.from) - 1
        }

        return LIKELIHOODS.map((item, index) => {
            const label = typeof item === 'number' ? item : <FormattedMessage {...messages[item]} />
            return (
                <Option key={item} value={item} disabled={index < maxIndex || index > minIndex}>
                    {label}
                </Option>
            )
        })
    }

    const generateSizeOptions = (type = '') => {
        let minIndex = 0
        let maxIndex = SIZES.length - 1
        if (type === 'centroid') {
            minIndex = SIZES.indexOf(chartPolygons.size.from)
            maxIndex = SIZES.indexOf(chartPolygons.size.to)
        } else if (type === 'from') {
            maxIndex = SIZES.indexOf(chartPolygons.size.to) - 1
        } else if (type === 'to') {
            minIndex = SIZES.indexOf(chartPolygons.size.from) + 1
        }

        return SIZES.map((item, index) => {
            const label = typeof item === 'number' ? item : <FormattedMessage {...messages[item]} />
            return (
                <Option key={item} value={item} disabled={index < minIndex || index > maxIndex}>
                    {label}
                </Option>
            )
        })
    }

    const handleOnValuesChange = (changedValues, allValues) => {
        const newChartPolygons = {
            centroid: {
                size: Number(allValues['centroid.size']),
                likelihood: allValues['centroid.likelihood'],
            },
            size: {
                from: Number(allValues['size.from']),
                to: Number(allValues['size.to']),
            },
            likelihood: {
                from: allValues['likelihood.from'],
                to: allValues['likelihood.to'],
            },
        }

        onChange(newChartPolygons)
    }

    useEffect(() => {
        setRequiredFieldsPresent(areRequiredFieldsFilled(chartPolygons))
    }, [chartPolygons])

    return (
        <div ref={formRef}>
            <Form form={form} layout="horizontal" onValuesChange={handleOnValuesChange}>
                <Row gutter={16}>
                    <Col span={24}>
                        <h3>
                            <FormattedMessage {...messages.centroid} />
                        </h3>
                    </Col>
                    <Col span={colWidth}>
                        <Form.Item name="centroid.size" label={<FormattedMessage {...messages.centroidSize} />}>
                            <Select disabled={disabled || !requiredFieldsPresent}>
                                {generateSizeOptions('centroid')}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={colWidth}>
                        <Form.Item
                            name="centroid.likelihood"
                            label={<FormattedMessage {...messages.centroidLikelihood} />}
                        >
                            <Select disabled={disabled || !requiredFieldsPresent}>
                                {generateLikelihoodOptions('centroid')}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <h3>
                            <FormattedMessage {...messages.sizeRange} />
                        </h3>
                    </Col>
                    <Col span={colWidth}>
                        <Form.Item name="size.from" label={<FormattedMessage {...messages.sizeRangeFrom} />}>
                            <Select disabled={disabled || !requiredFieldsPresent}>{generateSizeOptions('from')}</Select>
                        </Form.Item>
                    </Col>
                    <Col span={colWidth}>
                        <Form.Item name="size.to" label={<FormattedMessage {...messages.sizeRangeTo} />}>
                            <Select disabled={disabled || !requiredFieldsPresent}>{generateSizeOptions('to')}</Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <h3>
                            <FormattedMessage {...messages.likelihoodRange} />
                        </h3>
                    </Col>
                    <Col span={colWidth}>
                        <Form.Item
                            name="likelihood.from"
                            label={<FormattedMessage {...messages.likelihoodRangeFrom} />}
                        >
                            <Select disabled={disabled || !requiredFieldsPresent}>
                                {generateLikelihoodOptions('from')}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={colWidth}>
                        <Form.Item name="likelihood.to" label={<FormattedMessage {...messages.likelihoodRangeTo} />}>
                            <Select disabled={disabled || !requiredFieldsPresent}>
                                {generateLikelihoodOptions('to')}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <h3>
                            <FormattedMessage {...messages.centroidFromForm} />
                        </h3>
                    </Col>
                    <Col span={colWidth}>
                        <Form.Item name="form.size" label={<FormattedMessage {...messages.formDerivedSize} />}>
                            <Select disabled>{generateSizeOptions()}</Select>
                        </Form.Item>
                    </Col>
                    <Col span={colWidth}>
                        <Form.Item name="form.likelihood" label={<FormattedMessage {...messages.formDerivedSize} />}>
                            <Select disabled>{generateLikelihoodOptions()}</Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

// utils
const areRequiredFieldsFilled = (chartPolygons) => {
    const { centroid, size, likelihood } = chartPolygons
    return centroid.size && centroid.likelihood && size.from && size.to && likelihood.from && likelihood.to
}
