import create from 'zustand'
import { persist } from 'zustand/middleware'
import useSWR, { mutate } from 'swr'

import defaultAvatar from '../assets/static/avatar.png'
import { getFromNavigator, runLocaleSideEffects } from 'utils/Locales'
import { YYYYMMDD } from 'utils/Date'
import { fetchUser, putUser } from 'services/Users'

export const UserStore = create(
    persist(
        (set, get) => {
            // Run these on initial page load
            const initialLocale = getFromNavigator()
            runLocaleSideEffects(initialLocale)

            return {
                id: null,
                country: 'CA',
                picture: defaultAvatar,
                editingDisabled: false,
                setCountry: (country) => {
                    set({ country })
                },
                setPicture: (picture) => {
                    set({ picture })
                },
                setId: (id) => {
                    set({ id })
                },
                updateDetails: async (userDetails) => {
                    set({ editingDisabled: true })

                    await putUser(userDetails)

                    set({ editingDisabled: false })

                    const key = ['/users', get().id]
                    mutate(key)
                },
            }
        },
        { name: 'user-details' }
    )
)

// UserDetails server-side state via SWR
export const useUserDetails = () => {
    const id = useId()
    const key = ['/users', id]
    const { data, isLoading } = useSWR(id ? key : null, () => fetchUser(id))

    return {
        details: data?.data,
        isLoading,
    }
}
export const useLocale = () => useUserDetails().details?.language || 'en'
export const useDateFormat = () => useUserDetails().details?.dateFormat || YYYYMMDD
export const useColourScheme = () => useUserDetails().details?.colourScheme || 'light'
export const useEnhancedAccessibility = () => useUserDetails().details?.enhancedAccessibility || false
export const useNotificationLevel = () => useUserDetails().details?.notifications || 'error'
export const useUsername = () => useUserDetails().details?.name || 'User'

// Store state
export const useEditingDisabled = () => UserStore((state) => state.editingDisabled)
export const useCountry = () => UserStore((state) => state.country)
export const useSetCountry = () => UserStore((state) => state.setCountry)
export const usePicture = () => UserStore((state) => state.picture)
export const useSetPicture = () => UserStore((state) => state.setPicture)
export const useId = () => UserStore((state) => state.id)
export const useSetId = () => UserStore((state) => state.setId)
export const useUpdateDetails = () => UserStore((state) => state.updateDetails)
