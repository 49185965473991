import React from 'react'
import './LoadingSpinner.css'

export const LoadingSpinner = () => {
    return (
        <div className="lds-ring">
            <div />
            <div />
            <div />
            <div />
        </div>
    )
}
