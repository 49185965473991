import React from 'react'

import { FormattedMessage } from 'react-intl'
import { Button } from 'antd'

import messages from 'services/intl/messageDefinitions'

export const SmsCardTitle = ({ handleClick }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <FormattedMessage {...messages.sms} />
            <Button onClick={handleClick}>
                <FormattedMessage {...messages.copyFromHeadline} />
            </Button>
        </div>
    )
}
