import { Button, Col, Row, Typography } from 'antd'

import { FormattedMessage } from 'react-intl'
import messages from 'services/intl/messageDefinitions'

export const ModalFormFooter = ({ handleCancelClick, image }) => {
    return (
        <Row>
            <Col span={24} style={styles.colContainer}>
                {image && image.url ? (
                    <>
                        <Typography.Text type="secondary" style={styles.autoSaveMessage}>
                            <FormattedMessage {...messages.changesAreAutosaved} />
                        </Typography.Text>
                        <Button type="primary" onClick={() => handleCancelClick(image)} disabled={image && !image.url}>
                            <FormattedMessage {...messages.close} />
                        </Button>
                    </>
                ) : (
                    <Button onClick={() => handleCancelClick(image)}>
                        <FormattedMessage {...messages.cancel} />
                    </Button>
                )}
            </Col>
        </Row>
    )
}

const styles = {
    colContainer: {
        textAlign: 'right',
    },
    autoSaveMessage: {
        marginRight: 'var(--s-1)',
    },
}
