import React, { useEffect, useState } from 'react'

import { FormattedMessage } from 'react-intl'

import { uuid } from 'utils/String'
import { noop } from 'utils/function'
import messages from 'services/intl/messageDefinitions'
import { useContent, useSetContent, useDaysInView } from 'stores/ForecastStore'
import { EditScreenLayout } from 'components/EditScreenLayout/EditScreenLayout'
import { DayCardLayout } from 'components/DayCardLayout/DayCardLayout'
import { DayCard } from 'components/DayCard/DayCard'
import { ConfidenceSelectorForm } from 'components/SelectorForm/ConfidenceSelectorForm'
import { Select } from 'components/Dropdown/Select'
import { CONFIDENCE_OPTIONS } from 'utils/ForecastContent'
import { useUndo } from 'components/AvalancheForecast/Content/useUndo'
import { FloatMenu } from 'components/AvalancheForecast/Content/FloatMenu'
import { DEFAULT_VALUE_CONFIDENCE } from 'utils/Constants'

import './Confidence.css'

const MAX_CONFIDENCE_STATEMENTS = 3
const VIEW = 'confidence'

export const Confidence = ({ update, dayDetails, setReviewDrawerVisible, preview, compressed, colWidth }) => {
    const content = useContent()
    const setContent = useSetContent()
    const [cards, setCards] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)
    const [updateStatements, setUpdateStatements] = useState(false)
    const [updateRatings, setUpdateRatings] = useState(false)
    const [cloneNext, setCloneNext] = useState(false)
    const [cloneAll, setCloneAll] = useState(false)
    const daysInView = useDaysInView()
    const { undoUpdate, initUndo } = useUndo()

    const undo = async () => {
        const undoResult = await undoUpdate({ versionNumber: content.versionNumber, content, section: VIEW })
        if (undoResult) {
            setContent(undoResult)
            const unstructured = undoResult.confidence?.days ? undoResult.confidence.days : []
            const structured = structureDayData(unstructured)
            setCards(generateDayCards(structured))
        }
    }

    // create array that contains objects that have all day info including content
    const structureDayData = (data) => {
        return dayDetails.map((detail, d) => {
            const dayIndex = data.findIndex((el) => el.position === detail.position)

            let dataDay = { rating: DEFAULT_VALUE_CONFIDENCE, statements: [] }
            if (dayIndex !== -1) {
                dataDay = data[dayIndex]
            }

            return {
                // DayCard Setup
                value: d + 1,
                type: d == 0 ? 'Nowcast' : 'Forecast',
                date: detail.date,
                day: detail.day,

                // DayCard Content
                position: d,
                rating: dataDay.rating,
                statements: dataDay.statements ? dataDay.statements : [],
            }
        })
    }

    const confidenceOptions = CONFIDENCE_OPTIONS.map((item, i) => {
        return (
            <Select.Option key={i} value={item}>
                <FormattedMessage {...messages[`confidence.${item}.rating`]} />
            </Select.Option>
        )
    })

    const cloneToNextDay = (day) => {
        setCloneNext(day.position)
    }

    const cloneToAll = (day) => {
        setCloneAll(day.position)
    }

    const reviewNote = () => {
        setReviewDrawerVisible(true)
    }

    const actionHandlers = {
        cloneNext: (day) => cloneToNextDay(day),
        cloneAll: (day) => cloneToAll(day),
        reviewNote: () => reviewNote(),
        preview: () => preview(),
    }

    const generateDayCards = (structuredDays) => {
        return structuredDays.map((day, i) => {
            const handleRatingChange = (changeValues) => {
                // HACK: If it's not a string, it's an event and we have to parse the value (accessibility mode)
                if (typeof changeValues !== 'string') {
                    changeValues = changeValues.target.value
                }
                setUpdateRatings({ day: i, rating: changeValues })
            }

            const selectStatementHandler = (newSelectedStatements) => {
                setUpdateStatements({ day: i, statements: newSelectedStatements.map((s) => s.key) })
            }

            return (
                <DayCard
                    day={day}
                    key={uuid()}
                    cardContent={
                        <>
                            <Select
                                defaultValue={day.rating}
                                style={styles.select}
                                onChange={handleRatingChange}
                                data-test={`confidenceRating${day.position}`}
                            >
                                {confidenceOptions}
                            </Select>
                            <ConfidenceSelectorForm
                                selectedKeys={day.statements}
                                selectHandler={selectStatementHandler}
                                maxHeight="63vh"
                                limit={MAX_CONFIDENCE_STATEMENTS}
                            />
                        </>
                    }
                    actionHandlers={actionHandlers}
                    dataProvider={noop}
                    editable={compressed ? false : true}
                />
            )
        })
    }

    useEffect(() => {
        setDataLoaded(false)
    }, [daysInView])

    useEffect(() => {
        // This is only truthy once when content and statements are loaded
        if (!dataLoaded && Object.keys(content).length > 0 && dayDetails.length > 0) {
            setDataLoaded(true)

            const unstructured = content.confidence?.days ? content.confidence.days : []
            const structured = structureDayData(unstructured)
            setCards(generateDayCards(structured))
        }
    }, [content, dayDetails])

    const generateNewDays = (content) => {
        if (content.confidence?.days) {
            return content.confidence?.days
        }

        return content.forecastDays.map((day, i) => {
            return {
                // date: moment(day.date).format('YYYY-MM-DD'),
                position: i,
                rating: CONFIDENCE_OPTIONS[0].value,
                statements: [],
            }
        })
    }

    // Update Statements
    useEffect(() => {
        if (updateStatements !== false) {
            setUpdateStatements(false)

            const newDays = generateNewDays(content).map((day, i) => {
                return i === updateStatements.day ? { ...day, statements: updateStatements.statements } : day
            })

            // send API update
            update({ ...content, confidence: { days: newDays } }, VIEW)
        }
    }, [content, updateStatements])

    // Update Ratings
    useEffect(() => {
        if (updateRatings !== false) {
            setUpdateRatings(false)

            const newDays = generateNewDays(content).map((day, i) => {
                return i === updateRatings.day ? { ...day, rating: updateRatings.rating } : day
            })
            initUndo(content, VIEW)
            // send API update
            update({ ...content, confidence: { days: newDays } }, VIEW)
        }
    }, [content, updateRatings])

    // Clone to Next Day
    useEffect(() => {
        if (cloneNext !== false) {
            setCloneNext(false)

            let newDays = generateNewDays(content)
            const dayToBeCloned = newDays[cloneNext]
            newDays[cloneNext + 1] = { ...dayToBeCloned, position: newDays[cloneNext + 1].position }
            initUndo(content, VIEW)
            // send API update
            update({ ...content, confidence: { days: newDays } }, VIEW)

            // regenerate cards
            const structured = structureDayData(newDays)
            setCards(generateDayCards(structured))
        }
    }, [content, cloneNext])

    // Clone to All Days
    useEffect(() => {
        if (cloneAll !== false) {
            setCloneAll(false)

            let newDays = generateNewDays(content)
            const dayToBeCloned = newDays[cloneAll]
            newDays = newDays.map((day) => {
                return { ...day, rating: dayToBeCloned.rating, statements: dayToBeCloned.statements }
            })
            initUndo(content, VIEW)
            // send API update
            update({ ...content, confidence: { days: newDays } })

            // regenerate cards
            const structured = structureDayData(newDays)
            setCards(generateDayCards(structured))
        }
    }, [content, cloneAll])

    return (
        <>
            <EditScreenLayout dayDetails={dayDetails} compressed={compressed}>
                <DayCardLayout dayCards={cards} parentColWidth={colWidth} />
                <FloatMenu undoHandler={undo} />
            </EditScreenLayout>
        </>
    )
}

const styles = {
    select: {
        marginTop: 'var(--s0)',
        marginBottom: 'var(--s0)',
        width: '100%',
    },
}
