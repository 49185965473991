import React from 'react'

export const Swatch = ({ colour }) => {
    return (
        <span style={{ ...style.swatch, color: colour }} aria-hidden="true">
            ■
        </span>
    )
}

const style = {
    swatch: {
        marginRight: 'var(--s-2)',
        textShadow: '0 0 1px #000',
    },
}
