import React, { useState } from 'react'

// d and transform are SVG properties
export const AspectSlice = ({ d, transform, direction, isActive, colour, pointer, handleLeftClick }) => {
    const [hover, setHover] = useState(false)


    return (
        <path
            style={{ cursor: pointer ? 'pointer' : null }}
            // style={styles}
            id={direction}
            d={d}
            transform={transform}
            onClick={() => handleLeftClick(direction)}
            stroke={'black'}
            strokeWidth="0.5"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            fill={isActive ? colour : hover && pointer ? 'var(--grey-light)' : 'var(--grey-lighter)'}
        />
    )
}
