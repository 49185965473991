import React, { useRef, useEffect } from 'react'

import { createPortal } from 'react-dom'
import mapbox from 'mapbox-gl'

import { useMap } from 'components/Map/Map'
import { MountainIcon } from 'components/Map/DRMountainIcon'

export const Icon = ({ name, longitude, latitude, show = true, dangerRatings, offsetY = 0 }) => {
    const ref = useRef(document.createElement('div'))
    const map = useMap()
    const marker = useRef(null)

    const { alp, tln, btl } = dangerRatings

    const image = (
        <MountainIcon
            title={name}
            alp={DRColourMapping[alp] || 'white'}
            tln={DRColourMapping[tln] || 'white'}
            btl={DRColourMapping[btl] || 'white'}
            show={show}
        />
    )

    useEffect(() => {
        const options = {
            offset: [0, offsetY]
        }
        marker.current = new mapbox.Marker(ref.current, options)
        marker.current.setLngLat([longitude, latitude]).addTo(map)

        return () => {
            marker.current.remove()
        }
    }, [])

    useEffect(() => {
        marker.current.setLngLat([longitude, latitude])
    }, [longitude, latitude])

    return createPortal(image, ref.current)
}

const DRColourMapping = {
    low: 'green',
    moderate: 'yellow',
    considerable: 'orange',
    high: 'red',
    extreme: 'black',
    spring: '#0072C6',
    earlySeason: '#0072C6',
}
