import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { FormattedMessage } from 'react-intl'
import { Row, Col, Tag, Typography } from 'antd'

import { useSetActiveWeakLayerId } from 'stores/WeakLayerStore'
import { Date } from '../Date/Date'
import messages from 'services/intl/messageDefinitions'

export const Summary = ({ item }) => {
    const { Title } = Typography
    const [queryParams, setQueryParams] = useSearchParams()
    const setActiveWeakLayerId = useSetActiveWeakLayerId()

    const handleClick = () => {
        setActiveWeakLayerId(item.id)
        setQueryParams({ focus: item.id })
    }

    return (
        <div
            onClick={handleClick}
            style={{ ...styles.container, borderLeft: `6px solid ${item.colour}` }}
            data-test={'weakLayerSummary'}
        >
            <Row style={styles.rowSpacing}>
                <Col style={styles.titleSpacing}>
                    <Title level={4} style={styles.name} data-test={'weakLayerTitle'}>
                        {item.name}
                    </Title>
                    <Date value={item.burialDate} label={'buried'} />
                </Col>
                <Col style={styles.tagSpacing}>
                    <Tag color={'var(--grey)'} style={{ marginLeft: '10px' }}>
                        <FormattedMessage {...messages[item.status]} />
                    </Tag>
                </Col>
            </Row>
        </div>
    )
}

const styles = {
    rowSpacing: {
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
    },
    titleSpacing: {
        maxWidth: 'calc(100% - 100px)',
        flexShrink: 1,
    },
    tagSpacing: {
        flex: '0 0 auto',
    },
    name: {
        fontWeight: 500,
        fontSize: '18px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    container: {
        paddingLeft: 'var(--s1)',
        height: '50px',
        position: 'relative',
    },
}
