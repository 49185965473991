import { useEffect, useState } from 'react'

import { previewEmailHTML } from 'services/HighwayDiscussions'
import { getImages } from './indexedDB'

const PREFIX = 'TEMP_IMAGE_URL_PREFIX'
const SUFFIX = 'TEMP_IMAGE_URL_SUFFIX'

export const EmailPreviewHTML = ({ productId }) => {
    const [html, setHtml] = useState({ __html: '' })

    useEffect(() => {
        const generateEmailHTML = async () => {
            const images = await getImages()
            const imageUrlsAndGroupsIDs = images.map((image) => {
                return {
                    url: PREFIX + image.groupID + SUFFIX,
                    groupId: image.groupID,
                }
            })

            const response = await previewEmailHTML(productId, imageUrlsAndGroupsIDs)

            // I opted not to upload images to Cloudinary at this step, as it'd be happening on every email preview.
            // This is a bit of a hacky solution, but it works (displays images from IndexedDB in the email preview)
            const emailString = await injectImageUrlsFromIndexedDB(response.data, images)
            const html = { __html: emailString }
            setHtml(html)
        }

        generateEmailHTML()
    }, [])

    return <div dangerouslySetInnerHTML={html}></div>
}

const injectImageUrlsFromIndexedDB = async (emailString, images) => {
    // Regular expression to match the entire placeholder and capture the UUID
    const regex = new RegExp(`${PREFIX}([a-f0-9-]{36})${SUFFIX}`, 'g')

    return emailString.replaceAll(regex, (_, uuid) => {
        const image = images.find((image) => image.groupID === uuid)

        return image.image
    })
}
