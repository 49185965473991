import React, { useEffect, useState } from 'react'

import { FormattedMessage } from 'react-intl'
import { Card, Space } from 'antd'

import { useContent } from 'stores/ForecastStore'
import { useCharacterLimits } from 'hooks/Settings'
import { TranslatedTextField } from 'components/TextField/TranslatedTextField'
import {
    updateField,
    addTranslationToField,
    removeTranslationFromField,
    copyHeadlineToSms,
} from 'components/TextField/productHelpers'
import { EditScreenLayout } from 'components/EditScreenLayout/EditScreenLayout'
import { SmsCardTitle } from 'components/Product/SmsCardTitle'
import messages from 'services/intl/messageDefinitions'

export const Communications = ({ update, compressed }) => {
    const content = useContent()
    const [updateProduct, setUpdateProduct] = useState(false)
    const [render, setRender] = useState(true)
    const [SmsField, setSmsField] = useState(null)
    const characterLimit = useCharacterLimits()?.headline

    const generateHandler = (field, mode) => (language, value) => {
        setUpdateProduct({ field, mode, language, value })
    }

    const handleHeadlineChange = generateHandler(['communications', 'headline'], 'update')
    const handleAddHeadlineTranslation = generateHandler(['communications', 'headline'], 'add')
    const handleRemoveHeadlineTranslation = generateHandler(['communications', 'headline'], 'remove')
    const handleSmsChange = generateHandler(['communications', 'sms'], 'update')
    const handleAddSmsTranslation = generateHandler(['communications', 'sms'], 'add')
    const handleRemoveSmsTranslation = generateHandler(['communications', 'sms'], 'remove')
    const handleCopyHeadlineToSms = generateHandler(null, 'copyHeadlineToSms')

    useEffect(() => {
        if (updateProduct) {
            setUpdateProduct(false)

            const { field, mode, language, value } = updateProduct
            let updatedProduct
            switch (mode) {
                case 'update':
                    updatedProduct = updateField(content, field, language, value)
                    break
                case 'add':
                    setRender(true)
                    updatedProduct = addTranslationToField(content, field, language)
                    break
                case 'remove':
                    setRender(true)
                    updatedProduct = removeTranslationFromField(content, field, language)
                    break
                case 'copyHeadlineToSms':
                    updatedProduct = copyHeadlineToSms(content)
                    setRender(true)
                    break
                default:
                    break
            }
            update(updatedProduct)
        }
    }, [updateProduct, content])

    // Need to control when to render the SmsField so that it doesn't rerender every time text is updated
    // but does update when we add/remove new translations or copy from headline.
    const generateSmsField = () => {
        return (
            <TranslatedTextField
                handleChange={handleSmsChange}
                handleAddTranslation={handleAddSmsTranslation}
                handleRemoveTranslation={handleRemoveSmsTranslation}
                translations={content.communications.sms}
                plainText={true}
                characterLimit={140}
            />
        )
    }

    useEffect(() => {
        if (render && Object.keys(content).length > 0) {
            setRender(false)
            setSmsField(generateSmsField())
        }
    }, [content])

    return (
        <>
            <EditScreenLayout showDaySelector={false} compressed={compressed}>
                <form style={styles.wrapper}>
                    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                        <Card title={<FormattedMessage {...messages.headline} />}>
                            <TranslatedTextField
                                handleChange={handleHeadlineChange}
                                handleAddTranslation={handleAddHeadlineTranslation}
                                handleRemoveTranslation={handleRemoveHeadlineTranslation}
                                translations={content.communications?.headline}
                                plainText={false}
                                characterLimit={characterLimit}
                            />
                        </Card>
                        <Card size="small" title={<SmsCardTitle handleClick={handleCopyHeadlineToSms} />}>
                            {!render && SmsField}
                        </Card>
                    </Space>
                </form>
            </EditScreenLayout>
        </>
    )
}

const styles = {
    wrapper: {
        margin: 'var(--s0)',
    },
}
