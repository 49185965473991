import React from 'react'

import { FormattedMessage } from 'react-intl'

import { Checkbox } from './CheckBox'
import messages from 'services/intl/messageDefinitions'

export const CheckBoxFilter = ({ options, activeFilters, useAddFilter, useRemoveFilter, radioStyle }) => {
    return options.map((option, i) => {
        return (
            <Checkbox
                key={option.value}
                value={option.value}
                label={<FormattedMessage {...messages[option.label]} />}
                checked={activeFilters.includes(option.value)}
                useAddFilter={useAddFilter}
                useRemoveFilter={useRemoveFilter}
                radioStyle={radioStyle}
            />
        )
    })
}
