import React, { useState } from 'react'

import { FormattedMessage } from 'react-intl'
import { Dropdown, Menu, Space, Button } from 'antd'
import { DownOutlined } from '@ant-design/icons'

import { LOCALE_TEXTS } from 'utils/Locales'
import messages from 'services/intl/messageDefinitions'

// existingTranslations is an object like { en: '', fr: '' }
export const AddLanguageDropdown = ({ existingTranslations, handleClick, dataProvider, buttonDisabled = false }) => {
    const data = dataProvider ? dataProvider() : null
    const [dropdownVisible, setDropdownVisible] = useState(false)
    const languageMenuItems = [...LOCALE_TEXTS].map(([key, value]) => {
        return {
            key,
            label: value,
            disabled: existingTranslations && existingTranslations[key] !== undefined,
        }
    })
    const disabled = languageMenuItems.every((item) => item.disabled)
    const menu = (
        <Menu items={languageMenuItems} onClick={(event) => handleClick(event.key, data)} style={styles.menu} />
    )

    return (
        <Button
            data-test="addLanguage"
            onClick={() => setDropdownVisible(!dropdownVisible)}
            onMouseEnter={() => setDropdownVisible(true)}
            onMouseLeave={() => setDropdownVisible(false)}
            disabled={buttonDisabled}
        >
            <Dropdown overlay={menu} disabled={disabled} visible={dropdownVisible}>
                <Space>
                    <FormattedMessage {...messages.addLanguage} />
                    <DownOutlined />
                </Space>
            </Dropdown>
        </Button>
    )
}

const styles = {
    menu: {
        minWidth: '147px',
        left: '-15px',
    },
}
