export const PROBLEM_TYPES = [
    'dryLoose',
    'wetLoose',
    'stormSlab',
    'windSlab',
    'persistentSlab',
    'deepPersistentSlab',
    'wetSlab',
    'cornice',
]

export const DANGER_RATINGS = [
    {
        key: 'extreme',
        colour: 'black',
        textModifier: 'white',
    },
    {
        key: 'high',
        colour: 'red',
    },
    {
        key: 'considerable',
        colour: 'orange',
    },
    {
        key: 'moderate',
        colour: 'yellow',
        textModifier: 'black',
    },
    {
        key: 'low',
        colour: 'green',
    },
    {
        key: 'noRating',
        colour: '',
    },
]
