import React, { useEffect, useCallback } from 'react'

import { useSearchParams } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { FILTER_OPTIONS } from 'utils/Product'
import { putSpecialProduct } from 'services/SpecialProducts'
import { usePolygons } from 'hooks/polygons'
import messages from 'services/intl/messageDefinitions'
import { useLocale } from 'stores/UserStore'
import { SpecialProductStore } from 'stores/SpecialProductStore'
import { Map, WithMapReady } from 'components/Map/Map'
import { Sidebar } from 'components/Sidebar/Sidebar'
import { Details } from 'components/SpecialProducts/Details'
import { Summary } from 'components/Product/Summary'
import { CreateForm } from 'components/SpecialProducts/CreateForm'
import { MultiProductPolygon } from 'components/Map/MultiProductPolygon'
import { TwoColLayoutPrimaryRight } from 'components/TwoColumnLayout/TwoColLayoutPrimaryRight'
import { UnassignedPolygons } from 'components/Map/UnassignedPolygons'
import { MapStatusBar } from 'components/MapStatusBar/MapStatusBar'
import { ProductStatusBarText } from 'components/Product/ProductStatusBarText'
import { Accessibility } from 'components/Accessibility'
import { MultipleProductsTable } from 'components/Map/Accessibility/MultipleProductsTable'
import { useActiveSpecialProduct, useSpecialProducts, useFilteredSpecialProducts, useUnassignedPolygons } from 'hooks/specialProducts'

export const SpecialProducts = () => {
    const [queryParams] = useSearchParams()
    const locale = useLocale()
    const polygons = usePolygons()
    const filters = SpecialProductStore.useFilters()
    const addFilter = SpecialProductStore.useAddFilter()
    const setActiveSpecialProductId = SpecialProductStore.useSetActiveProductId()
    const activeSpecialProduct = useActiveSpecialProduct()
    const unassignedCount = useUnassignedPolygons()
    const queryID = queryParams.get('focus') || ''
    const disableSetupButton = filters[0] !== 'draft'
    const intl = useIntl()
    const { specialProducts, mutate } = useSpecialProducts()

    useEffect(() => {
        document.title = intl.formatMessage(messages.specialproduct)
    }, [])

    useEffect(() => {
        if (queryID) {
            setActiveSpecialProductId(queryID)
        }
        filters.forEach((filter) => {
            // default filter
            addFilter(filter)
        })
        if (activeSpecialProduct && activeSpecialProduct.id === queryID) {
            addFilter(activeSpecialProduct.status)
        }
    }, [specialProducts])

    const standardA11yComponent = (
        <>
            <Map locale={locale}>
                <WithMapReady>
                    {polygons && polygons.features && (
                        <>
                            <MultiProductPolygon
                                useEditing={SpecialProductStore.useEditingProductSetup}
                                useActiveProduct={useActiveSpecialProduct}
                                apiCall={putSpecialProduct}
                                mutateProducts={mutate}
                                polygons={polygons}
                                useDraftProduct={SpecialProductStore.useDraftProduct}
                                useUpdateDraftProduct={SpecialProductStore.useUpdateDraftProduct}
                                allProducts={specialProducts}
                                useFilteredProducts={useFilteredSpecialProducts}
                                useAssignedPolygons={useFilteredSpecialProducts}
                                productSource="specialProduct"
                            />
                        </>
                    )}
                </WithMapReady>
            </Map>
            <UnassignedPolygons unassignedCount={unassignedCount} />
            {activeSpecialProduct && (
                <MapStatusBar
                    product={activeSpecialProduct}
                    text={<ProductStatusBarText product={activeSpecialProduct} />}
                />
            )}
        </>
    )

    const enhancedA11yComponent = polygons && polygons.features && (
        <MultipleProductsTable
            useEditing={SpecialProductStore.useEditingProductSetup}
            unassignedCount={unassignedCount}
            useProducts={useFilteredSpecialProducts}
            useActiveItem={useActiveSpecialProduct}
            putRequest={putSpecialProduct}
            mutateProducts={mutate}
            polygons={polygons}
            useDraftItem={SpecialProductStore.useDraftProduct}
            useUpdateDraftItem={SpecialProductStore.useUpdateDraftProduct}
        />
    )

    return (
        <TwoColLayoutPrimaryRight
            left={
                <Accessibility
                    standardA11yComponent={standardA11yComponent}
                    enhancedA11yComponent={enhancedA11yComponent}
                />
            }
            right={
                <Sidebar
                    title={'specialProducts'}
                    emptyMessage={'noSpecialProductsFound'}
                    Details={Details}
                    Summary={Summary}
                    dataProvider={useFilteredSpecialProducts}
                    filterProvider={SpecialProductStore.useFilters}
                    listData={specialProducts}
                    filterOptions={FILTER_OPTIONS}
                    activeFilters={filters}
                    useAddFilter={SpecialProductStore.useAddFilter}
                    useRemoveFilter={SpecialProductStore.useRemoveFilter}
                    useClearActiveID={SpecialProductStore.useClearActiveProductId}
                    createLabel={'createSpecialProduct'}
                    disableSetupButton={disableSetupButton}
                    useSetActiveId={SpecialProductStore.useSetActiveProductId}
                    useSetEditing={SpecialProductStore.useSetEditingProductSetup}
                    useSetEditingContent={SpecialProductStore.useSetEditingProductContent}
                    useResetDraft={SpecialProductStore.useResetDraftProduct}
                    CreateForm={CreateForm}
                    mutateProducts={mutate}
                    useActiveItem={useActiveSpecialProduct}
                    radioStyleFiltering={true}
                ></Sidebar>
            }
        />
    )
}
