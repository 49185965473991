import React from 'react'
import { Typography } from 'antd'

export const RemainingCharacterCount = ({ count, limit }) => {
    const type = count > limit ? styles.danger : {}

    return (
        <Typography.Text type={'secondary'}>
            <span style={type}>{count}</span> / {limit}
        </Typography.Text>
    )
}

const styles = {
    danger: {
        color: '#ff4d4f',
    },
}
