const aspects = [
    {
        direction: 'n_btl',
        d: `M273.752359,103.550007 C338.899545,38.4028203 428.899545,-1.89155221 528.3108,-1.89155221 L528.3108,118.108448 C462.03663,118.108448 402.03663,144.971363 358.605172,188.40282 L273.752359,103.550007 Z`,
        transform: 'translate(401.031579, 93.255634) rotate(22.500000) translate(-401.031579, -93.255634) ',
    },
    {
        direction: 'n_tln',
        d: 'M316.178765,196.841957 C359.610223,153.4105 419.610223,126.547585 485.884393,126.547585 L485.884393,246.547585 C452.747308,246.547585 422.747308,259.979042 401.031579,281.694771 L316.178765,196.841957 Z',
        transform: 'translate(401.031579, 204.121178) rotate(22.500000) translate(-401.031579, -204.121178) ',
    },
    {
        direction: 'n_alp',
        d: 'M358.605172,290.133908 C380.320901,268.418179 410.320901,254.986722 443.457986,254.986722 L443.457986,374.986722 L358.605172,290.133908 Z',
        transform: 'translate(401.031579, 314.986722) rotate(22.500000) translate(-401.031579, -314.986722) ',
    },
    {
        direction: 'ne_btl',
        d: 'M486.310641,178.004299 C551.457827,112.857112 641.457827,72.5627399 740.869082,72.5627399 L740.869082,192.56274 C674.594912,192.56274 614.594912,219.425655 571.163454,262.857112 L486.310641,178.004299 Z',
        transform: 'translate(613.589861, 167.709926) rotate(-652.500000) translate(-613.589861, -167.709926) ',
    },
    {
        direction: 'ne_tln',
        d: 'M450.34327,238.824483 C493.774727,195.393026 553.774727,168.530111 620.048897,168.530111 L620.048897,288.530111 C586.911812,288.530111 556.911812,301.961568 535.196083,323.677297 L450.34327,238.824483 Z',
        transform: 'translate(535.196083, 246.103704) rotate(-652.500000) translate(-535.196083, -246.103704) ',
    },
    {
        direction: 'ne_alp',
        d: 'M414.375899,299.644668 C436.091627,277.928939 466.091627,264.497482 499.228712,264.497482 L499.228712,384.497482 L414.375899,299.644668 Z',
        transform: 'translate(456.802306, 324.497482) rotate(-652.500000) translate(-456.802306, -324.497482) ',
    },
    {
        direction: 'e_btl',
        d: 'M584.311864,381.09655 C649.45905,315.949363 739.45905,275.654991 838.870305,275.654991 L838.870305,395.654991 C772.596135,395.654991 712.596135,422.517906 669.164678,465.949363 L584.311864,381.09655 Z',
        transform: 'translate(711.591084, 370.802177) rotate(-247.500000) translate(-711.591084, -370.802177) ',
    },
    {
        direction: 'e_tln',
        d: 'M515.872727,363.522957 C559.304184,320.091499 619.304184,293.228584 685.578354,293.228584 L685.578354,413.228584 C652.441269,413.228584 622.441269,426.660042 600.725541,448.37577 L515.872727,363.522957 Z',
        transform: 'translate(600.725541, 370.802177) rotate(-247.500000) translate(-600.725541, -370.802177) ',
    },
    {
        direction: 'e_alp',
        d: 'M447.43359,345.949363 C469.149319,324.233635 499.149319,310.802177 532.286404,310.802177 L532.286404,430.802177 L447.43359,345.949363 Z',
        transform: 'translate(489.859997, 370.802177) rotate(-247.500000) translate(-489.859997, -370.802177) ',
    },
    {
        direction: 'se_btl',
        d: 'M510.235023,593.88117 C575.382209,528.733984 665.382209,488.439611 764.793464,488.439611 L764.793464,608.439611 C698.519294,608.439611 638.519294,635.302526 595.087837,678.733984 L510.235023,593.88117 Z',
        transform: 'translate(637.514244, 583.586797) rotate(-922.500000) translate(-637.514244, -583.586797) ',
    },
    {
        direction: 'se_tln',
        d: 'M474.267652,497.913799 C517.699109,454.482341 577.699109,427.619426 643.973279,427.619426 L643.973279,547.619426 C610.836194,547.619426 580.836194,561.050884 559.120466,582.766613 L474.267652,497.913799 Z',
        transform: 'translate(559.120466, 505.193020) rotate(-922.500000) translate(-559.120466, -505.193020) ',
    },
    {
        direction: 'se_alp',
        d: 'M438.300281,401.946428 C460.01601,380.230699 490.01601,366.799242 523.153095,366.799242 L523.153095,486.799242 L438.300281,401.946428 Z',
        transform: 'translate(480.726688, 426.799242) rotate(-922.500000) translate(-480.726688, -426.799242) ',
    },
    {
        direction: 's_btl',
        d: 'M307.148004,690.732176 C372.29519,625.584989 462.29519,585.290617 561.706445,585.290617 L561.706445,705.290617 C495.432275,705.290617 435.432275,732.153532 392.000818,775.584989 L307.148004,690.732176 Z',
        transform: 'translate(434.427225, 680.437803) rotate(-157.500000) translate(-434.427225, -680.437803) ',
    },
    {
        direction: 's_tln',
        d: 'M349.574411,562.293038 C393.005868,518.861581 453.005868,491.998666 519.280038,491.998666 L519.280038,611.998666 C486.142953,611.998666 456.142953,625.430123 434.427225,647.145852 L349.574411,562.293038 Z',
        transform: 'translate(434.427225, 569.572259) rotate(-157.500000) translate(-434.427225, -569.572259) ',
    },
    {
        direction: 's_alp',
        d: 'M392.000818,433.853901 C413.716547,412.138173 443.716547,398.706715 476.853632,398.706715 L476.853632,518.706715 L392.000818,433.853901 Z',
        transform: 'translate(434.427225, 458.706715) rotate(-157.500000) translate(-434.427225, -458.706715) ',
    },
    {
        direction: 'sw_btl',
        d: 'M94.3633839,616.655335 C159.51057,551.508148 249.51057,511.213776 348.921825,511.213776 L348.921825,631.213776 C282.647655,631.213776 222.647655,658.076691 179.216198,701.508148 L94.3633839,616.655335 Z',
        transform: 'translate(221.642604, 606.360962) rotate(-832.500000) translate(-221.642604, -606.360962) ',
    },
    {
        direction: 'sw_tln',
        d: 'M215.183569,520.687964 C258.615026,477.256506 318.615026,450.393591 384.889196,450.393591 L384.889196,570.393591 C351.752111,570.393591 321.752111,583.825049 300.036382,605.540777 L215.183569,520.687964 Z',
        transform: 'translate(300.036382, 527.967184) rotate(-832.500000) translate(-300.036382, -527.967184) ',
    },
    {
        direction: 'sw_alp',
        d: 'M336.003753,424.720593 C357.719482,403.004864 387.719482,389.573406 420.856567,389.573406 L420.856567,509.573406 L336.003753,424.720593 Z',
        transform: 'translate(378.430160, 449.573406) rotate(-832.500000) translate(-378.430160, -449.573406) ',
    },
    {
        direction: 'w_btl',
        d: 'M-2.48762165,413.568316 C62.6595646,348.421129 152.659565,308.126757 252.07082,308.126757 L252.07082,428.126757 C185.79665,428.126757 125.79665,454.989672 82.3651921,498.421129 L-2.48762165,413.568316 Z',
        transform: 'translate(124.791599, 403.273943) rotate(-67.500000) translate(-124.791599, -403.273943) ',
    },
    {
        direction: 'w_tln',
        d: 'M150.804329,395.994723 C194.235787,352.563265 254.235787,325.70035 320.509957,325.70035 L320.509957,445.70035 C287.372872,445.70035 257.372872,459.131808 235.657143,480.847536 L150.804329,395.994723 Z',
        transform: 'translate(235.657143, 403.273943) rotate(-67.500000) translate(-235.657143, -403.273943) ',
    },
    {
        direction: 'w_alp',
        d: 'M304.09628,378.421129 C325.812009,356.705401 355.812009,343.273943 388.949094,343.273943 L388.949094,463.273943 L304.09628,378.421129 Z',
        transform: 'translate(346.522687, 403.273943) rotate(-67.500000) translate(-346.522687, -403.273943) ',
    },
    {
        direction: 'nw_btl',
        d: 'M71.5892193,200.783696 C136.736406,135.636509 226.736406,95.3421367 326.147661,95.3421367 L326.147661,215.342137 C259.873491,215.342137 199.873491,242.205052 156.442033,285.636509 L71.5892193,200.783696 Z',
        transform: 'translate(198.868440, 190.489323) rotate(-742.500000) translate(-198.868440, -190.489323) ',
    },
    {
        direction: 'nw_tln',
        d: 'M192.409404,261.60388 C235.840862,218.172423 295.840862,191.309508 362.115032,191.309508 L362.115032,311.309508 C328.977947,311.309508 298.977947,324.740965 277.262218,346.456694 L192.409404,261.60388 Z',
        transform: 'translate(277.262218, 268.883101) rotate(-742.500000) translate(-277.262218, -268.883101) ',
    },
    {
        direction: 'nw_alp',
        d: 'M313.229589,322.424065 C334.945318,300.708336 364.945318,287.276879 398.082403,287.276879 L398.082403,407.276879 L313.229589,322.424065 Z',
        transform: 'translate(355.655996, 347.276879) rotate(-742.500000) translate(-355.655996, -347.276879) ',
    },
]
export default aspects
