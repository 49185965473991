import { useState } from 'react'

import { Button, Collapse } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'

import { sendErrorNotification } from 'utils/Notifications'
import { REVIEW_STATUS_REVIEWED } from 'utils/ForecastContent'
import messages from 'services/intl/messageDefinitions'
import { markReviewed } from 'services/Products'
import { API_SUCCESS_CODES } from 'utils/Constants'

export const PublishedProductReviewCheck = ({ product, mutate }) => {
    const { Panel } = Collapse
    const intl = useIntl()
    const [activeKey, setActiveKey] = useState(0)

    const handleMarkReviewed = async () => {
        const response = await markReviewed(product.id)

        if (API_SUCCESS_CODES.includes(response.status)) {
            mutate()
            setActiveKey(0)
        } else {
            sendErrorNotification(intl.formatMessage({ ...messages.markAsReviewedError }))
        }
    }

    return (
        <Collapse style={styles.collapse} activeKey={activeKey} onChange={() => setActiveKey(activeKey ? 0 : 1)}>
            <Panel
                header={
                    <div>
                        <FormattedMessage {...messages.reviewNote} />
                    </div>
                }
                key="1"
            >
                <>
                    <div dangerouslySetInnerHTML={{ __html: product?.review?.notes }}></div>
                    <Button
                        type="primary"
                        onClick={handleMarkReviewed}
                        disabled={product?.review?.status === REVIEW_STATUS_REVIEWED}
                    >
                        <FormattedMessage {...messages.markAsReviewed} />
                    </Button>
                </>
            </Panel>
        </Collapse>
    )
}

const styles = {
    collapse: {
        marginTop: 'var(--s0)',
    },
}
