import { FormattedMessage } from 'react-intl'

import { Space, Card } from 'antd'
import messages from 'services/intl/messageDefinitions'
import { ImagePreview } from 'components/Product/ImagePreview'
import { TextDisplay } from 'components/TextDisplay/TextDisplay'

export const Preview = ({ item }) => {
    return (
        <>
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <h1 style={styles.title}>
                    <FormattedMessage
                        {...messages.previewProductContent}
                        values={{
                            product: item.name,
                        }}
                    />
                </h1>
                <Space direction="vertical" size="middle" style={styles.space}>
                    <Card title={<FormattedMessage {...messages.headline} />}>
                        <TextDisplay idKey={'headline'} translatedText={item.communications.headline} />
                    </Card>
                    <Card title={<FormattedMessage {...messages.sms} />}>
                        <TextDisplay idKey={'sms'} translatedText={item.communications.sms} />
                    </Card>
                    <Card title={<FormattedMessage {...messages.message} />}>
                        <TextDisplay idKey={'message'} translatedText={item.message} />
                    </Card>
                    <Card title={<FormattedMessage {...messages.media} />}>
                        <ImagePreview product={item} />
                    </Card>
                </Space>
            </Space>
        </>
    )
}

const styles = {
    title: {
        fontWeight: 300,
    },
    space: {
        display: 'flex',
    },
    translationLabel: {
        marginBottom: '5px',
        marginLeft: '2px',
        fontWeight: 'bold',
    },
}
