import React from 'react'

import { Marker } from './Marker'

export const MitigationIcon = ({ lngLat }) => {
    return (
        <Marker lngLat={lngLat}>
            <img alt={'Mitigation Pending'} width={30} height={30} src={'/assets/mitigation_icon.svg'} />
        </Marker>
    )
}
