import { uuid } from 'utils/String'

export const MAX_IMAGES = 3

export const TAGS = [
    {
        label: 'weatherTag',
        value: 'weather',
    },
    {
        label: 'avalancheTag',
        value: 'avalanche',
    },
    {
        label: 'snowpackTag',
        value: 'snowpack',
    },
    {
        label: 'incidentTag',
        value: 'incident',
    },
    {
        label: 'generalTag',
        value: 'general',
    },
]

export const createNewImage = (locale = 'en', tag = 'avalanche') => {
    return {
        credit: '',
        url: '',
        id: uuid(),
        caption: {
            [locale]: '',
        },
        tag,
        altText: {
            [locale]: '',
        },
        dateTaken: null,
        imageLastUpdated: new Date().toISOString(),
        isArchived: false,
    }
}

export const generateCloudinaryThumbnailUrl = (url, width, height) => {
    return url.replace('/upload/', `/upload/c_fill,w_${width},h_${height}/f_auto/`)
}
