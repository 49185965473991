import { APIClient } from './APIClient'

export const fetchRegionalDiscussions = (language) => {
    return APIClient.get(`/v1/${language}/products/regionaldiscussion`, language)
}

export const fetchRegionalDiscussion = (id, language) => {
    return APIClient.get(`/v1/${language}/products/id/${id}`, language)
}

export const fetchRegionalDiscussionsByDates = (startDate, endDate, searchTerm, language) => {
    return APIClient.get(
        `/v1/${language}/products/regionaldiscussion/history?startDate=${startDate}&endDate=${endDate}&searchTerm=${searchTerm}`,
        language
    )
}

export const postRegionalDiscussion = (data, language) => {
    return APIClient.post(`/v1/${language}/products/regionaldiscussion`, {
        ...data,
    })
}

export const putRegionalDiscussion = (data, language) => {
    return APIClient.put(`/v1/${language}/products/regionaldiscussion`, {
        ...data,
    })
}

export const publishRegionalDiscussion = (id, language) => {
    return APIClient.post(`/v1/${language}/products/publish/${id}`)
}

export const deleteRegionalDiscussion = (id, language) => {
    return APIClient.delete(`/v1/${language}/products/id/` + id, language)
}

export const cloneRegionalDiscussion = (id, language) => {
    return APIClient.post(`/v1/${language}/products/clone/` + id, language)
}

export const cloneAllRegionalDiscussions = (language) => {
    return APIClient.post(`/v1/${language}/products/regionaldiscussion/cloneall`, language)
}
