import { Route, Routes, Navigate } from 'react-router-dom'

import { WeakLayers } from '../pages/WeakLayers/WeakLayers'
import { AvalancheForecast } from '../pages/AvalancheForecast/AvalancheForecast'
import { RegionalDiscussions } from '../pages/RegionalDiscussions/RegionalDiscussions'
import { HighwayForecasts } from '../pages/Highways/HighwayForecasts'
import { HighwayDiscussions } from '../pages/Highways/HighwayDiscussions'
import { Mitigation } from '../pages/Highways/Mitigation'
import { SpecialProducts } from 'pages/SpecialProducts/SpecialProducts'
import { AvFxContent } from 'pages/AvalancheForecast/AvFxContent'
import { Profile } from '../components/Profile/Profile'
import { Documentation } from 'pages/Documentation/Documentation'
import { Archive } from 'pages/Archive/Archive'
import { HwyFxContent } from 'pages/Highways/HwyFxContent'
import { ALL_VISIBLE_TABS } from 'utils/Constants'

export const AppRouter = () => {
    const tabs = process.env.REACT_APP_VISIBLE_TABS ? JSON.parse(process.env.REACT_APP_VISIBLE_TABS) : ALL_VISIBLE_TABS
    // set the default tab. If avalanche-forceast is hidden then assume it is a highway forecast environment, if not, just take the first tab
    const defaultTab = tabs.includes('avalanche-forecast')
        ? 'avalanche-forecast'
        : tabs.includes('hwy-forecast')
        ? 'hwy-forecast'
        : tabs[0]

    return (
        <Routes>
            <Route path="/" element={<Navigate to={defaultTab} />} />
            {tabs.includes('weak-layers') && <Route path="/weak-layers" element={<WeakLayers />} />}
            {tabs.includes('avalanche-forecast') && (
                <Route path="/avalanche-forecast" element={<AvalancheForecast />} />
            )}
            {tabs.includes('regional-discussions') && (
                <Route path="/regional-discussions" element={<RegionalDiscussions />} />
            )}
            {tabs.includes('special-products') && <Route path="/special-products" element={<SpecialProducts />} />}
            {tabs.includes('hwy-forecast') && <Route path="/hwy-forecast" element={<HighwayForecasts />} />}
            {tabs.includes('hwy-discussion') && <Route path="/hwy-discussion" element={<HighwayDiscussions />} />}
            {tabs.includes('mitigation') && <Route path="/mitigation" element={<Mitigation />} />}
            {tabs.includes('avalanche-forecast') && (
                <Route path="/avalanche-forecast-content" element={<AvFxContent />} />
            )}
            {tabs.includes('hwy-forecast') && <Route path="/highway-forecast-content" element={<HwyFxContent />} />}
            {tabs.includes('profile') && <Route path="/profile" element={<Profile />} />}
            {tabs.includes('documentation') && <Route path="/documentation" element={<Documentation />} />}
            {tabs.includes('archive') && <Route path="/archive" element={<Archive />} />}
        </Routes>
    )
}
