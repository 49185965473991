import { Button, Col, Row } from 'antd'

import { FormattedMessage } from 'react-intl'
import messages from 'services/intl/messageDefinitions'
import { MAX_IMAGES } from 'utils/Image'
import { ImageCard } from './ImageCard'

export const PanelContents = ({ section, sectionImages, handlers }) => {
    const { handleLeftClick, handleDeleteClick, handleRightClick, handleImageClick, handleAddImageClick } = handlers

    return (
        <Row style={styles.mediaContainer}>
            <Row>
                {sectionImages &&
                    sectionImages.map((image, i) => {
                        return (
                            <ImageCard
                                image={image}
                                index={i}
                                numImages={sectionImages.length}
                                handleLeft={i === 0 ? null : (e, image) => handleLeftClick(e, image, sectionImages)}
                                handleDelete={(e, image) => handleDeleteClick(e, image)}
                                handleRight={
                                    i === sectionImages.length - 1
                                        ? null
                                        : (e, image) => handleRightClick(e, image, sectionImages)
                                }
                                handleImageClick={() => handleImageClick(image, section)}
                                key={image.id}
                            />
                        )
                    })}
            </Row>
            <Col span={24} style={styles.addImageContainer}>
                <Button
                    onClick={() => handleAddImageClick(section)}
                    data-test={'addImageButton'}
                    disabled={sectionImages?.length >= MAX_IMAGES}
                >
                    <FormattedMessage {...messages.addImage} />
                </Button>
            </Col>
        </Row>
    )
}

const styles = {
    mediaContainer: {
        marginLeft: 'var(--s-4)',
        marginRight: 'var(--s-4)',
    },
    mediaCardContainer: {
        marginBottom: 'var(--s-2)',
    },
    addImageContainer: {
        paddingLeft: 0,
    },
}
