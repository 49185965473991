import useSWR from 'swr'

import { useLocale } from 'stores/UserStore'
import { fetchMitigations } from 'services/Mitigation'
import { MitigationStore } from 'stores/MitigationStore'
import { filterAndSort, unassignedCount } from 'utils/Product'
import { usePolygons } from 'hooks/polygons'

export const useMitigations = () => {
    const language = useLocale()
    const key = ['products/mitigation', language]
    const { data, isLoading, mutate } = useSWR(key, () => fetchMitigations(language))

    return {
        mitigations: data?.data,
        isLoading,
        mutate,
    }
}

export const useFilteredMitigations = () => {
    const { mitigations, isLoading } = useMitigations()
    const filters = MitigationStore.useFilters()

    return isLoading ? [] : filterAndSort(mitigations, 'status', filters)
}

export const useActiveMitigation = () => {
    const activeProductId = MitigationStore.useActiveProductId()
    const { mitigations } = useMitigations()
    const draftProduct = MitigationStore.useDraftProduct()

    if (activeProductId === 'draft') {
        return draftProduct
    }

    return mitigations?.find((product) => product.id === activeProductId)
}

export const useUnassignedPolygons = () => {
    const polygons = usePolygons()
    const draftProduct = MitigationStore.useDraftProduct()
    const filteredProducts = useFilteredMitigations()

    if (!polygons || !filteredProducts || !draftProduct) {
        return -1
    }

    const count = unassignedCount(polygons, filteredProducts, draftProduct)

    return count
}
