import { Dragger } from 'components/Dragger/Dragger'
import React, { useState } from 'react'

import { useEnhancedAccessibility } from 'stores/UserStore'

export const TwoColLayoutPrimaryLeft = ({
    left,
    right,
    leftRole = 'main',
    rightRole = 'complementary',
    leftFraction = 30,
    boxShadow = true,
}) => {
    const viewPortWidth = window.innerWidth
    const [leftWidth, setLeftWidth] = useState(viewPortWidth * (leftFraction / 100))
    const [rightWidth, setRightWidth] = useState(viewPortWidth * ((100 - leftFraction) / 100))
    const [delta, setDelta] = useState(0)
    const shadow = boxShadow ? '4px 0 8px rgba(0, 0, 0, 0.25)' : 'none'
    const enhancedAccessibility = useEnhancedAccessibility()
    const overflowY = enhancedAccessibility ? 'auto' : 'inherit' // Prevents scrollbar above map with enhance-a11y mode off

    const resizeSplit = (e, data) => {
        const newLeftWidth = leftWidth + data.deltaX
        const newRightWidth = viewPortWidth - newLeftWidth
        setLeftWidth(newLeftWidth)
        setRightWidth(newRightWidth)
        setDelta(data.deltaX)
        window.dispatchEvent(new Event('resize'))
    }

    return (
        <div style={styles.wrapper}>
            <div style={{ ...styles.left, width: `${leftWidth}px`, boxShadow: shadow }} role={leftRole}>
                <div style={styles.column}>{left}</div>
                <Dragger onDrag={resizeSplit} position={{ x: delta, y: 0 }} />
            </div>
            <div style={{ ...styles.right, width: `${rightWidth}px` }} role={rightRole}>
                {right}
            </div>
        </div>
    )
}

const styles = {
    left: {
        background: 'var(--background-colour)',
        position: 'relative',
        overflowY: 'auto',
        zIndex: 10,
    },
    right: {
        width: '100%',
        position: 'relative',
    },
    wrapper: {
        display: 'flex',
        height: '100%',
    },
}
