import React from 'react'

import Draggable from 'react-draggable'

import css from './Dragger.module.css'

export const Dragger = ({ onDrag, position = null, orientation = 'right' }) => {
    return (
        <Draggable axis="x" onDrag={onDrag} position={position}>
            <div
                className={css.dragger}
                style={{
                    [orientation]: 0,
                }}
            />
        </Draggable>
    )
}
