import { useEffect } from 'react'

import { useMap } from 'components/Map/Map'

export const ViewOnlyPolygons = ({ polygons }) => {
    // Map params
    const map = useMap()
    const polygonSource = 'viewOnlyPolygons'

    // Update map layers based on filters
    useEffect(() => {
        if (map.getSource(polygonSource)) {
            map.getSource(polygonSource).setData(polygons)
        }
    }, [map, polygons, polygonSource])

    // Add sources and layers for polygons
    useEffect(() => {
        if (!map.getSource(polygonSource)) {
            map.addSource(polygonSource, { promoteId: 'id', type: 'geojson', data: polygons })
        }
        if (!map.getLayer(polygonSource)) {
            map.addLayer({
                id: polygonSource,
                source: polygonSource,
                type: 'fill',
                paint: {
                    'fill-color': ['get', 'colorCode'],
                    'fill-opacity': 0.5,
                },
            })
            map.addLayer({
                id: polygonSource + '-line',
                source: polygonSource,
                type: 'line',
                paint: {
                    'line-color': ['get', 'colorCode'],
                    'line-width': 1,
                },
            })
        }
    }, [])

    return null
}
