import React, { useEffect, useState } from 'react'

import { FormattedMessage } from 'react-intl'

import { useContent } from 'stores/ForecastStore'
import { RichTextEditor } from 'components/RichTextEditor/RichTextEditor'
import messages from 'services/intl/messageDefinitions'

export const ReviewNotesEditor = ({ update }) => {
    const content = useContent()
    const [notes, setNotes] = useState(null)
    const [notesUpdated, setNotesUpdated] = useState(false)

    const handleNotesChange = (html) => {
        setNotes(html)
        setNotesUpdated(true)
    }

    // sets the RTE content when the data has loaded
    useEffect(() => {
        if (content.review) {
            const rawReview = content.review ? content.review : {}
            setNotes(rawReview?.notes)
        }
    }, [content])

    useEffect(() => {
        if (notesUpdated) {
            setNotesUpdated(false)

            update({
                ...content,
                review: {
                    status: content.review?.status ? content.review.status : 'notRequired',
                    notes,
                },
            })
        }
    }, [notes])

    return (
        <>
            {notes !== null && (
                <RichTextEditor
                    title={<FormattedMessage {...messages.review} />}
                    content={notes}
                    handleEditorStateChange={(html) => handleNotesChange(html)}
                />
            )}
        </>
    )
}
