import React, { useEffect } from 'react'

import { FormattedMessage } from 'react-intl'
import { Form, Card } from 'antd'

import { REVIEW_STATUS_OPTIONS } from 'utils/ForecastContent'
import messages from 'services/intl/messageDefinitions'
import { useContent } from 'stores/ForecastStore'
import { EditScreenLayout } from 'components/EditScreenLayout/EditScreenLayout'
import { Swatch } from 'components/Swatch/Swatch'
import { ReviewNotesEditor } from './ReviewNotesEditor'
import { Select } from 'components/Dropdown/Select'

export const Review = ({ update, compressed }) => {
    const content = useContent()
    const [form] = Form.useForm()

    // Generate options for status select
    const statusOptions = [...REVIEW_STATUS_OPTIONS].map((item, i) => {
        return (
            <Select.Option key={i} value={item.value}>
                {item.colour && <Swatch colour={item.colour} />}
                <FormattedMessage {...messages[item.label]} />
            </Select.Option>
        )
    })

    const handleStatusChange = (changeValues) => {
        update({
            ...content,
            review: {
                ...content.review,
                status: changeValues.status,
            },
        })
    }

    useEffect(() => {
        form.setFieldsValue({ status: content.review?.status ? content.review.status : 'notRequired' })
    }, [content])

    return (
        <>
            <EditScreenLayout showDaySelector={false} compressed={compressed}>
                <Card style={styles.card} title={<FormattedMessage {...messages.review} />}>
                    <Form form={form} layout="horizontal" autoComplete="on" onValuesChange={handleStatusChange}>
                        <Form.Item
                            label={<FormattedMessage {...messages.status} />}
                            name="status"
                            rules={[{ required: true }]}
                            initialValue={'notRequired'}
                            data-test={'reviewStatus'}
                        >
                            <Select style={styles.select}>{statusOptions}</Select>
                        </Form.Item>
                    </Form>
                    <ReviewNotesEditor update={update} />
                </Card>
            </EditScreenLayout>
        </>
    )
}

const styles = {
    card: {
        margin: 'var(--s0)',
    },
    select: {
        width: 150,
    },
}
