import { useEffect } from 'react'

import { useThemeSwitcher } from 'react-css-theme-switcher'

import { useMap } from 'components/Map/Map'
import { darkSchemePolygonsColour, lightSchemePolygonsColour } from './mapHelpers'

export const Polygons = ({ polygons }) => {
    // Map params
    const map = useMap()
    const polygonSource = 'polygons'

    // Secondary data
    const { currentTheme } = useThemeSwitcher()
    const polygonsColour = currentTheme === 'light' ? lightSchemePolygonsColour : darkSchemePolygonsColour

    // Update map layers based on filters
    useEffect(() => {
        if (map.getSource(polygonSource)) {
            map.getSource(polygonSource).setData(polygons)
        }
    }, [map, polygons, polygonSource])

    // Add sources and layers for polygons
    useEffect(() => {
        if (!map.getSource(polygonSource)) {
            map.addSource(polygonSource, { promoteId: 'id', type: 'geojson', data: polygons })
        }
        if (!map.getLayer(polygonSource)) {
            map.addLayer({
                id: polygonSource,
                source: polygonSource,
                type: 'fill',
                paint: {
                    'fill-color': polygonsColour,
                },
            })
            map.addLayer({
                id: polygonSource + '-line',
                source: polygonSource,
                type: 'line',
                paint: {
                    'line-width': 1.5,
                    'line-color': polygonsColour,
                },
            })
        }
    }, [])

    return null
}
