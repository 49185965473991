import { APIClient } from './APIClient'

export const fetchMitigations = (language) => {
    return APIClient.get(`/v1/${language}/products/mitigation`, language)
}

export const fetchMitigation = (id, language) => {
    return APIClient.get(`/v1/${language}/products/id/${id}`, language)
}

export const fetchMitigationsByDates = (startDate, endDate, searchTerm, language) => {
    return APIClient.get(
        `/v1/${language}/products/mitigation/history?startDate=${startDate}&endDate=${endDate}&searchTerm=${searchTerm}`,
        language
    )
}

export const postMitigation = (data, language) => {
    return APIClient.post(`/v1/${language}/products/mitigation`, {
        ...data,
    })
}

export const putMitigation = (data, language) => {
    return APIClient.put(`/v1/${language}/products/mitigation`, {
        ...data,
    })
}

export const publishMitigation = (id, language) => {
    return APIClient.post(`/v1/${language}/products/publish/${id}`)
}

export const deleteMitigation = (id, language) => {
    return APIClient.delete(`/v1/${language}/products/id/` + id, language)
}

export const cloneMitigation = (id, language) => {
    return APIClient.post(`/v1/${language}/products/clone/` + id, language)
}

export const cloneAllMitigations = (language) => {
    return APIClient.post(`/v1/${language}/products/mitigation/cloneall`, language)
}

export const expireMitigationNow = (id, language) => {
    return APIClient.post(`/v1/${language}/products/id/${id}/expirenow`, language)
}
