import { clearHtmlFormatting } from 'utils/String'

export const updateField = (item, field, language, value) => {
    if (typeof field === 'string') {
        // For top-level product fields, e.g. 'message'
        return {
            ...item,
            [field]: {
                ...item[field],
                [language]: value,
            },
        }
    } else if (Array.isArray(field) && field.length === 2) {
        // For nested product fields, e.g. communications.headline, passed in as ['communications', 'headline']
        return {
            ...item,
            [field[0]]: {
                ...item[field[0]],
                [field[1]]: {
                    ...item[field[0]][field[1]],
                    [language]: value,
                },
            },
        }
    } else {
        return null
    }
}

export const addTranslationToField = (item, field, language) => {
    if (typeof field === 'string') {
        // For top-level product fields, e.g. 'message'
        return {
            ...item,
            [field]: {
                ...item[field],
                [language]: '',
            },
        }
    } else if (Array.isArray(field) && field.length === 2) {
        // For nested product fields, e.g. communications.headline, passed in as ['communications', 'headline']
        return {
            ...item,
            [field[0]]: {
                ...item[field[0]],
                [field[1]]: {
                    ...item[field[0]][field[1]],
                    [language]: '',
                },
            },
        }
    } else {
        return null
    }
}

export const removeTranslationFromField = (item, field, language) => {
    if (typeof field === 'string') {
        // For top-level product fields, e.g. 'message'
        const remainingTranslations = {}
        for (let key in item[field]) {
            if (key !== language) {
                remainingTranslations[key] = item[field][key]
            }
        }

        return {
            ...item,
            [field]: remainingTranslations,
        }
    } else if (Array.isArray(field) && field.length === 2) {
        // For nested product fields, e.g. communications.headline, passed in as ['communications', 'headline']
        const remainingTranslations = {}
        for (let key in item[field[0]][field[1]]) {
            if (key !== language) {
                remainingTranslations[key] = item[field[0]][field[1]][key]
            }
        }

        return {
            ...item,
            [field[0]]: {
                ...item[field[0]],
                [field[1]]: remainingTranslations,
            },
        }
    } else {
        return null
    }
}

export const copyHeadlineToSms = (item) => {
    const sms = {}
    for (let key in item.communications.headline) {
        sms[key] = clearHtmlFormatting(item.communications.headline[key])
    }

    return {
        ...item,
        communications: {
            ...item.communications,
            sms: {
                ...sms,
            },
        },
    }
}
