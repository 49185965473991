// uploadImage - uploads an image to Cloudinary
export const uploadImage = async (file) => {
    const data = new FormData()

    data.append('file', file)
    data.append('upload_preset', process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET)
    data.append('cloud_name', process.env.REACT_APP_CLOUDINARY_NAME)

    const response = await fetch(process.env.REACT_APP_CLOUDINARY_BASE_URL, {
        method: 'post',
        body: data,
    })

    if (!response.ok) {
        throw new Error('Error uploading image to Cloudinary')
    }

    return response.json()
}
