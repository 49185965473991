import create from 'zustand'
import moment from 'moment'

import { ACTIVE_PRODUCTS } from 'utils/Constants'

const ArchiveStore = create((set) => ({
    search: '',
    productTypes: ACTIVE_PRODUCTS,
    dateRange: [
        moment()
            .subtract(1, 'week')
            .subtract(1, 'day')
            .set('hour', 16)
            .set('minute', 0),
        moment()
            .subtract(1, 'day')
            .set('hour', 16)
            .set('minute', 0),
    ],
    selectedPolygonId: null,
    activeProduct: null,
    updateSearch: (search) => {
        set({ search })
    },
    updateProductTypes: (productTypes) => {
        set({ productTypes })
    },
    updateDateRange: (dateRange) => {
        set({ dateRange })
    },
    updateActiveProduct: (activeProduct) => {
        set({ activeProduct })
    },
    updateSelectedPolygonId: (selectedPolygonId) => {
        set({ selectedPolygonId })
    },
}))

export const useSearch = () => ArchiveStore((state) => state.search)
export const useUpdateSearch = () => ArchiveStore((state) => state.updateSearch)
export const useProductTypes = () => ArchiveStore((state) => state.productTypes)
export const useUpdateProductTypes = () => ArchiveStore((state) => state.updateProductTypes)
export const useDateRange = () => ArchiveStore((state) => state.dateRange)
export const useUpdateDateRange = () => ArchiveStore((state) => state.updateDateRange)
export const useActiveProduct = () => ArchiveStore((state) => state.activeProduct)
export const useUpdateActiveProduct = () => ArchiveStore((state) => state.updateActiveProduct)
export const useSelectedPolygonId = () => ArchiveStore((state) => state.selectedPolygonId)
export const useUpdateSelectedPolygonId = () => ArchiveStore((state) => state.updateSelectedPolygonId)
