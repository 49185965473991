import React, { useEffect, useState, useCallback } from 'react'

import { TreeSelect, Tooltip } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { FormattedMessage, useIntl } from 'react-intl'

import { useLocale } from 'stores/UserStore'
import { fetchWeakLayers } from 'services/WeakLayers'
import { WeakLayerTreeNode } from './WeakLayerTreeNode'
import { truncate } from 'utils/String'
import messages from 'services/intl/messageDefinitions'
import { Select } from 'components/Dropdown/Select'

// current zone is the polygons in content (content.polygons)
export const WeakLayerSelector = ({ currentZone, onSelect, selected, errorStatus }) => {
    const intl = useIntl()
    const { TreeNode } = TreeSelect
    const locale = useLocale()
    const [weakLayers, setWeakLayers] = useState()
    const [weakLayerSelects, setWeakLayerSelects] = useState([])
    const [selectedWeakLayer, setSelectedWeakLayer] = useState(selected)
    const [deletedLayer, setDeletedLayer] = useState(false)

    // GET layers from the API
    const getLayers = useCallback(async () => {
        const response = await fetchWeakLayers(locale)
        setWeakLayers(response.data)
    }, [])

    // find weak layers that are in the same area as this forecast
    const findWeakLayers = (weakLayers) => {
        return weakLayers.filter((layer) => {
            const intersection = currentZone.filter((element) => layer.polygons.includes(element))
            if (intersection.length > 0) {
                return layer
            }
        })
    }

    // Generate the select options
    const generateWeakLayerSelectOptions = (list) => {
        const activeNodes = list
            .filter((layer) => layer.status === 'active')
            .map((layer) => {
                return (
                    <Select.Option value={layer.name} key={layer.name}>
                        <WeakLayerTreeNode name={layer.name} type={layer.grainType} status="active" />
                    </Select.Option>
                )
            })

        const developingNodes = list
            .filter((layer) => layer.status === 'developing')
            .map((layer) => {
                return (
                    <Select.Option value={layer.name} key={layer.name}>
                        <WeakLayerTreeNode name={layer.name} type={layer.grainType} status="developing" />
                    </Select.Option>
                )
            })

        const dormantNodes = list
            .filter((layer) => layer.status === 'dormant')
            .map((layer) => {
                return (
                    <Select.Option value={layer.name} key={layer.name}>
                        <WeakLayerTreeNode name={layer.name} type={layer.grainType} status="dormant" />
                    </Select.Option>
                )
            })

        const selects = (
            <>
                <TreeNode
                    style={styles.dropdownLabelFirst}
                    disabled
                    key="active"
                    value="active"
                    title={intl.formatMessage({ ...messages[`active`] })}
                />
                {activeNodes}
                <TreeNode
                    style={styles.dropdownLabel}
                    disabled
                    key="developing"
                    value="developing"
                    title={intl.formatMessage({ ...messages[`developing`] })}
                />
                {developingNodes}
                <TreeNode
                    style={styles.dropdownLabel}
                    disabled
                    key="dormant"
                    value="dormant"
                    title={intl.formatMessage({ ...messages[`dormant`] })}
                />
                {dormantNodes}
            </>
        )

        setWeakLayerSelects(selects)
    }

    const getLayerId = (name) => {
        if (weakLayers && weakLayers.length) {
            const match = weakLayers.filter((layer) => {
                if (layer.name === name) {
                    return layer
                }
            })[0]

            return match ? match.id : undefined
        }
    }

    const handleSelect = (value) => {
        // HACK: If it's not a string, it's an event and we have to parse the value (accessibility mode)
        if (typeof value !== 'string') {
            value = value.target.value
        }

        if (value && weakLayers && weakLayers.length) {
            const weakLayerObject = weakLayers.filter((layer) => {
                return layer.name === value
            })[0]

            onSelect(weakLayerObject)
            setSelectedWeakLayer(weakLayerObject)
        }
    }

    useEffect(() => {
        getLayers()
    }, [])

    useEffect(() => {
        if (weakLayers && weakLayers.length > 0) {
            const matchingLayers = findWeakLayers(weakLayers)
            generateWeakLayerSelectOptions(matchingLayers)
            if (selectedWeakLayer) {
                setDeletedLayer(getLayerId(selectedWeakLayer.name) ? false : true)
            }
        }
    }, [weakLayers])

    return (
        <>
            {selectedWeakLayer.name ? (
                <div style={styles.layerDisplay}>
                    {deletedLayer ? (
                        <Tooltip title={intl.formatMessage({ ...messages[`deletedWeakLayer`] })}>
                            {truncate(selectedWeakLayer.name, 22)}
                        </Tooltip>
                    ) : (
                        <a target="_blank" href={'/weak-layers?focus=' + selectedWeakLayer.id}>
                            {truncate(selectedWeakLayer.name, 22)}
                        </a>
                    )}

                    {/* TODO: make this fully keyboard accessible, not just with Voice over */}
                    <CloseOutlined
                        onClick={() => {
                            setSelectedWeakLayer({})
                            setDeletedLayer(false)
                        }}
                        style={styles.close}
                    />
                </div>
            ) : (
                <>
                    <Select
                        status={errorStatus ? 'error' : undefined}
                        showSearch
                        value={selectedWeakLayer}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        onSelect={handleSelect}
                        onChange={handleSelect}
                        data-test={'weakLayerSelector'}
                    >
                        {weakLayerSelects}
                    </Select>

                    {errorStatus && (
                        <span style={styles.error}>
                            <FormattedMessage {...messages.required} />
                        </span>
                    )}
                </>
            )}
        </>
    )
}

const styles = {
    error: {
        color: '#ff4d4f',
    },
    dropdownLabel: {
        fontWeight: '500',
        marginLeft: '5px',
        fontSize: '12px',
        marginTop: 'var(--s0)',
    },
    dropdownLabelFirst: {
        fontWeight: '500',
        marginLeft: '5px',
        fontSize: '12px',
    },
    layerDisplay: {
        background: 'var(--grey-lighter)',
        width: '100%',
        height: '32px',
        borderRadius: 'var(--radius)',
        padding: '5px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    close: {
        fontSize: '14px',
        marginTop: '4px',
        marginRight: '3px',
        color: 'var(--grey)',
    },
}
