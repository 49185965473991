import React from 'react'
import { Checkbox as AntDesignCheckbox } from 'antd'
import { Radio as AntDesignRadio } from 'antd'
import './CheckBox.css'

export const Checkbox = ({ value, label, checked, useAddFilter, useRemoveFilter, radioStyle }) => {
    const addFilter = useAddFilter()
    const removeFilter = useRemoveFilter()

    const handleChange = () => {
        if (!checked) {
            addFilter(value)
        }

        if (checked && !radioStyle) {
            removeFilter(value)
        }
    }

    if (radioStyle) {
        return (
            <AntDesignRadio value={value} defaultChecked={true} checked={checked} onChange={handleChange}>
                {label}
            </AntDesignRadio>
        )
    }

    return (
        <AntDesignCheckbox
            value={value}
            defaultChecked={true}
            checked={checked}
            onChange={handleChange}
            data-test={'weakLayerFilters'}
        >
            {label}
        </AntDesignCheckbox>
    )
}
