export const arrayWithoutElementAtIndex = function(arr, index) {
    var ret = arr.slice() //make a copy
    ret.splice(index, 1) //remove the item from given index
    return ret //return the copy
}

export const arrayUp = (arr, new_index) => {
    const updated = []

    for (let i = 0; i < arr.length; i++) {
        if (i == new_index) {
            updated.push(arr[i + 1])
            updated.push(arr[i])
            i++
        } else {
            updated.push(arr[i])
        }
    }
    return updated
}

export const arrayDown = (arr, old_index) => {
    const updated = []

    for (let i = 0; i < arr.length; i++) {
        if (i == old_index) {
            updated.push(arr[i + 1])
            updated.push(arr[i])
            i++
        } else {
            updated.push(arr[i])
        }
    }
    return updated
}
