import React from 'react'

export const MountainIcon = ({ alp, tln, btl, title, show }) => {
    return (
        <svg
            version="1.1"
            x="0px"
            y="0px"
            width="50px"
            height="50px"
            viewBox="398.5 12.1 555 560"
            enableBackground="new 398.5 12.1 555 560"
            visibility={show ? 'visible' : 'hidden'}
        >
            <title>{title}</title>
            <path
                opacity="0.6"
                fill="#FFFFFF"
                enableBackground="new"
                d="M874.5,437H838v36.5c-90.5,73.6-220.8,73.6-311.5,0V437H490c-73.7-90.7-73.6-221.4,0.4-312h36.7V88.5c90.5-73.1,220.5-72.8,310.8,0.5v36.5h36.5C948.1,216.2,948.1,346.5,874.5,437z"
            ></path>
            <path
                opacity="0.4"
                fill="#2B2F33"
                d="M682.3,12.1c-148.7,0-269.2,120.5-269.2,269.2s120.5,269.2,269.2,269.2S951.4,430,951.4,281.3S831,12.1,682.3,12.1z M838,437v36.5c-90.5,73.6-220.8,73.6-311.5,0V437H490c-73.7-90.7-73.6-221.4,0.4-312h36.7V88.5c90.5-73.1,220.5-72.8,310.8,0.5v36.5h36.5c73.6,90.5,73.6,220.8,0,311.3H838V437z"
            ></path>
            <polygon
                id="alp"
                fill={alp}
                stroke="#000"
                strokeWidth="2"
                points="747.7,218.1 623.1,197.6 678.8,109.8"
            ></polygon>
            <polygon
                id="tln"
                fill={tln}
                stroke="#000"
                strokeWidth="2"
                points="794.2,291 542.8,323.6 616.7,207.4 755.5,230.3"
            ></polygon>
            <polygon
                id="btl"
                fill={btl}
                stroke="#000"
                strokeWidth="2"
                points="858.3,391.8 499.4,391.8 535.1,335.5 800.6,301.1"
            ></polygon>
        </svg>
    )
}
