import useSWR from 'swr'

import { fetchAvalancheForecastsByDates } from 'services/AvalancheForecast'
import { useLocale } from 'stores/UserStore'
import { fetchSpecialProductsByDates } from 'services/SpecialProducts'
import { fetchRegionalDiscussionsByDates } from 'services/RegionalDiscussions'
import { fetchHighwayForecastsByDate } from 'services/HighwayForecasts'
import { fetchHighwayDiscussionsByDates } from 'services/HighwayDiscussions'
import { fetchMitigationsByDates } from 'services/Mitigation'
import { useSearch, useProductTypes, useDateRange } from 'stores/ArchiveStore'

export const useAvalancheForecastsResults = () => {
    const search = useSearch()
    const productTypes = useProductTypes()
    const dateRange = useDateRange()
    const locale = useLocale()

    return useSWR(
        productTypes.includes('avalanche-forecast')
            ? [
                  'avalanche-forecast',
                  {
                      search,
                      dateRange,
                  },
              ]
            : null,
        () => fetchAvalancheForecastsByDates(dateRange[0].format(), dateRange[1].format(), search, locale)
    )
}

export const useRegionalDiscussionsResults = () => {
    const search = useSearch()
    const productTypes = useProductTypes()
    const dateRange = useDateRange()
    const locale = useLocale()

    return useSWR(
        productTypes.includes('regional-discussions')
            ? [
                  'regional-discussions',
                  {
                      search,
                      dateRange,
                  },
              ]
            : null,
        () => fetchRegionalDiscussionsByDates(dateRange[0].format(), dateRange[1].format(), search, locale)
    )
}

export const useSpecialProductsResults = () => {
    const search = useSearch()
    const productTypes = useProductTypes()
    const dateRange = useDateRange()
    const locale = useLocale()

    return useSWR(
        productTypes.includes('special-products')
            ? [
                  'special-products',
                  {
                      search,
                      dateRange,
                  },
              ]
            : null,
        () => fetchSpecialProductsByDates(dateRange[0].format(), dateRange[1].format(), search, locale)
    )
}

export const useHighwayForecastsResults = () => {
    const search = useSearch()
    const productTypes = useProductTypes()
    const dateRange = useDateRange()
    const locale = useLocale()

    return useSWR(
        productTypes.includes('hwy-forecast')
            ? [
                  'hwy-forecast',
                  {
                      search,
                      dateRange,
                  },
              ]
            : null,
        () => fetchHighwayForecastsByDate(dateRange[0].format(), dateRange[1].format(), search, locale)
    )
}

export const useHighwayDiscussionsResults = () => {
    const search = useSearch()
    const productTypes = useProductTypes()
    const dateRange = useDateRange()
    const locale = useLocale()

    return useSWR(
        productTypes.includes('hwy-discussion')
            ? [
                  'hwy-discussion',
                  {
                      search,
                      dateRange,
                  },
              ]
            : null,
        () => fetchHighwayDiscussionsByDates(dateRange[0].format(), dateRange[1].format(), search, locale)
    )
}

export const useMitigationResults = () => {
    const search = useSearch()
    const productTypes = useProductTypes()
    const dateRange = useDateRange()
    const locale = useLocale()

    return useSWR(
        productTypes.includes('mitigation')
            ? [
                  'mitigation',
                  {
                      search,
                      dateRange,
                  },
              ]
            : null,
        () => fetchMitigationsByDates(dateRange[0].format(), dateRange[1].format(), search, locale)
    )
}
