import React, { useCallback, useState } from 'react'

import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Divider, Typography, Row, Col, Modal } from 'antd'

import { useLocale } from 'stores/UserStore'
import { RegionalDiscussionStore } from 'stores/RegionalDiscussionStore'
import { Stat } from 'components/Stat/Stat'
import { Date } from 'components/Date/Date'
import { PolygonList } from 'components/PolygonList/PolygonList'
import { CreateForm } from './CreateForm'
import { EditContentForm } from 'components/Product/EditContentForm'
import { DeleteButton } from 'components/DeleteButton/DeleteButton'
import { Preview } from 'components/SpecialProducts/Preview'
import { Spacer } from 'components/VerticalSpacer/Spacer'
import {
    putRegionalDiscussion,
    cloneRegionalDiscussion,
    publishRegionalDiscussion,
    deleteRegionalDiscussion,
} from 'services/RegionalDiscussions'
import { useSendNotification, sendErrorNotification } from 'utils/Notifications'
import messages from 'services/intl/messageDefinitions'
import { API_SUCCESS_CODES } from 'utils/Constants'
import { usePolygons } from 'hooks/polygons'
import { useRegionalDiscussions } from 'hooks/regionalDiscussions'

export const Details = ({ item, closeAndReload, closeAndOpenCloned }) => {
    const intl = useIntl()
    const [product, setProduct] = useState(item)
    const [cloneError, setCloneError] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const { Title } = Typography
    const editingProductSetup = RegionalDiscussionStore.useEditingProductSetup()
    const setEditingProductSetup = RegionalDiscussionStore.useSetEditingProductSetup()
    const editingProductContent = RegionalDiscussionStore.useEditingProductContent()
    const setEditingProductContent = RegionalDiscussionStore.useSetEditingProductContent()
    const locale = useLocale()
    const sendNotification = useSendNotification()
    const { mutate } = useRegionalDiscussions()
    const polygons = usePolygons()

    const updateDiscussion = useCallback(async (data, locale) => {
        const response = await putRegionalDiscussion(data, locale)
        if (API_SUCCESS_CODES.includes(response.status)) {
            sendNotification(intl.formatMessage({ ...messages.regionalDiscussionSaved }, { name: data.name }))
            mutate()
            setProduct(response.data)
        } else {
            sendNotification(intl.formatMessage({ ...messages.regionalDiscussionSaveError }, { name: data.name }))
        }
    }, [])

    const cloneSelectedDiscussion = useCallback(async (item, locale) => {
        const response = await cloneRegionalDiscussion(item.id, locale)
        if (API_SUCCESS_CODES.includes(response.status)) {
            closeAndOpenCloned(response.data.id)
        } else {
            if (response.data && response.data.message === 'Polygons are assigned to draft product') {
                setCloneError(true)
            } else {
                sendErrorNotification(intl.formatMessage({ ...messages.productCloneError }))
            }
        }
    }, [])

    const publish = useCallback(async (item) => {
        await publishRegionalDiscussion(item.id, locale)
        mutate()
    }, [])

    const deleteDiscussion = useCallback(async (id, locale) => {
        await deleteRegionalDiscussion(id, locale)
        sendNotification(intl.formatMessage({ ...messages.regionalDiscussionDeleted }, { name: item.name }))
        setDeleteModalVisible(false)
        closeAndReload()
    }, [])

    // Updates the various fields in the product
    // details should be in the format: [{key: <field to be changed> value: <updated values>}, {...}]
    const update = (details) => {
        const data = { ...item }
        for (let d in details) {
            const detail = details[d]
            data[detail.key] = detail.value
        }

        updateDiscussion(data, locale)
    }

    const apiPut = (data) => {
        updateDiscussion(data, locale)
    }

    const toggleEdit = () => {
        setEditingProductSetup(!editingProductSetup)
    }

    const toggleContentEdit = () => {
        setEditingProductContent(!editingProductContent)
    }

    const detailsView = (
        <div style={styles.detailsBody}>
            <Title level={2} style={styles.title}>
                {item.name}
            </Title>
            <div style={styles.header}>
                <div>{item.username}</div>
                <Date label={'lastModified'} time={true} value={item.lastModifiedDateTime} />
            </div>
            <div style={styles.detailsContainer}>
                <Divider orientation="left" plain>
                    <FormattedMessage {...messages.details} />
                </Divider>
                <Row gutter={8}>
                    <Col style={styles.detailCol} span={12}>
                        <Stat label={'issueDateTime'} value={item.issueDateTime} type={'date'} time={true} />
                    </Col>
                    <Col style={styles.detailCol} span={12}>
                        <Stat label={'expiryDateTime'} value={item.expiryDateTime} type={'date'} time={true} />
                    </Col>
                </Row>
                <PolygonList
                    polygonList={item.polygons}
                    colour={item.colour}
                    polygons={polygons}
                />
            </div>
            {item.status === 'draft' && (
                <>
                    <Row style={styles.buttonContainer}>
                        <Button onClick={toggleEdit}>
                            <FormattedMessage {...messages.editSetup} />
                        </Button>
                        <Button
                            onClick={toggleContentEdit}
                            disabled={!item.userID}
                            title={intl.formatMessage({ ...messages.missingForecaster })}
                            data-test="editContentButton"
                        >
                            <FormattedMessage {...messages.editContent} />
                        </Button>
                        <Button onClick={() => cloneSelectedDiscussion(product, locale)}>
                            <FormattedMessage {...messages.cloneToDraft} />
                        </Button>
                        <Button type="primary" onClick={() => publish(product)}>
                            <FormattedMessage {...messages.publish} />
                        </Button>
                    </Row>
                    <DeleteButton
                        setDeleteModalVisible={setDeleteModalVisible}
                        buttonText={'deleteRegionalDiscussion'}
                    />
                </>
            )}
            {(item.status === 'completed' || item.status === 'live') && (
                <>
                    <Row style={styles.buttonContainer}>
                        <Button onClick={() => cloneSelectedDiscussion(item, locale)}>
                            <FormattedMessage {...messages.cloneToDraft} />
                        </Button>
                    </Row>
                    <DeleteButton
                        setDeleteModalVisible={setDeleteModalVisible}
                        buttonText={'deleteRegionalDiscussion'}
                    />
                </>
            )}

            <Spacer />
            {['completed', 'live'].includes(item.status) && <Preview item={item} />}
        </div>
    )

    const editSetupView = (
        <>
            <CreateForm
                toggleEdit={toggleEdit}
                editing={editingProductSetup}
                update={update}
                initialValues={product}
                autoUpdate={true}
                closeAndReload={closeAndReload}
                setDeleteModalVisible={setDeleteModalVisible}
            />
        </>
    )

    const editContentView = (
        <EditContentForm toggleContentEdit={toggleContentEdit} item={product} update={update} apiPut={apiPut} />
    )

    // Show details by default; otherwise, show editSetup or editContent
    let contentView = detailsView

    if (editingProductSetup) {
        contentView = editSetupView
    }

    if (editingProductContent) {
        contentView = editContentView
    }

    return (
        <>
            {contentView}
            <Modal
                title={<FormattedMessage {...messages.warning} />}
                visible={deleteModalVisible}
                onOk={() => deleteDiscussion(item.id, locale)}
                onCancel={() => setDeleteModalVisible(false)}
                okText={<FormattedMessage {...messages.yesConfirmDeleteRegionalDiscussion} />}
                cancelText={<FormattedMessage {...messages.cancel} />}
            >
                <p>
                    <FormattedMessage {...messages.deleteRegionalDiscussionConfirmation} values={{ name: item.name }} />
                </p>
            </Modal>
            <Modal
                title={<FormattedMessage {...messages.clonePolygonError} />}
                visible={cloneError}
                onCancel={() => setCloneError(false)}
                okText={<FormattedMessage {...messages.ok} />}
                footer={
                    <Button type="primary" onClick={() => setCloneError(false)}>
                        <FormattedMessage {...messages.ok} />
                    </Button>
                }
            >
                <p>
                    <FormattedMessage {...messages.productCloneErrorPolygons} />
                </p>
            </Modal>
        </>
    )
}

const styles = {
    detailsBody: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
    },
    title: {
        fontWeight: '400',
        flex: '0 1 auto',
    },
    header: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 'var(--s-1)',
        justifyContent: 'space-between',
        flex: '0 1 auto',
    },
    detailsContainer: {
        marginTop: 'var(--s0)',
    },
    detailCol: {
        marginBottom: 'var(--s0)',
    },
    buttonContainer: {
        marginTop: '25px',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 'var(--s0)',
    },
}
