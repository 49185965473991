import React from 'react'
import { FormattedMessage } from 'react-intl'

import { formatDate } from 'utils/Date'
import { useDateFormat } from 'stores/UserStore'
import messages from 'services/intl/messageDefinitions'

export const Date = ({ label, value, extraStyles, time }) => {
    const storeFormat = useDateFormat()
    const dateFormat = time ? storeFormat + ' HH:mm' : storeFormat
    const formattedValue = formatDate(value, dateFormat)

    return (
        <p style={{ ...styles.label, ...extraStyles }}>
            <FormattedMessage {...messages[label]} />:<span style={styles.value}> {formattedValue}</span>
        </p>
    )
}

export const removeNullTime = (value) => {
    const formattedValue = value.indexOf('00:00') > 1 ? value.substring(0, value.indexOf('00:00')) : value
    return formattedValue
}

const styles = {
    label: {
        fontSize: '14px',
        fontWeight: '600',
    },
    value: {
        fontWeight: '400',
    },
}
