import React from 'react'

import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { Button, Typography, Row, Col } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'

import { useUserDetails } from 'stores/UserStore'
import messages from 'services/intl/messageDefinitions'
import { UserDetailsForm } from './UserDetailsForm'

export const Profile = () => {
    const intl = useIntl()
    const { Title } = Typography
    const navigate = useNavigate()
    const { logout } = useAuth0()
    const { details } = useUserDetails()

    const handleLogout = () => {
        logout({ returnTo: window.location.origin })
    }

    return (
        <main className="page-content">
            <article>
                <Title level={3}>
                    {intl.formatMessage(
                        {
                            id: 'profilePageh3',
                            defaultMessage: 'Welcome, {user}',
                            description: 'Profile page title',
                        },
                        { user: details?.name || '' }
                    )}
                </Title>
                {details && <UserDetailsForm />}
                <Row style={styles.buttonContainer}>
                    <Col offset={18} span={6}>
                        <Button style={styles.logout} type="primary" danger onClick={handleLogout}>
                            <FormattedMessage {...messages.logout} />
                        </Button>
                        <Button type="primary" onClick={() => navigate(-1)}>
                            <FormattedMessage {...messages.back} />
                        </Button>
                    </Col>
                </Row>
            </article>
        </main>
    )
}

const styles = {
    logout: {
        marginRight: 'var(--s0)',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
    },
}
