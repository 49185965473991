import React from 'react'

import { Auth0Provider } from '@auth0/auth0-react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import { App } from './App'
import { reportWebVitals } from './reportWebVitals'

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <BrowserRouter>
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            redirectUri={window.location.origin}
            audience={process.env.REACT_APP_AUTH0}
            cacheLocation="localstorage" // bypass check authentication on page reload
        >
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </Auth0Provider>
    </BrowserRouter>
)

reportWebVitals()
