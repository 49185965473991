import { Progress } from 'antd'
import { FormattedMessage } from 'react-intl'

import { HighwayMap } from 'pages/Highways/HighwayMap'
import messages from 'services/intl/messageDefinitions'
import {
    useDoneGeneratingImages,
    useGroupIDCount,
    useGroupIDs,
    useIDIndex,
    useSetDoneGeneratingImages,
    useSetIDIndex,
} from 'stores/HighwayDiscussionImageStore'
import { EmailPreviewHTML } from './EmailPreviewHTML'
import { saveImage } from './indexedDB'

export const ScreenshotGenerator = ({ productId }) => {
    const groupIDs = useGroupIDs()
    const IDIndex = useIDIndex()
    const groupLength = useGroupIDCount()
    const doneGeneratingImages = useDoneGeneratingImages()
    // A bit ugly, but it makes 100% not show until the last image is done
    const percent = doneGeneratingImages ? 100 : (IDIndex / groupLength) * 100
    const setDoneGeneratingImages = useSetDoneGeneratingImages()
    const setIDIndex = useSetIDIndex()
    const groupID = groupIDs?.[IDIndex]

    const handleScreenshot = async (groupID, imgData) => {
        // Save the screenshot image to IndexedDB
        await saveImage(imgData, groupID)

        // Move to the next group ID after capturing screenshot
        if (IDIndex < groupIDs.length - 1) {
            setIDIndex(IDIndex + 1)
        } else {
            setDoneGeneratingImages(true)
        }
    }

    return (
        <>
            {groupIDs?.length > 0 && groupID && !doneGeneratingImages && (
                <>
                    <FormattedMessage
                        {...messages.generatingScreenshots}
                        values={{
                            current: IDIndex + 1,
                            total: groupLength,
                        }}
                    />
                    <Progress percent={percent} />
                    <div style={styles.mapContainer}>
                        <HighwayMap key={groupIDs[IDIndex]} groupID={groupID} onScreenshot={handleScreenshot} />
                    </div>
                </>
            )}
            {doneGeneratingImages && <EmailPreviewHTML productId={productId} />}
        </>
    )
}

const styles = {
    mapContainer: {
        width: '600px',
        height: '600px',
        margin: '0 auto',
    },
}
