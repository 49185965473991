import useSWR from 'swr'

import { useLocale } from 'stores/UserStore'
import { fetchSpecialProducts } from 'services/SpecialProducts'
import { SpecialProductStore } from 'stores/SpecialProductStore'
import { filterAndSort, unassignedCount } from 'utils/Product'
import { usePolygons } from 'hooks/polygons'

export const useSpecialProducts = () => {
    const language = useLocale()
    const key = ['products/specialproduct', language]
    const { data, isLoading, mutate } = useSWR(key, () => fetchSpecialProducts(language))

    return {
        specialProducts: data?.data,
        isLoading,
        mutate,
    }
}

export const useFilteredSpecialProducts = () => {
    const { specialProducts, isLoading } = useSpecialProducts()
    const filters = SpecialProductStore.useFilters()

    return isLoading ? [] : filterAndSort(specialProducts, 'status', filters)
}

export const useActiveSpecialProduct = () => {
    const activeProductId = SpecialProductStore.useActiveProductId()
    const { specialProducts } = useSpecialProducts()
    const draftProduct = SpecialProductStore.useDraftProduct()

    if (activeProductId === 'draft') {
        return draftProduct
    }

    return specialProducts?.find((product) => product.id === activeProductId)
}

export const useUnassignedPolygons = () => {
    const polygons = usePolygons()
    const draftProduct = SpecialProductStore.useDraftProduct()
    const filteredProducts = useFilteredSpecialProducts()

    if (!polygons || !filteredProducts || !draftProduct) {
        return -1
    }

    const count = unassignedCount(polygons, filteredProducts, draftProduct)

    return count
}
