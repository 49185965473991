import { uuid } from 'utils/String'
import { REVIEW_STATUS_OPTIONS } from 'utils/ForecastContent'

export const MENU_ITEMS = {
    weatherSummary: {
        progressPortion: 15,
    },
    snowpackSummary: {
        progressPortion: 15,
    },
    avalancheSummary: {
        progressPortion: 15,
    },
    avalancheProblems: {
        progressPortion: 20,
    },
    highwayHazard: {
        progressPortion: 5,
    },
    confidence: {
        progressPortion: 5,
    },
    media: {
        progressPortion: 5,
    },
    communications: {
        progressPortion: 10,
    },
    review: {
        progressPortion: 10,
    },
}

export const HWY_HAZARD_OPTION_NONE = 'none'
export const HWY_HAZARD_OPTION_NO_RATING = 'noRating'
export const HWY_HAZARD_OPTION_NOTICE = 'notice'
export const HWY_HAZARD_OPTION_CAUTION = 'caution'
export const HWY_HAZARD_OPTION_WARNING = 'warning'

export const HIGHWAY_HAZARD_OPTIONS = new Map([
    [HWY_HAZARD_OPTION_NONE, '#808080'],
    [HWY_HAZARD_OPTION_NO_RATING, '#FFFFFF'],
    [HWY_HAZARD_OPTION_NOTICE, '#008000'],
    [HWY_HAZARD_OPTION_CAUTION, '#FFFF00'],
    [HWY_HAZARD_OPTION_WARNING, '#FF0000'],
])

export const DEFAULT_HIGHWAY_HAZARD_VALUE = 'none'

// TODO: these are duplicated from ForecastContent.js. I've left them here in case the order diverges in the future.
export const getOrderOfReviewStatus = (status) => {
    return status ? REVIEW_STATUS_OPTIONS.find((option) => option.value === status)?.order : 4
}
export const getColourOfReviewStatus = (status) => {
    return status ? REVIEW_STATUS_OPTIONS.find((option) => option.value === status)?.colour : ''
}
export const getReviewStatusTextColour = (status) => {
    return status ? REVIEW_STATUS_OPTIONS.find((option) => option.value === status)?.textColour : ''
}

export const progressToPercent = (progress) => {
    return progress
        ? progress.reduce((sum, item) => {
              return sum + (item.complete ? MENU_ITEMS[item.element]?.progressPortion : 0)
          }, 0)
        : 0
}

export const avalancheProblemSchema = (locale) => {
    return {
        id: uuid(),
        public: false,
        problemType: null,
        weakLayer: {},
        depth: {
            min: null,
            max: null,
        },
        threshold: null,
        effectOnRoadway: null,
        comment: {
            [locale]: null,
        },
        aspectElevations: [],
        terrainTravelAdvice: [],
        // Conceptual model fields
        // form fields
        distribution: null,
        sensitivity: null,
        typicalSize: null,
        likelihoodHighways: null, // redundant property manually set via drop-down per CAIC request
        // chart fields
        size: {
            min: null,
            max: null,
        },
        likelihoodRange: {
            min: null,
            max: null,
        },
        hazardSize: null,
        likelihood: null,
    }
}
