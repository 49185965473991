import React, { useState, useEffect, useCallback } from 'react'

import { Form, Input, Divider, Empty } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import lunr from 'lunr'

import { noop } from 'utils/function'
import { fetchTerrainAndTravelAdvice } from 'services/TerrainAndTravelAdvice'
import { useLocale } from 'stores/UserStore'
import { Statement } from 'components/SelectorForm/Statement'
import messages from 'services/intl/messageDefinitions'
import { sendErrorNotification } from 'utils/Notifications'

const useTerrainSelectorForm = (selectedKeys, selectHandler, maxHeight, limit) => {
    const [filter, setFilter] = useState('')
    const [localSelectedStatements, setLocalSelectedStatements] = useState([])
    const [form] = Form.useForm()

    const clear = () => {
        setFilter('')
        form.setFieldsValue({
            terrainAndTravelAdvice: '',
        })
        setLocalSelectedStatements([])
    }

    return {
        SelectorForm: (
            <TerrainSelectorForm
                selectedKeys={selectedKeys}
                selectHandler={selectHandler}
                maxHeight={maxHeight}
                form={form}
                filter={filter}
                setFilter={setFilter}
                limit={limit}
                localSelectedStatements={localSelectedStatements}
                setLocalSelectedStatements={setLocalSelectedStatements}
            />
        ),
        clearStatementInput: clear,
    }
}

const TerrainSelectorForm = ({
    selectedKeys,
    selectHandler,
    maxHeight,
    form,
    filter,
    setFilter,
    limit,
    localSelectedStatements,
    setLocalSelectedStatements,
}) => {
    const [filteredStatements, setFilteredStatements] = useState([])
    const [statements, setStatements] = useState([])
    const intl = useIntl()
    const locale = useLocale()

    const getStatements = useCallback(async () => {
        const response = await fetchTerrainAndTravelAdvice(locale)
        setStatements(response.data)
    }, [])

    const getStatementsFromKeys = (selectedStatementKeys) => {
        if (!selectedStatementKeys || statements.length === 0) {
            return []
        }
        return selectedStatementKeys.map((key) => {
            return statements.find((statement) => statement.key === key)
        })
    }

    const swapStatements = useCallback((array, index1, index2) => {
        const temp = array[index1]
        array[index1] = array[index2]
        array[index2] = temp
        return array
    })

    const handleStatementClick = (statement) => {
        if (localSelectedStatements.includes(statement)) {
            return
        }
        if (limit && localSelectedStatements.length >= limit) {
            sendErrorNotification(intl.formatMessage({ ...messages.statementLimit }))
            return
        }
        setLocalSelectedStatements([...localSelectedStatements, statement])
        selectHandler([...localSelectedStatements, statement])

        const index = filteredStatements.indexOf(statement)
        if (index < filteredStatements.length - 1) {
            document.getElementById(`statement-${filteredStatements[index + 1].key}`).focus()
        }
    }

    const handleUp = (statement) => {
        const index = localSelectedStatements.indexOf(statement)
        if (index < 1 || index >= localSelectedStatements.length) {
            return
        }

        const newlocalSelectedStatements = swapStatements(localSelectedStatements, index, index - 1)
        setLocalSelectedStatements([...newlocalSelectedStatements])
        selectHandler([...newlocalSelectedStatements])
    }

    const handleDown = (statement) => {
        const index = localSelectedStatements.indexOf(statement)
        if (index < 0 || index >= localSelectedStatements.length - 1) {
            return
        }

        const newlocalSelectedStatements = swapStatements(localSelectedStatements, index, index + 1)
        setLocalSelectedStatements([...newlocalSelectedStatements])
        selectHandler([...newlocalSelectedStatements])
    }

    const handleDelete = (statement) => {
        const newlocalSelectedStatements = localSelectedStatements.filter((s) => s.key !== statement.key)
        setLocalSelectedStatements(newlocalSelectedStatements)
        selectHandler(newlocalSelectedStatements)

        // Move focus after deleting
        // If there is only one statement left, focus on the input
        if (localSelectedStatements.length === 1) {
            setTimeout(() => {
                document.getElementById('terrainAndTravelAdvice').focus()
            }, 20)
        } else {
            const index = localSelectedStatements.indexOf(statement)
            // If we're deleting the last statement, focus on the input
            if (index === localSelectedStatements.length - 1) {
                document.getElementById('terrainAndTravelAdvice').focus()
            } else {
                // Otherwise focus on the next statement
                document.getElementById(`selected-statement-${localSelectedStatements[index + 1].key}`).focus()
            }
        }
    }

    const search = (query) => {
        const index = getIndex(statements)

        const results = index.search(query)
        const newFilteredStatements = results.map((ref) => {
            return statements.find((statement) => statement.key === ref.ref)
        })

        setFilteredStatements(newFilteredStatements)
    }

    const getIndex = (statements) => {
        const index = lunr(function () {
            this.field('message')
            this.field('tags')
            this.ref('key')
            statements.forEach((statement) => {
                const tags = statement.tags.map((tag) => tag.trim().replace(/\s/g, '')).filter((value) => value !== '')
                this.add({ ...statement, tags })
            }, this)
        })
        return index
    }

    const handleFilterChange = (changedValues) => {
        setFilter(Object.values(changedValues)[0])
        if (Object.values(changedValues)[0] === '') {
            setFilteredStatements([])
        }
    }

    // debounce
    useEffect(() => {
        const handler = setTimeout(() => {
            if (filter) {
                search(filter)
            }

            if (filter === '') {
                setFilteredStatements([])
            }
        }, 200)

        return () => {
            clearTimeout(handler)
        }
    }, [filter])

    useEffect(() => {
        getStatements()
    }, [])

    useEffect(() => {
        setLocalSelectedStatements(getStatementsFromKeys(selectedKeys))
    }, [statements])

    return (
        <div data-testid={'terrainTravelSelector'} style={{ ...styles.statementList, maxHeight: maxHeight }}>
            {localSelectedStatements.length > 0 &&
                localSelectedStatements.map((statement, i) => (
                    <Statement
                        key={statement.key}
                        statement={statement}
                        handleClick={noop}
                        selected={true}
                        handleUp={i === 0 ? false : handleUp}
                        handleDown={i >= localSelectedStatements.length - 1 ? false : handleDown}
                        handleDelete={handleDelete}
                    />
                ))}
            <Divider orientation="left" plain>
                <FormattedMessage {...messages.filter} />
            </Divider>
            <Form form={form} onValuesChange={handleFilterChange}>
                <Form.Item name="terrainAndTravelAdvice">
                    <Input placeholder={intl.formatMessage({ ...messages.tagSearch })} value={filter} />
                </Form.Item>
            </Form>
            {filteredStatements.length || !filter.length ? (
                filteredStatements.map((statement) => (
                    <Statement
                        key={statement.key}
                        statement={statement}
                        handleClick={handleStatementClick}
                        disabled={localSelectedStatements.includes(statement)}
                        selected={localSelectedStatements.includes(statement)}
                    />
                ))
            ) : (
                <div>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
            )}
        </div>
    )
}

export { TerrainSelectorForm, useTerrainSelectorForm }

const styles = {
    statementList: {
        overflowY: 'auto',
        overflowX: 'hidden',
    },
}
