import React from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Comment, List } from 'antd'
import './Comments.css'
import moment from 'moment'
import parse from 'html-react-parser'
import { useDateFormat } from 'stores/UserStore'

export const Comments = ({ comments }) => {
    const dateFormat = useDateFormat()

    const header = (
        <>
            <div>{comments.length} Comments</div>
        </>
    )

    return (
        <List
            className="comment-list"
            header={header}
            itemLayout="horizontal"
            dataSource={comments}
            renderItem={(item) => (
                <li style={styles.li}>
                    <Comment
                        author={item.username}
                        // avatar={'https://joeschmoe.io/api/v1/random'} // TODO:: send avatar in comment and then use it in comment obj
                        content={parse(item.comment)}
                        datetime={moment(item.datetime).format(dateFormat)} // TODO:: this should be set by locale
                    />
                </li>
            )}
        />
    )
}

const styles = {
    li: {
        listStyle: 'none',
    },
}
