import { useEffect, useCallback, useRef } from 'react'

import MapboxDraw from '@mapbox/mapbox-gl-draw'
import bbox from '@turf/bbox'
import intersect from '@turf/intersect'
import { useThemeSwitcher } from 'react-css-theme-switcher'

import FreeDraw from 'utils/FreeDraw'
import { useLocale } from 'stores/UserStore'
import { useMap } from 'components/Map/Map'
import {
    createFeatureCollection,
    getPolygonLayers,
    darkSchemePolygonsColour,
    lightSchemePolygonsColour,
} from './mapHelpers'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { usePolygonCentroids } from 'hooks/polygons'

const MAP_BUFFER = 50
const ZOOM_CUTOFF = 6 // Hide items when zoomed out below this threshold

export const MultiProductPolygon = ({
    apiCall,
    polygons,
    allProducts,
    useFilteredProducts,
    useEditing,
    useActiveProduct,
    useDraftProduct,
    useUpdateDraftProduct,
    useAssignedPolygons,
    productSource,
    mutateProducts = () => { },
    secondaryMutate = () => { }, // Currently only used for Highway Forecasts
}) => {
    // Hooks
    const locale = useLocale()
    const labelsGeoJson = usePolygonCentroids()

    // Map params
    const map = useMap()
    const polygonsSource = 'polygons'
    const labelsSource = 'labels'
    const productLayerId = productSource
    const productSourceName = productSource + 's'
    const drawToolRef = useRef()

    // Store params
    const assignedPolygons = useAssignedPolygons()
    const filteredProducts = useFilteredProducts()
    const isEditing = useEditing()
    const activeProduct = useActiveProduct()
    const draftProduct = useDraftProduct()
    const updateDraftProduct = useUpdateDraftProduct()

    // Secondary data
    const { currentTheme } = useThemeSwitcher()
    const polygonsColour = currentTheme === 'light' ? lightSchemePolygonsColour : darkSchemePolygonsColour

    // Set zoom extents on initial load
    // TODO: We should pull this out into a generic map component/hook
    const zoomExtents = useCallback((polygons) => {
        if (process.env.REACT_APP_INITIAL_ZOOM) {
            map.fitBounds(JSON.parse(process.env.REACT_APP_INITIAL_ZOOM))
        } else {
            let bounds = bbox(polygons)
            map.fitBounds(bounds, { padding: MAP_BUFFER })
        }
    }, [])

    useEffect(() => {
        zoomExtents(polygons)
    }, [])

    // Zooms to the active polygon
    useEffect(() => {
        if (activeProduct && activeProduct.polygons.length > 0 && !isEditing) {
            const activeProductPolygons = {
                ...polygons,
                features: polygons.features.filter((polygon) => {
                    return activeProduct.polygons.includes(polygon.properties.id)
                }),
            }
            const bounds = bbox(activeProductPolygons)
            map.fitBounds(bounds, { padding: MAP_BUFFER })
        }

        if (!activeProduct) {
            zoomExtents(polygons)
        }
    }, [activeProduct, isEditing])

    // API calls
    const productPutCall = useCallback(
        async (data, locale) => {
            await apiCall(data, locale)
            // Optimistic update to prevent "newer version exists" error
            const newData = [...allProducts].map(product => {
                if (product.id === data.id) {
                    return {
                        ...data,
                        versionNumber: product.versionNumber + 1
                    }
                }

                return product
            })
            mutateProducts((data) => {
                return {
                    ...data,
                    data: newData
                }
            })
            secondaryMutate()
        },
        [allProducts, apiCall, mutateProducts, secondaryMutate]
    )

    const update = useCallback(
        (details) => {
            const data = { ...activeProduct }
            for (let d in details) {
                const detail = details[d]
                data[detail.key] = detail.value
            }
            productPutCall(data, locale)
        },
        [activeProduct, productPutCall, locale]
    )

    // Polygon selection
    const checkForExistingAssignment = useCallback(
        (polygons) => {
            const alreadyAssignedPolygons = assignedPolygons
                .filter((product) => product.id !== activeProduct.id)
                .map((product) => product.polygons)
                .flat()

            return polygons.filter((polygon) => !alreadyAssignedPolygons.includes(polygon))
        },
        [activeProduct, assignedPolygons]
    )

    const mergeClickedPolygonsWithExisting = useCallback(
        (ids) => {
            if (activeProduct) {
                const polygons = new Set(activeProduct.polygons)
                for (const id of ids) {
                    if (polygons.has(id)) {
                        polygons.delete(id)
                    } else {
                        polygons.add(id)
                    }
                }

                return Array.from(polygons)
            }
        },
        [activeProduct]
    )

    const mergeLassoedPolygonsWithExisting = useCallback(
        (ids, mode) => {
            if (activeProduct) {
                const polygons = new Set(activeProduct.polygons)
                if (mode === 'add') {
                    for (const id of ids) {
                        if (!polygons.has(id)) {
                            polygons.add(id)
                        }
                    }
                } else {
                    for (const id of ids) {
                        if (polygons.has(id)) {
                            polygons.delete(id)
                        }
                    }
                }

                return Array.from(polygons)
            }
        },
        [activeProduct]
    )

    const updateExistingOrDraftProduct = useCallback(
        (polygons) => {
            if (activeProduct && activeProduct.id) {
                const updateData = {
                    key: 'polygons',
                    value: polygons,
                }
                update([updateData])
            } else {
                updateDraftProduct({
                    ...draftProduct,
                    polygons,
                })
            }
        },
        [activeProduct, draftProduct, update, updateDraftProduct]
    )

    const clickPolygon = useCallback(
        (event) => {
            if (activeProduct && isEditing) {
                const features = event.target.queryRenderedFeatures(event.point, {
                    layers: [polygonsSource, polygonsSource + '-line'],
                })
                const ids = new Set(features.map((feature) => feature.properties.id))

                if (ids.size > 0) {
                    updateExistingOrDraftProduct(
                        mergeClickedPolygonsWithExisting(checkForExistingAssignment(Array.from(ids)))
                    )
                }
            }
        },
        [activeProduct, checkForExistingAssignment, isEditing, mergeClickedPolygonsWithExisting, updateExistingOrDraftProduct]
    )

    const lassoPolygon = useCallback(
        (event) => {
            const shiftHeld = window.event.shiftKey
            const mode = shiftHeld ? 'remove' : 'add'
            const [polygon] = event.features

            const ids = polygons.features
                .filter((feature) => intersect(feature, polygon))
                .map((feature) => feature.properties.id)

            updateExistingOrDraftProduct(mergeLassoedPolygonsWithExisting(checkForExistingAssignment(ids), mode))

            drawToolRef.current.deleteAll()
        },
        [polygons.features, updateExistingOrDraftProduct, mergeLassoedPolygonsWithExisting, checkForExistingAssignment]
    )

    // Update map layers based on filters; note that polygon and label layers don't need to be updated
    useEffect(() => {
        if (map.getSource(productSourceName)) {
            map.getSource(productSourceName).setData(
                createFeatureCollection([...filteredProducts, draftProduct], polygons)
            )
        }
    }, [draftProduct, filteredProducts, map, polygons, productSourceName])

    useEffect(() => {
        drawToolRef.current = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
                polygon: true,
            },
            modes: Object.assign(MapboxDraw.modes, {
                draw_polygon: FreeDraw,
            }),
        })
        map.addControl(drawToolRef.current)

        return () => {
            // HACK: Check if the drawTool exists before removing it to prevent crash during navigation
            if (map.hasControl(drawToolRef.current)) {
                map.removeControl(drawToolRef.current)
            }
        }
    }, [])

    // Show/hide the lasso button
    useEffect(() => {
        const lassoButton = document.getElementsByClassName('mapbox-gl-draw_ctrl-draw-btn')
        if (!lassoButton) return null

        if (activeProduct && isEditing) {
            lassoButton[0].classList.remove('inactive')
        } else {
            lassoButton[0].classList.add('inactive')
        }
    }, [activeProduct, isEditing])

    // Add sources and layers for polygons
    useEffect(() => {
        // Adding the polygon source and layers to the map
        if (!map.getSource(polygonsSource)) {
            map.addSource(polygonsSource, { promoteId: 'id', type: 'geojson', data: polygons })
        }
        if (!map.getSource(labelsSource)) {
            map.addSource(labelsSource, { promoteId: 'id', type: 'geojson', data: labelsGeoJson })
        }
        if (!map.getLayer(polygonsSource)) {
            map.addLayer({
                id: polygonsSource,
                source: polygonsSource,
                type: 'fill',
                paint: {
                    'fill-color': polygonsColour,
                },
            })

            map.addLayer({
                id: polygonsSource + '-line',
                source: polygonsSource,
                type: 'line',
                paint: {
                    'line-color': polygonsColour,
                    'line-width': 1.5,
                },
            })

            map.addLayer({
                id: labelsSource + '-text',
                source: labelsSource,
                type: 'symbol',
                layout: {
                    'text-field': ['get', 'display_name'],
                    'text-size': 10,
                },
                paint: {
                    'text-color': currentTheme === 'light' ? '#000' : '#fff',
                    'text-halo-color': currentTheme === 'light' ? '#fff' : '#000',
                    'text-halo-width': 2,
                    'text-halo-blur': 2,
                },
                minzoom: ZOOM_CUTOFF,
            })
        }

        // Adding the product source and layers to the map
        if (!map.getSource(productSourceName)) {
            map.addSource(productSourceName, {
                type: 'geojson',
                data: createFeatureCollection(filteredProducts, polygons),
                promoteId: 'polygonId',
            })
        }
        for (const layer of getPolygonLayers(productSourceName, productLayerId)) {
            if (!map.getLayer(layer.id)) {
                map.addLayer(layer)
            }
        }
    }, [])

    useEffect(() => {
        // Add listeners only once to prevent repeated calls
        map.on('click', clickPolygon)
        map.on('draw.create', lassoPolygon)

        return () => {
            map.off('click', clickPolygon)
            map.off('draw.create', lassoPolygon)
        }
    }, [activeProduct, clickPolygon, isEditing, map, lassoPolygon])

    // Highlight/unhighlight the active product
    useEffect(() => {
        if (activeProduct) {
            const { polygons } = activeProduct

            for (let id of polygons) {
                map.setFeatureState({ source: productSourceName, id }, { hover: true })
                map.setFeatureState({ source: productSourceName, id: id + '-line' }, { hover: true })
            }
        } else {
            map.removeFeatureState({ source: productSourceName })
        }
    }, [activeProduct, map])

    return null
}
