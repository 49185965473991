import React from 'react'

import { FormattedMessage } from 'react-intl'

import { useDateFormat } from 'stores/UserStore'
import { formatDate } from 'utils/Date'
import messages from 'services/intl/messageDefinitions'

export const ProductStatusBarText = ({ product }) => {
    const dateFormat = useDateFormat()
    const { name, expiryDateTime, issueDateTime, lastModifiedDateTime } = product || {}
    const formattedExpiryDate = formatDate(expiryDateTime, dateFormat)
    const formattedIssueDate = formatDate(issueDateTime, dateFormat)
    const formattedLastModifiedDateTime = formatDate(lastModifiedDateTime, dateFormat + ' HH:mm')

    return (
        <>
            <strong>{name}</strong>
            <span>
                <FormattedMessage {...messages.expiry} /> {formattedExpiryDate}
            </span>
            <span>
                <FormattedMessage {...messages.issuedStatusBar} /> {formattedIssueDate}
            </span>
            <span>({formattedLastModifiedDateTime})</span>
        </>
    )
}
