import { useEffect } from 'react'

import { useMap } from 'components/Map/Map'
import bbox from '@turf/bbox'
import { createFeatureCollection, getPolygonLayers } from 'components/Map/mapHelpers'
import { ArchiveMitigationIconSet } from './ArchiveMitigationIconSet'

const MAP_BUFFER = 50

export const ActivePolygonProduct = ({ product, polygons }) => {
    const map = useMap()
    const source = 'activePolygonProduct'

    // Add source and layers
    useEffect(() => {
        if (!map.getSource(source)) {
            map.addSource(source, {
                type: 'geojson',
                data: createFeatureCollection([product], polygons),
                promoteId: 'polygonId',
            })
        }
        for (const layer of getPolygonLayers(source, product.id)) {
            if (!map.getLayer(layer.id)) {
                map.addLayer(layer)
            }
        }

        // Cleanup on unmount, necessary to remove items after unselection (panel close)
        return () => {
            try {
                for (const layer of getPolygonLayers(source, product.id)) {
                    if (map.getLayer(layer.id)) {
                        map.removeLayer(layer.id)
                    }
                }
                if (map.getSource(source)) {
                    map.removeSource(source)
                }
            } catch (error) {
                // here ot prevent app crash when switching from archive viewing an AvFx to AvFx tab
            }
        }
    }, [])

    // Update map layers when the product changes
    useEffect(() => {
        if (map.getSource(source)) {
            map.getSource(source).setData(createFeatureCollection([product], polygons))
        }
    }, [map, polygons, product])

    // Zoom to the active product's polygons
    useEffect(() => {
        if (product && product.polygons.length > 0) {
            const activeItemPolygons = {
                ...polygons,
                features: polygons.features.filter((polygon) => {
                    return product.polygons.includes(polygon.properties.id)
                }),
            }
            const bounds = bbox(activeItemPolygons)
            map.fitBounds(bounds, { padding: MAP_BUFFER })
        }
    }, [map, polygons, product])

    if (product.type === 'mitigation') {
        return <ArchiveMitigationIconSet mitigation={product} polygons={polygons} />
    }
}
