import { useCallback, useState } from 'react'

import { useSearchParams } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { Table, Modal, Button, Tooltip } from 'antd'
import { CopyOutlined } from '@ant-design/icons'

import messages from 'services/intl/messageDefinitions'
import { useActiveProduct, useUpdateActiveProduct } from 'stores/ArchiveStore'
import {
    useAvalancheForecastsResults,
    useSpecialProductsResults,
    useRegionalDiscussionsResults,
    useHighwayForecastsResults,
    useHighwayDiscussionsResults,
    useMitigationResults,
} from 'hooks/archive'
import { useSelectedPolygonId } from 'stores/ArchiveStore'
import { formatDate } from 'utils/Date'
import { useDateFormat, useLocale } from 'stores/UserStore'
import { cloneProduct } from 'services/Products'
import { API_SUCCESS_CODES, PRODUCT_TYPE_ROUTING_MAP } from 'utils/Constants'
import { sendErrorNotification } from 'utils/Notifications'

import './ArchiveTable.css'

const TIME_FORMAT = 'HH:mm'

export const ArchiveTable = () => {
    const [searchParams, setSearchParams] = useSearchParams({})
    const intl = useIntl()
    const dateFormat = useDateFormat()
    const locale = useLocale()

    const activeProduct = useActiveProduct()
    const setActiveProduct = useUpdateActiveProduct()
    const selectedPolygonId = useSelectedPolygonId()

    const [cloneError, setCloneError] = useState(false)
    const [productToBeCloned, setProductToBeCloned] = useState(null)

    const { data: avFxResults } = useAvalancheForecastsResults()
    const avalancheForecastsResults = avFxResults?.status === 200 ? avFxResults?.data || [] : []

    const { data: RDResults } = useRegionalDiscussionsResults()
    const regionalDiscussionsResults = RDResults?.status === 200 ? RDResults?.data || [] : []

    const { data: SPResults } = useSpecialProductsResults()
    const specialProductsResults = SPResults?.status === 200 ? SPResults?.data || [] : []

    const { data: hwyFxResults } = useHighwayForecastsResults()
    const highwayForecastsResults = hwyFxResults?.status === 200 ? hwyFxResults?.data || [] : []

    const { data: hwyDiscResults } = useHighwayDiscussionsResults()
    const highwayDiscussionsResults = hwyDiscResults?.status === 200 ? hwyDiscResults?.data || [] : []

    const { data: mitResults } = useMitigationResults()
    const mitigationsResults = mitResults?.status === 200 ? mitResults?.data || [] : []

    const handleCloneClick = useCallback(async (id) => {
        setProductToBeCloned(null)
        const response = await cloneProduct(id, locale)
        if (API_SUCCESS_CODES.includes(response.status)) {
            const productType = response.data?.type
            const productTypeUrl = PRODUCT_TYPE_ROUTING_MAP.get(productType)
            if (productTypeUrl) {
                window.open(`/${productTypeUrl}?focus=${response.data.id}`)
            } else {
                sendErrorNotification(intl.formatMessage({ ...messages.productCloneNewWindowError }))
            }
        } else {
            if (response.data && response.data.message === 'Polygons are assigned to draft product') {
                setCloneError(true)
            } else {
                sendErrorNotification(intl.formatMessage({ ...messages.productCloneError }))
            }
        }
    })

    const handleRow = (record) => {
        return {
            onClick: () => {
                setSearchParams({
                    focus: record.key,
                    type: record.type,
                })
                setActiveProduct(record.product)
            },
        }
    }

    // Create columns for forecaster, type, issue date, expiry date, and actions
    const columns = [
        {
            title: <FormattedMessage {...messages['archive.titles.forecaster']} />,
            dataIndex: 'forecaster',
            key: 'forecaster',
            sorter: (a, b) => a.forecaster.localeCompare(b.forecaster),
        },
        {
            title: <FormattedMessage {...messages['archive.titles.productType']} />,
            dataIndex: 'type',
            key: 'type',
            sorter: (a, b) => a.type.localeCompare(b.type),
        },
        {
            title: <FormattedMessage {...messages['archive.titles.issueDate']} />,
            dataIndex: 'issueDate',
            key: 'issueDate',
            render: (text) => {
                return <Tooltip title={formatDate(text, TIME_FORMAT)}>{formatDate(text, dateFormat)}</Tooltip>
            },
            sorter: (a, b) => a.issueDate.localeCompare(b.issueDate),
            defaultSortOrder: 'ascend',
        },
        {
            title: <FormattedMessage {...messages['archive.titles.expiryDate']} />,
            dataIndex: 'expiry',
            key: 'expiry',
            render: (expiry) => {
                const expiryDateTime = expiry[0]
                if (expiry[1]) {
                    const originalExpiryDateTime = expiry[1]
                    const toolTipText = (
                        <FormattedMessage
                            id="archive.expiry.tooltip"
                            defaultMessage={'Original expiry date: {originalTime}{br}Manually expired: {manualTime}'}
                            values={{
                                originalTime: formatDate(originalExpiryDateTime, dateFormat + ' ' + TIME_FORMAT),
                                manualTime: formatDate(expiryDateTime, dateFormat + ' ' + TIME_FORMAT),
                                br: <br />,
                            }}
                            description={'Archive tooltip expiry dates'}
                        />
                    )
                    return <Tooltip title={toolTipText}>{formatDate(expiry[0], dateFormat)}*</Tooltip>
                } else {
                    return (
                        <Tooltip title={formatDate(expiry[0], TIME_FORMAT)}>
                            {formatDate(expiry[0], dateFormat)}
                        </Tooltip>
                    )
                }
            },
            sorter: (a, b) => a.expiry[0].localeCompare(b.expiry[0]),
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
        },
    ]

    // Create data for each row
    const allData = [
        ...avalancheForecastsResults,
        ...specialProductsResults,
        ...regionalDiscussionsResults,
        ...highwayForecastsResults,
        ...highwayDiscussionsResults,
        ...mitigationsResults,
    ]
        .filter((product) => {
            if (selectedPolygonId) {
                return product.polygons.includes(selectedPolygonId)
            } else {
                return true
            }
        })
        .map((product) => {
            return {
                key: product.id,
                forecaster: product.userHandle,
                type: intl.formatMessage({ ...messages[product?.type.toLowerCase()] }),
                issueDate: product.issueDateTime,
                expiry: product.originalExpiryDateTime
                    ? [product.expiryDateTime, product.originalExpiryDateTime]
                    : [product.expiryDateTime], // An array is required for the cell render function
                actions: <CopyOutlined onClick={() => setProductToBeCloned(product)} />,
                product,
            }
        })

    return (
        <>
            <Table
                pagination={false}
                dataSource={allData}
                columns={columns}
                onRow={handleRow}
                rowClassName={(record) => record.key === activeProduct?.id && 'activeProduct'}
            />
            <Modal
                title={<FormattedMessage {...messages.cloneConfirmationTitle} />}
                visible={productToBeCloned !== null}
                onCancel={() => setProductToBeCloned(null)}
                okText={<FormattedMessage {...messages.cloneConfirm} />}
                cancelText={<FormattedMessage {...messages.cancel} />}
                onOk={() => handleCloneClick(productToBeCloned?.id)}
                zIndex={1001}
            >
                <p>
                    <FormattedMessage
                        {...messages.cloneConfirmationmessage}
                        values={{ name: productToBeCloned?.name }}
                    />
                </p>
            </Modal>
            <Modal
                title={<FormattedMessage {...messages.clonePolygonError} />}
                visible={cloneError}
                onCancel={() => setCloneError(false)}
                okText={<FormattedMessage {...messages.ok} />}
                footer={
                    <Button type="primary" onClick={() => setCloneError(false)}>
                        <FormattedMessage {...messages.ok} />
                    </Button>
                }
            >
                <p>
                    <FormattedMessage {...messages.productCloneErrorPolygons} />
                </p>
            </Modal>
        </>
    )
}
