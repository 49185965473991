import React, { useEffect, useCallback } from 'react'

import { useSearchParams } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { FILTER_OPTIONS } from 'utils/Product'
import { usePolygons } from 'hooks/polygons'
import messages from 'services/intl/messageDefinitions'
import { useLocale } from 'stores/UserStore'
import { MitigationStore } from 'stores/MitigationStore'
import { Map, WithMapReady } from 'components/Map/Map'
import { Sidebar } from 'components/Sidebar/Sidebar'
import { Details } from 'components/Highways/Mitigations/Details'
import { Summary } from 'components/Product/Summary'
import { CreateForm } from 'components/Highways/Mitigations/CreateForm'
import { MultiProductPolygon } from 'components/Map/MultiProductPolygon'
import { TwoColLayoutPrimaryRight } from 'components/TwoColumnLayout/TwoColLayoutPrimaryRight'
import { UnassignedPolygons } from 'components/Map/UnassignedPolygons'
import { putMitigation, cloneAllMitigations } from 'services/Mitigation'
import { MapStatusBar } from 'components/MapStatusBar/MapStatusBar'
import { ProductStatusBarText } from 'components/Product/ProductStatusBarText'
import { CloneAllButton } from 'components/Product/CloneAllButton'
import { Accessibility } from 'components/Accessibility'
import { MultipleProductsTable } from 'components/Map/Accessibility/MultipleProductsTable'
import { MitigationIconSet } from 'components/Map/MitigationIconSet'
import { useActiveMitigation, useMitigations, useFilteredMitigations, useUnassignedPolygons } from 'hooks/mitigations'

export const Mitigation = () => {
    const [queryParams] = useSearchParams()
    const locale = useLocale()
    const polygons = usePolygons()
    const filters = MitigationStore.useFilters()
    const addFilter = MitigationStore.useAddFilter()
    const setActiveMitigationId = MitigationStore.useSetActiveProductId()
    const activeMitigation = useActiveMitigation()
    const unassignedCount = useUnassignedPolygons()
    const queryID = queryParams.get('focus') || ''
    const disableSetupButton = filters[0] !== 'draft'
    const intl = useIntl()
    const { mitigations, mutate } = useMitigations()

    useEffect(() => {
        document.title = intl.formatMessage(messages.mitigation)
    }, [])

    useEffect(() => {
        if (queryID) {
            setActiveMitigationId(queryID)
        }
        filters.forEach((filter) => {
            // default filter
            addFilter(filter)
        })
        if (activeMitigation && activeMitigation.id === queryID) {
            addFilter(activeMitigation.status)
        }
    }, [mitigations])

    const standardA11yComponent = (
        <>
            <Map locale={locale}>
                <WithMapReady>
                    {polygons && polygons.features && (
                        <>
                            <MultiProductPolygon
                                useEditing={MitigationStore.useEditingProductSetup}
                                useActiveProduct={useActiveMitigation}
                                apiCall={putMitigation}
                                mutateProducts={mutate}
                                polygons={polygons}
                                useDraftProduct={MitigationStore.useDraftProduct}
                                useUpdateDraftProduct={MitigationStore.useUpdateDraftProduct}
                                allProducts={mitigations}
                                useFilteredProducts={useFilteredMitigations}
                                useAssignedPolygons={useFilteredMitigations}
                                productSource="mitigation"
                            />
                            <MitigationIconSet polygons={polygons} />
                        </>
                    )}
                </WithMapReady>
            </Map>
            <UnassignedPolygons unassignedCount={unassignedCount} />
            {activeMitigation && (
                <MapStatusBar product={activeMitigation} text={<ProductStatusBarText product={activeMitigation} />} />
            )}
        </>
    )

    const enhancedA11yComponent = polygons && polygons.features && (
        <MultipleProductsTable
            useEditing={MitigationStore.useEditingProductSetup}
            unassignedCount={unassignedCount}
            useActiveItem={useActiveMitigation}
            useProducts={useFilteredMitigations}
            putRequest={putMitigation}
            mutateProducts={mutate}
            polygons={polygons}
            useDraftItem={MitigationStore.useDraftProduct}
            useUpdateDraftItem={MitigationStore.useUpdateDraftProduct}
        />
    )

    return (
        <TwoColLayoutPrimaryRight
            left={
                <Accessibility
                    standardA11yComponent={standardA11yComponent}
                    enhancedA11yComponent={enhancedA11yComponent}
                />
            }
            right={
                <Sidebar
                    title={'mitigation'}
                    emptyMessage={'noMitigationsFound'}
                    Details={Details}
                    Summary={Summary}
                    dataProvider={useFilteredMitigations}
                    filterProvider={MitigationStore.useFilters}
                    listData={mitigations}
                    filterOptions={FILTER_OPTIONS}
                    activeFilters={filters}
                    useAddFilter={MitigationStore.useAddFilter}
                    useRemoveFilter={MitigationStore.useRemoveFilter}
                    useClearActiveID={MitigationStore.useClearActiveProductId}
                    createLabel={'createMitigation'}
                    disableSetupButton={disableSetupButton}
                    useSetActiveId={MitigationStore.useSetActiveProductId}
                    useSetEditing={MitigationStore.useSetEditingProductSetup}
                    useSetEditingContent={MitigationStore.useSetEditingProductContent}
                    useResetDraft={MitigationStore.useResetDraftProduct}
                    CreateForm={CreateForm}
                    mutateProducts={mutate}
                    useActiveItem={useActiveMitigation}
                    radioStyleFiltering={true}
                    buttons={
                        <CloneAllButton
                            mutateProducts={mutate}
                            useFilters={MitigationStore.useFilters}
                            useAddFilter={MitigationStore.useAddFilter}
                            cloneAllApiCall={cloneAllMitigations}
                            buttonId={'cloneAllButton'}
                            confirmationText="cloneAllYes"
                        />
                    }
                ></Sidebar>
            }
        />
    )
}
