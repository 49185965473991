import React, { useState, useEffect } from 'react'

import { Button } from 'antd'

import { uuid } from 'utils/String'
import { useId, useUsername } from 'stores/UserStore'
import { useUpdateDraftLayer } from 'stores/WeakLayerStore'
import { RichTextEditor } from 'components/RichTextEditor/RichTextEditor'

export const CommentEditor = ({ item, update }) => {
    const [editorState, setEditorState] = useState('')
    const [valid, setValidity] = useState(false)
    const userID = useId()
    const username = useUsername()
    const updateDraftLayer = useUpdateDraftLayer()

    const postComment = () => {
        const newComment = {
            id: uuid(),
            userID,
            username,
            comment: editorState,
            datetime: new Date(),
        }
        const layerComments = [...item.comments, newComment]
        const details = [
            {
                key: 'comments',
                value: layerComments,
            },
        ]

        setEditorState('')

        if (update) {
            update(details)
        } else {
            // Update the draft layer instead
            const newDraftLayer = {
                ...item,
                comments: layerComments,
            }
            updateDraftLayer(newDraftLayer)
        }
    }

    useEffect(() => {
        if (editorState && editorState.length > 0) {
            setValidity(true)
        } else {
            setValidity(false)
        }
    }, [editorState])

    return (
        <>
            <RichTextEditor handleEditorStateChange={setEditorState} content={editorState} />
            <div style={styles.container}>
                <Button
                    data-test={'commentEditorPost'}
                    type="primary"
                    onClick={postComment}
                    style={styles.postButton}
                    disabled={!valid}
                >
                    Post
                </Button>
            </div>
        </>
    )
}

const styles = {
    editor: {
        border: '2px solid var(--grey-light)',
        borderRadius: '4px',
        marginBottom: 'var(--s1)',
    },
    postButton: {
        margin: '0 0 var(--s0) auto',
    },
    container: {
        display: 'flex',
    },
}
