import React, { useState, useCallback } from 'react'

import { FormattedMessage } from 'react-intl'
import { Button, Modal } from 'antd'

import { useLocale } from 'stores/UserStore'
import { useSendNotification } from 'utils/Notifications'
import messages from 'services/intl/messageDefinitions'

export const CloneAllButton = ({
    mutateProducts = () => {},
    secondaryMutate = () => {},
    useFilters,
    useAddFilter,
    cloneAllApiCall,
    buttonId,
    confirmationText,
}) => {
    const [modalVisible, setModalVisibilty] = useState(false)
    const locale = useLocale()
    const filters = useFilters()
    const addFilter = useAddFilter()
    const sendNotification = useSendNotification()
    const disabled = filters[0] !== 'live'

    const cloneAll = useCallback(async () => {
        await cloneAllApiCall(locale)
        sendNotification(<FormattedMessage {...messages.cloneAllSuccess} />)
        mutateProducts()
        secondaryMutate()
        addFilter('draft')
    }, [addFilter, cloneAllApiCall, locale, mutateProducts, secondaryMutate, sendNotification])

    const handleCloneAll = () => {
        cloneAll()
        setModalVisibilty(false)
    }

    return (
        <>
            <Button
                disabled={disabled}
                onClick={() => setModalVisibilty(true)}
                type="primary"
                data-test={'cloneAllButton'}
            >
                <FormattedMessage {...messages[buttonId]} />
            </Button>
            <Modal
                title={<FormattedMessage {...messages.warning} />}
                visible={modalVisible}
                onOk={() => handleCloneAll()}
                onCancel={() => setModalVisibilty(false)}
                okText={<FormattedMessage {...messages[confirmationText]} />}
                cancelText={<FormattedMessage {...messages.cancel} />}
                data-test={'deleteModal'}
            >
                <FormattedMessage {...messages.cloneAllWarning} />
            </Modal>
        </>
    )
}
