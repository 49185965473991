import { openDB } from 'idb'

const DB_NAME = 'highwayDiscussionImages'
const STORE_NAME = 'images'

export const initDB = async () => {
    return openDB(DB_NAME, 1, {
        upgrade(db) {
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                db.createObjectStore(STORE_NAME, { keyPath: 'groupID' })
            }
        },
    })
}

export const saveImage = async (image, groupID) => {
    const db = await initDB()
    await db.put(STORE_NAME, { image, groupID })
}

export const getImages = async () => {
    const db = await initDB()
    return db.getAll(STORE_NAME)
}

export const clearImages = async () => {
    const db = await initDB()
    await db.clear(STORE_NAME)
}
