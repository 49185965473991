import useSWR from 'swr'

import { useLocale } from 'stores/UserStore'
import { fetchSettings } from 'services/Settings'

export const useSettings = () => {
    const language = useLocale()
    const key = ['settings', language]

    return useSWR(key, () => fetchSettings(language))
}

export const useCharacterLimits = () => {
    const { data } = useSettings()

    return data?.data?.characterLimits ? data.data.characterLimits : {}
}
