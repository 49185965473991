import { useState } from 'react'

import { FormattedMessage } from 'react-intl'
import { Card, Select, Space, Form } from 'antd'

import { useContent } from 'stores/ForecastStore'
import { EditScreenLayout } from 'components/EditScreenLayout/EditScreenLayout'
import messages from 'services/intl/messageDefinitions'
import { HIGHWAY_HAZARD_OPTIONS } from 'utils/HighwayContent'
import { Swatch } from 'components/Swatch/Swatch'

export const HighwayHazard = ({ update, compressed }) => {
    const content = useContent()
    const [hazard, setHazard] = useState(content.hazard)

    const handleHazardChange = (hazard) => {
        setHazard(hazard)

        update({
            ...content,
            hazard: hazard,
        })
    }

    return (
        <>
            <EditScreenLayout showDaySelector={false} compressed={compressed}>
                <form style={styles.wrapper}>
                    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                        <Card title={<FormattedMessage {...messages.highwayHazard} />}>
                            <Form.Item
                                label={<FormattedMessage {...messages.highwayHazard} />}
                                style={styles.hazardField}
                            >
                                <Select value={hazard} style={styles.select} onChange={handleHazardChange}>
                                    {[...HIGHWAY_HAZARD_OPTIONS.entries()].map(([rating, colour]) => {
                                        return (
                                            <Select.Option value={rating} key={rating}>
                                                <Swatch colour={colour} />
                                                <FormattedMessage {...messages[`highwayHazard.${rating}`]} />
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Card>
                    </Space>
                </form>
            </EditScreenLayout>
        </>
    )
}

const styles = {
    wrapper: {
        margin: 'var(--s0)',
    },
    hazardField: {
        marginBottom: 0,
    },
    select: {
        width: '140px',
    },
}
