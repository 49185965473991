import { useState } from 'react'

import { DatePicker } from 'antd'
import moment from 'moment'
import { useIntl } from 'react-intl'

import { useDateFormat } from 'stores/UserStore'
import { useDateRange, useUpdateDateRange } from 'stores/ArchiveStore'
import { sendErrorNotification } from 'utils/Notifications'
import messages from 'services/intl/messageDefinitions'

export const LimitedRangePicker = ({ rangeLimit = 31 }) => {
    const { RangePicker } = DatePicker
    const intl = useIntl()

    const dateFormat = useDateFormat()

    const [dates, setDates] = useState(null)
    const value = useDateRange()
    const setValue = useUpdateDateRange()

    const disabledDate = (current) => {
        if (!dates) {
            return false
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') >= rangeLimit
        const tooEarly = dates[1] && dates[1].diff(current, 'days') >= rangeLimit
        return !!tooEarly || !!tooLate || current > moment().endOf('day')
    }

    const onOpenChange = (open) => {
        if (open) {
            setDates([null, null])
        } else {
            setDates(null)
        }
    }

    const handleDateChange = (val) => {
        if (val[0] && val[1]) {
            // Check if the range is too big
            if (val[1].diff(val[0], 'days') >= rangeLimit) {
                // If so, set the start date to the end date minus the range limit
                val[0] = val[1].clone().subtract(rangeLimit - 1, 'days')

                // Send notification to user
                sendErrorNotification(
                    intl.formatMessage(
                        { ...messages.dateRangeError },
                        { rangeLimit, startDate: val[0].format(dateFormat) }
                    )
                )
            }
        }

        setValue(val)
    }

    return (
        <RangePicker
            value={dates || value}
            disabledDate={disabledDate}
            onCalendarChange={(val) => {
                setDates(val)
            }}
            onChange={(val) => {
                handleDateChange(val)
            }}
            onOpenChange={onOpenChange}
            changeOnBlur
            allowClear={false}
            format={`${dateFormat} HH:mm`}
            showTime={{ format: 'HH:mm' }}
            minuteStep={15}
        />
    )
}
