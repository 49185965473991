import React, { useCallback, useState, useEffect } from 'react'

import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Divider, Typography, Row, Col, Modal } from 'antd'

import { useLocale } from 'stores/UserStore'
import { MitigationStore } from 'stores/MitigationStore'
import { Stat } from 'components/Stat/Stat'
import { Date as DateDisplay } from 'components/Date/Date'
import { PolygonList } from 'components/PolygonList/PolygonList'
import { CreateForm } from './CreateForm'
import { DeleteButton } from 'components/DeleteButton/DeleteButton'
import {
    putMitigation,
    cloneMitigation,
    publishMitigation,
    deleteMitigation,
    expireMitigationNow,
} from 'services/Mitigation'
import { useSendNotification, sendErrorNotification } from 'utils/Notifications'
import messages from 'services/intl/messageDefinitions'
import { API_SUCCESS_CODES } from 'utils/Constants'
import { usePolygons } from 'hooks/polygons'
import { useMitigations } from 'hooks/mitigations'

export const Details = ({ item, closeAndReload, closeAndOpenCloned }) => {
    // External imports
    const { Title } = Typography
    const intl = useIntl()

    // User store data
    const locale = useLocale()

    // Store data
    const editingProductSetup = MitigationStore.useEditingProductSetup()
    const setEditingProductSetup = MitigationStore.useSetEditingProductSetup()
    const polygons = usePolygons()
    const { mutate } = useMitigations()

    // Local state
    const [cloneError, setCloneError] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [expireModalVisible, setExpireModalVisible] = useState(false)

    // Notification
    const sendNotification = useSendNotification()

    const updateMitigation = useCallback(async (data, locale) => {
        const response = await putMitigation(data, locale)
        if (API_SUCCESS_CODES.includes(response.status)) {
            sendNotification(intl.formatMessage({ ...messages.mitigationSaved }, { name: data.name }))
            mutate()
        } else {
            sendNotification(intl.formatMessage({ ...messages.mitigationSaveFailed }, { name: data.name }))
        }
    }, [])

    const cloneGivenMitigation = useCallback(async (item, locale) => {
        const response = await cloneMitigation(item.id, locale)
        if (API_SUCCESS_CODES.includes(response.status)) {
            closeAndOpenCloned(response.data.id)
        } else {
            if (response.data && response.data.message === 'Polygons are assigned to draft product') {
                setCloneError(true)
            } else {
                sendErrorNotification(intl.formatMessage({ ...messages.productCloneError }))
            }
        }
    }, [])

    const publish = useCallback(async (item) => {
        await publishMitigation(item.id, locale)
        mutate()
    }, [])

    const deleteGivenMitigation = useCallback(async (id, locale) => {
        await deleteMitigation(id, locale)
        sendNotification(intl.formatMessage({ ...messages.mitigationDeleted }, { name: item.name }))
        setDeleteModalVisible(false)
        closeAndReload()
    }, [])

    const expireProduct = async (product, locale) => {
        const response = await expireMitigationNow(product.id, locale)
        if (API_SUCCESS_CODES.includes(response.status)) {
            sendNotification(intl.formatMessage({ ...messages.productExpiredNow }, { name: product.name }))
            setExpireModalVisible(false)
            closeAndReload(response.data)
        } else {
            sendErrorNotification(
                intl.formatMessage({
                    ...messages.expireNowError,
                })
            )
        }
    }

    // Updates the various fields in the product
    // details should be in the format: [{key: <field to be changed> value: <updated values>}, {...}]
    const update = (details) => {
        const data = { ...item }
        for (let d in details) {
            const detail = details[d]
            data[detail.key] = detail.value
        }

        updateMitigation(data, locale)
    }

    const toggleEdit = () => {
        setEditingProductSetup(!editingProductSetup)
    }

    const detailsView = (
        <div style={styles.detailsBody}>
            <Title level={2} style={styles.title}>
                {item.name}
            </Title>
            <div style={styles.header}>
                <div>{item.username}</div>
                <DateDisplay label={'lastModified'} time={true} value={item.lastModifiedDateTime} />
            </div>
            <div style={styles.detailsContainer}>
                <Divider orientation="left" plain>
                    <FormattedMessage {...messages.details} />
                </Divider>
                <Row gutter={8}>
                    <Col style={styles.detailCol} span={12}>
                        <Stat label={'issueDateTime'} value={item.issueDateTime} type={'date'} time={true} />
                    </Col>
                    <Col style={styles.detailCol} span={12}>
                        <Stat label={'expiryDateTime'} value={item.expiryDateTime} type={'date'} time={true} />
                    </Col>
                    {item.originalExpiryDateTime && (
                        <Col style={styles.detailCol} span={12}>
                            <Stat
                                label={'originalExpiryDateTime'}
                                value={item.originalExpiryDateTime}
                                type={'date'}
                                time={true}
                            />
                        </Col>
                    )}
                </Row>
                <PolygonList
                    polygonList={item.polygons}
                    colour={item.colour}
                    polygons={polygons}
                />
            </div>
            {item.status === 'draft' && (
                <>
                    <Row style={styles.buttonContainer}>
                        <Button onClick={toggleEdit}>
                            <FormattedMessage {...messages.editSetup} />
                        </Button>
                        <Button onClick={() => cloneGivenMitigation(item, locale)}>
                            <FormattedMessage {...messages.cloneToDraft} />
                        </Button>
                        <Button type="primary" onClick={() => publish(item)}>
                            <FormattedMessage {...messages.publish} />
                        </Button>
                    </Row>
                    <DeleteButton setDeleteModalVisible={setDeleteModalVisible} buttonText={'deleteMitigation'} />
                </>
            )}
            {(item.status === 'completed' || item.status === 'live') && (
                <>
                    <Row style={styles.buttonContainer}>
                        <Button onClick={() => cloneGivenMitigation(item, locale)}>
                            <FormattedMessage {...messages.cloneToDraft} />
                        </Button>
                        <Button
                            onClick={() => setExpireModalVisible(true)}
                            disabled={item.status === 'completed' || new Date(item.expiryDateTime) < Date.now()}
                        >
                            <FormattedMessage {...messages.expireNow} />
                        </Button>
                    </Row>
                    <DeleteButton setDeleteModalVisible={setDeleteModalVisible} buttonText={'deleteMitigation'} />
                </>
            )}
        </div>
    )

    const editSetupView = (
        <>
            <CreateForm
                toggleEdit={toggleEdit}
                editing={editingProductSetup}
                update={update}
                initialValues={item}
                autoUpdate={true}
                closeAndReload={closeAndReload}
                setDeleteModalVisible={setDeleteModalVisible}
            />
        </>
    )

    // Show details by default; otherwise, show editSetup or editContent
    let contentView = detailsView

    if (editingProductSetup) {
        contentView = editSetupView
    }

    return (
        <>
            {contentView}
            <Modal
                title={<FormattedMessage {...messages.warning} />}
                visible={deleteModalVisible}
                onOk={() => deleteGivenMitigation(item.id, locale)}
                onCancel={() => setDeleteModalVisible(false)}
                okText={<FormattedMessage {...messages.yesConfirmDeleteMitigation} />}
                cancelText={<FormattedMessage {...messages.cancel} />}
            >
                <p>
                    <FormattedMessage {...messages.deleteMitigationConfirmation} values={{ name: item.name }} />
                </p>
            </Modal>
            <Modal
                title={<FormattedMessage {...messages.warning} />}
                visible={expireModalVisible}
                onOk={() => expireProduct(item, locale)}
                onCancel={() => setExpireModalVisible(false)}
                okText={<FormattedMessage {...messages.yesConfirmExpireNow} />}
                cancelText={<FormattedMessage {...messages.cancel} />}
            >
                <p>
                    <FormattedMessage {...messages.expireProductConfirmation} values={{ name: item.name }} />
                </p>
            </Modal>
            <Modal
                title={<FormattedMessage {...messages.clonePolygonError} />}
                visible={cloneError}
                onCancel={() => setCloneError(false)}
                okText={<FormattedMessage {...messages.ok} />}
                footer={
                    <Button type="primary" onClick={() => setCloneError(false)}>
                        <FormattedMessage {...messages.ok} />
                    </Button>
                }
            >
                <p>
                    <FormattedMessage {...messages.productCloneErrorPolygons} />
                </p>
            </Modal>
        </>
    )
}

const styles = {
    detailsBody: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
    },
    title: {
        fontWeight: '400',
        flex: '0 1 auto',
    },
    header: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 'var(--s-1)',
        justifyContent: 'space-between',
        flex: '0 1 auto',
    },
    detailsContainer: {
        marginTop: 'var(--s0)',
    },
    detailCol: {
        marginBottom: 'var(--s0)',
    },
    buttonContainer: {
        marginTop: '25px',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 'var(--s0)',
    },
}
