import React, { useCallback, useState } from 'react'

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Col, Collapse, DatePicker, Row, Switch, Typography, Upload } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import moment from 'moment'

import { Select } from 'components/Dropdown/Select'
import { TextField } from 'components/TextField/TextField'
import { TranslatedTextField } from 'components/TextField/TranslatedTextField'
import messages from 'services/intl/messageDefinitions'
import { generateCloudinaryThumbnailUrl, TAGS } from 'utils/Image'
import { sendErrorNotification } from 'utils/Notifications'
import {
    addTranslationToImageCaption,
    removeImage,
    removeTranslationFromImageCaption,
    supportedUrl,
    updateImageCaption,
    updateImageField,
} from './helpers'
import './ImageForm.css'
import { TranslatedSimpleTextField } from 'components/TextField/TranslatedSimpleTextField'

export const ImageForm = ({ image, index, count, apiPut, product, view }) => {
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const disabled = !image.url

    const updateProduct = useCallback(async (data) => {
        await apiPut(data, view)
    }, [])

    // Click handlers
    const handleRemoveImageClick = () => {
        const updatedProduct = removeImage(product, image.id)
        updateProduct(updatedProduct)
    }

    const handleTagChange = (value) => {
        const updatedProduct = updateImageField(product, image.id, { tag: value })
        updateProduct(updatedProduct)
    }

    const handleCreditChange = (value) => {
        const updatedProduct = updateImageField(product, image.id, { credit: value })
        updateProduct(updatedProduct)
    }

    const handleCaptionChange = (language, value) => {
        const updatedProduct = updateImageCaption(product, image.id, language, value)
        updateProduct(updatedProduct)
    }

    const handleAddCaptionLanguage = (language) => {
        const updatedProduct = addTranslationToImageCaption(product, image.id, language)
        updateProduct(updatedProduct)
    }

    const handleRemoveCaptionLanguage = (language) => {
        const updatedProduct = removeTranslationFromImageCaption(product, image.id, language)
        updateProduct(updatedProduct)
    }

    const handleAltTextChange = (language, value) => {
        const updatedProduct = updateImageField(product, image.id, { altText: { ...image.altText, [language]: value } })
        updateProduct(updatedProduct)
    }

    const handleAddAltTextLanguage = (language) => {
        const updatedProduct = updateImageField(product, image.id, { altText: { ...image.altText, [language]: '' } })
        updateProduct(updatedProduct)
    }

    const handleRemoveAltTextLanguage = (language) => {
        const updatedProduct = updateImageField(product, image.id, { altText: { ...image.altText, [language]: null } })
        updateProduct(updatedProduct)
    }

    const handleUrlChange = (value) => {
        if (!isValidUrl(value)) {
            sendErrorNotification(intl.formatMessage({ ...messages.invalidImageUrl }))
            return
        }

        const updatedProduct = updateImageField(product, image.id, { url: value, supportedUrl: supportedUrl(value) })
        updateProduct(updatedProduct)
    }

    const handleDateTakenChange = (value) => {
        const updatedProduct = updateImageField(product, image.id, { dateTaken: value })
        updateProduct(updatedProduct)
    }

    const handleArchivedPhotoChange = (value) => {
        const updatedProduct = updateImageField(product, image.id, { isArchived: value })
        updateProduct(updatedProduct)
    }

    // Image uploading
    const uploadImage = (options) => {
        const { onSuccess, onError, file } = options
        const data = new FormData()

        data.append('file', file)
        data.append('upload_preset', process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET)
        data.append('cloud_name', process.env.REACT_APP_CLOUDINARY_NAME)

        fetch(process.env.REACT_APP_CLOUDINARY_BASE_URL, {
            method: 'post',
            body: data,
        })
            .then((response) => {
                onSuccess(file)
                return response.json()
            })
            .then((response) => {
                // Get url from Cloudinary response
                const { url, width, height } = response
                // TODO: error handling not working as POST errors are getting intercepted
                if (response && response.error) {
                    throw new Error(response.error.message)
                }
                const updatedProduct = updateImageField(product, image.id, {
                    url,
                    supportedUrl: supportedUrl(url),
                    width,
                    height,
                })
                updateProduct(updatedProduct)
            })
            .catch((err) => {
                if (err && err.message && err.message === 'Invalid image file') {
                    sendErrorNotification(intl.formatMessage({ ...messages.invalidImageFile }))
                }
                sendErrorNotification(intl.formatMessage({ ...messages.imageUploadFailed }))
                onError(err)
            })
    }

    const handleImageUploadChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true)
            return
        }

        if (info.file.status === 'done') {
            setLoading(false)
        }
    }

    // Misc. components
    const uploadButton = (
        <div data-test={'imageUpload'}>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>
                <FormattedMessage {...messages.upload} />
            </div>
        </div>
    )

    const header = (
        <div style={styles.containerHeader}>
            {image.supportedUrl || image.url ? (
                <img src={generateCloudinaryThumbnailUrl(image.supportedUrl || image.url, 50, 50)} alt="thumbnail" />
            ) : null}
            <FormattedMessage {...messages.imageIndex} values={{ index: index + 1, count }} />
        </div>
    )

    const expanded = image.url ? null : 0 // Collapse if the image already exists to minimize scrolling

    return (
        <Collapse defaultActiveKey={expanded} className="image-form">
            <Collapse.Panel header={header}>
                <Row>
                    <Upload
                        listType="picture-card"
                        showUploadList={false}
                        customRequest={uploadImage}
                        onChange={handleImageUploadChange}
                    >
                        {image.supportedUrl || image.url ? (
                            <img src={image.supportedUrl || image.url} alt="thumbnail" style={styles.fullImage} />
                        ) : (
                            uploadButton
                        )}
                    </Upload>
                </Row>
                <Row style={styles.CldUrl}>
                    <TextField
                        value={image.url}
                        onChange={(value) => handleUrlChange(value)}
                        dataTest={'imageURL'}
                        autoUpdate={true}
                    />
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Row style={styles.labelRow}>
                            <FormattedMessage {...messages.credit} />
                        </Row>
                        <Row>
                            <TextField
                                disabled={disabled}
                                value={image.credit}
                                onChange={handleCreditChange}
                                dataTest={'imageCredit'}
                            />
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row style={styles.labelRow}>
                            <FormattedMessage {...messages.tag} />
                        </Row>
                        <Row>
                            <Select
                                value={image.tag}
                                disabled={disabled}
                                name="tag"
                                style={styles.select}
                                onChange={(value) => handleTagChange(value)}
                                data-test={'imageTag'}
                            >
                                {[...TAGS].map(({ label, value }) => (
                                    <Select.Option key={value} value={value}>
                                        <FormattedMessage {...messages[label]} />
                                    </Select.Option>
                                ))}
                            </Select>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Row style={styles.labelRow}>
                            <FormattedMessage {...messages.dateTaken} />
                        </Row>
                        <Row>
                            <DatePicker
                                disabled={disabled || image.isArchived}
                                value={moment(image.dateTaken)}
                                onChange={(value) => handleDateTakenChange(value)}
                                data-test={'imageDateTaken'}
                            />
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row style={styles.labelRow}>
                            <FormattedMessage {...messages.archivedPhoto} />
                        </Row>
                        <Row style={styles.alignItmesCenter}>
                            <Switch
                                disabled={disabled}
                                checked={image.isArchived}
                                onChange={(value) => handleArchivedPhotoChange(value)}
                                data-test={'arhivedPhoto'}
                            />
                        </Row>
                    </Col>
                </Row>
                <Row style={styles.translationHeader}>
                    <Typography.Title level={5}>
                        <FormattedMessage {...messages.altText} />
                    </Typography.Title>
                </Row>
                <TranslatedSimpleTextField
                    handleChange={handleAltTextChange}
                    handleAddTranslation={handleAddAltTextLanguage}
                    handleRemoveTranslation={handleRemoveAltTextLanguage}
                    translations={image.altText}
                />
                <Row style={styles.translationHeader}>
                    <Typography.Title level={5}>
                        <FormattedMessage {...messages.caption} />
                    </Typography.Title>
                </Row>
                <TranslatedTextField
                    handleChange={handleCaptionChange}
                    handleAddTranslation={handleAddCaptionLanguage}
                    handleRemoveTranslation={handleRemoveCaptionLanguage}
                    translations={image.caption}
                    plainText={true}
                />
                <Row style={styles.removeImageButtonRow}>
                    <Button danger style={styles.deleteButton} onClick={handleRemoveImageClick}>
                        <FormattedMessage {...messages.deleteImage} />
                    </Button>
                </Row>
            </Collapse.Panel>
        </Collapse>
    )
}

const styles = {
    wrapper: {
        marginBottom: 'var(--s-2)',
        padding: 'var(--s-2)',
        borderRadius: 'var(--radius)',
        backgroundColor: 'var(--grey-lighter)',
    },
    containerHeader: {
        display: 'inline-flex',
        gap: 'var(--s0)',
    },
    select: {
        width: '100%',
    },
    labelRow: {
        margin: 'var(--s-2) 0',
    },
    translationHeader: {
        marginTop: 'var(--s0)',
    },
    removeImageButtonRow: {
        marginTop: 'var(--s-1)',
    },
    deleteButton: {
        marginLeft: 'auto',
    },
    fullImage: {
        width: '100%',
        maxWidth: '500px',
    },
    CldUrl: {
        marginBottom: 'var(--s-1)',
        marginTop: 'var(--s-1)',
    },
    alignItmesCenter: {
        height: '32px',
        display: 'flex',
        alignItems: 'center',
    },
}

// Utils
// Must be a valid Cloudinary URL
const isValidUrl = (url) => {
    // Check that it has a cloudinary domain
    const regex = /^https?:\/\/res\.cloudinary\.com\/.*\/image\/upload\/.*$/
    if (!regex.test(url)) return false

    // Check that it has a valid image extension
    const extensionRegex = /(\.jpg|\.jpeg|\.png|\.gif|\.svg)$/
    if (!extensionRegex.test(url)) return false

    return true
}
