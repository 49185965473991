import React from 'react'

import { useSearchParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Row, Col } from 'antd'

import messages from 'services/intl/messageDefinitions'
import { useDaysInView, useSetDaysInView } from 'stores/ForecastStore'

export const DaySelector = () => {
    const [queryParams, setQueryParams] = useSearchParams()
    const daysInView = useDaysInView()
    const setDaysInView = useSetDaysInView()

    const handleClick = (dayIndex) => {
        const updatedDays = { ...daysInView }
        updatedDays[dayIndex] = !daysInView[dayIndex]
        setDaysInView(updatedDays)

        // Set the query params
        let dayString = ''
        for (const day in updatedDays) {
            dayString += updatedDays[day] ? day : ''
        }
        setQueryParams({ ...Object.fromEntries([...queryParams]), days: dayString })
    }

    return (
        <Row>
            <Col span={24} style={styles.label}>
                <p style={styles.label}>
                    <FormattedMessage {...messages.visibleCards} />
                </p>
            </Col>
            <Col span={24}>
                <Row data-test={'daySelector'}>
                    <div style={daysInView[1] ? styles.active : styles.inactive} onClick={() => handleClick(1)}>
                        1
                    </div>
                    <div style={daysInView[2] ? styles.active : styles.inactive} onClick={() => handleClick(2)}>
                        2
                    </div>
                    <div style={daysInView[3] ? styles.active : styles.inactive} onClick={() => handleClick(3)}>
                        3
                    </div>
                    <div style={daysInView[4] ? styles.active : styles.inactive} onClick={() => handleClick(4)}>
                        4
                    </div>
                </Row>
            </Col>
        </Row>
    )
}

const styles = {
    label: {
        color: 'var(--grey)',
        marginBottom: 'var(--s-4)',
        textTransform: 'capitalize',
        fontWeight: '600',
        fontSize: '10px',
    },
    active: {
        // common styles between the two
        minWidth: '60px',
        height: '40px',
        borderRadius: 'var(--radius)',
        textAlign: 'center',
        paddingTop: '7px',
        cursor: 'pointer',
        marginRight: '5px',
        // active styles
        border: '1px solid var(--blue)',
        color: 'var(--white)',
        background: 'var(--blue)',
    },
    inactive: {
        // common styles between the two
        minWidth: '60px',
        height: '40px',
        borderRadius: 'var(--radius)',
        textAlign: 'center',
        paddingTop: '7px',
        cursor: 'pointer',
        marginRight: '5px',
        // inactive styles
        border: '1px solid var(--grey)',
        color: 'var(--white)',
        background: 'var(--grey)',
    },
}
