import React, { useEffect } from 'react'

import { useSearchParams } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { FILTER_OPTIONS } from 'utils/Product'
import { putAvalancheForecast, cloneAllAvalancheForecasts } from 'services/AvalancheForecast'
import { usePolygons } from 'hooks/polygons'
import messages from 'services/intl/messageDefinitions'
import { useLocale } from 'stores/UserStore'
import { AvalancheForecastStore } from 'stores/AvalancheForecastStore'
import { Map, WithMapReady } from 'components/Map/Map'
import { Sidebar } from 'components/Sidebar/Sidebar'
import { Details } from 'components/AvalancheForecast/Setup/Details'
import { Summary } from 'components/AvalancheForecast/Setup/Summary'
import { CreateForm } from 'components/AvalancheForecast/Setup/CreateForm'
import { MultiProductPolygon } from 'components/Map/MultiProductPolygon'
import { TwoColLayoutPrimaryRight } from 'components/TwoColumnLayout/TwoColLayoutPrimaryRight'
import { UnassignedPolygons } from 'components/Map/UnassignedPolygons'
import { MapStatusBar } from 'components/MapStatusBar/MapStatusBar'
import { ProductStatusBarText } from 'components/Product/ProductStatusBarText'
import { DangerRatingIconSet } from 'components/Map/DangerRatingIconSet'
import { CloneAllButton } from 'components/Product/CloneAllButton'
import { Accessibility } from 'components/Accessibility'
import { MultipleProductsTable } from 'components/Map/Accessibility/MultipleProductsTable'
import {
    useActiveAvalancheForecast,
    useAvalancheForecasts,
    useFilteredAvalancheForecasts,
    useUnassignedPolygons,
} from 'hooks/avalancheForecasts'

export const AvalancheForecast = () => {
    const [queryParams, setQueryParams] = useSearchParams()
    const locale = useLocale()
    const polygons = usePolygons()
    const filters = AvalancheForecastStore.useFilters()
    const addFilter = AvalancheForecastStore.useAddFilter()
    const setActiveAvalancheForecastId = AvalancheForecastStore.useSetActiveProductId()
    const activeAvalancheForecast = useActiveAvalancheForecast()
    const unassignedCount = useUnassignedPolygons()
    const queryID = queryParams.get('focus') || ''
    const disableSetupButton = filters[0] !== 'draft'
    const intl = useIntl()
    const { avalancheForecasts, mutate } = useAvalancheForecasts()

    useEffect(() => {
        document.title = intl.formatMessage(messages.avalancheForecast)
    }, [])

    useEffect(() => {
        if (queryID) {
            setActiveAvalancheForecastId(queryID)
        }
        filters.forEach((filter) => {
            // default filter
            addFilter(filter)
        })
        if (activeAvalancheForecast && activeAvalancheForecast.id === queryID) {
            addFilter(activeAvalancheForecast.status)
        }
    }, [avalancheForecasts])

    const standardA11yComponent = (
        <>
            <Map locale={locale}>
                <WithMapReady>
                    {polygons && polygons.features && (
                        <>
                            <MultiProductPolygon
                                useEditing={AvalancheForecastStore.useEditingProductSetup}
                                useActiveProduct={useActiveAvalancheForecast}
                                apiCall={putAvalancheForecast}
                                mutateProducts={mutate}
                                polygons={polygons}
                                useDraftProduct={AvalancheForecastStore.useDraftProduct}
                                useUpdateDraftProduct={AvalancheForecastStore.useUpdateDraftProduct}
                                allProducts={avalancheForecasts}
                                useFilteredProducts={useFilteredAvalancheForecasts}
                                useAssignedPolygons={useFilteredAvalancheForecasts}
                                productSource="avalancheForecast"
                            />
                            <DangerRatingIconSet />
                        </>
                    )}
                </WithMapReady>
            </Map>
            <UnassignedPolygons unassignedCount={unassignedCount} />
            {activeAvalancheForecast && (
                <MapStatusBar
                    product={activeAvalancheForecast}
                    text={<ProductStatusBarText product={activeAvalancheForecast} />}
                />
            )}
        </>
    )
    const enhancedA11yComponent = polygons && polygons.features && (
        <MultipleProductsTable
            unassignedCount={unassignedCount}
            useProducts={useFilteredAvalancheForecasts}
            useEditing={AvalancheForecastStore.useEditingProductSetup}
            useActiveItem={useActiveAvalancheForecast}
            putRequest={putAvalancheForecast}
            mutateProducts={mutate}
            polygons={polygons}
            useDraftItem={AvalancheForecastStore.useDraftProduct}
            useUpdateDraftItem={AvalancheForecastStore.useUpdateDraftProduct}
        />
    )

    return (
        <TwoColLayoutPrimaryRight
            left={
                <Accessibility
                    standardA11yComponent={standardA11yComponent}
                    enhancedA11yComponent={enhancedA11yComponent}
                />
            }
            right={
                <Sidebar
                    title={'avalancheForecast'}
                    emptyMessage={'noAvalancheForecastsFound'}
                    Details={Details}
                    Summary={Summary}
                    dataProvider={useFilteredAvalancheForecasts}
                    filterProvider={AvalancheForecastStore.useFilters}
                    listData={avalancheForecasts}
                    filterOptions={FILTER_OPTIONS}
                    activeFilters={filters}
                    useAddFilter={AvalancheForecastStore.useAddFilter}
                    useRemoveFilter={AvalancheForecastStore.useRemoveFilter}
                    useClearActiveID={AvalancheForecastStore.useClearActiveProductId}
                    createLabel={'createAvalancheForecast'}
                    disableSetupButton={disableSetupButton}
                    useSetActiveId={AvalancheForecastStore.useSetActiveProductId}
                    useSetEditing={AvalancheForecastStore.useSetEditingProductSetup}
                    useResetDraft={AvalancheForecastStore.useResetDraftProduct}
                    CreateForm={CreateForm}
                    mutateProducts={mutate}
                    useActiveItem={useActiveAvalancheForecast}
                    radioStyleFiltering={true}
                    buttons={
                        <CloneAllButton
                            mutateProducts={mutate}
                            useFilters={AvalancheForecastStore.useFilters}
                            useAddFilter={AvalancheForecastStore.useAddFilter}
                            cloneAllApiCall={cloneAllAvalancheForecasts}
                            buttonId={'cloneAllButton'}
                            confirmationText="initializeAllYes"
                        />
                    }
                ></Sidebar>
            }
        />
    )
}
