import React, { useState, useEffect } from 'react'

import { Row, Col, Button, Form } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'

import { LIKELIHOODS_TO_VALUES, VALUES_TO_LIKELIHOODS } from 'utils/AvalancheProblems'
import { avalancheProblemSchema } from 'utils/HighwayContent'
import { HAZARD_LIKELIHOOD_MATRIX } from 'utils/AvalancheProblems'
import { getWindowDimensions } from 'utils/screen'
import { getCharacterCount } from 'utils/String'
import messages from 'services/intl/messageDefinitions'
import { useEnhancedAccessibility, useLocale } from 'stores/UserStore'
import { HwyFxAvalancheProblemForm } from './HwyFxAvalancheProblemForm'
import { HazardChart } from 'components/AvalancheForecast/Content/AvalancheProblems/HazardChart'
import { AccessibleHazardChart } from 'components/AvalancheForecast/Content/AvalancheProblems/AccessibleChart'
import { AspectElevation } from 'components/AspectElevation/AspectElevation'
import { TranslatedTextField } from 'components/TextField/TranslatedTextField'
import { useCharacterLimits } from 'hooks/Settings'
import { sendErrorNotification } from 'utils/Notifications'

const GRID_THRESHOLD = 1400

export const HwyFxAvalancheProblemEditor = ({
    content,
    problemData = null,
    hideModal,
    saveProblem,
    editing,
    currentDay,
}) => {
    // External
    const [form] = Form.useForm()
    const intl = useIntl()

    // Utils
    const locale = useLocale()
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
    const actionButtonLabel = !editing ? messages.submitProblem : messages.updateProblem
    const characterLimit = useCharacterLimits().problemDiscussion
    const enhancedAccessibility = useEnhancedAccessibility()
    const [guidanceEnabled, setGuidanceEnabled] = useState(false)

    // Problem State
    const [problem, setProblem] = useState(problemData || avalancheProblemSchema(locale))

    // Window resizing
    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions())
        }
        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [])

    // Close modal
    const handleCancel = () => {
        hideModal()
    }

    // Aspect and elevation
    const updateAspectsAndElevations = (list) => {
        setProblem({
            ...problem,
            aspectElevations: list,
        })
    }

    // Comments (ie translations)
    const addNewTranslation = (language) => {
        setProblem({
            ...problem,
            comment: {
                ...problem.comment,
                [language]: '',
            },
        })
    }

    const removeTranslation = (language) => {
        const newTranslations = { ...problem.comment }
        delete newTranslations[language]

        setProblem({
            ...problem,
            comment: newTranslations,
        })
    }

    const handleEditorChange = (language, value) => {
        setProblem({
            ...problem,
            comment: {
                ...problem.comment,
                [language]: value,
            },
        })
    }

    // Conceptual model fields
    useEffect(() => {
        // Check if we're ready to generate the chart polygons
        // If we have sensitivity, distribution and typical size, we can generate the chart polygons
        if (problem.sensitivity && problem.distribution && problem.typicalSize) {
            setGuidanceEnabled(true)

            // if hazardSize isn't defined, we can assume that none of the chart polygons have been generated
            if (!problem.hazardSize) {
                generateChartPolygons()
            }
        }
    }, [problem])

    const handleChartChange = (val) => {
        const size = { min: val.size.from, max: val.size.to }
        const likelihoodRange = { min: val.likelihood.from, max: val.likelihood.to }
        const hazardSize = val.centroid.size
        const likelihood = val.centroid.likelihood

        setProblem({
            ...problem,
            size,
            likelihoodRange,
            hazardSize,
            likelihood,
        })
    }

    const generateChartPolygons = () => {
        if (!problem.hazardSize) {
            setProblem({
                ...problem,
                size: { min: 1, max: 2 },
                likelihoodRange: { min: 'unlikely', max: 'possible' },
                hazardSize: 1.5,
                likelihood: 'possible_unlikely',
            })
        }
    }

    const acceptHazardEval = () => {
        // create a chartPolygon around the centroid
        const hazardLikelihood = HAZARD_LIKELIHOOD_MATRIX[problem.distribution][problem.sensitivity]
        const hazardLikelihoodIndex = LIKELIHOODS_TO_VALUES.get(hazardLikelihood)
        const minHazardLikelihoodIndex = Math.max(hazardLikelihoodIndex - 0.5, 1)
        const maxHazardLikelihoodIndex = Math.min(hazardLikelihoodIndex + 0.5, 5)
        const minHazardLikelihood = VALUES_TO_LIKELIHOODS.get(minHazardLikelihoodIndex)
        const maxHazardLikelihood = VALUES_TO_LIKELIHOODS.get(maxHazardLikelihoodIndex)

        setProblem({
            ...problem,
            size: { min: Math.max(problem.typicalSize - 0.5, 1), max: Math.min(problem.typicalSize + 0.5, 5) },
            likelihoodRange: { min: minHazardLikelihood, max: maxHazardLikelihood },
            hazardSize: problem.typicalSize,
            likelihood: hazardLikelihood,
        })
    }

    const validateProblem = () => {
        let valid = true

        // No fields are required here, therefore we are not doing any validation, unlike AvalancheProblemEditor
        form.validateFields()

        if (problem.depth.from > problem.depth.to) {
            valid = false
        }

        if (problem.comment.en && characterLimit.en && getCharacterCount(problem.comment.en) > characterLimit.en) {
            sendErrorNotification(intl.formatMessage({ ...messages.saveProblemCharacterCountError }))
            valid = false
        }

        if (problem.comment.fr && characterLimit.fr && getCharacterCount(problem.comment.fr) > characterLimit.fr) {
            sendErrorNotification(intl.formatMessage({ ...messages.saveProblemCharacterCountError }))
            valid = false
        }

        if (valid) {
            try {
                saveProblem(problem, [])
            } catch (e) {
                console.log(e)
            }
            hideModal()
        }
    }

    const chartPolygons = {
        centroid: { size: problem.hazardSize, likelihood: problem.likelihood },
        size: { from: problem.size.min, to: problem.size.max },
        likelihood: { from: problem.likelihoodRange.min, to: problem.likelihoodRange.max },
    }
    const formMarker = {
        size: problem.typicalSize,
        likelihood:
            problem.distribution && problem.sensitivity
                ? HAZARD_LIKELIHOOD_MATRIX[problem.distribution][problem.sensitivity]
                : null,
    }

    return (
        <div style={styles.container}>
            <Row>
                <Col span={windowDimensions.width < GRID_THRESHOLD ? 12 : 8}>
                    <HwyFxAvalancheProblemForm
                        problem={problem}
                        content={content}
                        updateProblem={setProblem}
                        form={form}
                    />
                    {windowDimensions.width < GRID_THRESHOLD && (
                        <Row gutter={8}>
                            <Col span={12}>
                                <AspectElevation
                                    updateDraftItem={(item) => {
                                        updateAspectsAndElevations(item.aspectElevations)
                                    }}
                                    draftItem={{}}
                                    aspectElevations={problem.aspectElevations}
                                    update={() => {}}
                                    create={() => {}}
                                    colour={'#1890ff'}
                                    errorStatus={false}
                                />
                            </Col>
                            <Col span={12}>
                                {enhancedAccessibility ? (
                                    <AccessibleHazardChart
                                        chartPolygons={chartPolygons}
                                        formMarker={formMarker}
                                        onChange={handleChartChange}
                                    />
                                ) : (
                                    <HazardChart
                                        chartPolygons={chartPolygons}
                                        formMarker={formMarker}
                                        onChange={handleChartChange}
                                    />
                                )}
                            </Col>
                        </Row>
                    )}
                    <TranslatedTextField
                        handleChange={handleEditorChange}
                        handleAddTranslation={addNewTranslation}
                        handleRemoveTranslation={removeTranslation}
                        translations={problem.comment}
                        plainText={false}
                        translationRequired={currentDay?.position !== 0}
                        characterLimit={characterLimit}
                    />
                    <div data-test={'editors'}>
                        <Button
                            style={styles.rec}
                            onClick={acceptHazardEval}
                            disabled={!guidanceEnabled}
                            data-test={'guidanceButton'}
                        >
                            <FormattedMessage {...messages.guidance} />
                        </Button>
                    </div>
                </Col>
                <Col style={styles.visuals} span={windowDimensions.width < GRID_THRESHOLD ? 12 : 9}>
                    {windowDimensions.width > GRID_THRESHOLD && (
                        <Row>
                            <Col span={24}>
                                <AspectElevation
                                    updateDraftItem={(item) => {
                                        updateAspectsAndElevations(item.aspectElevations)
                                    }}
                                    draftItem={{}}
                                    aspectElevations={problem.aspectElevations}
                                    update={() => {}}
                                    create={() => {}}
                                    colour={'#1890ff'}
                                    errorStatus={false}
                                />
                            </Col>
                            <Col span={24}>
                                {enhancedAccessibility ? (
                                    <AccessibleHazardChart
                                        chartPolygons={chartPolygons}
                                        formMarker={formMarker}
                                        onChange={handleChartChange}
                                    />
                                ) : (
                                    <HazardChart
                                        chartPolygons={chartPolygons}
                                        formMarker={formMarker}
                                        onChange={handleChartChange}
                                    />
                                )}
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
            <div style={styles.footer}>
                <Button onClick={validateProblem} type="primary" data-test={'saveProblemButton'}>
                    <FormattedMessage {...actionButtonLabel} />
                </Button>
                <Button onClick={handleCancel} style={styles.cancel}>
                    <FormattedMessage {...messages.cancel} />
                </Button>
            </div>
        </div>
    )
}

const styles = {
    error: {
        color: 'var(--red)',
        textAlign: 'center',
    },
    errorRight: {
        color: 'var(--red)',
    },
    container: {
        paddingBottom: '75px',
    },
    visuals: {
        padding: ' var(--s0)',
    },
    rec: {
        float: 'right',
        marginTop: '-33px',
    },
    footer: {
        justifyContent: 'flex-end',
        display: 'flex',
        position: 'absolute',
        bottom: '0px',
        width: '100%',
        background: 'var(--background-colour)',
        height: '60px',
        borderTop: '1px solid var(--grey-light)',
        padding: '15px 10px 10px',
        right: '0px',
    },
    cancel: {
        marginLeft: 'var(--s0)',
    },
    publicLabel: {
        marginBottom: '3px',
        textTransform: 'capitalize',
    },
    toggleContainers: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    translationLabel: {
        marginBottom: '5px',
        marginLeft: '2px',
    },
    translationTag: {
        marginBottom: 'var(--s-3)',
        marginLeft: 'var(--s-1)',
    },
    roseToggle: {
        marginBottom: 'var(--s-2)',
    },
}
