import React, { useState } from 'react'

import { Row, Col, Dropdown, Menu, Button } from 'antd'
import {
    RightOutlined,
    DoubleRightOutlined,
    ReadOutlined,
    MoreOutlined,
    InteractionOutlined,
    CommentOutlined,
} from '@ant-design/icons'
import { useIntl } from 'react-intl'

import { Stat } from 'components/Stat/Stat'
import messages from 'services/intl/messageDefinitions'

export const DayCard = ({ day, cardContent, actionHandlers, dataProvider, editable = true }) => {
    const data = dataProvider()
    const [moreMenuVisible, setMoreMenuVisible] = useState(false)
    const intl = useIntl()

    const menuClick = (action) => {
        setMoreMenuVisible(false)

        switch (action) {
            case 'cloneNext':
                actionHandlers.cloneNext(day, data)
                break
            case 'cloneAll':
                actionHandlers.cloneAll(day, data)
                break
            case 'reviewNote':
                actionHandlers.reviewNote()
                break
            case 'preview':
                actionHandlers.preview()
                break
        }
    }

    const menu = (
        <Menu
            onClick={(event) => menuClick(event.key)}
            items={[
                {
                    key: 'cloneNext',
                    label: intl.formatMessage({ ...messages.copyToNextDay }),
                    icon: <RightOutlined />,
                    disabled: actionHandlers.cloneNext && editable ? false : true,
                },
                {
                    key: 'cloneAll',
                    label: intl.formatMessage({ ...messages.copyToAllDays }),
                    icon: <DoubleRightOutlined />,
                    disabled: actionHandlers.cloneAll && editable ? false : true,
                },
                {
                    key: 'reviewNote',
                    label: intl.formatMessage({ ...messages.reviewNote }),
                    icon: <CommentOutlined />,
                    disabled: actionHandlers.reviewNote && editable ? false : true,
                },
                {
                    key: 'preview',
                    label: intl.formatMessage({ ...messages.preview }),
                    icon: <ReadOutlined />,
                    disabled: actionHandlers.preview && editable ? false : true,
                },
            ]}
        />
    )

    const header = (
        <Row>
            <Col style={day.type === 'Nowcast' ? styles.nowHeaderNumber : styles.headerNumber}>
                <h5 style={styles.headerNumberText}>{day.value}</h5>
            </Col>
            <Col flex="auto" style={day.type === 'Nowcast' ? styles.nowHeaderTitle : styles.headerTitle}>
                <div style={styles.detailsContainer}>
                    <div>
                        <Stat
                            label={`${day.day}.${day.type}`}
                            value={day.date}
                            valueStyles={{ color: '#fff' }}
                            labelStyles={{ color: '#ffffffbf' }}
                        />
                    </div>
                    <Button
                        size="large"
                        style={day.type === 'Nowcast' ? styles.nowMoreButton : styles.moreButton}
                        onClick={() => setMoreMenuVisible(!moreMenuVisible)}
                        onMouseEnter={() => setMoreMenuVisible(true)}
                        onMouseLeave={() => setMoreMenuVisible(false)}
                        data-test={`dayCardMoreButton-${day.value}`}
                    >
                        <Dropdown overlay={menu} trigger={['click', 'hover']} visible={moreMenuVisible}>
                            <MoreOutlined style={styles.menuIcon} />
                        </Dropdown>
                    </Button>
                </div>
            </Col>
        </Row>
    )

    return (
        <div style={{ ...styles.container, border: `${editable ? '1px solid var(--grey-light)' : null}` }}>
            {header}
            <div style={styles.content}>{cardContent}</div>
        </div>
    )
}

const styles = {
    menuIcon: {
        color: 'var(--white)',
        fontSize: '25px',
        marginTop: '6px',
    },
    detailsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    content: {
        padding: 'var(--s0)',
    },
    container: {
        backgroundColor: 'var(--background-colour)',
        height: '100%',
        borderRadius: '3px',
    },
    headerNumber: {
        backgroundColor: 'var(--blue-lt)',
        height: '81px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderTopLeftRadius: '3px',
        width: '60px',
    },
    nowHeaderNumber: {
        backgroundColor: 'var(--orange-lt)',
        height: '81px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderTopLeftRadius: '3px',
        width: '60px',
    },
    headerNumberText: {
        fontSize: '25px',
        fontWeight: '400',
        marginBottom: '0',
        color: 'var(--white)',
    },
    headerTitle: {
        backgroundColor: 'var(--blue)',
        padding: '5px 2px 0px 10px',
        borderTopRightRadius: '3px',
    },
    nowHeaderTitle: {
        background: 'var(--orange)',
        padding: '5px 2px 0px 10px',
        borderTopRightRadius: '3px',
    },
    title: {
        color: 'var(--white)',
        fontWeight: '500',
        marginBottom: '0.2em',
        fontSize: '20px',
    },
    day: {
        color: 'var(--white)',
        fontWeight: '400',
    },
    nowMoreButton: {
        border: 'none',
        backgroundColor: 'var(--orange)',
    },
    moreButton: {
        border: 'none',
        backgroundColor: 'var(--blue)',
    },
}
