import React from 'react'
import { FormattedMessage } from 'react-intl'
import messages from 'services/intl/messageDefinitions'

export const TextDisplay = ({ translatedText, idKey }) => {
    if (!translatedText) return null

    return Object.keys(translatedText).map((language) => {
        return (
            <div key={idKey}>
                <p style={styles.translationLabel}>{<FormattedMessage {...messages[language]} />}</p>
                <div dangerouslySetInnerHTML={{ __html: translatedText[language] }}></div>
            </div>
        )
    })
}

const styles = {
    title: {
        fontWeight: 300,
    },
    space: {
        display: 'flex',
    },
    translationLabel: {
        marginBottom: '5px',
        marginLeft: '2px',
        fontWeight: 'bold',
    },
}
