import React from 'react'

import { Table } from 'antd'

import { FormattedMessage, useIntl } from 'react-intl'

import { DANGER_RATINGS } from '../../utils/Snowpack/ProblemTypes'
import messages from 'services/intl/messageDefinitions'

export const DangerRatingTable = () => {
    const intl = useIntl()

    const columns = [
        {
            title: <FormattedMessage {...messages['dangerRating.titles.dangerLevel']} />,
            key: 'name',
            dataIndex: 'name',
            render: (name, data) => {
                return {
                    props: {
                        style: { backgroundColor: data.colour },
                    },
                    children: (
                        <>
                            <h2 style={{ color: data.textModifier }}>{name}</h2>
                        </>
                    ),
                }
            },
        },
        {
            title: '',
            key: 'icon',
            dataIndex: 'icon',
            render: (icon) => (
                <span style={styles.iconSpan}>
                    <img width="175px" alt={icon} src={`/assets/danger-ratings/${icon}.svg`} />
                </span>
            ),
        },
        {
            title: <FormattedMessage {...messages['dangerRating.titles.travelAdvice']} />,
            key: 'travelAdvice',
            dataIndex: 'travelAdvice',
        },
        {
            title: <FormattedMessage {...messages['dangerRating.titles.likelihood']} />,
            key: 'likelihood',
            dataIndex: 'likelihood',
        },
        {
            title: <FormattedMessage {...messages['dangerRating.titles.sizeDistribution']} />,
            key: 'sizeDistribution',
            dataIndex: 'sizeDistribution',
        },
    ]

    const data = DANGER_RATINGS.map(({ key, colour, textModifier }) => ({
        key,
        icon: key,
        name: intl.formatMessage({ ...messages[`dangerRating.${key}.dangerLevel`] }),
        colour,
        textModifier,
        travelAdvice: intl.formatMessage({ ...messages[`dangerRating.${key}.travelAdvice`] }),
        likelihood: intl.formatMessage({ ...messages[`dangerRating.${key}.likelihood`] }),
        sizeDistribution: intl.formatMessage({ ...messages[`dangerRating.${key}.sizeDistribution`] }),
    }))

    return <Table pagination={false} dataSource={data} columns={columns} />
}

const styles = {
    iconSpan: {
        marginRight: 'var(--s-2)',
    },
}
