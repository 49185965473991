export const SENSITIVITY_TYPES = ['unreactive', 'stubborn', 'reactive', 'touchy']

export const SENSITIVITY_INFO = {
    titles: {
        sensitivity: 'Sensitivity',
        release: 'Natural Releases',
        humanTriggers: 'Human Triggers',
        size: 'Explosive Triggers - Size',
        result: 'Explosive Triggers - Result',
        corniceTriggers: 'Cornice Triggers',
    },
    unreactive: {
        sensitivity: 'Unreactive',
        release: 'No avalanches',
        humanTriggers: 'No avalanches',
        explosiveTriggersSize: 'Very large explosives in many locations',
        explosiveTriggersResult: 'No slab',
        corniceTriggers: 'No slab from very large cornice fall',
    },
    stubborn: {
        sensitivity: 'Stubborn',
        release: 'Few',
        humanTriggers: 'Difficult to trigger',
        explosiveTriggersSize: 'Large explosive and air blasts, often in several locations',
        explosiveTriggersResult: 'Some',
        corniceTriggers: 'Large',
    },
    reactive: {
        sensitivity: 'Reactive',
        release: 'Several',
        humanTriggers: 'Easy to trigger with ski cuts',
        explosiveTriggersSize: 'Single hand charge',
        explosiveTriggersResult: 'Many',
        corniceTriggers: 'Medium',
    },
    touchy: {
        sensitivity: 'Touchy',
        release: 'Numerous',
        humanTriggers: 'Triggering almost certain',
        explosiveTriggersSize: 'Any size',
        explosiveTriggersResult: 'Numerous',
        corniceTriggers: 'Any aize',
    },
    observation: {
        sensitivity: 'Description of Observation',
        release: 'Natural avalanche occurrence',
        humanTriggers: 'Ease of triggering by a single human',
        explosiveTriggersSize: 'Size of explosive and effect',
        explosiveTriggersResult: 'Size of explosive and effect',
        corniceTriggers: 'Size of cornice that will trigger a slab',
    },
}
