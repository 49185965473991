import React from 'react'
import { Row, Col, Typography } from 'antd'
import { Date } from '../Date/Date'
import { truncate } from 'utils/String'

export const Summary = ({ item, useSetActiveId }) => {
    const { Title } = Typography
    const setActiveItem = useSetActiveId()
    //TODO:: Dynamically truncate string based on sidebar size
    const formattedName = truncate(item.name)

    const handleClick = () => {
        setActiveItem(item.id)
    }

    return (
        <div onClick={handleClick} style={{ ...styles.container, borderLeft: `6px solid ${item.colour}` }}>
            <Row>
                <Col style={styles.detailCol} span={16}>
                    <Title level={4} style={styles.name} data-test={formattedName}>
                        {formattedName}
                    </Title>
                    <Date value={item.issueDateTime} label={'issued'} />
                </Col>
                <Col style={styles.detailCol} span={8}>
                    <p style={styles.forecaster}>{item.username}</p>
                </Col>
            </Row>
        </div>
    )
}

const styles = {
    percent: {
        textAlign: 'right',
        marginRight: '20px',
    },
    status: {
        marginLeft: 'auto',
    },
    name: {
        fontWeight: 500,
        fontSize: '18px',
    },
    container: {
        paddingLeft: 'var(--s1)',
        height: '50px',
        position: 'relative',
    },
    forecaster: {
        textAlign: 'right',
    },
}
