import { APIClient } from './APIClient'

// API-returned data is the same regardless of language so we've used 'en' as default
export const fetchUsers = () => {
    return APIClient.get(`/v1/en/users`)
}

export const fetchUser = (id) => {
    return APIClient.get(`/v1/en/users/${id}`)
}

export const putUser = (data) => {
    return APIClient.put(`/v1/en/users/${data.id}`, {
        ...data,
    })
}
