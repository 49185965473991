import React, { useState, useCallback, useEffect, useRef } from 'react'

import { select, event } from 'd3-selection'
import { axisBottom, axisLeft } from 'd3-axis'
import { scaleLinear } from 'd3-scale'
import { brush } from 'd3-brush'
import { drag } from 'd3-drag'
import { FormattedMessage, useIntl } from 'react-intl'

import messages from 'services/intl/messageDefinitions'
import { LIKELIHOODS_TO_VALUES, LIKELIHOOD_TEXTS, VALUES_TO_LIKELIHOODS } from 'utils/AvalancheProblems'

// Constants
const X_TEXT_OFFSET = 5
const Y_TEXT_OFFSET = 30
const WIDTH = 375
const HEIGHT = 300
const MARGIN = {
    top: 25,
    right: 25,
    bottom: 35,
    left: 100,
}
const EXTENT = [
    [MARGIN.left, MARGIN.top],
    [WIDTH - MARGIN.right, HEIGHT - MARGIN.bottom],
]
const INCREMENTX = (WIDTH - MARGIN.left - MARGIN.right) / 8
const INCREMENTY = (HEIGHT - MARGIN.top - MARGIN.bottom) / 8

export const HazardChart = ({ chartPolygons, formMarker, onChange }) => {
    const intl = useIntl()

    const centerCentroid = useRef(false)
    const [centroid, setCentroid] = useState(
        chartPolygons?.centroid
            ? {
                  x: X(chartPolygons.centroid?.size),
                  y: Y(LIKELIHOODS_TO_VALUES.get(chartPolygons.centroid.likelihood)),
              }
            : null
    )
    const [rectangle, setRectangle] = useState(
        chartPolygons?.size?.from &&
            chartPolygons?.size?.to &&
            chartPolygons?.likelihood?.from &&
            chartPolygons?.likelihood?.to
            ? {
                  x1: X(chartPolygons.size.from),
                  y1: Y(LIKELIHOODS_TO_VALUES.get(chartPolygons.likelihood.to)),
                  x2: X(chartPolygons.size.to),
                  y2: Y(LIKELIHOODS_TO_VALUES.get(chartPolygons.likelihood.from)),
              }
            : null
    )

    // Setting up Axes
    const drawXAxis = useCallback((g) => {
        select(g)
            .call((g) => g.attr('transform', `translate(0,${HEIGHT - MARGIN.bottom})`).call(axisBottom(X).ticks(5)))
            .call((g) =>
                g
                    .selectAll('.tick line')
                    .clone()
                    .attr('y2', -HEIGHT + MARGIN.top + MARGIN.bottom)
                    .attr('stroke-opacity', 0.1)
                    .clone()
                    .attr('transform', `translate(${(X(2) - X(1)) / 2},0)`)
                    .attr('stroke-opacity', 0.05)
            )
    }, [])

    const drawYAxis = useCallback((g) => {
        select(g)
            .call((g) =>
                g.attr('transform', `translate(${MARGIN.left},0)`).call(
                    axisLeft(Y)
                        .ticks(5)
                        .tickFormat((index) =>
                            intl.formatMessage(messages[`hazardChart.likelihood.${LIKELIHOOD_TEXTS[index - 1]}`])
                        )
                )
            )
            .call((g) =>
                g
                    .selectAll('.tick line')
                    .clone()
                    .attr('x2', WIDTH - MARGIN.left - MARGIN.right)
                    .attr('stroke-opacity', 0.1)
                    .clone()
                    .attr('transform', `translate(0,${(Y(2) - Y(1)) / 2})`)
                    .attr('stroke-opacity', 0.05)
            )
    }, [])

    // Centroid management
    useEffect(() => {
        if (chartPolygons?.centroid) {
            setCentroid({
                x: X(chartPolygons.centroid?.size),
                y: Y(LIKELIHOODS_TO_VALUES.get(chartPolygons.centroid.likelihood)),
            })
        }
    }, [chartPolygons])

    const handleCentroidDrag = () => {
        const { newX, newY } = checkedCentroidBounds(event, rectangle)
        setCentroid({ x: newX, y: newY })
    }

    const handleCentroidDragEnd = () => {
        const { newX, newY } = checkedCentroidBounds(event, rectangle)
        const [snappedX, snappedY] = snap({ x: newX, y: newY })

        const likelihood = VALUES_TO_LIKELIHOODS.get(Y.invert(snappedY))
        const size = X.invert(snappedX)
        onChange({ ...chartPolygons, centroid: { likelihood, size } })

        setCentroid({ x: snappedX, y: snappedY })
    }

    const centroidActions = (g) => {
        const centroid = select(g)
        centroid.call(drag().on('drag', handleCentroidDrag).on('end', handleCentroidDragEnd))
    }

    // Rectangle management
    useEffect(() => {
        if (
            chartPolygons?.size.to &&
            chartPolygons?.size.from &&
            chartPolygons?.likelihood.to &&
            chartPolygons?.likelihood.from
        ) {
            setRectangle({
                x1: X(chartPolygons.size.from),
                y1: Y(LIKELIHOODS_TO_VALUES.get(chartPolygons.likelihood.to)),
                x2: X(chartPolygons.size.to),
                y2: Y(LIKELIHOODS_TO_VALUES.get(chartPolygons.likelihood.from)),
            })
        }
    }, [chartPolygons])

    const handleBrushStart = () => {
        if (!event.sourceEvent) return

        const [[newX1, newY1], [newX2, newY2]] = event.selection

        // If newX1 and newX2 are very close to each other, we're drawing a new rectangle.
        // Same with newY1 and newY2
        // If that's the case, we want to center the centroid on the rectangle
        if (Math.abs(newX1 - newX2) < 1 && Math.abs(newY1 - newY2) < 1) {
            centerCentroid.current = true
        } else {
            centerCentroid.current = false
        }
    }

    const handleBrush = () => {
        if (!event.sourceEvent) return

        const [[newX1, newY1], [newX2, newY2]] = event.selection
        const { x1, y1, x2, y2 } = rectangle

        // If centerCentroid is true, we're drawing a new rectangle and we want to center the centroid on it
        if (centerCentroid.current) {
            const newCentroid = {
                x: (newX1 + newX2) / 2,
                y: (newY1 + newY2) / 2,
            }
            setCentroid(newCentroid)
        }

        // If the X and Y relative differences are the same, we're moving the rectangle. Move the centroid with it
        if (newX1 - x1 === newX2 - x2 && newY1 - y1 === newY2 - y2) {
            const { x, y } = centroid
            setCentroid({
                x: x + newX1 - x1,
                y: y + newY2 - y2,
            })
        }

        // Otherwise we're resizing it and we don't want to move the centroid, ie do nothing

        // No matter what, update the rectangle
        setRectangle({
            x1: newX1,
            y1: newY1,
            x2: newX2,
            y2: newY2,
        })
    }

    const handleBrushEnd = () => {
        if (!event.sourceEvent) return

        const [[newX1, newY1], [newX2, newY2]] = event.selection
        const { x1, y1, x2, y2 } = rectangle

        let [snappedX1, snappedY1] = snap({ x: newX1, y: newY1 })
        let [snappedX2, snappedY2] = snap({ x: newX2, y: newY2 })

        // If the rectangle is too small, make it bigger
        if (snappedX2 === snappedX1) {
            if (snappedX2 === WIDTH - MARGIN.right) {
                snappedX1 = snappedX2 - INCREMENTX
            } else {
                snappedX2 = snappedX1 + INCREMENTX
            }
        }
        if (snappedY2 === snappedY1) {
            if (snappedY2 === HEIGHT - MARGIN.bottom) {
                snappedY1 = snappedY2 - INCREMENTY
            } else {
                snappedY2 = snappedY1 + INCREMENTY
            }
        }

        let newCentroid = centroid
        const { x, y } = centroid
        // If centerCentroid is true, we're drawing a new rectangle and we want to center the centroid on it
        if (centerCentroid.current) {
            newCentroid = {
                x: (snappedX1 + snappedX2) / 2,
                y: (snappedY1 + snappedY2) / 2,
            }
            const [snappedCentroidX, snappedCentroidY] = snap(newCentroid)
            newCentroid = { x: snappedCentroidX, y: snappedCentroidY }
        }

        // If the X and Y relative differences are the same, we're moving the rectangle. Move the centroid with it
        if (newX1 - x1 === newX2 - x2 && newY1 - y1 === newY2 - y2) {
            newCentroid = {
                x: x + snappedX1 - x1,
                y: y + snappedY2 - y2,
            }
        }

        // Otherwise we're resizing it and we don't want to move the centroid
        // But we have to check that the centroid is still within the rectangle. If it isn't, revert the change to the rectangle
        if (
            snappedX1 > newCentroid.x ||
            snappedX2 < newCentroid.x ||
            snappedY2 < newCentroid.y ||
            snappedY1 > newCentroid.y
        ) {
            snappedX1 = X(chartPolygons.size.from)
            snappedY1 = Y(LIKELIHOODS_TO_VALUES.get(chartPolygons.likelihood.to))
            snappedX2 = X(chartPolygons.size.to)
            snappedY2 = Y(LIKELIHOODS_TO_VALUES.get(chartPolygons.likelihood.from))
        }

        // Set the values to return back to the parent
        const likelihood1 = VALUES_TO_LIKELIHOODS.get(Y.invert(snappedY1))
        const size1 = X.invert(snappedX1)
        const likelihood2 = VALUES_TO_LIKELIHOODS.get(Y.invert(snappedY2))
        const size2 = X.invert(snappedX2)
        const sizeCentroid = X.invert(newCentroid.x)
        const likelihoodCentroid = VALUES_TO_LIKELIHOODS.get(Y.invert(newCentroid.y))

        // Update state
        setCentroid(newCentroid)
        setRectangle({
            x1: snappedX1,
            y1: snappedY1,
            x2: snappedX2,
            y2: snappedY2,
        })

        // Update parent
        onChange({
            centroid: { size: sizeCentroid, likelihood: likelihoodCentroid },
            size: { from: size1, to: size2 },
            likelihood: { from: likelihood2, to: likelihood1 },
        })

        centerCentroid.current = false
    }

    const brushActions = (g) => {
        const brushFunc = brush().extent(EXTENT)
        const group = select(g).call(brushFunc)

        brushFunc.on('start', handleBrushStart).on('brush', handleBrush).on('end', handleBrushEnd)

        if (rectangle) {
            brushFunc.move(group, [
                [rectangle.x1, rectangle.y1],
                [rectangle.x2, rectangle.y2],
            ])
        }
    }

    return (
        <svg viewBox={`0 0 ${WIDTH} ${HEIGHT}`}>
            {/* Draw Axes */}
            <g ref={drawXAxis} />
            <g ref={drawYAxis} />
            <g fill="var(--contrast-colour)" fontSize="10" textAnchor="middle">
                <text x={(WIDTH - MARGIN.left - MARGIN.right) / 2 + MARGIN.left} y={HEIGHT - X_TEXT_OFFSET}>
                    <FormattedMessage {...messages.hazardSizeLabel} />
                </text>
                <text transform={`rotate(-90)`} x={-HEIGHT / 2} y={Y_TEXT_OFFSET}>
                    <FormattedMessage {...messages.hazardLikelihoodLabel} />
                </text>
            </g>

            {/* Red centroid (form inputs) */}
            {formMarker?.size && formMarker?.likelihood && (
                <rect
                    stroke="red"
                    fill="red"
                    width="5"
                    height="5"
                    x={X(formMarker.size) - 2}
                    y={Y(LIKELIHOODS_TO_VALUES.get(formMarker.likelihood)) - 2}
                />
            )}

            {/* White rectangle (chart inputs) */}
            {rectangle && <g ref={brushActions} />}

            {/* Blue centroid (chart inputs) */}
            {centroid?.x && centroid?.y && (
                <rect
                    ref={centroidActions}
                    stroke="blue"
                    fill="transparent"
                    width="8"
                    height="8"
                    x={centroid.x - 4}
                    y={centroid.y - 4}
                    style={{ cursor: 'grab' }}
                />
            )}
        </svg>
    )
}

// Utils
const X = scaleLinear()
    .domain([1, 5])
    .range([MARGIN.left, WIDTH - MARGIN.right])
const Y = scaleLinear()
    .domain([5, 1])
    .range([MARGIN.top, HEIGHT - MARGIN.bottom])

function snap(event) {
    const { x, y } = event
    return [X(Math.round(X.invert(x) * 2) / 2), Y(Math.round(Y.invert(y) * 2) / 2)]
}

const checkedCentroidBounds = (event, rectangle) => {
    let { x: newX, y: newY } = event

    if (newX < rectangle.x1) {
        newX = rectangle.x1
    }
    if (newX > rectangle.x2) {
        newX = rectangle.x2
    }
    if (newY > rectangle.y2) {
        newY = rectangle.y2
    }
    if (newY < rectangle.y1) {
        newY = rectangle.y1
    }

    return { newX, newY }
}
