import { FormattedMessage } from 'react-intl'
import { Tooltip } from 'antd'
import {
    BoldOutlined,
    ItalicOutlined,
    StrikethroughOutlined,
    FormOutlined,
    UnorderedListOutlined,
    OrderedListOutlined,
    MinusOutlined,
    UndoOutlined,
    LinkOutlined,
    ArrowsAltOutlined,
    DeleteOutlined,
    DisconnectOutlined,
} from '@ant-design/icons'

import { getCharacterCount } from 'utils/String'
import { RemainingCharacterCount } from 'components/RemainingCharacterCount/RemainingCharacterCount'
import messages from 'services/intl/messageDefinitions'

export const MenuBar = ({
    editor,
    setLink,
    setModalVisible,
    removeHandler,
    expandable,
    setDeleteModalVisible,
    characterLimit,
    position,
}) => {
    const html = editor ? editor.getHTML() : ''
    const characterCount = getCharacterCount(html)

    if (!editor) {
        return null
    }

    return (
        <div className="toolbar">
            {removeHandler && (
                <Tooltip placement="top" title={<FormattedMessage {...messages.removeTranslation} />}>
                    <span style={styles.toolBtn} onClick={() => setDeleteModalVisible(true)}>
                        <DeleteOutlined />
                    </span>
                </Tooltip>
            )}
            <Tooltip placement="top" title={<FormattedMessage {...messages.bold} />}>
                <span
                    style={styles.toolBtn}
                    onClick={() => editor.chain().focus().toggleBold().run()}
                    className={editor.isActive('bold') ? 'is-active' : ''}
                >
                    <BoldOutlined />
                </span>
            </Tooltip>
            <Tooltip placement="top" title={<FormattedMessage {...messages.italic} />}>
                <span
                    style={styles.toolBtn}
                    onClick={() => editor.chain().focus().toggleItalic().run()}
                    className={editor.isActive('italic') ? 'is-active' : ''}
                >
                    <ItalicOutlined />
                </span>
            </Tooltip>
            <Tooltip placement="top" title={<FormattedMessage {...messages.strike} />}>
                <span
                    style={styles.toolBtn}
                    onClick={() => editor.chain().focus().toggleStrike().run()}
                    className={editor.isActive('strike') ? 'is-active' : ''}
                >
                    <StrikethroughOutlined />
                </span>
            </Tooltip>
            <Tooltip placement="top" title={<FormattedMessage {...messages.textBlock} />}>
                <span
                    style={styles.toolBtn}
                    onClick={() => editor.chain().focus().toggleCodeBlock().run()}
                    className={editor.isActive('codeBlock') ? 'is-active' : ''}
                >
                    <FormOutlined />
                </span>
            </Tooltip>
            <Tooltip placement="top" title={<FormattedMessage {...messages.h1} />}>
                <span
                    style={styles.toolBtn}
                    onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                    className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
                >
                    H1
                </span>
            </Tooltip>
            <Tooltip placement="top" title={<FormattedMessage {...messages.h2} />}>
                <span
                    style={styles.toolBtn}
                    onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                    className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
                >
                    H2
                </span>
            </Tooltip>
            <Tooltip placement="top" title={<FormattedMessage {...messages.h3} />}>
                <span
                    style={styles.toolBtn}
                    onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
                    className={editor.isActive('heading', { level: 3 }) ? 'is-active' : ''}
                >
                    H3
                </span>
            </Tooltip>
            <Tooltip placement="top" title={<FormattedMessage {...messages.bulletList} />}>
                <span
                    style={styles.toolBtn}
                    onClick={() => editor.chain().focus().toggleBulletList().run()}
                    className={editor.isActive('bulletList') ? 'is-active' : ''}
                >
                    <UnorderedListOutlined />
                </span>
            </Tooltip>
            <Tooltip placement="top" title={<FormattedMessage {...messages.orderList} />}>
                <span
                    style={styles.toolBtn}
                    onClick={() => editor.chain().focus().toggleOrderedList().run()}
                    className={editor.isActive('orderedList') ? 'is-active' : ''}
                >
                    <OrderedListOutlined />
                </span>
            </Tooltip>
            <Tooltip placement="top" title={<FormattedMessage {...messages.divider} />}>
                <span style={styles.toolBtn} onClick={() => editor.chain().focus().setHorizontalRule().run()}>
                    <MinusOutlined />
                </span>
            </Tooltip>
            <Tooltip placement="top" title={<FormattedMessage {...messages.undo} />}>
                <span style={styles.toolBtn} onClick={() => editor.chain().focus().undo().run()}>
                    <UndoOutlined />
                </span>
            </Tooltip>
            {/* <span 
            style={styles.toolBtn}
            onClick={() => editor.chain().focus().redo().run()}>
          <RedoOutlined />
        </span> */}
            <Tooltip placement="top" title={<FormattedMessage {...messages.link} />}>
                <span style={styles.toolBtn} onClick={setLink} className={editor.isActive('link') ? 'is-active' : ''}>
                    <LinkOutlined />
                </span>
            </Tooltip>
            <Tooltip placement="top" title={<FormattedMessage {...messages.unlink} />}>
                <span style={styles.toolBtn} onClick={() => editor.chain().focus().unsetLink().run()}>
                    <DisconnectOutlined />
                </span>
            </Tooltip>
            {expandable && (
                <Tooltip placement="top" title={<FormattedMessage {...messages.popOutEditor} />}>
                    <span onClick={() => setModalVisible(true)} style={styles.toolBtn}>
                        <ArrowsAltOutlined />
                    </span>
                </Tooltip>
            )}
            {characterLimit && (
                <Tooltip placement="top" title={<FormattedMessage {...messages.charactersRemaining} />}>
                    <span style={styles.charCount}>
                        <RemainingCharacterCount limit={characterLimit} count={characterCount} />
                    </span>
                </Tooltip>
            )}
            <div style={styles.dividerContainer}>
                <div style={styles.divider} />
            </div>
        </div>
    )
}

const styles = {
    toolBtn: {
        color: '#999',
        cursor: 'pointer',
        // marginRight: '16px',
        padding: '6px 8px',
        display: 'inline-block',
        borderRadius: '5px',
    },
    dividerContainer: {
        width: '100%',
        height: '1px',
        padding: '0px 6px',
    },
    divider: {
        width: '100%',
        height: '1px',
        background: 'var(--grey-light)',
    },
    charCount: {
        marginRight: '8px',
        marginTop: '6px',
        float: 'right',
    },
}
