import { APIClient } from './APIClient'

export const fetchHighwayDiscussions = (language) => {
    return APIClient.get(`/v1/${language}/products/highwaydiscussion`, language)
}

export const fetchHighwayDiscussion = (id, language) => {
    return APIClient.get(`/v1/${language}/products/id/${id}`, language)
}

export const fetchHighwayDiscussionsByDates = (startDate, endDate, searchTerm, language) => {
    return APIClient.get(
        `/v1/${language}/products/highwaydiscussion/history?startDate=${startDate}&endDate=${endDate}&searchTerm=${searchTerm}`,
        language
    )
}

export const postHighwayDiscussion = (data, language) => {
    return APIClient.post(`/v1/${language}/products/highwaydiscussion`, {
        ...data,
    })
}

export const putHighwayDiscussion = (data, language) => {
    return APIClient.put(`/v1/${language}/products/highwaydiscussion`, {
        ...data,
    })
}

export const publishHighwayDiscussion = (id, language) => {
    return APIClient.post(`/v1/${language}/products/publish/${id}`)
}

export const deleteHighwayDiscussion = (id, language) => {
    return APIClient.delete(`/v1/${language}/products/id/` + id, language)
}

export const cloneHighwayDiscussion = (id, language) => {
    return APIClient.post(`/v1/${language}/products/clone/` + id, language)
}

export const cloneAllHighwayDiscussions = (language) => {
    return APIClient.post(`/v1/${language}/products/highwaydiscussion/cloneall`, language)
}

export const fetchPreviewEmail = (id) => {
    return APIClient.get(`/v1/en/products/id/${id}/previewEmail`)
}

export const previewEmailHTML = (id, data) => {
    return APIClient.post(`/v1/en/products/id/${id}/previewEmailHTML`, data)
}

export const sendEmail = (id, data) => {
    return APIClient.post(`/v1/en/products/id/${id}/sendEmail`, data)
}
