import React from 'react'

import { Button } from 'antd'
import { FormattedMessage } from 'react-intl'
import { useAuth0 } from '@auth0/auth0-react'

import { useLocale } from 'stores/UserStore'
import messages from 'services/intl/messageDefinitions'

export const PreviewButton = ({ id }) => {
    const base = process.env.REACT_APP_PREVIEW_BASE_URL
    const locale = useLocale()
    const apiUrl = process.env.REACT_APP_API_URL
    const apiKey = process.env.REACT_APP_API_KEY
    const { getAccessTokenSilently } = useAuth0()

    return (
        <Button
            onClick={async () => {
                const token = await getAccessTokenSilently({
                    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                    domain: process.env.REACT_APP_AUTH0_DOMAIN,
                })

                window.open(
                    `${base}/${locale}/preview?product=${id}&apiUrl=${apiUrl}&apiKey=${apiKey}&token=${token}`,
                    '_blank'
                )
            }}
        >
            <FormattedMessage {...messages.preview} />
        </Button>
    )
}
