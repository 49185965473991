import useSWR from 'swr'

import { useLocale } from 'stores/UserStore'
import { fetchHighwayLines } from 'services/HighwayLines'
import { HighwayForecastStore } from 'stores/HighwayForecastStore'

export const useHighwayLinesWithHazardRatings = () => {
    const language = useLocale()
    const key = ['highways/lines', language]
    const { data, isLoading, mutate } = useSWR(key, () => fetchHighwayLines(language))

    return {
        polylines: data?.data,
        isLoading,
        mutate,
    }
}

// These are lines representing highways segments, coloured according to the hazard rating of paths that cross them (used in Highway Forecasts)
export const useFilteredHighwayLinesWithHazardRatings = () => {
    const { polylines, isLoading } = useHighwayLinesWithHazardRatings()
    const filters = HighwayForecastStore.useFilters()

    if (isLoading || !polylines || filters.length === 0) {
        return []
    }

    return polylines[filters[0]]
}
