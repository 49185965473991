import { APIClient } from './APIClient'

export const fetchHighwayForecasts = (language) => {
    return APIClient.get(`/v1/${language}/products/highwayforecast`, language)
}

export const fetchHighwayForecast = (id, language) => {
    return APIClient.get(`/v1/${language}/products/id/${id}`, language)
}

export const fetchHighwayForecastsByDate = (startDate, endDate, searchTerm, language) => {
    return APIClient.get(
        `/v1/${language}/products/highwayforecast/history?startDate=${startDate}&endDate=${endDate}&searchTerm=${searchTerm}`,
        language
    )
}

export const postHighwayForecast = (data, language) => {
    return APIClient.post(`/v1/${language}/products/highwayforecast`, {
        ...data,
    })
}

export const putHighwayForecast = (data, language) => {
    return APIClient.put(`/v1/${language}/products/highwayforecast`, {
        ...data,
    })
}

export const publishHighwayForecast = (id, language) => {
    return APIClient.post(`/v1/${language}/products/publish/${id}`)
}

export const deleteHighwayForecast = (id, language) => {
    return APIClient.delete(`/v1/${language}/products/id/` + id, language)
}

export const cloneHighwayForecast = (id, language) => {
    return APIClient.post(`/v1/${language}/products/clone/` + id, language)
}

export const cloneAllHighwayForecasts = (language) => {
    return APIClient.post(`/v1/${language}/products/highwayforecast/cloneall`, language)
}
