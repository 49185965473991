import create from 'zustand'

import { clearImages } from 'components/Highways/HighwayDiscussions/indexedDB'

// This contains logic related to generating highway discussion screenshots. Note that some data—the actual image content—is also stored in indexedDB.
const HighwayDiscussionImageStore = create((set) => {
    return {
        showScreenshotModal: false,
        groupIDs: null,
        IDIndex: null,
        imageUrlsAndGroupIDs: null, // TS type: { url: string, groupId: string }[]
        doneGeneratingImages: false,
        setShowScreenshotModal: (showScreenshotModal) => {
            set({ showScreenshotModal })
        },
        setGroupIDs: (groupIDs) => {
            set({ groupIDs })
        },
        setIDIndex: (IDIndex) => {
            set({ IDIndex })
        },
        setImageUrlsAndGroupIDs: (imageUrlsAndGroupIDs) => {
            set({ imageUrlsAndGroupIDs })
        },
        setDoneGeneratingImages: (doneGeneratingImages) => {
            set({ doneGeneratingImages })
        },
        reinitialize: async ({ groupIDs }) => {
            await clearImages()

            set({
                showScreenshotModal: true,
                groupIDs,
                IDIndex: 0,
                imageUrlsAndGroupIDs: null,
                doneGeneratingImages: false,
            })
        },
    }
})

export const useShowScreenshotModal = () => HighwayDiscussionImageStore((state) => state.showScreenshotModal)
export const useSetShowScreenshotModal = () => HighwayDiscussionImageStore((state) => state.setShowScreenshotModal)
export const useGroupIDs = () => HighwayDiscussionImageStore((state) => state.groupIDs)
export const useSetGroupIDs = () => HighwayDiscussionImageStore((state) => state.setGroupIDs)
export const useIDIndex = () => HighwayDiscussionImageStore((state) => state.IDIndex)
export const useSetIDIndex = () => HighwayDiscussionImageStore((state) => state.setIDIndex)
export const useGroupIDCount = () => HighwayDiscussionImageStore((state) => state.groupIDs?.length || 0)
export const useImageUrlsAndGroupIDs = () => HighwayDiscussionImageStore((state) => state.imageUrlsAndGroupIDs)
export const useSetImageUrlsAndGroupIDs = () => HighwayDiscussionImageStore((state) => state.setImageUrlsAndGroupIDs)
export const useDoneGeneratingImages = () => HighwayDiscussionImageStore((state) => state.doneGeneratingImages)
export const useSetDoneGeneratingImages = () => HighwayDiscussionImageStore((state) => state.setDoneGeneratingImages)
export const useReinitialize = () => HighwayDiscussionImageStore((state) => state.reinitialize)
