import React, { useState, useEffect } from 'react'

import { DatePicker, Row, Col } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import moment from 'moment'

import { useDateFormat } from 'stores/UserStore'
import messages from 'services/intl/messageDefinitions'
import { sendErrorNotification } from 'utils/Notifications'

export const RangePicker = ({ disabled, onChange, initValues, dateRangeError }) => {
    const [startValue, setStartValue] = useState(initValues[0])
    const [endValue, setEndValue] = useState(initValues[1])
    const [issuedOpened, setIssuedOpened] = useState(false)
    const [expiryOpened, setExpiryOpened] = useState(false)
    const dateFormat = useDateFormat()
    const intl = useIntl()
    const today = moment().startOf('day')

    const disabledStartDate = (startValue) => {
        if (!startValue || !endValue) {
            return false && disabled
        }

        return today.diff(startValue) > -1
    }

    const disabledEndDate = (endValue) => {
        if (!endValue || !startValue) {
            return false && disabled
        }

        return today.diff(endValue) > -1
    }

    const showIssuedCloseError = () => {
        issuedOpened && sendErrorNotification(intl.formatMessage({ ...messages.dateSelectionError }))
    }

    const showExpireCloseError = () => {
        expiryOpened && sendErrorNotification(intl.formatMessage({ ...messages.dateSelectionError }))
    }

    useEffect(() => {
        expiryOpened && setIssuedOpened(false)
    }, [expiryOpened])

    useEffect(() => {
        issuedOpened && setExpiryOpened(false)
    }, [issuedOpened])

    const handleDateChange = (date, key) => {
        if (key === 'issued') {
            setStartValue(date)
            onChange([date.toDate(), endValue.toDate()])
        }

        if (key === 'expiry') {
            setEndValue(date)
            onChange([startValue.toDate(), date.toDate()])
        }
    }

    return (
        <>
            <Row gutter={16}>
                <Col span={12}>
                    <DatePicker
                        disabledDate={disabledStartDate}
                        showTime
                        minuteStep={15}
                        format={dateFormat + '   HH:mm'}
                        value={startValue}
                        placeholder={intl.formatMessage({ ...messages.issued })}
                        onChange={(value) => handleDateChange(value, 'issued')}
                        allowClear={false}
                        open={issuedOpened}
                        onClick={() => setIssuedOpened(!issuedOpened)}
                        onOk={() => setIssuedOpened(false)}
                        onBlur={() => showIssuedCloseError()}
                        disabled={disabled}
                        status={dateRangeError ? 'error' : ''}
                        // renderExtraFooter={() => <a>Publish Immediately</a>}
                    />
                </Col>
                <Col span={12}>
                    <DatePicker
                        disabledDate={disabledEndDate}
                        showTime
                        minuteStep={15}
                        format={dateFormat + '   HH:mm'}
                        placeholder={intl.formatMessage({ ...messages.expiry })}
                        value={endValue}
                        onChange={(value) => handleDateChange(value, 'expiry')}
                        allowClear={false}
                        open={expiryOpened}
                        onClick={() => setExpiryOpened(!expiryOpened)}
                        onOk={() => setExpiryOpened(false)}
                        onBlur={() => showExpireCloseError()}
                        disabled={disabled}
                        status={dateRangeError ? 'error' : ''}
                    />
                </Col>
            </Row>
            {dateRangeError && (
                <p style={styles.error}>
                    <FormattedMessage {...messages.invalidDateRange} />
                </p>
            )}
        </>
    )
}

const styles = {
    error: {
        color: '#a9222b',
        fontSize: '14px',
        // adding negative margin to mimic the way other error messages appear without changing the form spacing
        marginBottom: '-10px',
    },
}
