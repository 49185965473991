import moment from 'moment'

// The date formats below are only for UX, Dates sent to the API should always be in standard JS date format
export const YYYYMMDD = 'YYYY-MM-DD'
export const DDMMYYYY = 'DD-MM-YYYY'
export const MMDDYYYY = 'MM-DD-YYYY'

export const DATE_FORMATS = [YYYYMMDD, DDMMYYYY, MMDDYYYY]

export const removeNullTime = (value) => {
    const formattedValue = value.indexOf('00:00') > 1 ? value.substring(0, value.indexOf('00:00')) : value
    return formattedValue
}

export const formatDate = (date, format) => {
    return moment(date).format(format)
}
