import { CONFIDENCE_NO_RATING } from './ForecastContent'
import { HWY_HAZARD_OPTION_NONE } from './HighwayContent'

export const API_SUCCESS_CODES = [200, 201 /*202, 203, 204*/] // I believe we will only get 200 or 201 but copilot told me to include the other ones.

// Views and titles
export const LEFT_FRACTION_OPTIONS = [10, 40]
export const LEFT_FRACTION_DEFAULT = LEFT_FRACTION_OPTIONS[1]
export const ALL_VISIBLE_TABS = [
    'weak-layers',
    'avalanche-forecast',
    'regional-discussions',
    'special-products',
    'hwy-forecast',
    'hwy-discussion',
    'mitigation',
    'profile',
    'documentation',
    'archive',
]
export const ALL_PRODUCTS = [
    'weak-layers',
    'avalanche-forecast',
    'regional-discussions',
    'special-products',
    'hwy-forecast',
    'hwy-discussion',
    'mitigation',
]
export const PRODUCT_TRANSLATION_KEY_MAP = new Map([
    ['weak-layers', 'weakLayers'],
    ['avalanche-forecast', 'avalancheForecast'],
    ['regional-discussions', 'regionalDiscussions'],
    ['special-products', 'specialProducts'],
    ['hwy-forecast', 'hwyForecast'],
    ['hwy-discussion', 'hwyDiscussion'],
    ['mitigation', 'mitigation'],
])
export const PRODUCT_TYPE_ROUTING_MAP = new Map([
    ['weaklayer', 'weak-layers'],
    ['avalancheforecast', 'avalanche-forecast'],
    ['regionaldiscussion', 'regional-discussions'],
    ['specialproduct', 'special-products'],
    ['highwayforecast', 'hwy-forecast'],
    ['highwaydiscussion', 'hwy-discussion'],
    ['mitigation', 'mitigation'],
])

// get the active products from the env vars
const activeTabs = process.env.REACT_APP_VISIBLE_TABS
    ? JSON.parse(process.env.REACT_APP_VISIBLE_TABS)
    : ALL_VISIBLE_TABS
export const ACTIVE_PRODUCTS = ALL_PRODUCTS.filter((product) => activeTabs.includes(product))

// Values used throughout the products
export const DEFAULT_VALUE_DANGER_RATINGS = 'noRating'
export const DEFAULT_VALUE_CONFIDENCE = CONFIDENCE_NO_RATING
export const DEFAULT_VALUE_REVIEW = 'notRequired'
export const DEFAULT_VALUE_HWY_HAZARD = HWY_HAZARD_OPTION_NONE

// Default views for forecast content
export const WEATHER_SUMMARY_VIEW = 'weatherSummary'
export const SNOWPACK_SUMMARY_VIEW = 'snowpackSummary'
export const AVALANCHE_SUMMARY_VIEW = 'avalancheSummary'
export const DEFAULT_VIEW = WEATHER_SUMMARY_VIEW
