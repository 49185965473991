import React from 'react'

import { useIntl } from 'react-intl'

import { hexToRGB } from 'utils/Color'
import messages from 'services/intl/messageDefinitions'

export const UnassignedPolygons = ({ unassignedCount }) => {
    const intl = useIntl()
    const colour = unassignedCount > 0 ? '#ff0000' : '#008000' // red : green
    const backgroundColour = hexToRGB(colour, 0.35)
    const shadowColour = hexToRGB('#000000', 0.35)

    return (
        <div
            style={{
                ...style.positioning,
                background: backgroundColour,
                borderColor: colour,
                textShadow: `1px 1px 5px ${shadowColour}`,
            }}
        >
            {intl.formatMessage({ ...messages.unassignedPolygons }, { count: unassignedCount })}
        </div>
    )
}

const style = {
    positioning: {
        position: 'absolute',
        color: 'white',
        backdropFilter: 'blur(4px)',
        fontSize: '0.85rem',
        border: '2px solid',
        bottom: 'var(--s2)',
        left: 'var(--s-1)',
        padding: 'var(--s-5) var(--s-1)',
        borderRadius: 'var(--radius)',
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.3)',
    },
}
